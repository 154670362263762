import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import Url from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { addPatientTeamListAction, patientTeamListAction } from "./Actions";
import { AddPatientTeamTypes, ADD_PATIENT_TEAM_FAIL, ADD_PATIENT_TEAM_LOADING, ADD_PATIENT_TEAM_SUCCESS, PatientTeamTypes, PATIENT_TEAM_FAIL, PATIENT_TEAM_LOADING, PATIENT_TEAM_SUCCESS } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

// Get Patient Team List
export const getAppointmentPatientTeamList = (body: any) => {
    return function (dispatch: Dispatch<PatientTeamTypes>) {
        dispatch({
            type: PATIENT_TEAM_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl +
            `/patientapp/api/patientTeam/get_patientTeam_request`,
            { "caseId": body }, { headers })
            .then((res) => {
                let patientCasePhone = res.data.patientCasePhone;
                let ownerDetails = res.data.ownerDetails;
                let patientTeam = res.data.patientTeam;
                let message = res.data.message;
                let status = res.data.status;
                dispatch(patientTeamListAction(
                    patientCasePhone, ownerDetails, patientTeam, message, status
                ));
                dispatch({
                    type: PATIENT_TEAM_SUCCESS,
                    patientCasePhone: patientCasePhone,
                    ownerDetails: ownerDetails,
                    patientTeam: patientTeam,
                    message: message,
                    status: status
                });
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.message;
                dispatch({
                    type: PATIENT_TEAM_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};
// Add Patient Team
export const addPatientTeam = (body: any) => {
    return function (dispatch: Dispatch<AddPatientTeamTypes>) {
        dispatch({
            type: ADD_PATIENT_TEAM_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl +
            `/patientapp/api/patientTeam/add_patientTeam_request`,
            body, { headers })
            .then((res) => {
                let message = res.data.message;
                let status = res.data.status;
                dispatch(addPatientTeamListAction(message, status));
                dispatch({
                    type: ADD_PATIENT_TEAM_SUCCESS,
                    message: message,
                    status: status
                });
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.message;
                dispatch({
                    type: ADD_PATIENT_TEAM_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};