import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardContent } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { AppState } from "../../redux/store/Store";
import { providerOrgPtDetails } from "../../redux/ProviderOrganizationPatientDetailsList/API";
import { ProviderOrgListBody } from "../../redux/ProviderOrganizationPatientDetailsList/Model";
import MWExceptionList from "../../component/MWExceptionList";
import { checkReturnValue } from "../../component/Utility";
import PageLayout from "../Layout/PageLayout";
import MWPageTitle from "../../component/MWPageTitle";
import AdminLoader from "../AdminLoader";

// function useQuery() {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

export default function PatientDetailsList() {
//   let query = useQuery();
//   const history = useHistory();
//   const patientDetailsId = query.get("documentid");
  const dispatch = useDispatch();
  const { organizationid } = useParams() as {
    organizationid: string;
  };
//   const [selectedItem, setSelectedItem] = React.useState<any>(
//     ({} as any) || undefined
//   );
  // Api call from store.....
  const doctorList = useSelector(
    (state: AppState) => state.providerOrgPtDetails
  );

//   const [docViwerActive, setDocViwerActive] = React.useState(false);
//   const dialogClose = (childState: any) => {
//     setDocViwerActive(childState);
//   };

//   useEffect(() => {
//     if (patientDetailsId !== null) {
//       setDocViwerActive(true);
//     } else {
//       setDocViwerActive(false);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [patientDetailsId]);

  useEffect(() => {
    dispatch(
      providerOrgPtDetails({
        providerorgid: organizationid,
      } as ProviderOrgListBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);

  console.log("doctorList", doctorList);
  useEffect(() => {
    if (doctorList?.providerOrgPtDtRes?.patientDetailsList !== undefined) {
      let dataPointArr = doctorList?.providerOrgPtDtRes?.patientDetailsList.map(
        (element: any) => ({
          id: element.id,
          patientId: element.patient.id,
          description: element.description,
          startDate: dayjs(element.startDate).format(
            "dddd, MMMM D, YYYY h:mm A"
          ),
          date: element.startDate,
          image:
            element?.patientDocument?.document !== undefined
              ? element?.patientDocument?.document
              : "",
          imageId:
            element?.patientDocument?.id !== undefined
              ? String(element?.patientDocument?.id)
              : undefined,
          patientDetailsType:
            checkReturnValue(element?.patientDetailsType) !== "Not Set"
              ? checkReturnValue(element?.patientDetailsType?.displayValue)
              : "Not Set",
        })
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorList]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      minWidth: 100,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
        //   onClick={() => {
        //     setDocViwerActive(true);
        //     setSelectedItem(
        //       Object.assign(params.row, params.id, params.row.patientId)
        //     );
        //     history.push(
        //       `/patientdetails/${params.row.patientId}?documentid=${params.id}`
        //       // patientdetails/5578?documentid=54843
        //     );
        //   }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Details Id",
      type: "string",
      minWidth: 130,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 230,
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "patientDetailsType",
      headerName: "Patient Details Type",
      type: "string",
      minWidth: 230,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      minWidth: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
  ];
  const DoctorListTable = (
    <Card>
      <CardContent>
        {doctorList?.providerOrgPtDtRes?.patientDetailsList !== undefined &&
        doctorList?.providerOrgPtDtRes?.patientDetailsList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {doctorList.loading === true ? <AdminLoader /> : null}
      <PageLayout>
        <MWPageTitle
          title="Patient Details List"
          // enableCount={true}
          // count={listCount}
          reload={false}
          // reloadAction={reloadAppointmentList}
        />
        {DoctorListTable}
        {/* {docViwerActive ? (
        <PatientDetailsViewer
          dialogState={docViwerActive}
          dialogClose={dialogClose}
          value={selectedItem}
          // page={page}
        />
      ) : null} */}
      </PageLayout>
    </Box>
  );
}
