import React from "react";
import { Provider } from "react-redux";
import "./Translation/i18n";
import App from "./App";
import store from "./redux/store/Store";
import { CssBaseline, ThemeProvider } from "@mui/material";
// import mwTheme from "./mwtheme";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from 'react-dom/client';
import darkPalette from "./theme/palette/darkPalette";


const root = createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={darkPalette}>
    <CssBaseline enableColorScheme />
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);
reportWebVitals();