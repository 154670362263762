import {
  GET_ORGANIZATION_LIST_FAIL,
  GET_ORGANIZATION_LIST_LOADING,
  GET_ORGANIZATION_LIST_SUCCESS,
  GET_ORGANIZATION_LIST_API_MSG,
  GET_ORGANIZATION_LIST_API_RES,
  GetOriganizationListActionTypes,
} from "./ActionTypes";
import { GetOriganizationListRes } from "./Model";

export const getOriganizationListLoadingAction = (
  loading: boolean
): GetOriganizationListActionTypes => {
  return {
    type: GET_ORGANIZATION_LIST_LOADING,
    loading: loading,
  };
};

export const getOriganizationListSuccessAction = (
  getOrganizationListResponse: GetOriganizationListRes,
): GetOriganizationListActionTypes => {
  return {
    type: GET_ORGANIZATION_LIST_SUCCESS,
    payload: getOrganizationListResponse,
  };
};

export const getOriganizationListErrorAction = (
  getOrganizationListResponse: GetOriganizationListRes,
  errMsg: string,
  status: number
): GetOriganizationListActionTypes => {
  return {
    type: GET_ORGANIZATION_LIST_FAIL,
    payload: getOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getOriganizationListAPIMsgAction = (
  getOrganizationListResponse: GetOriganizationListRes,
  errMsg: string,
  status: number
): GetOriganizationListActionTypes => {
  return {
    type: GET_ORGANIZATION_LIST_API_MSG,
    payload: getOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getOriganizationListAPIResClearAction = (
  getOrganizationListResponse: GetOriganizationListRes,
  errMsg: string,
  status: number
): GetOriganizationListActionTypes => {
  return {
    type: GET_ORGANIZATION_LIST_API_RES,
    payload: getOrganizationListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
