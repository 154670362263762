import { ProviderDoctorListRes } from "./Model";

export const PROVIDER_DOCTOR_LIST_LOADING = "PROVIDER_DOCTOR_LIST_LOADING";
export const PROVIDER_DOCTOR_LIST_SUCCESS = "PROVIDER_DOCTOR_LIST_SUCCESS";
export const PROVIDER_DOCTOR_LIST_FAIL = "PROVIDER_DOCTOR_LIST_FAIL";
export const ADD_PATIENT_DATA = "ADD_PATIENT_DATA";
export const PROVIDER_DOCTOR_LIST_API_MSG = "PROVIDER_DOCTOR_LIST_API_MSG";
export const PROVIDER_DOCTOR_LIST_API_RES = "PROVIDER_DOCTOR_LIST_RES";

export interface ProviderDoctorListDetails {
  providerDoctorListResponse: ProviderDoctorListRes;
  loading: boolean;
  message: string;
  status: number;
  errRes: string;
}
export interface ProviderDoctorListLoading {
  type: typeof PROVIDER_DOCTOR_LIST_LOADING;
  loading: boolean;
}
export interface ProviderDoctorListSuccess {
  type: typeof PROVIDER_DOCTOR_LIST_SUCCESS;
  payload: ProviderDoctorListRes;
}
export interface ProviderDoctorListFail {
  type: typeof PROVIDER_DOCTOR_LIST_FAIL;
  payload: ProviderDoctorListRes;
  message: string;
  status: number;
}

export interface ProviderDoctorListUpdateAPIMsg {
  type: typeof PROVIDER_DOCTOR_LIST_API_MSG;
  payload: ProviderDoctorListRes;
  message: string;
  status: number;
}
export interface ProviderDoctorListUpdateAPIRes {
  type: typeof PROVIDER_DOCTOR_LIST_API_RES;
  payload: ProviderDoctorListRes;
  message: string;
  status: number;
}
interface ProviderDoctorListAction {
  type: typeof ADD_PATIENT_DATA;
  payload: ProviderDoctorListRes;
  errRes: string;
}

export type ProviderDoctorListActionTypes =
  | ProviderDoctorListAction
  | ProviderDoctorListLoading
  | ProviderDoctorListSuccess
  | ProviderDoctorListFail
  | ProviderDoctorListUpdateAPIMsg
  | ProviderDoctorListUpdateAPIRes;
