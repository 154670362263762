import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import {
  Box,
  Grid,
  TextField,
  DialogTitle,
  IconButton,
  Typography,
  Alert,
  Stack,
  AlertColor,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AddPatientDataPointBody } from "../../../redux/addPointData/Model";
import {
  addPatientDataPoint,
  addPatientDataPointUpdateAPIResMsg,
} from "../../../redux/addPointData/API";
import { useDispatch, useSelector } from "react-redux";
import { getPatientDataPointLists } from "../../../redux/patientDataPointList/patientDataPointListByPatientID/API";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import MWUploader from "../../../component/MWUploader";
import { UpdatePatientDataPointBody } from "../../../redux/UpdatePatientDataPoint/Model";
import {
  clearPatientDataPointEditAPIRes,
  updatePatientDataPointApi,
  updatePatientDataPointEditAPIResMsg,
} from "../../../redux/UpdatePatientDataPoint/API";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import AdminLoader from "../../AdminLoader";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  });

type Props = {
  itemId?: string | any;
  addDataPointDialogEnable?: boolean | any;
  addDataPointDialogUpdateState?: boolean | any;
  value?: any;
  page?: number | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: "8px",
  },
}))(MuiDialogActions);

export default function AddDataPointDialog({
  itemId,
  addDataPointDialogEnable,
  addDataPointDialogUpdateState,
  value,
  page,
}: Props) {
  const dispatch = useDispatch();
  const [imageId, setImageId] = React.useState("");
  const { patientid } = useParams() as {
    patientid: string;
  };

  const [dataPoint, setDataPoint] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState<Date | any>(
    new Date()
  );
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const [selectedItem, setSelectedItem] = React.useState("");
  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addDataPointDialogUpdateState(false);
    setDataPoint("");
    setSelectedDate(new Date());
    dispatch(clearPatientDataPointEditAPIRes());
  };

  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };

  const closeSummaryBanner = () => {
    dispatch(addPatientDataPointUpdateAPIResMsg());
    dispatch(updatePatientDataPointEditAPIResMsg());
  };

  const [updateDataPointSuccess, setUpdateDataPointSuccess] =
    React.useState("");
  const [alertType, setAlertType] = useState<AlertColor>("success");
  const updateDataPointSuccessDiv = updateDataPointSuccess ? (
    <Alert
      variant="outlined"
      severity={alertType}
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{updateDataPointSuccess}</Typography>
    </Alert>
  ) : null;

  const dataPointBody = {
    patientId: patientid!,
    patientDocumentId: imageId!,
    // dataType: dataType !== undefined ? dataType?.label : "",
    startDate: dayjs(String(selectedDate!)).format("YYYY-MM-DDTHH:mm:ss"),
    dataPoint: dataPoint!,
  } as AddPatientDataPointBody;
  const [editImageId, setEditImageId] = useState("");

  React.useEffect(() => {
    setSelectedItem(itemId);
    setDialogState(addDataPointDialogEnable);
  }, [itemId, addDataPointDialogEnable]);

  const addDataPointStoreVal = useSelector(
    (state: AppState) => state.addPointData
  );
  useEffect(() => {
    if (value !== undefined) {
      // if (value.description !== undefined) {
      //   let type = value.description.split(":");
      //   setDataType(
      //     dataTypeValueList.find(
      //       (o) => o.label === type[0].toUpperCase()
      //     ) as DataType
      //   );
      // } else setDataType(null);
      if (value.description !== undefined) {
        // let type = value.description.split(":");
        // setDataPoint(String(type[1]));
        setDataPoint(value.description);
      } else setDataPoint("");
      setSelectedDate(value?.date !== undefined ? value?.date : null);
      setEditImageId(value?.imageId !== undefined ? value?.imageId : "");
    } else {
      setDataPoint("");
      setSelectedDate(new Date());
      setEditImageId("");
    }
  }, [value]);
  React.useEffect(() => {
    if (addDataPointStoreVal.addPatientDataPointRes.message !== undefined) {
      setUpdateDataPointSuccess(
        addDataPointStoreVal.addPatientDataPointRes.message
      );
      dispatch(getPatientDataPointLists(selectedItem, page));
    }
    if (addDataPointStoreVal?.message !== "") {
      setAlertType("error");
      setUpdateDataPointSuccess(addDataPointStoreVal?.message);
    } else {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataPointStoreVal?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataPointStoreVal]);
  const updateDataPointVal = useSelector(
    (state: AppState) => state.editPatientDataPoint
  );
  React.useEffect(() => {
    if (updateDataPointVal?.updatePatientDataPointRes?.message !== undefined) {
      setUpdateDataPointSuccess(
        updateDataPointVal?.updatePatientDataPointRes?.message
      );
      dispatch(getPatientDataPointLists(selectedItem, page));
    }
    if (updateDataPointVal?.message !== "") {
      setAlertType("error");
      setUpdateDataPointSuccess(updateDataPointVal?.message);
    } else {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataPointStoreVal?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataPointVal]);
  const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

  let editImgId = imageId !== undefined ? imageId! : editImageId;
  const editDataPointBody = {
    patientDetailsId: String(value?.id!),
    // dataType: dataType !== undefined ? dataType?.label : "",
    description: dataPoint!,
    startDate: dayjs(String(selectedDate)).format("YYYY-MM-DDTHH:mm:ss"),
    patientDocumentId: String(editImgId),
  } as UpdatePatientDataPointBody;

  const submitPatientData = () => {
      value?.id === undefined
        ? dispatch(addPatientDataPoint(dataPointBody))
        : dispatch(updatePatientDataPointApi(editDataPointBody));
  };
  const delImgAction = (passeVal: boolean) => {
    console.log("passeVal", passeVal);
  };
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };
  return (
    <Dialog
      onClose={() => null}
      aria-labelledby="customized-dialog-title"
      open={dialogState}
      PaperProps={{
        style: {
          minHeight: "40vh",
          minWidth: "40vw",
          overflow: "hidden",
        },
      }}
    >
      <Box>
        {updateDataPointVal?.loading || addDataPointStoreVal?.loading ? (
          <AdminLoader />
        ) : null}
      </Box>
      <DialogTitle id="customized-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4">
              {value?.id !== undefined ? "Update " : "Add "}Patient Observation
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "grey",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <TextField
                fullWidth
                autoFocus
                id="data"
                label="Data"
                variant="outlined"
                value={dataPoint}
                onChange={(e) => {
                  setDataPoint(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      size="small"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MWUploader
              documentType="patient-image"
              criteria="Upload a file less than 10 mb."
              updatedDataState={updateDataStateFromChild}
              title={"Upload Patient Observation"}
              imageUrl={value?.image}
              deleteImgAction={delImgAction}
              previewImageData={viewPreviewImageData}
            />
          </Grid>
        </Grid>
        <Box>{updateDataPointSuccessDiv}</Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={submitPatientData}
          variant="contained"
          color="primary"
          style={{ fontSize: "12px" }}
          disabled={
            imageUploadRes?.loading ||
            addDataPointStoreVal?.loading ||
            updateDataPointVal?.loading ||
            checkImgUpload
          }
        >
          {value?.id !== undefined ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
