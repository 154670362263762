import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  getPatientImportConfigListActionTypes,
  GET_PATIENT_IMPORT_CONFIG_LIST_FAIL,
  GET_PATIENT_IMPORT_CONFIG_LIST_LOADING,
  GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
} from "./ActionTypes";
import {
  PatientImportConfigListDetails,
  PatientImportConfigListValue,
} from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

export const getPatientInfortConfigListApi = (
  body: PatientImportConfigListDetails
) => {
  return function (dispatch: Dispatch<getPatientImportConfigListActionTypes>) {
    dispatch({
      type: GET_PATIENT_IMPORT_CONFIG_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/patientapp/api/dataimport/get_patient_import_config_list`,
        body,
        {
          headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PATIENT_IMPORT_CONFIG_LIST_FAIL,
          payload: {} as PatientImportConfigListValue,
          errRes: error.response.data.message,
        });
      });
  };
};
