import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { AppState } from "../redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AdminLoader from "./AdminLoader";
import {
  answerQuestion,
  getQuestionListValue,
  setVerifyValue,
} from "../redux/QuestionAnswer/ApiCall";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import PageLayout from "./Layout/PageLayout";

export default function QuestionAnswer() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionListValue());
  }, [dispatch]);

  // const reloadQuestionlist = () => {
  //   dispatch(getQuestionListValue());
  // };
  const questionverify = (selectedResponse: any) => {
    const verifyBody = {
      questionId: selectedResponse,
      questionStatus: "verified",
    };
    dispatch(setVerifyValue(verifyBody));
    dispatch(getQuestionListValue());
    setVerifyQuestionSuccessToastActive(
      (verifyQuestionSuccessToastActive) => !verifyQuestionSuccessToastActive
    );
    setVerifyQuestionErrorToastActive(
      (verifyQuestionErrorToastActive) => !verifyQuestionErrorToastActive
    );
  };
  const closeQuestionSuccess = () => {
    setAnsSuccess("");
  };

  const [answerModalActive, setAnswerModalActive] = useState(false);
  const [questionTextShow, setQuestionTextShow] = useState(false);
  const [selectedQuestionID, setSelectedQuestionID] = useState(false);
  const [selectedCaseID, setSelectedCaseID] = useState(false);

  const [ansSuccess, setAnsSuccess] = useState("");
  const ansSuccessDiv = ansSuccess ? (
    <Alert onClick={closeQuestionSuccess} severity="success">
      <Typography>{ansSuccess}</Typography>
    </Alert>
  ) : null;
  // popup open function..........
  function handleToggle(
    selectedResponse: any,
    questionText: any,
    selectedCaseID: any
  ) {
    setAnswerModalActive((answerModalActive) => !answerModalActive);
    setQuestionTextShow(questionText);
    setSelectedQuestionID(selectedResponse);
    setSelectedCaseID(selectedCaseID);
    setAnsSuccess("");
    setAnswerDescription("");
  }

  const closeModal = () => {
    setAnswerModalActive((answerModalActive) => !answerModalActive);
  };
  const [answerDescription, setAnswerDescription] = useState("");
  const handleQuestionDescriptionChange = useCallback((newValue: any) => {
    setAnswerDescription(newValue);
  }, []);

  const [questionDescriptionError] = useState("");
  const questionDescriptionErrordiv = questionDescriptionError ? (
    <Typography variant="subtitle1" gutterBottom color="warning">
      {questionDescriptionError}
    </Typography>
  ) : (
    ""
  );

  const AddQuestionAnswerMessage = useSelector(
    (state: AppState) => state.adminQuestionAnswerValue.payload.message
  );
  const AddQuestionAnswerLoadingvalue = useSelector(
    (state: AppState) => state.adminQuestionAnswerValue.loading
  );
  const AddQuestionAnswerStatus = useSelector(
    (state: AppState) => state.adminQuestionAnswerValue.payload.status
  );

  // Add Question Answer success toast
  const [addQuestionAnswerToastActive, setAddQuestionAnswerToastActive] =
    useState(false);
  const toggleSuccessAddWuestionAnswerActive = () => {
    setAddQuestionAnswerToastActive(
      (addQuestionAnswerToastActive) => !addQuestionAnswerToastActive
    );
    dispatch(getQuestionListValue());
  };

  const toastQuestionAnswerSuccessMarkup = addQuestionAnswerToastActive ? (
    <Snackbar
      open={addQuestionAnswerToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessAddWuestionAnswerActive}
    >
      <Alert
        onClose={toggleSuccessAddWuestionAnswerActive}
        severity="success"
        sx={{ width: "100%" }}
      >
        {AddQuestionAnswerMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Add Question Answer error toast
  const [
    addQuestionAnswerErrorToastActive,
    setAddQuestionAnswerErrorToastActive,
  ] = useState(false);
  const handleAddQuestionAnswerErrorToastChange = () => {
    setAddQuestionAnswerErrorToastActive(
      (addQuestionAnswerErrorToastActive) => !addQuestionAnswerErrorToastActive
    );
    dispatch(getQuestionListValue());
  };
  const toastAddQuestionAnswerErrorMarkup =
    addQuestionAnswerErrorToastActive ? (
      <Snackbar
        open={addQuestionAnswerErrorToastActive}
        autoHideDuration={6000}
        onClose={handleAddQuestionAnswerErrorToastChange}
      >
        <Alert
          onClose={handleAddQuestionAnswerErrorToastChange}
          severity="error"
          sx={{ width: "100%" }}
        >
          {AddQuestionAnswerMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // CAll Verify Question Store
  const verifyQuestionAnswerMessage = useSelector(
    (state: AppState) => state.verifyQuestionValue.payload.message
  );
  const verifyQuestionAnswerLoadingvalue = useSelector(
    (state: AppState) => state.verifyQuestionValue.loading
  );
  const verifyQuestionAnswerStatus = useSelector(
    (state: AppState) => state.verifyQuestionValue.payload.status
  );

  // Add Question Answer success toast
  const [
    verifyQuestionSuccessToastActive,
    setVerifyQuestionSuccessToastActive,
  ] = useState(false);
  const toggleSuccessVerifyQuestionActive = () => {
    setVerifyQuestionSuccessToastActive(
      (verifyQuestionSuccessToastActive) => !verifyQuestionSuccessToastActive
    );
    dispatch(getQuestionListValue());
  };

  const toastVerifyQuestionSuccessMarkup = verifyQuestionSuccessToastActive ? (
    <Snackbar
      open={verifyQuestionSuccessToastActive}
      autoHideDuration={6000}
      onClose={toggleSuccessVerifyQuestionActive}
    >
      <Alert
        onClose={toggleSuccessVerifyQuestionActive}
        severity="success"
        sx={{ width: "100%" }}
      >
        {verifyQuestionAnswerMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Add Question Answer error toast
  const [verifyQuestionErrorToastActive, setVerifyQuestionErrorToastActive] =
    useState(false);

  const handleVerifyQuestionErrorToastChange = () => {
    setVerifyQuestionErrorToastActive(
      (verifyQuestionErrorToastActive) => !verifyQuestionErrorToastActive
    );
    dispatch(getQuestionListValue());
  };
  const toastVerifyQuestionErrorMarkup = addQuestionAnswerErrorToastActive ? (
    <Snackbar
      open={verifyQuestionErrorToastActive}
      autoHideDuration={6000}
      onClose={handleVerifyQuestionErrorToastChange}
    >
      <Alert
        onClose={handleVerifyQuestionErrorToastChange}
        severity="error"
        sx={{ width: "100%" }}
      >
        {verifyQuestionAnswerMessage}
      </Alert>
    </Snackbar>
  ) : null;
  const AdminQuestionList = useSelector(
    (state: AppState) => state.questionListValue
  );
  const questionListValues = AdminQuestionList.questionListValue;
  const columns: GridColDef[] = [
    {
      field: "questionText",
      headerName: "Question Text",
      flex: 1,
    },
    { field: "questionStatus", headerName: "Question Status", flex: 1 },

    {
      field: "selectedResponse",
      headerName: "Verify",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => {
            questionverify(params.row.id);
          }}
        >
          Verify
        </Button>
      ),
    },
    {
      field: "selectedCaseID",
      headerName: "Answer",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={() => {
            handleToggle(
              params.row.id,
              params.row.questionText,
              params.row.caseId
            );
          }}
        >
          Answer
        </Button>
      ),
    },
  ];
  // question answer body
  const questionBody = {
    questionId: selectedQuestionID,
    type: "admin",
    caseId: selectedCaseID,
    answerText: answerDescription,
  };
  // call submit answer api
  const answerSubmit = () => {
    dispatch(answerQuestion(questionBody));
    setAnswerModalActive((answerModalActive) => !answerModalActive);
    dispatch(getQuestionListValue());
    setAddQuestionAnswerToastActive(
      (addQuestionAnswerToastActive) => !addQuestionAnswerToastActive
    );
    setAddQuestionAnswerErrorToastActive(
      (addQuestionAnswerErrorToastActive) => !addQuestionAnswerErrorToastActive
    );
  };
  // Question Table
  const questionTableBody = (
    <Card>
      <CardContent>
        {questionListValues !== undefined ? (
          questionListValues?.length > 0 ? (
            <DataGrid
              rows={questionListValues}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const answerModalBody = (
    <Dialog
      open={answerModalActive}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h4">Add Answer</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            Question:&nbsp;&nbsp;{questionTextShow}
          </Typography>
        </DialogContentText>
        <Box>
          <TextField
            label="Answer"
            placeholder="Please enter your answer here"
            value={answerDescription}
            onChange={(e) => {
              handleQuestionDescriptionChange(e.target.value);
            }}
            id="questionDescriptionID"
            fullWidth
            multiline
            rows={4}
          />
          <Typography>{questionDescriptionErrordiv}</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <Box>{ansSuccessDiv}</Box>
      <DialogActions>
        <Button
          variant="contained"
          onClick={answerSubmit}
          sx={{ textTransform: "none" }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      {AdminQuestionList.loading ||
      AddQuestionAnswerLoadingvalue ||
      verifyQuestionAnswerLoadingvalue ? (
        <Stack>{loadingMarkup}</Stack>
      ) : null}
      <PageLayout>
        <MWPageTitle title={"Question List"} />
        {questionTableBody}
        {answerModalBody}
        {AddQuestionAnswerStatus! === 1 ? toastQuestionAnswerSuccessMarkup : ""}
        {AddQuestionAnswerStatus! === 0
          ? toastAddQuestionAnswerErrorMarkup
          : ""}
        {verifyQuestionAnswerStatus! === 1
          ? toastVerifyQuestionSuccessMarkup
          : ""}
        {verifyQuestionAnswerStatus! === 0
          ? toastVerifyQuestionErrorMarkup
          : ""}
      </PageLayout>
    </Box>
  );
}
