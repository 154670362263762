import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { AddDemographicsDetailsActionTypes } from "./ActionTypes";
import { AddDemographicsDetailsRes, AddDemographicsDetailsReq } from "./Model";
import {
  addPatientDemographicsDetailsAPIResClearAction,
  addPatientDemographicsDetailsErrorAction,
  addPatientDemographicsDetailsLoadingAction,
  addPatientDemographicsDetailsSuccessAction,
  addPatientDemographicsDetailsUpdateAPIMsgAction,
} from "./Action";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as AddDemographicsDetailsRes;
export const addDemographicsDetails = (payload: AddDemographicsDetailsReq) => {
  return function (dispatch: Dispatch<AddDemographicsDetailsActionTypes>) {
    dispatch(addPatientDemographicsDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl + `/patientapp/api/patient/add_patient`,
        payload,
        {
          headers,
        }
      )
      .then((res) => {
        apiRes =
          res.data !== undefined ? res.data : ({} as AddDemographicsDetailsRes);
        dispatch(addPatientDemographicsDetailsLoadingAction(false));
        dispatch(
          addPatientDemographicsDetailsSuccessAction(
            res.data !== undefined
              ? res.data
              : ({} as AddDemographicsDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "New patient has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addPatientDemographicsDetailsLoadingAction(false));
        dispatch(
          addPatientDemographicsDetailsErrorAction(
            {} as AddDemographicsDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateAddDemographicsDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDemographicsDetailsActionTypes>) {
    dispatch(
      addPatientDemographicsDetailsUpdateAPIMsgAction(
        apiRes as AddDemographicsDetailsRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAddDemographicsDetailsAPIRes = () => {
  return function (dispatch: Dispatch<AddDemographicsDetailsActionTypes>) {
    dispatch(
      addPatientDemographicsDetailsAPIResClearAction(
        {} as AddDemographicsDetailsRes,
        "",
        "",
        0
      )
    );
  };
};
