import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { CaseList } from "../../redux/interfaces/Model";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ActivePatientCaseNotes from "../Notes/PatientCaseNotes/ActivePatientCaseNotes";
import InActivePatientCaseNotes from "../Notes/PatientCaseNotes/InActivePatientCaseNotes";
import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import MWSnackbar from "../../component/MWSnackbar";
import { updatePatientCaseDetails } from "../../redux/Patient/AppointmentSelectPatient/updatePatientCaseDetails/API";
import { UpdatePatientCaseStatusBody } from "../../redux/Patient/AppointmentSelectPatient/updatePatientCaseDetails/Model";
import { CaseStatusList } from "../../redux/Patient/AppointmentSelectPatient/caseStatusList/Model";
import { getCaseStatusList } from "../../redux/Patient/AppointmentSelectPatient/caseStatusList/API";
import { GetPatientCaseDetailsByIdBody } from "../../redux/Patient/AppointmentSelectPatient/getPatientCseDetailsById/Model";
import { getPatientCaseStatus } from "../../redux/Patient/AppointmentSelectPatient/getPatientCseDetailsById/API";
import { getAdminNoteListByPatient } from "../../redux/Notes/GetNotesListByPateint/API";
import { GetAdminNoteListByPatientBody } from "../../redux/Notes/GetNotesListByPateint/Model";
import ReplayIcon from "@mui/icons-material/Replay";

type Props = {
  appointmentSelectPatientLoader?: boolean | any;
};
export default function AppointmentSelectPatient({
  appointmentSelectPatientLoader,
}: Props) {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [caseStatusList, setCaseStatusList] = useState<CaseStatusList[]>(
    [] as CaseStatusList[]
  );
  const getPatientCaseStatusBody = {
    caseId: caseid,
  } as GetPatientCaseDetailsByIdBody;

  const [selectedCaseStatus, setSelectedCaseStatus] = useState<any | null>(
    null
  );
  const handlePatientNameChange = (_e: any, dropdownDayName: any) => {
    const updatePatientCaseDetailsBody = {
      statusId: String(dropdownDayName.value),
      caseId: caseid,
    } as UpdatePatientCaseStatusBody;
    dispatch(updatePatientCaseDetails(updatePatientCaseDetailsBody));
    setSelectedCaseStatus(dropdownDayName);
  };

  // Patient name show
  const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
  const caseDetails: CaseList = _.filter(PatientListFromAPI.caseValue, [
    "caseId",
    Number(caseid),
  ])[0];
  const patientName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName
  );

  // Case Status List Value
  const caseStatusListValue = useSelector(
    (state: AppState) => state.caseStatusList
  );
  useEffect(() => {
    appointmentSelectPatientLoader(caseStatusListValue?.loading);
    if (
      caseStatusListValue?.getCaseStatusListRes?.caseStatusList !== undefined
    ) {
      setCaseStatusList(
        caseStatusListValue?.getCaseStatusListRes?.caseStatusList
      );
    } else setCaseStatusList([] as CaseStatusList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStatusListValue]);

  const [apiMsg, setAPIMsg] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");

  // Update Case Status store value
  const updateCaseStatusValue = useSelector(
    (state: AppState) => state.addStatus
  );
  useEffect(() => {
    appointmentSelectPatientLoader(updateCaseStatusValue?.loading);
    if (updateCaseStatusValue?.successMsg !== "") {
      setSnackBarType("success");
      setAPIMsg(updateCaseStatusValue?.successMsg);
      dispatch(getPatientCaseStatus(getPatientCaseStatusBody));
    }
    if (updateCaseStatusValue?.errorMsg !== "") {
      setSnackBarType("error");
      setAPIMsg(updateCaseStatusValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCaseStatusValue]);

  // SelectedCase Status Value
  const selectedCaseStatusValue = useSelector(
    (state: AppState) => state.getPatientCaseStatus
  );

  useEffect(() => {
    appointmentSelectPatientLoader(
      selectedCaseStatusValue?.loading || caseStatusListValue?.loading
    );

    if (
      caseStatusListValue?.getCaseStatusListRes?.caseStatusList !== undefined &&
      selectedCaseStatusValue?.getPatientCaseDetailsByIdRes?.patientCaseDetail
        ?.caseStatus?.value !== undefined
    ) {
      setSelectedCaseStatus(
        caseStatusListValue.getCaseStatusListRes.caseStatusList.find(
          (o) =>
            o.value ===
            selectedCaseStatusValue?.getPatientCaseDetailsByIdRes
              ?.patientCaseDetail?.caseStatus?.value!
        )
      );
    } else setSelectedCaseStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStatusListValue, selectedCaseStatusValue]);

  useEffect(() => {
    // Do api call here on page load....
    window.scrollTo(0, 0);
    dispatch(getPatientCaseStatus(getPatientCaseStatusBody));
    if (caseStatusList.length === 0) {
      dispatch(getCaseStatusList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const symptomsAddInfo = (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="h6" gutterBottom>
              Patient Name:
            </Typography>
            <Typography variant="subtitle1" gutterBottom>{`${
              caseDetails === undefined
                ? `${patientName === undefined ? "" : patientName}`
                : `${patientName === undefined ? "" : patientName}`
            }`}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="h6">Update Case Status:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Autocomplete
                  disableClearable
                  value={selectedCaseStatus}
                  options={caseStatusList}
                  onChange={(event: any, newValue: any) => {
                    handlePatientNameChange(event, newValue);
                  }}
                  getOptionLabel={(option) => `${option.label}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Case Status"
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const [activeNoteLoader, setActiveNoteLoader] = useState(false);
  const [inactiveNoteLoader, setInactiveNoteLoader] = useState(false);
  appointmentSelectPatientLoader(
    activeNoteLoader === true || inactiveNoteLoader === true ? true : false
  );

  const [selected, setSelected] = useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };
  const getDrActiveNoteListBody = {
    patientId: patientid,
    caseId: caseid,
    isActive: true,
  } as GetAdminNoteListByPatientBody;
  const getDrInactiveNoteListBody = {
    patientId: patientid,
    caseId: caseid,
    isActive: false,
  } as GetAdminNoteListByPatientBody;
  const [addNoteDialog, seAddNoteDialog] = useState(false);
  const dialogCloseAction = (passedVal: boolean) => {
    seAddNoteDialog(passedVal);
  };

  const noteList = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h5">Patient Notes</Typography>
            </Grid>
            <Grid item>
              {selected === "1" ? (
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => seAddNoteDialog(true)}
                >
                  Add Note
                </Button>
              ) : null}
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <TabContext value={selected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab
                label="Active Note List"
                icon={
                  <ReplayIcon
                    onClick={() =>
                      dispatch(
                        getAdminNoteListByPatient(getDrActiveNoteListBody)
                      )
                    }
                  />
                }
                iconPosition="end"
                value="1"
              />
              <Tab
                label="Inactive Note List"
                icon={
                  <ReplayIcon
                    onClick={() =>
                      dispatch(
                        getAdminNoteListByPatient(getDrInactiveNoteListBody)
                      )
                    }
                  />
                }
                iconPosition="end"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            {
              <ActivePatientCaseNotes
                dialogStatus={addNoteDialog}
                activeNoteLoader={setActiveNoteLoader}
                dialogUpdateState={dialogCloseAction}
              />
            }
          </TabPanel>
          <TabPanel value="2">
            {
              <InActivePatientCaseNotes
                inactiveNoteLoader={setInactiveNoteLoader}
              />
            }
          </TabPanel>
        </TabContext>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        {symptomsAddInfo}
        {noteList}
      </Stack>
      <MWSnackbar msg={apiMsg} type={snackBarType} />
    </Box>
  );
}
