import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AssessmentIcon from '@mui/icons-material/Assessment';

export const DrawerList = [
  {
    name: "Back to Home",
    path: "/dashboard",
    icon: <KeyboardBackspaceIcon />,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    name: "Healthcamp List",
    path: "/healthcamplist",
    icon: <FavoriteIcon />,
  },
  {
    name: "Doctor List",
    path: "/doctors",
    icon: <PersonAddIcon />,
  },
  {
    name: "Patient List",
    path: "/patients",
    icon: <PeopleAltIcon />,
  },
  {
    name: "Appointment List",
    path: "/appointmentlist",
    icon: <DisplaySettingsIcon />,
  },
  // {
  //   name: "Provider Organization List",
  //   path: "/providerorganization",
  //   icon: <PersonIcon />,
  // },
  {
    name: "Lab Report List",
    path: "/labreportlist",
    icon: <AssessmentIcon />,
  },
  {
    name: "User Profile",
    path: "/admindetails",
    icon: <AccountCircleIcon />,
  },
  {
    name: "Sign out",
    path: "/dashboard",
    icon: <LogoutIcon />,
  },
];
