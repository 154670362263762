import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addDrNotes,
  changeDrNoteStatus,
  getDoctorNoteList,
} from "../../../redux/Notes/ApiCall";
import { AppState } from "../../../redux/store/Store";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Alert,
  Snackbar,
} from "@mui/material";
import MWExceptionList from "../../../component/MWExceptionList";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

export default function InActivePatientCaseNotes(props: any) {
  const dispatch = useDispatch();

  const { inactiveNoteLoader } = props;

  const [notes, setNotes] = useState("");
  const handleNotesChange = useCallback((event: any) => {
    setNotes(event.target.value);
  }, []);

  const [notesError, setNotesError] = useState("");
  const notesErrorDiv = notesError ? <Typography>{notesError}</Typography> : "";

  const closeNotesSubmitSuccess = () => {
    setNotesSubmitSuccess("");
  };

  const [notesSubmitSuccess, setNotesSubmitSuccess] = useState("");
  const notesSubmitSuccessDiv = notesSubmitSuccess ? (
    <Alert onClick={closeNotesSubmitSuccess} severity="success">
      <Typography>{notesSubmitSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const closeNotesSubmitError = () => {
    setNotesSubmitError("");
  };

  const [notesSubmitError, setNotesSubmitError] = useState("");
  const notesSubmitErrorDiv = notesSubmitError ? (
    <Alert onClick={closeNotesSubmitError} severity="error">
      <Typography>{notesSubmitError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [notesModalActive, setNotesModalActive] = useState(false);
  const handleNotesModalToggle = () => {
    setNotesModalActive((notesModalActive) => !notesModalActive);
    setNotes("");
    setNotesError("");
    setNotesSubmitSuccess("");
    setNotesSubmitError("");
  };
  // Call Notes Store
  const AddDrNoteLoadingvalue = useSelector(
    (state: AppState) => state.addDrNote.loading
  );
  const AddChiefComplaintStatus = useSelector(
    (state: AppState) => state.addDrNote.response.status
  );

  // Add Chief complaint success toast
  const [
    successAddChiefComplaintToastActive,
    setSuccessAddChiefComplaintToastActive,
  ] = useState(false);
  const toggleSuccessAddChiefComplaintActive = () => {
    setSuccessAddChiefComplaintToastActive(
      (successAddChiefComplaintToastActive) =>
        !successAddChiefComplaintToastActive
    );
    dispatch(getDoctorNoteList(getDrNoteListBody));
  };

  const toastAddChiefComplaintSuccessMarkup =
    successAddChiefComplaintToastActive ? (
      // <Toast
      //   content="Note added successfully"
      //   onDismiss={toggleSuccessAddChiefComplaintActive}
      //   duration={1000}
      // />
      <Snackbar
        open={successAddChiefComplaintToastActive}
        autoHideDuration={6000}
        onClose={toggleSuccessAddChiefComplaintActive}
      >
        <Alert
          severity="success"
          onClose={toggleSuccessAddChiefComplaintActive}
          sx={{ width: "100%" }}
        >
          Note added successfully
        </Alert>
      </Snackbar>
    ) : null;

  // Add Chief complaint error toast
  const [
    addChiefComplaintErrorToastActive,
    setAddChiefComplaintErrorToastActive,
  ] = useState(false);
  const handleAddChiefComplaintErrorToastChange = () => {
    setAddChiefComplaintErrorToastActive(
      (addChiefComplaintErrorToastActive) => !addChiefComplaintErrorToastActive
    );
  };
  const toastAddChiefComplaintErrorMarkup =
    addChiefComplaintErrorToastActive ? (
      // <Toast
      //   content="Something went wrong"
      //   error
      //   onDismiss={handleAddChiefComplaintErrorToastChange}
      // />
      <Snackbar
        open={addChiefComplaintErrorToastActive}
        autoHideDuration={6000}
        onClose={handleAddChiefComplaintErrorToastChange}
      >
        <Alert
          severity="error"
          onClose={handleAddChiefComplaintErrorToastChange}
          sx={{ width: "100%" }}
        >
          Something went wrong
        </Alert>
      </Snackbar>
    ) : null;

  const { profileId } = useParams() as {
    profileId: string;
  };

  // Get Patient Note List Body
  const getDrNoteListBody = {
    patientId: profileId,
    isActive: false,
  };
  // Get Patient List API
  const getPatientNotes = () => dispatch(getDoctorNoteList(getDrNoteListBody));

  const changeDrNoteStatusLoadingvalue = useSelector(
    (state: AppState) => state.changeDrNoteStatusValue.loading
  );
  const changeDrNoteStatusStatus = useSelector(
    (state: AppState) => state.changeDrNoteStatusValue.response.status
  );
  console.log("ActiveNoteStatusChange===", changeDrNoteStatusStatus);
  const changeDrNoteStatusMessage = useSelector(
    (state: AppState) => state.changeDrNoteStatusValue.response.message
  );

  // Add Chief complaint success toast
  const [
    successChangeDrNoteStatusToastActive,
    setSuccessChangeDrNoteStatusToastActive,
  ] = useState(false);
  const toggleSuccessChangeDrNoteStatusActive = () => {
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) =>
        !successChangeDrNoteStatusToastActive
    );
    dispatch(getDoctorNoteList(getDrNoteListBody));
  };

  const toastChangeDrNoteStatusSuccessMarkup =
    successChangeDrNoteStatusToastActive ? (
      // <Toast
      //   content={changeDrNoteStatusMessage}
      //   onDismiss={toggleSuccessChangeDrNoteStatusActive}
      //   duration={1000}
      // />
      <Snackbar
        open={successChangeDrNoteStatusToastActive}
        autoHideDuration={6000}
        onClose={toggleSuccessChangeDrNoteStatusActive}
      >
        <Alert
          severity="success"
          onClose={toggleSuccessChangeDrNoteStatusActive}
          sx={{ width: "100%" }}
        >
          {changeDrNoteStatusMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // Add Chief complaint error toast
  const [changeDrNoteStatusToastActive, setErrorChangeDrNoteStatusToastActive] =
    useState(false);
  const handleErrorChangeDrNoteStatusToastChange = () => {
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive
    );
  };
  const toastChangeDrNoteStatusErrorMarkup = changeDrNoteStatusToastActive ? (
    // <Toast
    //   content={changeDrNoteStatusMessage}
    //   error
    //   onDismiss={handleErrorChangeDrNoteStatusToastChange}
    // />
    <Snackbar
      open={changeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={handleErrorChangeDrNoteStatusToastChange}
    >
      <Alert
        severity="error"
        onClose={handleErrorChangeDrNoteStatusToastChange}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Call Patient Note List Store
  const ptNoteListFromAPI = useSelector(
    (state: AppState) => state.drNoteListValue
  );
  const ptNoteValues = ptNoteListFromAPI.getAdminNoteListByPatientRes.noteList;
  const noteListConfig: any = [];
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  if (ptNoteValues !== undefined) {
    for (var i = 0; i < ptNoteValues.length; i++) {
      let selectedResponse = ptNoteValues[i].id;
      let note = ptNoteValues[i].note === "" ? "" : ptNoteValues[i].note;
      let createdDate =
        ptNoteValues[i].createdDate === null
          ? null
          : new Date(ptNoteValues[i].createdDate).toLocaleDateString(
              "en-US",
              DATE_OPTIONS
            );
      let createBy =
        ptNoteValues[i].createdBy === null
          ? ""
          : ptNoteValues[i].createdBy.user.first_name +
            " " +
            ptNoteValues[i].createdBy.user.last_name;
      let modifiedDate =
        ptNoteValues[i].modifiedDate === null
          ? null
          : new Date(ptNoteValues[i].modifiedDate).toLocaleDateString(
              "en-US",
              DATE_OPTIONS
            );
      let modifyBy =
        ptNoteValues[i].modifiedBy === null
          ? ""
          : ptNoteValues[i].modifiedBy.user.first_name +
            " " +
            ptNoteValues[i].modifiedBy.user.last_name;
      noteListConfig.push([
        note,
        createdDate,
        createBy,
        <Button
          variant="outlined"
          onClick={() => toggleNoteStatus(selectedResponse)}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>,
        modifiedDate,
        modifyBy,
      ]);
    }
  }

  const noteListValue: any[] = noteListConfig;
  // Add Patient Note Body
  const addNoteBody = {
    patientId: profileId,
    note: notes,
  };
  // Add notes with patientId details
  const addNotes = () => {
    if (notes === "") {
      setNotesError("Please add notes");
    } else {
      setNotesSubmitError("");
      dispatch(addDrNotes(addNoteBody));
      setNotesModalActive((notesModalActive) => !notesModalActive);
      setSuccessAddChiefComplaintToastActive(
        (successAddChiefComplaintToastActive) =>
          !successAddChiefComplaintToastActive
      );
      setAddChiefComplaintErrorToastActive(
        (addChiefComplaintErrorToastActive) =>
          !addChiefComplaintErrorToastActive
      );
    }
  };

  // Notes Status change API
  function toggleNoteStatus(response: any) {
    dispatch(changeDrNoteStatus(response));
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) =>
        !successChangeDrNoteStatusToastActive
    );
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive
    );
  }

  useEffect(() => {
    // inActiveNotes();
    dispatch(getDoctorNoteList(getDrNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Active Notes List
  const getInActiveNoteList = (
    <Card>
      <Grid>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>{"Inactive Notes List"}</Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    <Button variant="outlined" onClick={getPatientNotes}>
                      Reload
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleNotesModalToggle}
                    >
                      Add Note
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          <Box pl={3}>
            {noteListValue && noteListValue.length ? (
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Note</TableCell>
                      <TableCell>Create Date</TableCell>
                      <TableCell>Create By</TableCell>
                      <TableCell>Modify Status</TableCell>
                      <TableCell>Modify By</TableCell>
                      <TableCell>Modify Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {noteListValue.map((row: any) => (
                      <TableRow
                        key={row.description}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{row[0]}</TableCell>
                        <TableCell align="left">{row[1]}</TableCell>
                        <TableCell align="left">{row[2]}</TableCell>
                        <TableCell align="left">{row[3]}</TableCell>
                        <TableCell align="left">{row[4]}</TableCell>
                        <TableCell align="left">{row[5]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>
                <MWExceptionList />
              </Typography>
            )}
          </Box>
        </CardContent>
      </Grid>
    </Card>
  );
  //Dr. Notes Modal
  const drNotesModalMarkup = (
    <Dialog open={notesModalActive} onClose={handleNotesModalToggle} fullWidth>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h6">Add Note</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleNotesModalToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              value={notes}
              label="Doctor Notes"
              placeholder="Please provide notes"
              id="notesID"
              onChange={handleNotesChange}
              fullWidth
            />
            <Typography sx={{ color: "#c62828" }}>{notesErrorDiv}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Box pt={1}>{notesSubmitSuccessDiv}</Box>
      <Box pt={1}>{notesSubmitErrorDiv}</Box>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button variant="outlined" onClick={handleNotesModalToggle}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={addNotes}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        {ptNoteListFromAPI.loading === true ||
        AddDrNoteLoadingvalue === true ||
        changeDrNoteStatusLoadingvalue === true
          ? inactiveNoteLoader(true)
          : inactiveNoteLoader(false)}
        {getInActiveNoteList}
        {drNotesModalMarkup}
        {AddChiefComplaintStatus! === 1
          ? toastAddChiefComplaintSuccessMarkup
          : ""}
        {AddChiefComplaintStatus! === 0
          ? toastAddChiefComplaintErrorMarkup
          : ""}
        {changeDrNoteStatusStatus! === 1
          ? toastChangeDrNoteStatusSuccessMarkup
          : ""}
        {changeDrNoteStatusStatus! === 0
          ? toastChangeDrNoteStatusErrorMarkup
          : ""}
      </Container>
    </Box>
  );
}
