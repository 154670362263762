import { PageCount, GetOrganizationPatientListDetails } from "./Model";

// Patient Demographics Loading State
export const PATIENT_LIST_BY_ORGANIZATION_ID_LOADING =
  "PATIENT_LIST_BY_ORGANIZATION_ID_LOADING";
export const PATIENT_LIST_BY_ORGANIZATION_ID_SUCCESS =
  "PATIENT_LIST_BY_ORGANIZATION_ID_SUCCESS";
export const PATIENT_LIST_BY_ORGANIZATION_ID_FAIL =
  "PATIENT_LIST_BY_ORGANIZATION_ID_FAIL";
export const PATIENT_LIST_BY_ORGANIZATION_ID_DATA =
  "PATIENT_LIST_BY_ORGANIZATION_ID_DATA";
export const ORGANIZATION_PATIENT_LIST_API_MSG = "ORGANIZATION_PATIENT_LIST_API_MSG";
export const ORGANIZATION_PATIENT_LIST_API_RES = "ORGANIZATION_PATIENT_LIST_API_RES";

export interface PatientListOfOrganizationIdDetailsState {
  PatientListOfOrganizationIdRes: GetOrganizationPatientListDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface PatientListOfOrganizationIdDetailsLoading {
  type: typeof PATIENT_LIST_BY_ORGANIZATION_ID_LOADING;
  loading: boolean;
}
export interface PatientListOfOrganizationIdDetailsSuccess {
  type: typeof PATIENT_LIST_BY_ORGANIZATION_ID_SUCCESS;
  payload: GetOrganizationPatientListDetails;
}
export interface PatientListOfOrganizationIdDetailsFail {
  type: typeof PATIENT_LIST_BY_ORGANIZATION_ID_FAIL;
  payload: GetOrganizationPatientListDetails;
  message: string;
  status: number;
}
export interface PatientListOfOrganizationIdDetailsAPIMsg {
  type: typeof ORGANIZATION_PATIENT_LIST_API_MSG;
  payload: GetOrganizationPatientListDetails;
  message: string;
  status: number;
}
export interface PatientListOfOrganizationIdRes {
  type: typeof ORGANIZATION_PATIENT_LIST_API_RES;
  payload: GetOrganizationPatientListDetails;
  message: string;
  status: number;
}
interface PatientListOfOrganizationIdDetailsDataAction {
  type: typeof PATIENT_LIST_BY_ORGANIZATION_ID_DATA;
  payload: GetOrganizationPatientListDetails;
  pageCount: PageCount;
}

export type GetOrganizationPatientListActionTypes =
  | PatientListOfOrganizationIdDetailsDataAction
  | PatientListOfOrganizationIdDetailsLoading
  | PatientListOfOrganizationIdDetailsSuccess
  | PatientListOfOrganizationIdDetailsFail
  | PatientListOfOrganizationIdDetailsAPIMsg
  | PatientListOfOrganizationIdRes;
