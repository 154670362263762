import { forgotPasswordValue } from "./Model";

// Patient Meeting Loading State
export const FORGOT_PASSWORD_LOADING =
  "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS =
  "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export interface forgotPasswordDetails {
  forgotPasswordRes: forgotPasswordValue;
  loading: boolean;
  errRes: string;
}
export interface forgotPasswordLoading {
  type: typeof FORGOT_PASSWORD_LOADING;
}
export interface forgotPasswordSuccess {
  type: typeof FORGOT_PASSWORD_SUCCESS;
  payload: forgotPasswordValue;
  errRes: string;
}
export interface forgotPasswordFail {
  type: typeof FORGOT_PASSWORD_FAIL;
  errRes: string;
}

interface forgotPasswordAction {
  type: typeof FORGOT_PASSWORD;
  payload: forgotPasswordValue;
  errRes: string;
}

export type forgotPasswordActionTypes =
  | forgotPasswordAction
  | forgotPasswordLoading
  | forgotPasswordSuccess
  | forgotPasswordFail;
