import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { GovtIDProofActionTypes } from "./ActionTypes";
import { GovtIdProofResponse } from "./Model";
import {
  govtIDProofSuccessAction,
  govtIDProofErrorAction,
  govtIDProofLoadingAction,
} from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const govtIdProofListApi = () => {
  return function (dispatch: Dispatch<GovtIDProofActionTypes>) {
    dispatch(govtIDProofLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        baseUrl.baseUrl + `/indivoapp/api/govtIdType/dropdown_list`,
        {
          headers,
        }
      )
      .then((res) => {
        dispatch(govtIDProofLoadingAction(false));
        dispatch(
          govtIDProofSuccessAction(res.data, res.data.message, res.data.status)
        );
      })
      .catch((error) => {
        dispatch(govtIDProofLoadingAction(false));
        dispatch(
          govtIDProofErrorAction(
            {} as GovtIdProofResponse,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};
