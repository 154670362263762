import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import AdminLoader from "./AdminLoader";
import { getAdminAppointmentList } from "../redux/Doctor/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";
import PreviewIcon from "@mui/icons-material/Preview";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWPageTitle from "../component/MWPageTitle";

export default function DoctorAppointments() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { doctorId } = useParams() as {
    doctorId: string;
  };
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;

  function gotoDetails(patientId: number, caseId: Number) {
    history.push(`/casedetails/${patientId}/${caseId}`);
  }

  const appointmentBody = {
    targetDoctorId: doctorId,
  };
  // Selection Type GET API
  const getAppoinmentRequestList = () => {
    setIsLoading(true);
    dispatch(getAdminAppointmentList(appointmentBody));
  };

  // CAll Appontment list from Store
  const appointmentList = useSelector(
    (state: AppState) => state.appointment.appointmentValue
  );
  console.log("APPTLIST", appointmentList);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  let appoinmentValueList = [];
  for (var i = 0; i < appointmentList.length; i++) {
    let patientId =
      appointmentList[i].patientId === null ? "" : appointmentList[i].patientId;
    let caseId =
      appointmentList[i].caseId === null ? "" : appointmentList[i].caseId;
    let description =
      appointmentList[i].name === "" || appointmentList[i].name === null
        ? "No Details Available"
        : appointmentList[i].name;
    let createdDate =
      appointmentList[i].createDate === null
        ? ""
        : new Date(appointmentList[i].createDate).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let endDate =
      appointmentList[i].end === null
        ? ""
        : new Date(appointmentList[i].end).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let modifiedDate =
      appointmentList[i].modifiedDate === null
        ? ""
        : new Date(appointmentList[i].modifiedDate).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let appoinmentStatus = appointmentList[i].hasOwnProperty("status")
      ? appointmentList[i].status === null || appointmentList[i].status === ""
        ? ""
        : appointmentList[i].status
      : "";
    appoinmentValueList.push([
      <Button
        variant="outlined"
        onClick={() => gotoDetails(Number(patientId), Number(caseId))}
        startIcon={<PreviewIcon />}
      >
        View
      </Button>,
      description,
      createdDate,
      endDate,
      modifiedDate,
      appoinmentStatus,
    ]);
  }
  const appointmentListValue: any[] = appoinmentValueList;

  useEffect(() => {
    // Do api call here....
    getAppoinmentRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selfTaskDetailsApi() {
    dispatch(getAppoinmentRequestList());
  }

  const paymentName = "Doctor Appointment List";

  const tableMarkup = (
    <Box sx={{ pt: 4 }}>
      <Card>
        <CardHeader></CardHeader>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => dispatch(getAppoinmentRequestList())}
            >
              Reload Appointment List
            </Button>
          </Grid>
        </CardActions>
        <Grid>
          <CardContent>
            <Box pl={3}>
              {appointmentListValue && appointmentListValue.length ? (
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>View Details</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Create Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Modified Date</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointmentListValue.map((row: any) => (
                        <TableRow
                          key={row.description}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row[0]}</TableCell>
                          <TableCell align="left">{row[1]}</TableCell>
                          <TableCell align="left">{row[2]}</TableCell>
                          <TableCell align="left">{row[3]}</TableCell>
                          <TableCell align="left">{row[4]}</TableCell>
                          <TableCell align="left">{row[5]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>
                  <MWExceptionList />
                </Typography>
              )}
            </Box>
          </CardContent>
        </Grid>
      </Card>
    </Box>
  );

  return (
    <div>
      {loadingMarkup}
      <Container maxWidth="xl">
        <MWPageTitle
          title={paymentName}
          backButton={true}
          reload={true}
          reloadAction={selfTaskDetailsApi}
        />
        {tableMarkup}
      </Container>
    </div>
  );
}
