import { LogOutResponse } from "./Model";

export const LOG_OUT_LOADING = "LOG_OUT_LOADING";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_PROGRESS = "LOG_OUT_PROGRESS";
export const LOG_OUT_FAIL = "LOG_OUT_FAIL";
export const LOG_OUT = "LOG_OUT";

export interface LogOutDetails {
  imageUploadResponse: LogOutResponse;
  loading: boolean;
  message: string;
  status: number;
}
export interface LogOutLoading {
  type: typeof LOG_OUT_LOADING;
  loading: boolean;
}
export interface LogOutSuccess {
  type: typeof LOG_OUT_SUCCESS;
  payload: LogOutResponse;
}
export interface LogOutFail {
  type: typeof LOG_OUT_FAIL;
  payload: LogOutResponse;
  message: string;
  status: number;
}

export type LogOutActionTypes = LogOutLoading | LogOutSuccess | LogOutFail;
