import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import axios from "axios";
import {
    AddRequestAppointmentTypes, ADD_REQUEST_APPOINTMENT_FAIL, ADD_REQUEST_APPOINTMENT_LOADING, ADD_REQUEST_APPOINTMENT_SUCCESS, RequestAppointmentTypes, REQUEST_APPOINTMENT_DETAILS_LIST_FAIL,
    REQUEST_APPOINTMENT_DETAILS_LIST_LOADING, REQUEST_APPOINTMENT_DETAILS_LIST_SUCCESS
} from "./ActionTypes";
import { addrequestAppointmentAction, requestAppointmentAction } from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";


const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

export const requestAppointmentDetails = (state = {}) => {
    return function (dispatch: Dispatch<RequestAppointmentTypes>) {
        dispatch({
            type: REQUEST_APPOINTMENT_DETAILS_LIST_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + "/appointmentapp/api/appointment/get_appointment_details_for_case", state, { headers })
            .then((result) => {
                if (result) {
                    let responseValue = result.data;
                    let responseDataValue = result.data.appointmentRequestList
                    dispatch(requestAppointmentAction(responseValue, responseDataValue));
                    dispatch({
                        type: REQUEST_APPOINTMENT_DETAILS_LIST_SUCCESS,
                        payload: result.data,
                        listData: responseDataValue
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: REQUEST_APPOINTMENT_DETAILS_LIST_FAIL,
                });
            });
    };
};

export const addBookAppointment = (bookAppoinmentBody = {}) => {
    return function (dispatch: Dispatch<AddRequestAppointmentTypes>) {
        dispatch({
            type: ADD_REQUEST_APPOINTMENT_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + "/appointmentapp/api/appointment/book_appointment", bookAppoinmentBody, { headers })
            .then((result) => {
                if (result) {
                    let responseValue = result.data;
                    dispatch(addrequestAppointmentAction(responseValue));
                    dispatch({
                        type: ADD_REQUEST_APPOINTMENT_SUCCESS,
                        payload: result.data,
                    });
                }
            })
            .catch((error) => {
                let responseValue = error.response.data;
                // dispatch(addrequestAppointmentAction(responseValue));
                dispatch({
                    type: ADD_REQUEST_APPOINTMENT_FAIL,
                    payload: responseValue,
                });
            });
    };
};