import {
  GET_PROVIDER_LOGIN_DETAILS_FAIL,
  GET_PROVIDER_LOGIN_DETAILS_LOADING,
  GET_PROVIDER_LOGIN_DETAILS_SUCCESS,
  GET_PROVIDER_LOGIN_DETAILS_API_MSG,
  GET_PROVIDER_LOGIN_DETAILS_API_RES,
  GetProviderDetailsActionTypes,
} from "./ActionTypes";
import { GetProviderDetailsRes } from "./Model";

export const getProviderDetailsLoadingAction = (
  loading: boolean
): GetProviderDetailsActionTypes => {
  return {
    type: GET_PROVIDER_LOGIN_DETAILS_LOADING,
    loading: loading,
  };
};

export const getProviderDetailsSuccessAction = (
  getProviderLoginDetailsResponse: GetProviderDetailsRes,
  successMsg: string
): GetProviderDetailsActionTypes => {
  return {
    type: GET_PROVIDER_LOGIN_DETAILS_SUCCESS,
    payload: getProviderLoginDetailsResponse,
    successMsg: successMsg,
  };
};

export const getProviderDetailsErrorAction = (
  getProviderLoginDetailsResponse: GetProviderDetailsRes,
  errMsg: string,
  status: number
): GetProviderDetailsActionTypes => {
  return {
    type: GET_PROVIDER_LOGIN_DETAILS_FAIL,
    payload: getProviderLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getProviderDetailsAPIMsgAction = (
  getProviderLoginDetailsResponse: GetProviderDetailsRes,
  errMsg: string,
  status: number
): GetProviderDetailsActionTypes => {
  return {
    type: GET_PROVIDER_LOGIN_DETAILS_API_MSG,
    payload: getProviderLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getProviderDetailsAPIResClearAction = (
  getProviderLoginDetailsResponse: GetProviderDetailsRes,
  errMsg: string,
  status: number
): GetProviderDetailsActionTypes => {
  return {
    type: GET_PROVIDER_LOGIN_DETAILS_API_RES,
    payload: getProviderLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
