import { createTheme } from "@mui/material/styles";

var darkPalette = createTheme({
  typography: {
    fontFamily: [
      "Niveau-Grotesk",
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h6: {
      color: "#072a2e",
    },
    h5: {
      color: "#072a2e",
    },
    h4: {
      color: "#072a2e",
    },
    h3: {
      color: "#072a2e",
    },
    h2: {
      color: "#072a2e",
    },
    h1: {
      color: "#072a2e",
    },
  },
  palette: {
    mode: "light",
    primary: {
      light: "#0a3c42",
      main: "#000",
      dark: "#fff",
    },
    secondary: {
      light: "#0a3c42",
      main: "#000",
      dark: "#fff",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 75,
    },
  },
});

darkPalette = {
  ...darkPalette,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#188c9b",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: `linear-gradient(72deg, #CBF2F7 18%, #D1E4FF 50%, #CBF2F7 110%)`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#188c9b",
            color: "#fff",
          },
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
          backgroundColor: "#188c9b",
        },
        outlined: {
          borderColor: "#188c9b",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: darkPalette.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: "#34cce0",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [darkPalette.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
          "&.Mui-selected": {
            color: "#072a2e",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // padding: darkPalette.spacing(1),
          // "&:hover": {
          //   backgroundColor: "#e4f8fb",
          //   color: "#000",
          // },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#aceaf2",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#0a3c42",
          },
          color: "#0a3c42",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: darkPalette.typography.fontWeightMedium,
          color: "#0a3c42",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#0a3c42",
          minWidth: "auto",
          marginRight: darkPalette.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#0a3c42",
        },
        colorSecondary: {
          backgroundColor: "#0a3c42",
        },
      },
    },
  },
};

export default darkPalette;
