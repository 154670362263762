import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getTaskListValue } from "../redux/effects/ApiCall";
import { AppState } from "../redux/store/Store";
import { useHistory } from "react-router-dom";
import AdminLoader from "./AdminLoader";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";
import PageLayout from "./Layout/PageLayout";

export default function Dashboard() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();

  const pageTitle = "Dashboard";
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getTaskListValue());
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoTaskDetails(params.value);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "patientName", headerName: "Patient name", width: 200 },
    { field: "title", headerName: "Title", width: 350 },
    {
      field: "consultationType",
      headerName: "Type",
      width: 250,
    },
    { field: "created_date", headerName: "Create Date", width: 250 },
  ];

  const history = useHistory();
  const gotoTaskDetails = (rowIndex: any) => {
    history.push(`/selftaskdetails/${rowIndex}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTaskListValue());
  }, [dispatch]);

  // call Store
  const AdminAllTaskList = useSelector(
    (state: AppState) => state.taskListValue
  );
  const DataGridMarkup = (
    <Card>
      <CardContent>
        {AdminAllTaskList?.taskListValue !== undefined ? (
          AdminAllTaskList?.taskListValue.length > 0 ? (
            <DataGrid
              rows={AdminAllTaskList?.taskListValue}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminAllTaskList.loading === true ? loadingMarkup : ""}
      <PageLayout>
        <MWPageTitle
          title={pageTitle}
          reload={true}
          reloadAction={reloadActionFromChild}
        />
        {DataGridMarkup}
      </PageLayout>
    </Box>
  );
}
