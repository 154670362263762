import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  GetRelationshipListActionTypes,
  GET_RELATIONSHIP_LIST_FAIL,
  GET_RELATIONSHIP_LIST_LOADING,
  GET_RELATIONSHIP_LIST_SUCCESS,
} from "./ActionTypes";
import { GetRelationshipListRes } from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const getRelationshipList = () => {
  return function (dispatch: Dispatch<GetRelationshipListActionTypes>) {
    dispatch({
      type: GET_RELATIONSHIP_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        baseUrl.baseUrl + `/patientapp/api/patient/patient_relationship_list  `,
        {
          headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_RELATIONSHIP_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_RELATIONSHIP_LIST_FAIL,
          payload: {} as GetRelationshipListRes,
          errRes: error.response.data.message,
        });
      });
  };
};
