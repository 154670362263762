import {
  UpdateAdminNoteByPatientActionTypes,
  UPDATE_NOTE_FAIL,
  UPDATE_NOTE_LOADING,
  UPDATE_NOTE_SUCCESS,
  UpdateAdminNoteByPatientState,
  UPDATE_NOTE_UPDATE_API_MSG,
  UPDATE_NOTE_UPDATE_API_RES,
} from "./ActionTypes";
import { UpdateAdminNoteByPatientRes } from "./Model";

const initialStateUpdatePosts: UpdateAdminNoteByPatientState = {
  loading: false,
  updateNoteResRes: {} as UpdateAdminNoteByPatientRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const updateNoteReducer = (
  state = initialStateUpdatePosts,
  action: UpdateAdminNoteByPatientActionTypes
): UpdateAdminNoteByPatientState => {
  switch (action.type) {
    case UPDATE_NOTE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        updateNoteResRes: action.payload,
      };
    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        updateNoteResRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_NOTE_UPDATE_API_MSG:
      return {
        ...state,
        updateNoteResRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_NOTE_UPDATE_API_RES:
      return {
        ...state,
        updateNoteResRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
