import { GET_HEALTHCAMPPATIENT_LIST, HealthcampPatientGetActionTypes } from "./ActionTypes";
import { HealthcampPatientList, PageCount } from "./Model";

export const getHealthcampPatientAction = (
  healthcamppatient: HealthcampPatientList, pageCount: PageCount
): HealthcampPatientGetActionTypes => {
  return {
    type: GET_HEALTHCAMPPATIENT_LIST,
    payload: healthcamppatient,
    pageCount: pageCount
  };
};