import React from "react";
// import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
// import { useTranslation } from "react-i18next";

const MWSelectLang = () => {
    // const { t, i18n } = useTranslation(['home']);
    // const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lng") || "en");
    // const onClickLanguageChange = (e: any) => {
    //     const language = e.target.value;
    //     i18n.changeLanguage(language); //change the language
    //     localStorage.setItem("lng", language);
    //     setSelectedLang(language);
    // }
    return (
        <></>
        // <FormControl sx={{ m: 2 }} size="small">
        //     <Select value={selectedLang} onChange={onClickLanguageChange}>
        //         <MenuItem value="de" >German</MenuItem>
        //         <MenuItem value="en" >English</MenuItem>
        //     </Select>
        //     <FormHelperText variant="standard" sx={{ fontSize: 12 }}>{t("common.selectLang", { ns: ['home'] })}</FormHelperText>
        // </FormControl>
    );
};

export default MWSelectLang;