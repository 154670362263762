import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Typography,
  Box,
  Alert,
  Button,
  Grid,
  CardContent,
  CardHeader,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MWExceptionList from "../../../../component/MWExceptionList";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWTextField from "../../../../component/MWTextField";
import { AddPatientImportConfigDetails } from "../../../../redux/addPatientImportConfig/Model";
import { PatientImportConfigListDetails } from "../../../../redux/getPatientImportConfigList/Model";
import { EditPatientImportConfigDetails } from "../../../../redux/editPatientImportConfig/Model";
import { editPatientImportConfigApi } from "../../../../redux/editPatientImportConfig/API";
import { addPatientImportConfigApi } from "../../../../redux/addPatientImportConfig/API";
import { getPatientInfortConfigListApi } from "../../../../redux/getPatientImportConfigList/API";

export default function ImportedConfig() {
  const dispatch = useDispatch();
  const [importEmail, setImportEmail] = useState("");
  const [importWhatsapp, setImportWhatsapp] = useState("");
  const [editConfigId, seteditConfigId] = useState("");

  const [phonenumber] = useState("");

  const closeerror = () => {
    setError("");
  };
  const [masterConfigList, setMasterConfigList] = useState<any[]>([]);
  const { patientid } = useParams() as {
    patientid: string;
  };

  const [error, setError] = useState("");
  const errorDiv = error ? (
    <Alert onClick={closeerror} severity="error">
      <Typography>{error}</Typography>
    </Alert>
  ) : null;

  const [educationsuccess, setEducationssuccess] = useState("");
  const closeSuccess = () => {
    setEducationssuccess("");
  };

  const successDiv = educationsuccess ? (
    <Alert onClick={closeSuccess} severity="success">
      <Typography>{educationsuccess}</Typography>
    </Alert>
  ) : null;

  const [instituteerror] = useState("");
  const instituteErrordiv = instituteerror ? (
    <Typography>{instituteerror}</Typography>
  ) : null;

  // objemail: any, objcreatedate: any, objmodifieddate: any, objphno: any, objwhatsappno:any
  function updateImportConfig(
    id: any,
    objemail: any,
    objcreatedate: any,
    objmodifieddate: any,
    objphno: any,
    objwhatsappno: any
  ) {
    setModalActive((modalActive) => !modalActive);
    seteditConfigId(id);
    setImportEmail(objemail);
    setImportWhatsapp(objwhatsappno);
    setEducationssuccess("");
    setError("");
  }

  const [modalActive, setModalActive] = useState(false);
  const handleToggle = () => {
    setModalActive((modalActive) => !modalActive);
    seteditConfigId("");
    setImportEmail("");
    setImportWhatsapp("");
    // setPhonenumber('');
    setEducationssuccess("");
    setError("");
  };

  let newAwardBody = {
    patientId: patientid,
    email: importEmail,
    phoneNumber: phonenumber,
    whatsappNo: importWhatsapp,
  } as AddPatientImportConfigDetails;

  const getconfig = {
    patientId: patientid,
  } as PatientImportConfigListDetails;

  const addSymptomSubmit = () => {
    if (editConfigId) {
      let editBody = Object.assign(newAwardBody, {
        patientConfigId: editConfigId,
      }) as EditPatientImportConfigDetails;
      dispatch(editPatientImportConfigApi(editBody));
    } else {
      dispatch(addPatientImportConfigApi(newAwardBody));
    }
  };
  const addPatientImportConfig = useSelector(
    (state: AppState) => state.addPatientImportConfig
  );

  useEffect(() => {
    if (
      addPatientImportConfig?.addPatientImportConfigRes.message !== undefined
    ) {
      setEducationssuccess(
        addPatientImportConfig?.addPatientImportConfigRes.message
      );
      setError("");
    } else {
      setError(addPatientImportConfig?.errRes);
      setEducationssuccess("");
    }
  }, [addPatientImportConfig]);

  const getPatientImportConfigList = useSelector(
    (state: AppState) => state.getPatientImportConfigList
  );

  const editImportConfig = useSelector(
    (state: AppState) => state.editImportConfig
  );

  useEffect(() => {
    if (editImportConfig?.editPatientImportConfigRes?.message !== undefined) {
      setEducationssuccess(
        editImportConfig?.editPatientImportConfigRes?.message
      );
      setError("");
      dispatch(getPatientInfortConfigListApi(getconfig));
    } else {
      setError(editImportConfig?.errRes);
      setEducationssuccess("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editImportConfig]);

  useEffect(() => {
    if (
      getPatientImportConfigList?.getPatientImportConfigListRes
        ?.patientDataImportConfigList !== undefined
    ) {
      if (
        getPatientImportConfigList?.getPatientImportConfigListRes
          .patientDataImportConfigList !== undefined
      ) {
        let masterConfig =
          getPatientImportConfigList?.getPatientImportConfigListRes.patientDataImportConfigList.map(
            (resData: any) => ({
              id: resData.id,
              objcreatedate: new Date(resData.createDate).toLocaleDateString(
                "en-US",
                DATE_OPTIONS
              ),
              objmodifieddate: new Date(
                resData.modifiedDate
              ).toLocaleDateString("en-US", DATE_OPTIONS),
              objemail: resData.email,
              objwhatsappno: resData.whatsappNo,
              objphno: resData.phoneNumber,
            })
          );
        setMasterConfigList(masterConfig);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientImportConfigList]);

  useEffect(() => {
    // get call here....
    dispatch(getPatientInfortConfigListApi(getconfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  const columns: GridColDef[] = [
    { field: "objemail", headerName: "Email", flex: 1 },
    { field: "objcreatedate", headerName: "Create Date", flex: 1 },
    { field: "objmodifieddate", headerName: "Modified date", flex: 1 },
    { field: "objphno", headerName: "Phone Number", flex: 1 },
    { field: "edit", headerName: "Edit", flex: 1 },
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            updateImportConfig(
              params.row.id,
              params.row.objemail,
              params.row.objcreatedate,
              params.row.objmodifieddate,
              params.row.objphno,
              params.row.objwhatsappno
            );
          }}
        >
          View
        </Button>
      ),
    },
  ];

 
  const masterImportConfigList = (
    <Box sx={{ maxHeight: "340px", overflow: "auto" }}>
      <CardContent>
        {masterConfigList && masterConfigList.length ? (
          <DataGrid
            rows={masterConfigList}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Box>
  );

  const symptomsAddInfo = (
    <div>
      <Card>
        <CardHeader title="Import Configs"></CardHeader>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <CardContent>
            <Button variant="contained" onClick={handleToggle}>
              Add config
            </Button>
          </CardContent>
        </Grid>
        {masterImportConfigList}
      </Card>
      <br />
      <br />
      <Dialog open={modalActive} onClose={handleToggle} maxWidth="lg">
        <DialogTitle>
          {editConfigId ? "Update Import Config" : "Add Import Config"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            p={2}
          >
            <Grid item>
              <MWTextField
                fullWidth
                value={importEmail}
                onChange={(event) => setImportEmail(event?.target.value)}
                label=" Email to be used to import patient data"
                type="email"
                placeholder="Enter Your Email Address"
                id="Emailname"
              />
            </Grid>
            <Typography>{instituteErrordiv}</Typography>
            <Grid item>
              <MWTextField
                fullWidth
                value={importWhatsapp}
                onChange={(event) => setImportWhatsapp(event.target.value)}
                label=" Whatsapp number to be used to import patient data"
                type="number"
                placeholder="Enter Whatsapp Number"
              />
            </Grid>
            <div>{successDiv}</div>
            <div>{errorDiv}</div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={addSymptomSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  return <Box>{symptomsAddInfo}</Box>;
}
