import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { EditCaseBody, EditCaseResponse } from "./Model";
import {
  editCaseAPIResClearAction,
  editCaseErrorAction,
  editCaseLoadingAction,
  editCaseSuccessAction,
  editCaseUpdateAPIMsgAction,
} from "./Actions";
import { EditCaseActionTypes } from "./ActionTypes";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as EditCaseResponse;
export const EditCaseApi = (body: EditCaseBody) => {
  return function (dispatch: Dispatch<EditCaseActionTypes>) {
    dispatch(editCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/patient/edit_patient_case`, body, {
        headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(editCaseLoadingAction(false));
        dispatch(
          editCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient case updated successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(editCaseLoadingAction(false));
        dispatch(
          editCaseErrorAction(
            {} as EditCaseResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const editCaseAPIResMsg = () => {
  return function (dispatch: Dispatch<EditCaseActionTypes>) {
    dispatch(editCaseUpdateAPIMsgAction(apiRes as EditCaseResponse, "", "", 0));
  };
};

export const cleareditCaseAPIRes = () => {
  return function (dispatch: Dispatch<EditCaseActionTypes>) {
    dispatch(editCaseAPIResClearAction({} as EditCaseResponse, "", "", 0));
  };
};
