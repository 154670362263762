import {
  AddDoctorTeamTypes, AddDoctorToCaseTypes, ADD_DOCTORTEAMTYPE_FAIL, ADD_DOCTORTEAMTYPE_LOADING, 
  ADD_DOCTORTEAMTYPE_SUCCESS, ADD_DOCTORTOCASE_FAIL, ADD_DOCTORTOCASE_LOADING, ADD_DOCTORTOCASE_SUCCESS, 
  DeleteDoctorFromCaseTypes, 
  DELETE_DOCTORFROMCASE_FAIL, 
  DELETE_DOCTORFROMCASE_LOADING, 
  DELETE_DOCTORFROMCASE_SUCCESS, 
  DoctorAddToCaseTypes, 
  DoctorAddToCaseTypeStateType, 
GET_DOCTOR_TO_CASE_LIST_FAIL,GET_DOCTOR_TO_CASE_LIST_LOADING,GET_DOCTOR_TO_CASE_LIST_SUCCESS,SelectedDoctorTeamTypes, SelectedDoctorTeamTypeStateType, SelectedSpecialistDoctorTypes, 
  SelectedSpecialistDoctorTypeStateType, SELECTED_DOCTOR_TEAM_TYPE_LIST_FAIL,SELECTED_DOCTOR_TEAM_TYPE_LIST_LOADING, 
  SELECTED_DOCTOR_TEAM_TYPE_LIST_SUCCESS, SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_FAIL, 
  SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_LOADING, SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS, 
  SetAddDoctorTeamTypeStateType, SetAddDoctorToCaseStateType, SetDeleteDoctorFromCaseStateType, SpecialistDoctorTypes, SpecialistDoctorTypeStateType, 
  SPECIALIST_DOCTOR_TYPE_LIST_FAIL, SPECIALIST_DOCTOR_TYPE_LIST_LOADING, 
  SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS
} from "./ActionTypes";

//const initialDoctorTeamType: DoctorTeamTypeStateType = { loading: false, response: [] };
const initialAddDoctorTeamType: SetAddDoctorTeamTypeStateType = { loading: false, addCC: [], response: Object.assign({}) };
const initialSelectedDoctorTeamType: SelectedDoctorTeamTypeStateType = { loading: false, response: 0 };

const initialSpecialistStatus: SpecialistDoctorTypeStateType = { loading: false, response: [] };
const initialSpecialistDoctorStatus: SelectedSpecialistDoctorTypeStateType = { loading: false, response: [] };
const initialAddDoctorToCase: SetAddDoctorToCaseStateType = { loading: false, response: Object.assign({}) };
const initialAddSpecialistDoctorStatus: DoctorAddToCaseTypeStateType = { loading: false, response: [] };
const initialDeleteDoctorFromCase: SetAddDoctorToCaseStateType = { loading: false, response: Object.assign({}) };

// export const doctorTeamTypeListValue = (state = initialDoctorTeamType, action: DoctorTeamTypes): DoctorTeamTypeStateType => {
//   switch (action.type) {
//     case DOCTOR_TEAM_TYPE_LIST_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         response: action.payload,
//       };
//     case DOCTOR_TEAM_TYPE_LIST_LOADING:
//       return {
//         ...state,
//         loading: true,
//       };
//     case DOCTOR_TEAM_TYPE_LIST_FAIL:
//       return {
//         ...state,
//         loading: false,
//       };
//     default:
//       return state;
//   }
// };

export const addDoctorTeamTypeReducer = (state = initialAddDoctorTeamType, action: AddDoctorTeamTypes): SetAddDoctorTeamTypeStateType => {
  switch (action.type) {
    case ADD_DOCTORTEAMTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_DOCTORTEAMTYPE_LOADING:
      return {
        ...state,
        loading: true,
        addCC: []
      };
    case ADD_DOCTORTEAMTYPE_FAIL:
      return {
        ...state,
        addCC: [],
        loading: false
      };
    default:
      return state;
  }
};

export const selectedDoctorTeamType = (state = initialSelectedDoctorTeamType, action: SelectedDoctorTeamTypes): SelectedDoctorTeamTypeStateType => {
  switch (action.type) {
    case SELECTED_DOCTOR_TEAM_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case SELECTED_DOCTOR_TEAM_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SELECTED_DOCTOR_TEAM_TYPE_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSpecialist = (state = initialSpecialistStatus, action: SpecialistDoctorTypes): SpecialistDoctorTypeStateType => {
  switch (action.type) {
    case SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case SPECIALIST_DOCTOR_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SPECIALIST_DOCTOR_TYPE_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSpecialistDoctor = (state = initialSpecialistDoctorStatus, action: SelectedSpecialistDoctorTypes): SelectedSpecialistDoctorTypeStateType => {
  switch (action.type) {
    case SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addDoctorToCase = (state = initialAddDoctorToCase, action: AddDoctorToCaseTypes): SetAddDoctorToCaseStateType => {
  switch (action.type) {
    case ADD_DOCTORTOCASE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ADD_DOCTORTOCASE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_DOCTORTOCASE_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export const getaddedDoctorList = (state = initialAddSpecialistDoctorStatus, action: DoctorAddToCaseTypes): DoctorAddToCaseTypeStateType => {
  switch (action.type) {
    case GET_DOCTOR_TO_CASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_DOCTOR_TO_CASE_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCTOR_TO_CASE_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteDoctorFromCase = (state = initialDeleteDoctorFromCase, action: DeleteDoctorFromCaseTypes): SetDeleteDoctorFromCaseStateType => {
  switch (action.type) {
    case DELETE_DOCTORFROMCASE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DELETE_DOCTORFROMCASE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DOCTORFROMCASE_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
