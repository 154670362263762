import {
  EXTRACT_OBSERVATION_DETAILS_FAIL,
  EXTRACT_OBSERVATION_DETAILS_LOADING,
  EXTRACT_OBSERVATION_DETAILS_SUCCESS,
  EXTRACT_OBSERVATION_DETAILS_API_MSG,
  EXTRACT_OBSERVATION_DETAILS_API_RES,
  ExtractObservationDetailsActionTypes,
} from "./ActionTypes";
import { ExtractObservationDetailsRes } from "./Model";

export const extractObservationDetailsLoadingAction = (
  loading: boolean
): ExtractObservationDetailsActionTypes => {
  return {
    type: EXTRACT_OBSERVATION_DETAILS_LOADING,
    loading: loading,
  };
};

export const extractObservationDetailsSuccessAction = (
  updateDoctorDetailsResponse: ExtractObservationDetailsRes,
): ExtractObservationDetailsActionTypes => {
  return {
    type: EXTRACT_OBSERVATION_DETAILS_SUCCESS,
    payload: updateDoctorDetailsResponse,
  };
};

export const extractObservationDetailsErrorAction = (
  updateDoctorDetailsResponse: ExtractObservationDetailsRes,
  errMsg: string,
  status: number
): ExtractObservationDetailsActionTypes => {
  return {
    type: EXTRACT_OBSERVATION_DETAILS_FAIL,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const extractObservationDetailsAPIMsgAction = (
  updateDoctorDetailsResponse: ExtractObservationDetailsRes,
  errMsg: string,
  status: number
): ExtractObservationDetailsActionTypes => {
  return {
    type: EXTRACT_OBSERVATION_DETAILS_API_MSG,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const extractObservationDetailsAPIResClearAction = (
  updateDoctorDetailsResponse: ExtractObservationDetailsRes,
  errMsg: string,
  status: number
): ExtractObservationDetailsActionTypes => {
  return {
    type: EXTRACT_OBSERVATION_DETAILS_API_RES,
    payload: updateDoctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
