import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { PatientSearchByOrganizationIdActionTypes } from "./ActionTypes";
import {
  PatientSearchByOrganizationIdBody,
  PatientSearchByOrganizationIdDetails,
} from "./Model";
import {
  PatientSearchByOrganizationIdAPIResClearAction,
  PatientSearchByOrganizationIdErrorAction,
  PatientSearchByOrganizationIdLoadingAction,
  PatientSearchByOrganizationIdSuccessAction,
  PatientSearchByOrganizationIdAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as PatientSearchByOrganizationIdDetails;
export const patientSearchByOrganizationIdApi = (
  searchKey: PatientSearchByOrganizationIdBody,
  pageNo: any
) => {
  return function (
    dispatch: Dispatch<PatientSearchByOrganizationIdActionTypes>
  ) {
    dispatch(PatientSearchByOrganizationIdLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/searchapp/api/searchPatientInPatientOrganization/searchPatientListInPatientOrganization?page=${Number(
            pageNo
          )}&size=10`,
        searchKey,
        { headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(PatientSearchByOrganizationIdLoadingAction(false));
        dispatch(PatientSearchByOrganizationIdSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(PatientSearchByOrganizationIdLoadingAction(false));
        dispatch(
          PatientSearchByOrganizationIdErrorAction(
            {} as PatientSearchByOrganizationIdDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const organizationPatientSearchAPIResMsg = () => {
  return function (
    dispatch: Dispatch<PatientSearchByOrganizationIdActionTypes>
  ) {
    dispatch(
      PatientSearchByOrganizationIdAPIMsgAction(
        apiRes as PatientSearchByOrganizationIdDetails,
        "",
        0
      )
    );
  };
};

export const clearOrgPtSearchAPIRes = () => {
  return function (
    dispatch: Dispatch<PatientSearchByOrganizationIdActionTypes>
  ) {
    dispatch(
      PatientSearchByOrganizationIdAPIResClearAction(
        {} as PatientSearchByOrganizationIdDetails,
        "",
        0
      )
    );
  };
};
