import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  GetLanguageListActionTypes,
  GET_LANGUAGE_LIST_FAIL,
  GET_LANGUAGE_LIST_LOADING,
  GET_LANGUAGE_LIST_SUCCESS,
} from "./ActionTypes";
import { GetLanguageListRes } from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const getLanguageList = () => {
  return function (dispatch: Dispatch<GetLanguageListActionTypes>) {
    dispatch({
      type: GET_LANGUAGE_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        baseUrl.baseUrl + `/doctorapp/api/onboarding/language_list_dropdown`,
        {
          headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_LANGUAGE_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LANGUAGE_LIST_FAIL,
          payload: {} as GetLanguageListRes,
          errRes: error.response.data.message,
        });
      });
  };
};
