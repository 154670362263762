// import { GET_LIST } from "../types/ActionTypes";
import { HealthcampPatientList, PageCount } from "./Model";

// Healthcamp Patient Loading State
export const HCPATIENT_LIST_LOADING = "HCPATIENT_LIST_LOADING";
export const HCPATIENT_LIST_SUCCESS = "HCPATIENT_LIST_SUCCESS";
export const HCPATIENT_LIST_FAIL = "HCPATIENT_LIST_FAIL";
export const GET_HEALTHCAMPPATIENT_LIST = "GET_HEALTHCAMPPATIENT_LIST";

export interface GetHealthcampPatientStateType {
  healthcampPatientValue: HealthcampPatientList;
  loading: boolean;
  pageCount: PageCount;
}
export interface HealthcampPatientListLoading {
  type: typeof HCPATIENT_LIST_LOADING;
}
export interface HealthcampPatientListSuccess {
  type: typeof HCPATIENT_LIST_SUCCESS;
  payload: HealthcampPatientList;
  pageCount: PageCount;
}
export interface HealthcampPatientListFail {
  type: typeof HCPATIENT_LIST_FAIL;
}

interface GetHealthcampPatientActionType {
  type: typeof GET_HEALTHCAMPPATIENT_LIST;
  payload: HealthcampPatientList;
  pageCount: PageCount;
}

export type HealthcampPatientGetActionTypes =
  | GetHealthcampPatientActionType
  | HealthcampPatientListLoading
  | HealthcampPatientListSuccess
  | HealthcampPatientListFail;