import * as React from "react";
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import PageLayout from "../Layout/PageLayout";
import MWPageTitle from "../../component/MWPageTitle";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { AppState } from "../../redux/store/Store";
import { useEffect, useState } from "react";
import {
  DataGrid,
  // GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import MWExceptionList from "../../component/MWExceptionList";
import { ReportListBody } from "../../redux/reportList/Model";
import { reportList } from "../../redux/reportList/API";
import dayjs from "dayjs";
// import { ProviderDoctorListBody } from "../../redux/reportList/Model";

export default function LabReportList() {
  const dispatch = useDispatch();
  // const history = useHistory();
  // Api call from store.....
  const reportListValue = useSelector(
    (state: AppState) => state.providerReportListValue
  );
  const pageCount = useSelector(
    (state: AppState) =>
      state.providerReportListValue.providerReportListResponse.count
  );
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      // renderCell: (params: GridCellParams) => (
      //   <Button
      //     startIcon={<VisibilityIcon />}
      //     variant="outlined"
      //     onClick={() => {
      //       reportDetails(params.row.id);
      //     }}
      //   >
      //     View
      //   </Button>
      // ),
    },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "patientDetailsType", headerName: "Details Type", flex: 1 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
    },
    // { field: "doctorQualification", headerName: "Qualification", width: 400 },
    // { field: "doctorExperience", headerName: "Experience", width: 300 },
  ];

  const geReportListApiBody = {
    providerorgId: localStorage.getItem("orgId"),
  };

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(reportList(geReportListApiBody as ReportListBody, page));
  };

  useEffect(() => {
    dispatch(reportList(geReportListApiBody as ReportListBody, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // const reportDetails = (doctorId: any) => {
  //   console.log("doctorId==", doctorId);
  //   history.push(`/doctordetails/${doctorId}`);
  // };

  useEffect(() => {
    if (
      reportListValue?.providerReportListResponse?.patientDetailsList !==
        undefined &&
      reportListValue?.providerReportListResponse?.patientDetailsList.length > 0
    ) {
      let dataPointArr =
        reportListValue?.providerReportListResponse?.patientDetailsList.map(
          (element: any) => ({
            id: element.id,
            description: element.description,
            patientDetailsType:
              element.patientDetailsType !== null
                ? element.patientDetailsType.displayValue
                : "Not Set",
            startDate: dayjs(element?.startDate).format("dddd, MMMM DD, YYYY HH:mm"),
            // doctorQualification: element.qualification,
            // doctorExperience: element.specialtyWithYearsOfExperience,
          })
        );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportListValue]);

  const ReportListTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {reportListValue?.providerReportListResponse?.patientDetailsList !==
          undefined &&
        reportListValue?.providerReportListResponse?.patientDetailsList.length >
          0 ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <Box>
      <PageLayout>
        <MWPageTitle title="Lab Report List" reload={false} />
        {ReportListTable}
      </PageLayout>
    </Box>
  );
}
