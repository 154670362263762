import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { GetDoctorTeamTypeListActionTypes } from "./ActionTypes";
import { GetDoctorTeamTypeListBody, GetDoctorTeamTypeListRes } from "./Model";
import {
getDoctorTeamTypeListAPIResClearAction,
getDoctorTeamTypeListErrorAction,
getDoctorTeamTypeListLoadingAction,
getDoctorTeamTypeListSuccessAction,
getDoctorTeamTypeListUpdateAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as GetDoctorTeamTypeListRes;
export const getDoctorTeamTypeList = (payload: GetDoctorTeamTypeListBody) => {
  return function (dispatch: Dispatch<GetDoctorTeamTypeListActionTypes>) {
    dispatch(getDoctorTeamTypeListLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/patientapp/api/patientMedicalData/doctor_team_typeList`,
        payload,
        {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorTeamTypeListLoadingAction(false));
        dispatch(
            getDoctorTeamTypeListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor team type list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorTeamTypeListLoadingAction(false));
        dispatch(
            getDoctorTeamTypeListErrorAction(
            {} as GetDoctorTeamTypeListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateGetDoctorTeamTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorTeamTypeListActionTypes>) {
    dispatch(
        getDoctorTeamTypeListUpdateAPIMsgAction(apiRes as GetDoctorTeamTypeListRes, "", "", 0)
    );
  };
};

export const clearGetDoctorTeamTypeListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorTeamTypeListActionTypes>) {
    dispatch(getDoctorTeamTypeListAPIResClearAction({} as GetDoctorTeamTypeListRes, "", "", 0));
  };
};
