import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Grid,
  TextField,
  DialogTitle,
  IconButton,
  Typography,
  Alert,
  Stack,
  AlertColor,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import MWUploader from "../../../component/MWUploader";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import AdminLoader from "../../AdminLoader";
import {
  addPatientDataDetails,
  addPatientDataDetailsUpdateAPIResMsg,
  clearPatientDataPointClearAPIRes,
} from "../../../redux/patientDataDetails/addPatientDetails/API";
import { getPatientDataDetailsList } from "../../../redux/patientDataDetails/patientDataPointListByPatientID/API";
import { UpdatePatientDataDetailsBody } from "../../../redux/patientDataDetails/UpdatePatientDataPoint/Model";
import {
  clearPatientDataPointEditAPIRes,
  updatePatientDataDetailsApi,
  updatePatientDataDetailsEditAPIResMsg,
} from "../../../redux/patientDataDetails/UpdatePatientDataPoint/API";
import { AddPatientDataDetailsBody } from "../../../redux/patientDataDetails/addPatientDetails/Model";
import { clearImageUploadAPIRes } from "../../../redux/ImageUpload/API";
import MWSnackbar from "../../../component/MWSnackbar";
import { getPatientDataDetailsById } from "../../../redux/patientDataDetails/patientDetailsById/API";

type Props = {
  itemId?: string | any;
  addDataPointDialogEnable?: boolean | any;
  addDataPointDialogUpdateState?: boolean | any;
  value?: any;
  page?: number | any;
};

export default function AddDataPointDialog({
  itemId,
  addDataPointDialogEnable,
  addDataPointDialogUpdateState,
  value,
  page,
}: Props) {
  const dispatch = useDispatch();
  const [imageId, setImageId] = React.useState("");
  const { patientid } = useParams() as {
    patientid: string;
  };

  const [dataPoint, setDataPoint] = React.useState("");

  const [selectedDate, setSelectedDate] = React.useState<Date | any>(
    new Date()
  );
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const [selectedItem, setSelectedItem] = React.useState("");
  const [dialogState, setDialogState] = React.useState(false);

  const handleDialogClose = () => {
    setDialogState(false);
    addDataPointDialogUpdateState(false);
    setDataPoint("");
    setSelectedDate(new Date());
    dispatch(clearPatientDataPointClearAPIRes());
    dispatch(clearPatientDataPointEditAPIRes());
    dispatch(clearImageUploadAPIRes());
  };

  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };

  const closeSummaryBanner = () => {
    dispatch(addPatientDataDetailsUpdateAPIResMsg());
    dispatch(updatePatientDataDetailsEditAPIResMsg());
  };

  const [updateDataPointSuccess, setUpdateDataPointSuccess] =
    React.useState("");
  const [alertType, setAlertType] = useState<AlertColor>("success");
  const [updateDataPointError, setUpdateDataPointError] = useState("");
  const updateDataPointSuccessDiv = updateDataPointError ? (
    <Alert
      variant="outlined"
      severity={alertType}
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{updateDataPointError}</Typography>
    </Alert>
  ) : null;

  const dataPointBody = {
    patientId: patientid!,
    patientDocumentId: imageId!,
    startDate: dayjs(String(selectedDate!)).format("YYYY-MM-DDTHH:mm:ss"),
    description: dataPoint!,
  } as AddPatientDataDetailsBody;
  const [editImageId, setEditImageId] = useState("");

  useEffect(() => {
    setSelectedItem(itemId);
    setDialogState(addDataPointDialogEnable);
    if (addDataPointDialogEnable !== undefined) {
      setDataPoint("");
      setSelectedDate(new Date());
      dispatch(clearPatientDataPointClearAPIRes());
      dispatch(clearPatientDataPointEditAPIRes());
      dispatch(clearImageUploadAPIRes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, addDataPointDialogEnable]);

  const addDataDetailsStoreVal = useSelector(
    (state: AppState) => state.addPointDataDetailsRes
  );
  useEffect(() => {
    console.log("valueDIalog", value);
    if (value !== undefined) {
      if (value.description !== undefined) {
        setDataPoint(value.description);
      } else setDataPoint("");
      setSelectedDate(value?.startDate !== undefined ? value?.startDate : null);
      setEditImageId(value?.imageId !== undefined ? value?.imageId : "");
    } else {
      setDataPoint("");
      setSelectedDate(new Date());
      setEditImageId("");
    }
  }, [value]);
  React.useEffect(() => {
    if (
      addDataDetailsStoreVal?.addPatientDataDetailsRes.message !== undefined
    ) {
      setUpdateDataPointSuccess(
        addDataDetailsStoreVal?.addPatientDataDetailsRes.message
      );
      handleDialogClose();
      dispatch(getPatientDataDetailsList(patientid, page));
    }
    if (addDataDetailsStoreVal?.message !== "") {
      setAlertType("error");
      setUpdateDataPointError(addDataDetailsStoreVal?.message);
    } else {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);
  const updateDataDetailsVal = useSelector(
    (state: AppState) => state.updatePatientDataDetailsRes
  );
  React.useEffect(() => {
    if (
      updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined
    ) {
      setUpdateDataPointSuccess(
        updateDataDetailsVal?.updatePatientDataDetailsRes?.message
      );
      handleDialogClose();
      dispatch(getPatientDataDetailsList(patientid, page));
      if (selectedItem !== undefined) {
        dispatch(getPatientDataDetailsById(selectedItem));
      }
    }
    if (updateDataDetailsVal?.message !== "") {
      setAlertType("error");
      setUpdateDataPointError(updateDataDetailsVal?.message);
    } else {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataDetailsVal]);
  const imageUploadRes = useSelector((state: AppState) => state.imageUploading);

  let editImgId = imageId !== undefined ? imageId! : editImageId;
  const editDataPointBody = {
    patientDetailsId: String(value?.id!),
    description: dataPoint!,
    startDate: dayjs(String(selectedDate)).format("YYYY-MM-DDTHH:mm:ss"),
    patientDocumentId: String(editImgId),
  } as UpdatePatientDataDetailsBody;

  const submitPatientData = () => {
      value?.id === undefined
        ? dispatch(addPatientDataDetails(dataPointBody))
        : dispatch(updatePatientDataDetailsApi(editDataPointBody));
  };
  const delImgAction = (passeVal: boolean) => {
    if (passeVal) {
      dispatch(clearImageUploadAPIRes());
    }
  };
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearPatientDataPointClearAPIRes());
      dispatch(clearPatientDataPointEditAPIRes());
    }
  };
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };
  return (
    <Box>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        fullWidth
      >
        <Box>
          {updateDataDetailsVal?.loading || addDataDetailsStoreVal?.loading ? (
            <AdminLoader />
          ) : null}
        </Box>
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h4">
                {value?.id !== undefined ? "Update " : "Add "}Patient Details
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => handleDialogClose()}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "grey",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <TextField
                  fullWidth
                  autoFocus
                  id="data"
                  label="Data"
                  variant="outlined"
                  value={dataPoint}
                  onChange={(e) => {
                    setDataPoint(e.target.value);
                  }}
                  inputProps={{ style: { fontSize: 15 } }}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                  size="small"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    views={['year', 'month', 'day']}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        size="small"
                      />
                    )}
                    InputAdornmentProps={{ position: "start" }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MWUploader
                documentType="patient-image"
                criteria="Upload a file less than 10 mb."
                updatedDataState={updateDataStateFromChild}
                title={"Upload Patient Observation"}
                imageUrl={value?.patientDocument?.document}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
          <Box>{updateDataPointSuccessDiv}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={submitPatientData}
            variant="contained"
            disabled={
              imageUploadRes?.loading ||
              addDataDetailsStoreVal?.loading ||
              updateDataDetailsVal?.loading ||
              checkImgUpload
            }
          >
            {value?.id !== undefined ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <MWSnackbar
        msg={updateDataPointSuccess}
        type={alertType}
        alertClose={checkAlertClose}
      />
    </Box>
  );
}
