import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  forgotPasswordActionTypes,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
} from "./ActionTypes";
import { forgotPasswordDetails, forgotPasswordValue } from "./Model";


export const forgotPasswordApi = (
  email: forgotPasswordDetails
) => {
  return function (dispatch: Dispatch<forgotPasswordActionTypes>) {
    dispatch({
      type: FORGOT_PASSWORD_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(
        baseUrl.baseUrl + `/profileapp/forgot-password/${email.email}/`)
      .then((res) => {
        dispatch({
          type: FORGOT_PASSWORD_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: FORGOT_PASSWORD_FAIL,
          payload: {} as forgotPasswordValue,
          errRes: error.response.data.message || error.response.data.error,
        });
      });
  };
};
