import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  GetDemographicsDetailsByPtIdActionTypes,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
} from "./ActionTypes";
import {
  GetDemographicsDetailsByPtIdRequest,
  GetDemographicsDetailsByPtIdRes,
} from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const getDemographicsDetailsByPtId = (
  payload: GetDemographicsDetailsByPtIdRequest
) => {
  return function (
    dispatch: Dispatch<GetDemographicsDetailsByPtIdActionTypes>
  ) {
    dispatch({
      type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/patientapp/api/patient/get_patient_demographics_details`,
        payload,
        {
          headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
          payload: {} as GetDemographicsDetailsByPtIdRes,
          errRes: error.response.data.message,
        });
      });
  };
};
