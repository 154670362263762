import {
  AddOrganizationPatientDetails,
  PatientAddOrganizationActionTypes,
  ADD_ORGANIZATION_PATIENT_FAIL,
  ADD_ORGANIZATION_PATIENT_LOADING,
  ADD_ORGANIZATION_PATIENT_SUCCESS,
} from "./ActionTypes";
import { AddPatientOrganizationRes } from "./Model";

const initialStateGetPosts: AddOrganizationPatientDetails = {
  loading: false,
  addOrganizationPatientRes: {} as AddPatientOrganizationRes,
  errRes: "",
};
export const addOrganizationPatientReducer = (
  state = initialStateGetPosts,
  action: PatientAddOrganizationActionTypes
): AddOrganizationPatientDetails => {
  switch (action.type) {
    case ADD_ORGANIZATION_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addOrganizationPatientRes: action.payload,
        errRes: "",
      };
    case ADD_ORGANIZATION_PATIENT_LOADING:
      return {
        ...state,
        loading: true,
        addOrganizationPatientRes: {} as AddPatientOrganizationRes,
      };
    case ADD_ORGANIZATION_PATIENT_FAIL:
      return {
        ...state,
        addOrganizationPatientRes: {} as AddPatientOrganizationRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
