import {
  ADD_DATA_POINT_FAIL,
  ADD_DATA_POINT_LOADING,
  ADD_DATA_POINT_SUCCESS,
  ADD_PATIENT_API_MSG,
  ADD_PATIENT_API_RES,
  PatientAddPointDataActionTypes,
} from "./ActionTypes";
import { AddPatientRes } from "./Model";

export const addExistingPatientLoadingAction = (
  loading: boolean
): PatientAddPointDataActionTypes => {
  return {
    type: ADD_DATA_POINT_LOADING,
    loading: loading,
  };
};

export const addExistingPatientSuccessAction = (
  addExistingPatientResponse: AddPatientRes
): PatientAddPointDataActionTypes => {
  return {
    type: ADD_DATA_POINT_SUCCESS,
    payload: addExistingPatientResponse,
  };
};

export const addExistingPatientErrorAction = (
  addExistingPatientResponse: AddPatientRes,
  errMsg: string,
  status: number
): PatientAddPointDataActionTypes => {
  return {
    type: ADD_DATA_POINT_FAIL,
    payload: addExistingPatientResponse,
    message: errMsg,
    status: status,
  };
};
export const addExistingPatientUpdateAPIMsgAction = (
  addExistingPatientResponse: AddPatientRes,
  errMsg: string,
  status: number
): PatientAddPointDataActionTypes => {
  return {
    type: ADD_PATIENT_API_MSG,
    payload: addExistingPatientResponse,
    message: errMsg,
    status: status,
  };
};

export const addExistingPatientAPIResClearAction = (
  addExistingPatientResponse: AddPatientRes,
  message: string,
  status: number
): PatientAddPointDataActionTypes => {
  return {
    type: ADD_PATIENT_API_RES,
    payload: addExistingPatientResponse,
    message: message,
    status: status,
  };
};
