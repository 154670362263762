import { AppointmentConfirmStateType, AppointmentConfirmTypes, AppointmentListStateType, AppointmentListTypes, APPOINTMENT_CONFIRM_FAIL, APPOINTMENT_CONFIRM_LOADING, APPOINTMENT_CONFIRM_SUCCESS, APPOINTMENT_LIST_FAIL, APPOINTMENT_LIST_LOADING, APPOINTMENT_LIST_SUCCESS, CheckPaymentStatusStateType, CheckPaymentStatusTypes, CHECK_PAYMENT_STATUS_FAIL, CHECK_PAYMENT_STATUS_LOADING, CHECK_PAYMENT_STATUS_SUCCESS, PaymentListStateType, PaymentListTypes, PAYMENT_LIST_FAIL, PAYMENT_LIST_LOADING, PAYMENT_LIST_SUCCESS } from "./ActionTypes";

// get appointment payment list
const initialStatePaymentList: PaymentListStateType = {
    loading: false, consultationType: Object.assign({}), responseTimeType: Object.assign({}),
    doctorTeamType: Object.assign({}), paymentList: [],
    message: Object.assign({}), status: Object.assign({})
};

export const appointmentPaymentListReducer = (state = initialStatePaymentList, action: PaymentListTypes): PaymentListStateType => {
    switch (action.type) {
        case PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                consultationType: action.consultationType,
                responseTimeType: action.responseTimeType,
                doctorTeamType: action.doctorTeamType,
                paymentList: action.paymentList,
                message: action.message,
                status: action.status
            };
        case PAYMENT_LIST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_LIST_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};

// get Appointment List
const initialStateAppointmentList: AppointmentListStateType = {
    loading: false, consultationType: Object.assign({}), responseTimeType: Object.assign({}),
    doctorTeamType: Object.assign({}), appointmentList: [],
    message: Object.assign({}), status: Object.assign({})
};

export const appointmentListReducer = (state = initialStateAppointmentList, action: AppointmentListTypes): AppointmentListStateType => {
    switch (action.type) {
        case APPOINTMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                consultationType: action.consultationType,
                responseTimeType: action.responseTimeType,
                doctorTeamType: action.doctorTeamType,
                appointmentList: action.appointmentList,
                message: action.message,
                status: action.status
            };
        case APPOINTMENT_LIST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case APPOINTMENT_LIST_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// chek payment status
const initialStatePaymentStatus: CheckPaymentStatusStateType = {
    loading: false, message: Object.assign({}), status: Object.assign({})
};

export const appointmentPaymentStatusReducer = (state = initialStatePaymentStatus, action: CheckPaymentStatusTypes): CheckPaymentStatusStateType => {
    switch (action.type) {
        case CHECK_PAYMENT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status
            };
        case CHECK_PAYMENT_STATUS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CHECK_PAYMENT_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// confirm appointment
const initialStateAppointmentConfirm: AppointmentConfirmStateType = {
    loading: false, message: Object.assign({}), status: Object.assign({})
};

export const appointmentConfirmReducer = (state = initialStateAppointmentConfirm, action: AppointmentConfirmTypes): AppointmentConfirmStateType => {
    switch (action.type) {
        case APPOINTMENT_CONFIRM_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status
            };
        case APPOINTMENT_CONFIRM_LOADING:
            return {
                ...state,
                loading: true,
            };
        case APPOINTMENT_CONFIRM_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};