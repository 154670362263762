import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertColor,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import FirstAccordian from "./OpinionType";
import SecondAccordian from "./ConsultationType";
import ThirdAccordian from "./ResponseTimeType";
import PageLayout from "../../Layout/PageLayout";
import MWSnackbar from "../../../component/MWSnackbar";
import {  useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";

export default function ConsultationOption(props: any)  {
  const addPatientCaseIdvalue = useSelector(
    (state: AppState) => state.addPatientCase
  );
  const editPatientCaseIdvalue = useSelector(
    (state: AppState) => state.editPatientCase
  );
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<AlertColor>("success");
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      // dispatch(updateDeleteScheduleAPIResMsg());
      // dispatch(clearDeleteScheduleAPIRes());
    }
  };

  useEffect(() => {
    if (addPatientCaseIdvalue?.successMsg !== undefined) {
      setMessage(addPatientCaseIdvalue?.successMsg);
      setMessageType("success");
    }
  }, [addPatientCaseIdvalue]);

  useEffect(() => {
    if (editPatientCaseIdvalue?.successMsg !== undefined) {
      setMessage(editPatientCaseIdvalue?.successMsg);
      setMessageType("success");
    }
  }, [editPatientCaseIdvalue]);

  const accordian = (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary">
            {t("consultOpPage.qwhyMedWis", { ns: ["home"] })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FirstAccordian />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography color="primary">
            {t("consultOpPage.typeCon", { ns: ["home"] })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SecondAccordian />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography color="primary">
            {t("consultOpPage.resTime", { ns: ["home"] })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ThirdAccordian />
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <PageLayout>
      {accordian}
      {message !== "" || message !== undefined ? (
        <MWSnackbar
          msg={message}
          type={messageType}
          alertClose={checkAlertClose}
        />
      ) : null}
    </PageLayout>
  );
}
