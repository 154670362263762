import axios from "axios";
import { Dispatch } from "react";
import Url from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { getPatientTeamListAction, addpatientTeamAction } from "./Actions";
import {
  ADD_PATIENT_TEAM_FAIL,
  ADD_PATIENT_TEAM_LOADING,
  ADD_PATIENT_TEAM_SUCCESS,
  AdminGetPatientTeamListActionTypes,
  ALL_PATIENT_TEAM_FAIL,
  ALL_PATIENT_TEAM_LOADING,
  ALL_PATIENT_TEAM_SUCCESS,
  AnswerPatientTeamTypes,
} from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
// Admin get Patient Team List API
export const getPatientTeamList = (pageNo: any) => {
  return function (dispatch: Dispatch<AdminGetPatientTeamListActionTypes>) {
    dispatch({
      type: ALL_PATIENT_TEAM_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
          `/adminapp/api/patientTeam/patient_team_list_all?page=${Number(
            pageNo
          )}&size=50`,
        { headers }
      )
      .then((res) => {
        let patientTeamList = res.data;
        let patientTeamPageCount = res.data.numberOfPages;
        let patientTeamCurrentPageCount = res.data.current_page_no;
        dispatch(
          getPatientTeamListAction(patientTeamList, patientTeamPageCount)
        );
        dispatch({
          type: ALL_PATIENT_TEAM_SUCCESS,
          payload: patientTeamList,
          patientListPageCount: patientTeamPageCount,
          patientTeamCurrentPageCount: patientTeamCurrentPageCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: ALL_PATIENT_TEAM_FAIL,
        });
      });
  };
};
// Add patient Team
export const addpatientTeamByAdmin = (body: object) => {
  return function (dispatch: Dispatch<AnswerPatientTeamTypes>) {
    dispatch({
      type: ADD_PATIENT_TEAM_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/adminActions/add_patient_user_account`,
        body,
        { headers }
      )
      .then((result) => {
        if (result) {
          let message = result.data.message;
          let status = result.data.status;
          dispatch(addpatientTeamAction(message, status));
          dispatch({
            type: ADD_PATIENT_TEAM_SUCCESS,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: ADD_PATIENT_TEAM_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
