import {
  PROVIDER_DOCTOR_LIST_FAIL,
  PROVIDER_DOCTOR_LIST_LOADING,
  PROVIDER_DOCTOR_LIST_SUCCESS,
  PROVIDER_DOCTOR_LIST_API_MSG,
  PROVIDER_DOCTOR_LIST_API_RES,
  ProviderDoctorListActionTypes,
} from "./ActionTypes";
import { ProviderDoctorListRes } from "./Model";

export const providerDoctorListLoadingAction = (
  loading: boolean,
): ProviderDoctorListActionTypes => {
  return {
    type: PROVIDER_DOCTOR_LIST_LOADING,
    loading: loading,
  };
};

export const providerDoctorListSuccessAction = (
  providerDoctorListResponse: ProviderDoctorListRes,
): ProviderDoctorListActionTypes => {
  return {
    type: PROVIDER_DOCTOR_LIST_SUCCESS,
    payload: providerDoctorListResponse,
  };
};

export const providerDoctorListErrorAction = (
  providerDoctorListResponse: ProviderDoctorListRes,
  errMsg: string,
  status: number,
): ProviderDoctorListActionTypes => {
  return {
    type: PROVIDER_DOCTOR_LIST_FAIL,
    payload: providerDoctorListResponse,
    message: errMsg,
    status: status,
  };
};
export const providerDoctorListUpdateAPIMsgAction = (
  providerDoctorListResponse: ProviderDoctorListRes,
  errMsg: string,
  status: number,
): ProviderDoctorListActionTypes => {
  return {
    type: PROVIDER_DOCTOR_LIST_API_MSG,
    payload: providerDoctorListResponse,
    message: errMsg,
    status: status,
  };
};

export const providerDoctorListAPIResClearAction = (
  providerDoctorListResponse: ProviderDoctorListRes,
  message: string,
  status: number,
): ProviderDoctorListActionTypes => {
  return {
    type: PROVIDER_DOCTOR_LIST_API_RES,
    payload: providerDoctorListResponse,
    message: message,
    status: status,
  };
};
