import {
  AddPatientDetails,
  PatientAddPointDataActionTypes,
  ADD_DATA_POINT_FAIL,
  ADD_DATA_POINT_LOADING,
  ADD_DATA_POINT_SUCCESS,
  ADD_PATIENT_API_RES,
  ADD_PATIENT_API_MSG,
} from "./ActionTypes";
import { AddPatientRes } from "./Model";

const initialStateGetPosts: AddPatientDetails = {
  loading: false,
  addPatientRes: {} as AddPatientRes,
  message: "",
  status: 0,
  errRes: "",
};
export const addHealthcampExistingPatientReducer = (
  state = initialStateGetPosts,
  action: PatientAddPointDataActionTypes
): AddPatientDetails => {
  switch (action.type) {
    case ADD_DATA_POINT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DATA_POINT_SUCCESS:
      return {
        ...state,
        addPatientRes: action.payload,
      };

    case ADD_DATA_POINT_FAIL:
      return {
        ...state,
        addPatientRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADD_PATIENT_API_MSG:
      return {
        ...state,
        addPatientRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADD_PATIENT_API_RES:
      return {
        ...state,
        addPatientRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
