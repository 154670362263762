import {
  GenderListDetails,
  GetGenderListActionTypes,
  GET_GENDER_LIST_FAIL,
  GET_GENDER_LIST_LOADING,
  GET_GENDER_LIST_SUCCESS,
} from "./ActionTypes";
import { GetGenderListRes } from "./Model";

const initialStateGetPosts: GenderListDetails = {
  loading: false,
  getGenderListRes: {} as GetGenderListRes,
  errRes: "",
};
export const getGenderListReducer = (
  state = initialStateGetPosts,
  action: GetGenderListActionTypes
): GenderListDetails => {
  switch (action.type) {
    case GET_GENDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getGenderListRes: action.payload,
        errRes: "",
      };
    case GET_GENDER_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getGenderListRes: {} as GetGenderListRes,
      };
    case GET_GENDER_LIST_FAIL:
      return {
        ...state,
        getGenderListRes: {} as GetGenderListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
