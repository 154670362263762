import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import MWUploader from "../../../component/MWUploader";
import MWErrorText from "../../../component/MWErrorText";
import AdminLoader from "../../AdminLoader";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { AddPatientOrganizationBody } from "../../../redux/AddOrganizationPatient/Model";
import { addOrganizationPatient } from "../../../redux/AddOrganizationPatient/API";
import { GetOrganizationPatientListBody } from "../../../redux/PatientListByOrganization/Model";
import { getPatientListByOrganization } from "../../../redux/PatientListByOrganization/API";

type Props = {
  itemId?: string | any;
  addPatientDialogEnable?: boolean | any;
  addPatientDialogUpdateState?: boolean | any;
};

const GenderValueList = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
];

export default function AddPatientDialog({
  itemId,
  addPatientDialogEnable,
  addPatientDialogUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const [imageId, setImageId] = useState("");
  // const [isEdit, setIsEdit] = useState(false);
  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };

  const [disableDob, setDisableDob] = useState(false);
  const [disableAge, setDisableAge] = useState(true);
  const [enableAge, setAgeVisibility] = useState(false);
  const handleClickAge = () => {
    setAgeVisibility((show) => !show);
    setDisableAge((disableAge) => !disableAge);
    setDisableDob((disableDob) => !disableDob);
  };
  const handleMouseAgePassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const closeBanner = (passedVal: boolean) => {
    setBannerState(passedVal);
  };

  const closeSummaryBanner = () => {
    setAddHealthCampPatientSuccess("");
    setDialogState(false);
  };
  const [addHealthCampPatientSuccess, setAddHealthCampPatientSuccess] =
    useState("");
  const addHealthCampPatientSuccessDiv = addHealthCampPatientSuccess ? (
    <Alert
      variant="outlined"
      severity="success"
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      {addHealthCampPatientSuccess}
    </Alert>
  ) : (
    ""
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [bannerState, setBannerState] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(+0));
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const [selectedItem, setSelectedItem] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const handlePatienAgeChange = (age: string) => {
    setPatientAge(age);
    let year = new Date().getFullYear();
    let cal = Number(year) - Number(age);
    let ageRespectiveDate = String(`01/01/${cal}`);
    setSelectedDate(new Date(ageRespectiveDate));
  };
  const [patientPhone, setpatientPhone] = useState("");
  const [patientPincode, setPatientPincode] = useState("");
  const [patientDescription, setPatientDescription] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const filterGenderType = (genderType: any, _e: any) => {
    setPatientGender(genderType.value);
  };
  const [dialogState, setDialogState] = useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addPatientDialogUpdateState(false);
  };

  const birthday = dayjs(selectedDate).format("DD/MM/YYYY");

  const OrganizationPatientBody = {
    organizationId: selectedItem!,
    patientImageId: imageId!,
    firstname: firstName!,
    lastname: lastName!,
    dob: String(birthday),
    phone: patientPhone!,
    pincode: patientPincode!,
    description: patientDescription!,
    gender: patientGender!,
  } as AddPatientOrganizationBody;

  const addPatientSubmit = () => {
    if (firstName === "") {
      setErrorMsg("Please enter first name");
      setBannerState(true);
    } else if (lastName === "") {
      setErrorMsg("Please enter last name");
      setBannerState(true);
    } else if (selectedDate === null) {
      setErrorMsg("Please enter dob");
      setBannerState(true);
    } else if (patientPhone === "") {
      setErrorMsg("Please enter ph no");
      setBannerState(true);
    } else if (patientPincode === "") {
      setErrorMsg("Please enter pincode");
      setBannerState(true);
    } else {
      dispatch(addOrganizationPatient(OrganizationPatientBody));
    }
  };

  const addOrganizationPatientValueRes = useSelector(
    (state: AppState) => state.addOrganizationPatientValue
  );

  useEffect(() => {
    if (
      addOrganizationPatientValueRes?.addOrganizationPatientRes?.message !==
      undefined
    ) {
      const patientListBody = {
        organizationId: selectedItem,
      } as GetOrganizationPatientListBody;
      dispatch(getPatientListByOrganization(patientListBody, 1));
      setAddHealthCampPatientSuccess(
        addOrganizationPatientValueRes?.addOrganizationPatientRes?.message
      );
      setDialogState(false);
      addPatientDialogUpdateState(false);
      alert(addOrganizationPatientValueRes?.addOrganizationPatientRes?.message);
    }
    if (addOrganizationPatientValueRes?.errRes) {
      setErrorMsg(addOrganizationPatientValueRes?.errRes);
      setBannerState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOrganizationPatientValueRes]);

  useEffect(() => {
    setSelectedItem(itemId);
    setDialogState(addPatientDialogEnable);
  }, [itemId, addPatientDialogEnable]);

  useEffect(() => {
    if (selectedDate !== null) {
      setPatientAge(String(dayjs().diff(dayjs(selectedDate), "year")));
    } else {
      setPatientAge("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const delImgAction = (_passeVal: boolean) => {};
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };
  return (
    <Box>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "40vh",
            minWidth: "70vw",
            overflow: "hidden",
          },
        }}
      >
        <Box>
          {addOrganizationPatientValueRes?.loading ? <AdminLoader /> : null}
        </Box>
        <DialogTitle id="customized-dialog-title">
          Add Patient
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="fname"
                label="First Name"
                variant="outlined"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lname"
                label="Last Name"
                variant="outlined"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    views={["year", "month", "day"]}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        inputProps={{ ...params.inputProps }}
                        size="small"
                      />
                    )}
                    InputAdornmentProps={{ position: "start" }}
                    disabled={disableDob}
                    label="Dob"
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={patientAge}
                id="age"
                label="Age"
                variant="outlined"
                onChange={(e) => {
                  handlePatienAgeChange(e.target.value);
                }}
                size="small"
                disabled={disableAge}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickAge}
                        onMouseDown={handleMouseAgePassword}
                        edge="end"
                      >
                        {enableAge ? <ToggleOnIcon /> : <ToggleOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="phone"
                required
                label="Phone Number"
                variant="outlined"
                onChange={(e) => {
                  setpatientPhone(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="pincode"
                label="Pincode"
                variant="outlined"
                onChange={(e) => {
                  setPatientPincode(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                id="controllable-states-demo"
                getOptionLabel={(option) => `${option.label}`}
                options={GenderValueList}
                onChange={(event: any, newValue: any) => {
                  filterGenderType(newValue, event);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Gender" variant="outlined" />
                )}
                size="small"
                disableClearable
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="des"
                label="Description"
                variant="outlined"
                onChange={(e) => {
                  setPatientDescription(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MWUploader
                documentType="patient-image"
                criteria="Upload a file less than 50 mb."
                updatedDataState={updateDataStateFromChild}
                title={"Upload HealthCamp Patient Data"}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
          <Box px={2}>
            <MWErrorText
              errormessage={errorMsg}
              bannerErrorState={bannerState}
              closeBannerState={closeBanner}
            />
            {addHealthCampPatientSuccessDiv}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={addPatientSubmit}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
            disabled={checkImgUpload}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
