import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  Box,
  Container,
} from "@mui/material";
import { requestAppointmentDetails } from "../../../../redux/Patient/GetAppointmentList/API";

type Props = {
  caseid?: string | any;
  apptSelectLoder: boolean | any;
};
export default function AppointmentBooking({ caseid, apptSelectLoder }: Props) {
  const [toSignin] = useState(false);
  const dispatch = useDispatch();
  const selectionTypeBody = {
    caseId: caseid,
  };

  //Selection Type GET API
  const getAppoinmentRequestList = () => {
    dispatch(requestAppointmentDetails(selectionTypeBody));
  };
  const getrequestAppointmentDetailsVal = useSelector(
    (state: AppState) => state.getrequestAppointmentDetails
  );
  useEffect(() => {
    apptSelectLoder(getrequestAppointmentDetailsVal?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getrequestAppointmentDetailsVal]);

  // RequestAppointment Api Value from store
  let requestAppointmentListFromAPI = useSelector(
    (state: AppState) =>
      state.getrequestAppointmentDetails.getAppointmentListRes
  );

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    getAppoinmentRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectionOptionMarkup = (
    <Card>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Box pt={2} pl={5} pr={5}>
          <Grid item>
            <Typography component="h6" style={{ fontWeight: "bold" }}>
              Selected Options
            </Typography>
          </Grid>
          <Grid item>
            <Box p={3}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Stack>
                  <Typography component="h6" style={{ fontWeight: "bold" }}>
                    Consultation Type
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Alert variant="outlined" severity="info">
                      <Typography>
                        {requestAppointmentListFromAPI.consultationType}
                      </Typography>
                    </Alert>
                  </Box>
                </Stack>
                <Stack>
                  <Typography component="h6" style={{ fontWeight: "bold" }}>
                    Response Timing
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Alert variant="outlined" severity="info">
                      <Typography>
                        {requestAppointmentListFromAPI.responseTimeType}
                      </Typography>
                    </Alert>
                  </Box>
                </Stack>
                <Stack>
                  <Typography component="h6" style={{ fontWeight: "bold" }}>
                    Doctor Selection Type
                  </Typography>
                  <Divider />
                  <Box mt={2}>
                    <Alert variant="outlined" severity="info">
                      <Typography>
                        {requestAppointmentListFromAPI.doctorTeamType}
                      </Typography>
                    </Alert>
                  </Box>
                </Stack>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Card>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {toSignin ? <Redirect to="/signin" /> : null}
          {selectionOptionMarkup}
        </Stack>
      </Container>
    </Box>
  );
}
