import {
  AddProviderAdministratorDetailsActionTypes,
  ADD_PROVIDER_ADMINISTRATOR_FAIL,
  ADD_PROVIDER_ADMINISTRATOR_LOADING,
  ADD_PROVIDER_ADMINISTRATOR_SUCCESS,
  AddProviderOrgAdministratorState,
  ADD_PROVIDER_ADMINISTRATOR_API_MSG,
  ADD_PROVIDER_ADMINISTRATOR_API_RES,
} from "./ActionTypes";
import { AddProviderAdministratorDetailsRes } from "./Model";

const initialStateUpdatePosts: AddProviderOrgAdministratorState = {
  loading: false,
  addProviderOrgAdministratorRes: {} as AddProviderAdministratorDetailsRes,
  errorMsg: "",
  status: 0,
};
export const addProviderOrgAdministratorReducer = (
  state = initialStateUpdatePosts,
  action: AddProviderAdministratorDetailsActionTypes
): AddProviderOrgAdministratorState => {
  switch (action.type) {
    case ADD_PROVIDER_ADMINISTRATOR_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PROVIDER_ADMINISTRATOR_SUCCESS:
      return {
        ...state,
        addProviderOrgAdministratorRes: action.payload,
      };
    case ADD_PROVIDER_ADMINISTRATOR_FAIL:
      return {
        ...state,
        addProviderOrgAdministratorRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PROVIDER_ADMINISTRATOR_API_MSG:
      return {
        ...state,
        addProviderOrgAdministratorRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PROVIDER_ADMINISTRATOR_API_RES:
      return {
        ...state,
        addProviderOrgAdministratorRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
