import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ReviewNeededBody, NeededReviewResponse } from "./Model";
import {
  reviewNeededAPIResClearAction,
  reviewNeededErrorAction,
  reviewNeededLoadingAction,
  reviewNeededSuccessAction,
  reviewNeededAPIMsgAction,
} from "./Actions";
import { ReviewNeededActionTypes } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as NeededReviewResponse;
export const reviewNeededApiCall = (body: ReviewNeededBody) => {
  return function (dispatch: Dispatch<ReviewNeededActionTypes>) {
    dispatch(reviewNeededLoadingAction(true));
    axios
      .post(Url.baseUrl + `/adminapp/api/patientDetails/reviewNeeded`, body, {
        headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(reviewNeededLoadingAction(false));
        dispatch(
          reviewNeededSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient modified successfully.",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(reviewNeededLoadingAction(false));
        dispatch(
          reviewNeededErrorAction(
            {} as NeededReviewResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const reviewNeededAPIResMsg = () => {
  return function (dispatch: Dispatch<ReviewNeededActionTypes>) {
    dispatch(
      reviewNeededAPIMsgAction(apiRes as NeededReviewResponse, "", 0)
    );
  };
};

export const clearreviewNeededAPIRes = () => {
  return function (dispatch: Dispatch<ReviewNeededActionTypes>) {
    dispatch(
      reviewNeededAPIResClearAction({} as NeededReviewResponse, "", 0)
    );
  };
};
