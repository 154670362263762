import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { SecondOpinionResponse } from "./Model";
import {
  secondOpinionTypeAPIResClearAction,
  secondOpinionTypeErrorAction,
  secondOpinionTypeLoadingAction,
  secondOpinionTypeSuccessAction,
  secondOpinionTypeUpdateAPIMsgAction,
} from "./Actions";
import { SecondOpinionTypeActionTypes } from "./ActionTypes";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as SecondOpinionResponse;
export const getSecondOpinionTypeList = () => {
  return function (dispatch: Dispatch<SecondOpinionTypeActionTypes>) {
    dispatch(secondOpinionTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/secondopinionapp/api/secondopinion/second_opinion_option`, {
        headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(secondOpinionTypeLoadingAction(false));
        dispatch(
          secondOpinionTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Second opinion type fetch successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(secondOpinionTypeLoadingAction(false));
        dispatch(
          secondOpinionTypeErrorAction(
            {} as SecondOpinionResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const secondOpinionTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<SecondOpinionTypeActionTypes>) {
    dispatch(
      secondOpinionTypeUpdateAPIMsgAction(apiRes as SecondOpinionResponse, "","", 0)
    );
  };
};

export const clearsecondOpinionTypeAPIRes = () => {
  return function (dispatch: Dispatch<SecondOpinionTypeActionTypes>) {
    dispatch(
      secondOpinionTypeAPIResClearAction({} as SecondOpinionResponse, "","", 0)
    );
  };
};
