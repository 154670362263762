import {
  PATIENT_NOTE_LIST_FAIL,
  PATIENT_NOTE_LIST_LOADING,
  PATIENT_NOTE_LIST_SUCCESS,
  PATIENT_NOTE_LIST_UPDATE_API_MSG,
  PATIENT_NOTE_LIST_UPDATE_API_RES,
  GetAdminNoteListByPatientActionTypes,
} from "./ActionTypes";
import { GetAdminNoteListByPatientRes } from "./Model";

export const getAdminNoteListByPatientLoadingAction = (
  loading: boolean
): GetAdminNoteListByPatientActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_LOADING,
    loading: loading,
  };
};

export const getAdminNoteListByPatientSuccessAction = (
  getAdminNoteListByPatientResponse: GetAdminNoteListByPatientRes
): GetAdminNoteListByPatientActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_SUCCESS,
    payload: getAdminNoteListByPatientResponse,
  };
};

export const getAdminNoteListByPatientErrorAction = (
  getAdminNoteListByPatientResponse: GetAdminNoteListByPatientRes,
  errMsg: string,
  status: number
): GetAdminNoteListByPatientActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_FAIL,
    payload: getAdminNoteListByPatientResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getAdminNoteListByPatientUpdateAPIMsgAction = (
  getAdminNoteListByPatientResponse: GetAdminNoteListByPatientRes,
  errMsg: string,
  status: number
): GetAdminNoteListByPatientActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_UPDATE_API_MSG,
    payload: getAdminNoteListByPatientResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getAdminNoteListByPatientAPIResClearAction = (
  getAdminNoteListByPatientResponse: GetAdminNoteListByPatientRes,
  errMsg: string,
  status: number
): GetAdminNoteListByPatientActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_UPDATE_API_RES,
    payload: getAdminNoteListByPatientResponse,
    errorMsg: errMsg,
    status: status,
  };
};
