import React from "react";
import DemographicDetails from "./DemographicDetails";
import ImportedData from "./ImportedData";
import FileData from "./FIleData";
interface FilePreparationTabList {
  type?: string;
  label: string;
  content: any;
}
export const TabList = [
  {
    label: "Demographic Details",
    content: <DemographicDetails />,
  },
  {
    label: "Presenting Complaint(s)",
    content: (
      <FileData type={"chiefCompliants"} label={"Presenting Complaint(s)"} />
    ),
  },
  {
    label: "Ideas,Concerns,Expectations",
    content: (
      <FileData
        type={"ideasConcernsandExpectations"}
        label={"Ideas,Concerns,Expectations"}
      />
    ),
  },
  {
    label: "History of Presenting Complaint(s)",
    content: (
      <FileData
        type={"historyofChiefPresentingComplaints"}
        label={"History of Presenting Complaint(s)"}
      />
    ),
  },
  {
    label: "Review of Systems",
    content: <FileData type={"systemsReview"} label={"Review of Systems"} />,
  },
  {
    label: "Past History",
    content: <FileData type={"pastHistory"} label={"Past History"} />,
  },
  {
    label: "Tests,Procedures,Investigations",
    content: (
      <FileData
        type={"testsProceduresandInvestigations"}
        label={"Tests,Procedures,Investigations"}
      />
    ),
  },
  {
    label: "Medication / Prescriptions",
    content: (
      <FileData
        type={"medication/DrugHistory"}
        label={"Medication / Prescriptions"}
      />
    ),
  },
  {
    label: "Allergies",
    content: <FileData type={"allergies"} label={"Allergies"} />,
  },
  {
    label: "Immunization",
    content: <FileData type={"immunization"} label={"Immunization"} />,
  },
  {
    label: "Family History",
    content: <FileData type={"familyHistory"} label={"Family History"} />,
  },

  {
    label: "Nutritional History",
    content: (
      <FileData type={"nutritionalHistory"} label={"Nutritional History"} />
    ),
  },
  {
    label: "Personal and Social History",
    content: (
      <FileData
        type={"personalandSocialHistory"}
        label={"Personal and Social History"}
      />
    ),
  },
  {
    label: "Health Maintenance History",
    content: (
      <FileData
        type={"healthMaintenanceHistory"}
        label={"Health Maintenance History"}
      />
    ),
  },
  {
    label: "Risk Factors",
    content: <FileData type={"riskFactors"} label={"Risk Factors"} />,
  },
  {
    label: "Diagnosis",
    content: <FileData type={"diagnosis"} label={"Diagnosis"} />,
  },
  {
    label: "Medical Summary",
    content: <FileData type={"medicalSummary"} label={"Medical Summary"} />,
  },
  {
    label: "Problem List",
    content: (
      <FileData type={"problemListandTreatmentPlan"} label={"Problem List"} />
    ),
  },
  {
    label: "Treatment Plan",
    content: <FileData type={"treatmentPlan"} label={"Treatment Plan"} />,
  },
  {
    label: "Imported Data",
    content: <ImportedData />,
  },
] as FilePreparationTabList[];
