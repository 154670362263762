import { GetAppointmentListRes } from "./Model";
export const GET_APPOINTMENT_LIST_LOADING = "GET_APPOINTMENT_LIST_LOADING";
export const GET_APPOINTMENT_LIST_SUCCESS = "GET_APPOINTMENT_LIST_SUCCESS";
export const GET_APPOINTMENT_LIST_FAIL = "GET_APPOINTMENT_LIST_FAIL";
export const GET_APPOINTMENT_LIST_API_MSG =
  "GET_APPOINTMENT_LIST_API_MSG";
export const GET_APPOINTMENT_LIST_API_RES =
  "GET_APPOINTMENT_LIST_API_RES";

export interface GetAppointmentListState {
  getAppointmentListRes: GetAppointmentListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentListLoading {
  type: typeof GET_APPOINTMENT_LIST_LOADING;
  loading: boolean;
}
export interface GetAppointmentListSuccess {
  type: typeof GET_APPOINTMENT_LIST_SUCCESS;
  payload: GetAppointmentListRes;
  successMsg: string;
}
export interface GetAppointmentListFail {
  type: typeof GET_APPOINTMENT_LIST_FAIL;
  payload: GetAppointmentListRes;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentListAPIMsg {
  type: typeof GET_APPOINTMENT_LIST_API_MSG;
  payload: GetAppointmentListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentListAPIRes {
  type: typeof GET_APPOINTMENT_LIST_API_RES;
  payload: GetAppointmentListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetAppointmentListActionTypes =
  | GetAppointmentListLoading
  | GetAppointmentListSuccess
  | GetAppointmentListFail
  | GetAppointmentListAPIMsg
  | GetAppointmentListAPIRes;
