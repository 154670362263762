import axios from "axios";
import { Dispatch } from "react";
import Url from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { PatientQuriesListResponse } from "./Model";
import {
  executiveListErrorAction,
  patientQuriesListLoadingAction,
  patientQuriesListSuccessAction,
} from "./Actions";
import { PatientQuriesListActionTypes } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const PatientQuriesListApi = () => {
  return function (dispatch: Dispatch<PatientQuriesListActionTypes>) {
    dispatch(patientQuriesListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/adminapp/api/queries/patient_query_list`, {
        headers,
      })
      .then((res) => {
        dispatch(patientQuriesListLoadingAction(false));
        dispatch(patientQuriesListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(patientQuriesListLoadingAction(false));
        dispatch(
          executiveListErrorAction(
            {} as PatientQuriesListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};
