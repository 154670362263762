import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import axios from "axios";
import { DeleteOpinionActionTypes, DELETE_OPINION_LIST_FAIL, DELETE_OPINION_LIST_LOADING, DELETE_OPINION_LIST_SUCCESS, OpinionActionTypes, OPINION_LIST_FAIL, OPINION_LIST_LOADING, OPINION_LIST_SUCCESS } from "./ActionTypes";
import { deleteOpinionAction, getOpinionAction } from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

export const getOpinionApi = (state: {}) => {
    return function (dispatch: Dispatch<OpinionActionTypes>) {

        dispatch({
            type: OPINION_LIST_LOADING,
        });

        setupInterceptorsTo(axios).post(Url.baseUrl + "/patientapp/api/patientMedicalData/get_patientDetailsList", state, { headers })
            .then((res) => {
                let resData = res.data.patientDetailList;
                dispatch(getOpinionAction(resData));
                dispatch({
                    type: OPINION_LIST_SUCCESS,
                    payload: resData,
                });
            })
            .catch((error) => {
                dispatch({
                    type: OPINION_LIST_FAIL,
                });
            });
    };
};

export const deleteOpinion = (state: {}) => {
    return function (dispatch: Dispatch<DeleteOpinionActionTypes>) {

        dispatch({
            type: DELETE_OPINION_LIST_LOADING,
        });

        setupInterceptorsTo(axios).post(Url.baseUrl + "/patientapp/api/patientMedicalData/delete_patientDetails", state, { headers })
            .then((res) => {
                let resData = res.data;
                dispatch(deleteOpinionAction(resData));
                dispatch({
                    type: DELETE_OPINION_LIST_SUCCESS,
                    payload: resData,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_OPINION_LIST_FAIL,
                });
            });
    };
};