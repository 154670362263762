import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { UpdatePatientDataPointActionTypes } from "./ActionTypes";
import { SimpleHistoryRes } from "./Model";
import {
  updatePatientDataPointAPIResClearAction,
  updatePatientDataPointErrorAction,
  updatePatientDataPointHistoryLoadingAction,
  updatePatientDataPointHistorySuccessAction,
  updatePatientDataPointUpdateAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as SimpleHistoryRes;
export const showPatientDataPointHistoryApi = (payload: string) => {
  return function (dispatch: Dispatch<UpdatePatientDataPointActionTypes>) {
    dispatch(updatePatientDataPointHistoryLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/patientDetails/getPatientDetailsHistory`,
        { patientDetailsId: payload },
        { headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updatePatientDataPointHistoryLoadingAction(false));
        dispatch(updatePatientDataPointHistorySuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(updatePatientDataPointHistoryLoadingAction(false));
        dispatch(
          updatePatientDataPointErrorAction(
            {} as SimpleHistoryRes,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updatePatientDataPointEditAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdatePatientDataPointActionTypes>) {
    dispatch(
      updatePatientDataPointUpdateAPIMsgAction(
        apiRes as SimpleHistoryRes,
        "",
        0
      )
    );
  };
};

export const clearPatientDataPointEditAPIRes = () => {
  return function (dispatch: Dispatch<UpdatePatientDataPointActionTypes>) {
    dispatch(
      updatePatientDataPointAPIResClearAction({} as SimpleHistoryRes, "", 0)
    );
  };
};
