import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  GetGenderListActionTypes,
  GET_GENDER_LIST_FAIL,
  GET_GENDER_LIST_LOADING,
  GET_GENDER_LIST_SUCCESS,
} from "./ActionTypes";
import { GetGenderListRes } from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const getGenderList = () => {
  return function (dispatch: Dispatch<GetGenderListActionTypes>) {
    dispatch({
      type: GET_GENDER_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(baseUrl.baseUrl + `/indivoapp/api/gender/dropdown_list`, {
        headers,
      })
      .then((res) => {
        dispatch({
          type: GET_GENDER_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GENDER_LIST_FAIL,
          payload: {} as GetGenderListRes,
          errRes: error.response.data.message,
        });
      });
  };
};
