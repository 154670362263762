import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {  useParams } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import {
  Pagination,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { getPatientDataPointLists } from "../../redux/patientDataPointList/patientDataPointListByPatientID/API";
import dayjs from "dayjs";
import AddDataPointDialog from "./AddDataPointDialog";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridCellParams,
} from "@mui/x-data-grid";
import { getPatientCaseList } from "../../redux/effects/ApiCall";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import EditIcon from "@mui/icons-material/Edit";
import { PatientDatapointList } from "../../redux/patientDataPointList/patientDataPointListByPatientID/Model";
import DataPointHistoryDialog from "./DataPointHistoryDialog";
import { getExtractObservationByPatientId } from "../../redux/getExtractObservationByPatientId/API";

export default function PatientDataObservationListDetails() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const showDocument = (
    element: string,
    description: string,
    dataDate: string
  ) => {
    setDocViwerActive(true);
    setImageInfo(element);
    setDescriptionInfo(description);
    setDataDate(dataDate);
  };
  const editDocument = (value: any, enableDialog: boolean) => {
    setSelectedItem(value);
    setShowAddDataPointDialog(enableDialog);
  };

  const [showHistoryDataPointDialog, setShowHistoryDataPointDialog] =
    React.useState(false);
  const [viewHistoryId, setViewHistoryID] = React.useState("");
  const viewHistoryOpen = (
    dataPointHistoryId: PatientDatapointList,
    enableHisoryDialog: boolean
  ) => {
    setViewHistoryID(String(dataPointHistoryId.id));
    setShowHistoryDataPointDialog(enableHisoryDialog);
  };

  const [page, setPage] = React.useState(1);
  const [imageInfo, setImageInfo] = React.useState("");
  const [descriptionInfo, setDescriptionInfo] = React.useState("");
  const [dataDate, setDataDate] = React.useState("");
  // encounter list api call ...
  useEffect(() => {
    dispatch(getExtractObservationByPatientId(patientid, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const caseHistoryPtName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName
  );
  const [patientName, setPatientName] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getPatientDataPointLists(patientid, page));
    }
  };
  useEffect(() => {
    patientName !== ""
      ? setPageTitle(`Patient Observation List of ${patientName}`)
      : setPageTitle("");
  }, [patientName]);

  useEffect(() => {
    if (caseHistoryPtName === undefined) {
      dispatch(getPatientCaseList(patientid));
    }
    if (caseHistoryPtName !== undefined) {
      setPatientName(caseHistoryPtName);
    } else setPatientName("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseHistoryPtName]);

  const patientPointDataList = useSelector(
    (state: AppState) => state.patientDataPointList
  );

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientDataPointLists(patientid, value));
  };
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    patientPointDataList?.pageCount !== undefined
      ? setPageListCount(String(patientPointDataList?.pageCount))
      : setPageListCount("0");

    patientPointDataList?.patientDemographicsRes?.count !== undefined
      ? setListCount(
          String(patientPointDataList?.patientDemographicsRes?.count)
        )
      : setListCount("0");

    if (
      patientPointDataList?.patientDemographicsRes?.patientDatapointList !==
      undefined
    ) {
      let dataPointArr =
        patientPointDataList?.patientDemographicsRes?.patientDatapointList.map(
          (element: any) => ({
            id: element.id,
            patientId: element.patientId,
            description: element.description,
            startDate: dayjs(element.startDate).format(
              "dddd, MMMM D, YYYY h:mm A"
            ),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
          })
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(String(patientPointDataList?.pageCount));
    }
  }, [patientPointDataList]);

  const [selectedItem, setSelectedItem] = React.useState<any>(
    ({} as any) || undefined
  );
  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            showDocument(
              params.row.image,
              params.row.description,
              params.row.startDate
            );
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            editDocument(params.row, true);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "simpleHistory",
      headerName: "View Observation Edit History",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => viewHistoryOpen(params.row, true)}
        >
          View History
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const [docViwerActive, setDocViwerActive] = React.useState(false);
  const handleDocViewerChange = React.useCallback(
    () => setDocViwerActive(!docViwerActive),
    [docViwerActive]
  );
  const docViewerComponent = (
    <Dialog fullScreen open={docViwerActive} onClose={handleDocViewerChange}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h5">
              {patientName}'s health data of {descriptionInfo} recorded on{" "}
              {dataDate}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleDocViewerChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <iframe
                src={imageInfo}
                title="Small document"
                width="900"
                height="800"
              >
                This is an embedded{" "}
                <a
                  target="_blank"
                  href="http://office.com"
                  rel="noopener noreferrer"
                >
                  Microsoft Office
                </a>{" "}
                document, powered by{" "}
                <a
                  target="_blank"
                  href="http://office.com/webapps"
                  rel="noopener noreferrer"
                >
                  Office Online
                </a>
                .
              </iframe>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
  const [showAddDataPointDialog, setShowAddDataPointDialog] =
    React.useState(false);
  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
    setSelectedItem(undefined);
  };

  const updateHistoryDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowHistoryDataPointDialog(passedVal);
    setSelectedItem(undefined);
  };

  return (
    <Box>
      {patientPointDataList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={pageTitle}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={reloadActionFromChild}
              />
            </Grid>
          </Grid>
          {patientPointDataTable}
          {docViewerComponent}
          {showAddDataPointDialog ? (
            <AddDataPointDialog
              itemId={patientid}
              addDataPointDialogEnable={showAddDataPointDialog}
              addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
              value={selectedItem}
              page={page}
            />
          ) : null}
          {showHistoryDataPointDialog ? (
            <DataPointHistoryDialog
              itemId={viewHistoryId}
              addDataPointDialogEnable={showHistoryDataPointDialog}
              historyDataPointDialogUpdateState={
                updateHistoryDataPointDialogValFromChild
              }
            />
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
}
