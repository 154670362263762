import {
  LogOutDetails,
  LogOutActionTypes,
  LOG_OUT_FAIL,
  LOG_OUT_LOADING,
  LOG_OUT_SUCCESS,
} from "./ActionTypes";
import { LogOutResponse } from "./Model";

const initialStateGetPosts: LogOutDetails = {
  loading: false,
  imageUploadResponse: {} as LogOutResponse,
  message: "",
  status: 0,
};
export const logOutReducer = (
  state = initialStateGetPosts,
  action: LogOutActionTypes
): LogOutDetails => {
  switch (action.type) {
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadResponse: action.payload,
      };
    case LOG_OUT_LOADING:
      return {
        ...state,
        loading: true,
        imageUploadResponse: {} as LogOutResponse,
      };
    case LOG_OUT_FAIL:
      return {
        ...state,
        imageUploadResponse: {} as LogOutResponse,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
