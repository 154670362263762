import axios from "axios";
import Url from "../../../../ApiConfig";
import { Dispatch } from "react";
import { PatientListTypes, PATIENT_LIST_FAIL, PATIENT_LIST_LOADING, PATIENT_LIST_SUCCESS } from "./ActionTypes";
import { getPatientListActions } from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

// Active Corporate List API
export const getPatientList = (teamId: any) => {
    return function (dispatch: Dispatch<PatientListTypes>) {
        dispatch({
            type: PATIENT_LIST_LOADING,
        });
        setupInterceptorsTo(axios)
            .post(
                Url.baseUrl +
                `/adminapp/api/adminActions/patient_list_by_patientTeam_id`,
                { "patientTeamProfileId": teamId }, { headers }
            )
            .then((res) => {
                let patientList = res.data.patientList;
                let status = res.data.status;
                dispatch(getPatientListActions(patientList, status));
                dispatch({
                    type: PATIENT_LIST_SUCCESS,
                    payload: patientList,
                    status: status
                });
            })
            .catch((error) => {
                let status = error.response.data.status;
                dispatch({
                    type: PATIENT_LIST_FAIL,
                    status: status,
                });
            });
    };
};