import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Grid,
  Switch,
  Card,
  CardContent,
  TextField,
  CardActions,
  Button,
  styled,
  FormControlLabel,
} from "@mui/material";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import AdminLoader from "../../AdminLoader";
import { showPatientDataPointHistoryApi } from "../../../redux/SimpleHistory/API";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import dayjs from "dayjs";
import { ReviewNeededBody } from "../../../redux/patientDataDetails/reviewNeeded/Model";
import { reviewNeededApiCall } from "../../../redux/patientDataDetails/reviewNeeded/ApiCall";
import MWResponseBanner from "../../../component/MWResponseBanner";

type Props = {
  itemId?: string | any;
  addDataPointDialogEnable?: boolean | any;
  historyDataPointDialogUpdateState?: boolean | any;
};

export default function DataPointHistoryDialog({ itemId }: Props)  {
  const editHistoryList = useSelector((state: AppState) => state.showHistory);
  const dispatch = useDispatch();
  const updatedBannerState = (passedVal: any) => {
    setBannerOpen(passedVal);
  };
  const [review, setReview] = useState("");
  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState("");
  const [bannerMsg, setBannerMsg] = useState("");
  const reviewNeededValue = useSelector(
    (state: AppState) => state.reviewNeeded
  );
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (
      editHistoryList?.updatePatientDataPointRes
        ?.patientDetailsChangeHistoryList !== undefined
    ) {
      editHistoryList?.updatePatientDataPointRes
        ?.patientDetailsChangeHistoryList[0]?.history_object?.reviewNeeded ===
      false
        ? setIsEdit(false)
        : setIsEdit(true);
    }
    if (
      editHistoryList?.updatePatientDataPointRes
        ?.patientDetailsChangeHistoryList !== undefined
    ) {
      editHistoryList?.updatePatientDataPointRes
        ?.patientDetailsChangeHistoryList[0]?.history_object?.reviewNeeded ===
        false &&
      editHistoryList?.updatePatientDataPointRes
        ?.patientDetailsChangeHistoryList[0]?.history_object
        ?.commentForReview !== ""
        ? setIsEdit(true)
        : setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editHistoryList]);

  const submitReview = () => {
    let reviewNeededBody = {
      patientDetailsId: String(itemId),
      reviewNeeded: checked,
      commentForReview: review,
    } as ReviewNeededBody;
    if (review !== "") {
      dispatch(reviewNeededApiCall(reviewNeededBody));
    } else {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg("Please enter review");
    }
  };
  const [checked, setChecked] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked === false) {
      setIsEdit(false);
    }
    if (event.target.checked === true) {
      setIsEdit(false);
    }
  };

  const NeedReviewSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  useEffect(() => {
    if (reviewNeededValue?.reviewNeededRes?.message !== undefined) {
      dispatch(showPatientDataPointHistoryApi(itemId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewNeededValue]);
  useEffect(() => {
    if (
      editHistoryList?.updatePatientDataPointRes
        ?.patientDetailsChangeHistoryList !== undefined
    ) {
      let obj =
        editHistoryList?.updatePatientDataPointRes
          ?.patientDetailsChangeHistoryList[0]?.history_object?.reviewNeeded;
      setChecked(obj);
    } else {
      setChecked(false);
      setReview("");
    }
  }, [editHistoryList]);

  useEffect(() => {
    if (reviewNeededValue?.successMsg !== "") {
      setBannerOpen(true);
      setBannerColor("success");
      setBannerMsg(reviewNeededValue?.successMsg);
    } else if (reviewNeededValue?.errorMsg !== "") {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg(reviewNeededValue?.errorMsg);
    }
  }, [reviewNeededValue]);

  useEffect(() => {
    dispatch(showPatientDataPointHistoryApi(itemId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (
      editHistoryList?.updatePatientDataPointRes
        ?.patientDetailsChangeHistoryList !== undefined
    ) {
      setReview(
        editHistoryList?.updatePatientDataPointRes
          ?.patientDetailsChangeHistoryList[0]?.history_object?.commentForReview
      );
    }
  }, [editHistoryList]);

  return (
    <Box>
      {editHistoryList?.loading ? <AdminLoader /> : null}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControlLabel
                sx={{ pb: 2 }}
                control={
                  <NeedReviewSwitch
                    defaultChecked
                    onChange={handleChange}
                    checked={checked}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Review Needed"
              />
              <TextField
                id="outlined-basic"
                value={review}
                label="Please write your review"
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setReview(event.target.value);
                }}
                disabled={isEdit}
                multiline
                maxRows={3}
              />
            </CardContent>
            <Box p={1}>
              <MWResponseBanner
                bannerOpen={bannerOpen}
                bannerColor={bannerColor}
                responseMsg={bannerMsg}
                bannerClose={updatedBannerState}
              />
            </Box>
            <CardActions>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  onClick={submitReview}
                  disabled={isEdit}
                >
                  Submit
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Timeline>
        {editHistoryList?.updatePatientDataPointRes
          ?.patientDetailsChangeHistoryList !== undefined ? (
          editHistoryList?.updatePatientDataPointRes
            ?.patientDetailsChangeHistoryList?.length > 0 ? (
            editHistoryList?.updatePatientDataPointRes?.patientDetailsChangeHistoryList?.map(
              (history) => {
                return (
                  <TimelineItem key={history?.history_date}>
                    <TimelineOppositeContent color="textSecondary">
                      <Stack>
                        <Typography>
                          Modified On:{" "}
                          {dayjs(history?.history_date).format(
                            "MMM D, YYYY h:mm A"
                          )}
                        </Typography>
                        <Typography
                          variant="button"
                          sx={{ textTransform: "none" }}
                        >
                          Changed By: {history?.changedBy}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          variant="button"
                          sx={{ textTransform: "none" }}
                        >
                          Comment for Review:{" "}
                          {history?.history_object?.commentForReview}
                        </Typography>
                      </Stack>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="h6" component="span">
                        {history?.history_object?.description.split(":")[0]}
                      </Typography>
                      <Typography>
                        {history?.history_object?.description.split(":")[1]}
                      </Typography>
                      <Typography sx={{ mb: 2 }}>
                        {history?.history_object?.description.split(":")[2]}
                      </Typography>
                      <Box>
                        {history?.history_object?.patientDocument !== null ? (
                          history?.history_object?.patientDocument?.document.includes(
                            "mp4"
                          ) ||
                          history?.history_object?.patientDocument?.document.includes(
                            "pdf"
                          ) ||
                          history?.history_object?.patientDocument?.document.includes(
                            "quicktime"
                          ) ||
                          history?.history_object?.patientDocument?.document.includes(
                            "webm"
                          ) ||
                          history?.history_object?.patientDocument?.document.includes(
                            "3gpp"
                          ) ||
                          history?.history_object?.patientDocument?.document.includes(
                            "x-flv"
                          ) ||
                          history?.history_object?.patientDocument?.document.includes(
                            "x-ms-asf"
                          )  ||
                          history?.history_object?.patientDocument?.document.includes(
                            "mp3"
                          )? (
                            <iframe
                              src={
                                history?.history_object?.patientDocument
                                  ?.document
                              }
                              title="Small document"
                              width="100%"
                              height="100%"
                              allow="fullscreen"
                            ></iframe>
                          ) : (
                            <img
                              src={
                                history?.history_object?.patientDocument
                                  ?.document
                              }
                              srcSet={
                                history?.history_object?.patientDocument
                                  ?.document
                              }
                              alt=""
                              loading="lazy"
                              style={{
                                width: "200px",
                                height: "auto",
                              }}
                            />
                          )
                        ) : null}
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              }
            )
          ) : (
            <Typography variant="h6">No update history available!</Typography>
          )
        ) : (
          <Typography variant="h6">No update history available!</Typography>
        )}
      </Timeline>
    </Box>
  );
}
