import Url from "../../../ApiConfig";
import axios from "axios";
import { Dispatch } from "redux";
import {
    AddDoctorTeamTypes, AddDoctorToCaseTypes, ADD_DOCTORTEAMTYPE, ADD_DOCTORTEAMTYPE_FAIL,
    ADD_DOCTORTEAMTYPE_LOADING, ADD_DOCTORTEAMTYPE_SUCCESS, ADD_DOCTORTOCASE, ADD_DOCTORTOCASE_FAIL, ADD_DOCTORTOCASE_LOADING,
    ADD_DOCTORTOCASE_SUCCESS, DeleteDoctorFromCaseTypes, DELETE_DOCTORFROMCASE, DELETE_DOCTORFROMCASE_FAIL, DELETE_DOCTORFROMCASE_LOADING, DELETE_DOCTORFROMCASE_SUCCESS, DoctorAddToCaseTypes,
    GET_DOCTOR_TO_CASE, GET_DOCTOR_TO_CASE_LIST_FAIL,
    GET_DOCTOR_TO_CASE_LIST_LOADING, GET_DOCTOR_TO_CASE_LIST_SUCCESS, SelectedDoctorTeamTypes, SelectedSpecialistDoctorTypes,
    SELECTED_DOCTOR_TEAM_TYPE_LIST, SELECTED_DOCTOR_TEAM_TYPE_LIST_FAIL, SELECTED_DOCTOR_TEAM_TYPE_LIST_LOADING,
    SELECTED_DOCTOR_TEAM_TYPE_LIST_SUCCESS, SELECTED_SPECIALIST_DOCTOR_TYPE_LIST, SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_FAIL,
    SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_LOADING, SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS, SpecialistDoctorTypes,
    SPECIALIST_DOCTOR_TYPE_LIST, SPECIALIST_DOCTOR_TYPE_LIST_FAIL, SPECIALIST_DOCTOR_TYPE_LIST_LOADING,
    SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS
} from "./ActionTypes";
import {
    AddDoctorTeamTypeAction, AddDoctorToCaseAction, AddedDoctorToCaseListAction, DeleteDoctorFromCaseAction,
    SelectedDoctorTeamTypeListAction, SelectedSpecialistDoctorTypeListAction, SpecialistDoctorTypeListAction
} from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

// export const getDoctorTeamTypeList = (caseid: any) => {
//     return function (dispatch: Dispatch<DoctorTeamTypes>) {
//         dispatch({
//             type: DOCTOR_TEAM_TYPE_LIST_LOADING,
//         });
//         dispatch(DoctorTeamTypeListAction(DOCTOR_TEAM_TYPE_LIST));
//         setupInterceptorsTo(axios).post(Url.baseUrl + `/patientapp/api/patientMedicalData/doctor_team_typeList`, { "caseId": caseid }, { headers })
//             .then((result) => {
//                 if (result) {
//                     let values = result.data.doctorTeamTypeList;
//                     dispatch({
//                         type: DOCTOR_TEAM_TYPE_LIST_SUCCESS,
//                         payload: values,
//                     });
//                 }
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: DOCTOR_TEAM_TYPE_LIST_FAIL,
//                 });
//             });
//     };
// };

export const editDoctorTeamType = (state = {}) => {
    return function (dispatch: Dispatch<AddDoctorTeamTypes>) {
        dispatch({
            type: ADD_DOCTORTEAMTYPE_LOADING,
        });
        dispatch(AddDoctorTeamTypeAction(ADD_DOCTORTEAMTYPE));
        setupInterceptorsTo(axios).post(Url.baseUrl + "/patientapp/api/patient/edit_patient_case", state, { headers })
            .then((result) => {
                if (result) {
                    dispatch({
                        type: ADD_DOCTORTEAMTYPE_SUCCESS,
                        payload: result.data,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: ADD_DOCTORTEAMTYPE_FAIL,
                });
            });
    };
};

export const selectedDoctorTeamTypeList = (caseid: any) => {
    return function (dispatch: Dispatch<SelectedDoctorTeamTypes>) {
        dispatch({
            type: SELECTED_DOCTOR_TEAM_TYPE_LIST_LOADING,
        });
        dispatch(SelectedDoctorTeamTypeListAction(SELECTED_DOCTOR_TEAM_TYPE_LIST));
        setupInterceptorsTo(axios).post(Url.baseUrl + `/patientapp/api/patientMedicalData/doctorTeamType_for_patientCase`, { "caseId": caseid }, { headers })
            .then((result) => {
                if (result) {
                    let values = result.data.caseDoctorTeamTypelist[0].value;
                    console.log("values", values);
                    dispatch({
                        type: SELECTED_DOCTOR_TEAM_TYPE_LIST_SUCCESS,
                        payload: values,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SELECTED_DOCTOR_TEAM_TYPE_LIST_FAIL,
                });
            });
    };
};

export const getSpecialistDoctorList = () => {
    return function (dispatch: Dispatch<SpecialistDoctorTypes>) {
        dispatch({
            type: SPECIALIST_DOCTOR_TYPE_LIST_LOADING,
        });
        dispatch(SpecialistDoctorTypeListAction(SPECIALIST_DOCTOR_TYPE_LIST));
        setupInterceptorsTo(axios).get(Url.baseUrl + `/secondopinionapp/api/doctorCategory/doctor_category_list`, { headers })
            .then((result) => {
                if (result) {
                    let values = result.data.doctorCategoryList;
                    dispatch({
                        type: SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS,
                        payload: values,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SPECIALIST_DOCTOR_TYPE_LIST_FAIL,
                });
            });
    };
};

export const getSelectedSpecialistDoctorList = (selectedCategory: any) => {
    return function (dispatch: Dispatch<SelectedSpecialistDoctorTypes>) {
        dispatch({
            type: SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_LOADING,
        });
        dispatch(SelectedSpecialistDoctorTypeListAction(SELECTED_SPECIALIST_DOCTOR_TYPE_LIST));
        setupInterceptorsTo(axios).post(Url.baseUrl + `/patientapp/api/search-doctor/`, { "doctorCategoryId": String(selectedCategory) }, { headers })
            .then((result) => {
                if (result) {
                    let values = result.data.doctorsList;
                    dispatch({
                        type: SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS,
                        payload: values,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_FAIL,
                });
            });
    };
};

export const addSpecialistDoctor = (stateobj: {}) => {
    return function (dispatch: Dispatch<AddDoctorToCaseTypes>) {
        dispatch({
            type: ADD_DOCTORTOCASE_LOADING,
        });
        dispatch(AddDoctorToCaseAction(ADD_DOCTORTOCASE));
        setupInterceptorsTo(axios).post(Url.baseUrl + `/patientapp/api/patientMedicalData/add_doctorTo_patientCase`,
            stateobj, { headers })
            .then((result) => {
                if (result) {
                    let values = result.data;
                    console.log("ADDRESPONSE", values);
                    dispatch({
                        type: ADD_DOCTORTOCASE_SUCCESS,
                        payload: values,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: ADD_DOCTORTOCASE_FAIL,
                });
            });
    };
};

export const getaddedSpecialistDoctor = (caseid: any) => {
    return function (dispatch: Dispatch<DoctorAddToCaseTypes>) {
        dispatch({
            type: GET_DOCTOR_TO_CASE_LIST_LOADING,
        });
        dispatch(AddedDoctorToCaseListAction(GET_DOCTOR_TO_CASE));
        setupInterceptorsTo(axios).post(Url.baseUrl + `/patientapp/api/patientMedicalData/doctorList_for_patientCase`,
            { "caseId": caseid }, { headers })
            .then((result) => {
                if (result) {
                    let values = result.data.patientCaseDoctorlist;
                    console.log("RESPONSE", values);
                    dispatch({
                        type: GET_DOCTOR_TO_CASE_LIST_SUCCESS,
                        payload: values,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_DOCTOR_TO_CASE_LIST_FAIL,
                });
            });
    };
};

export const deleteSpecialistDoctor = (stateobj: {}) => {
    return function (dispatch: Dispatch<DeleteDoctorFromCaseTypes>) {
        dispatch({
            type: DELETE_DOCTORFROMCASE_LOADING,
        });
        dispatch(DeleteDoctorFromCaseAction(DELETE_DOCTORFROMCASE));
        setupInterceptorsTo(axios).post(Url.baseUrl + `/patientapp/api/patientMedicalData/delete_selected_doctor`,
            stateobj, { headers })
            .then((result) => {
                if (result) {
                    let values = result.data;
                    console.log("DeleteRESPONSE", values);
                    dispatch({
                        type: DELETE_DOCTORFROMCASE_SUCCESS,
                        payload: values,
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_DOCTORFROMCASE_FAIL,
                });
            });
    };
};
