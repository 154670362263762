import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import AdminLoader from "../AdminLoader";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getPatientListByHealthcamp } from "../../redux/patientListOfHealthcamp/API";
import { getHealthcampListValue } from "../../redux/healthcampList/API";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  GetPatientListByHealthCampBody,
  GetPatientListByHealthCampMainBody,
  PatientList,
} from "../../redux/patientListOfHealthcamp/Model";
import dayjs from "dayjs";
import MWExceptionList from "../../component/MWExceptionList";
import MWPageTitle from "../../component/MWPageTitle";
import { HealthCampList } from "../../redux/healthcampList/Model";
import { clearSearchAPIRes } from "../../redux/PatientSearchApi/API";
import {
  PatientSearchByHealthCampIdBody,
  SearchPatientList,
} from "../../redux/PatientSearchByHealcampId/Model";
import {
  clearAPIRes,
  patientSearchByHealthCampIdApi,
} from "../../redux/PatientSearchByHealcampId/API";
import PageLayout from "../Layout/PageLayout";

export default function PatientListByHealthCamp() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { healthcampid } = useParams() as {
    healthcampid: string;
  };

  const [ptId, setPtId] = useState("");
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      history.push(`/patientdetails/${ptId}`);
    }
  };
  const AdminAllHealthcampList = useSelector(
    (state: AppState) => state.healthcampListValue
  );

  const searchValueList = useSelector(
    (state: AppState) => state.searchPatientByHealthCampIdValue
  );

  const patientSearchByHealthCampIdRes =
    searchValueList.PatientSearchByHealthcampIdRes.patientList;

  const handleClick = () => {
    history.push(`/patientregistration/${healthcampid}`);
    dispatch(clearAPIRes());
    dispatch(clearSearchAPIRes());
  };
  const [selectedFilter, setSelectedFilter] = useState<any>(null);

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    if (selectedFilter !== null) {
      const filterPatientListBody = {
        healthCampId: healthcampid,
        orderByParam: selectedFilter.value!,
      } as GetPatientListByHealthCampBody;
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: value,
        } as GetPatientListByHealthCampMainBody)
      );
    } else {
      const filterPatientListBody = {
        healthCampId: healthcampid,
      } as GetPatientListByHealthCampBody;
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: value,
        } as GetPatientListByHealthCampMainBody)
      );
    }
  };
  const filterList = [
    { label: "First Name (Asc)", value: "firstName" },
    { label: "First Name (Desc)", value: "-firstName" },
    { label: "Create Date (Asc)", value: "createDate" },
    { label: "Create Date (Desc)", value: "-createDate" },
    { label: "Modified Date (Asc)", value: "modifiedDate" },
    { label: "Modified Date (Desc)", value: "-modifiedDate" },
    { label: "Pin code (Asc)", value: "pincode" },
    { label: "Pin code (Desc)", value: "-pincode" },
  ];

  const changeFilter = (selectedFilterVal: any) => {
    setSelectedFilter(selectedFilterVal);
    if (selectedFilterVal !== null) {
      // Call API with "selectedFilterVal"
      const filterPatientListBody = {
        healthCampId: healthcampid,
        orderByParam: selectedFilterVal.value!,
      } as GetPatientListByHealthCampBody;
      // dispatch(getFilteredHealthCampPatientList(filterPatientListBody));
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: page,
        } as GetPatientListByHealthCampMainBody)
      );
    }
  };
  const [campName, setCampName] = useState("");
  const [listCount, setListCount] = React.useState("");
  const [searchPatient, setSearchPatient] = useState("");
  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const patientSearchByHealthcampBody = {
    searchKey: searchPatient,
    healthCampId: healthcampid,
  } as PatientSearchByHealthCampIdBody;

  useEffect(() => {
    if (selectedFilter !== null) {
      const filterPatientListBody = {
        healthCampId: healthcampid,
        orderByParam: selectedFilter.value!,
      } as GetPatientListByHealthCampBody;
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: 1,
        } as GetPatientListByHealthCampMainBody)
      );
    } else {
      const filterPatientListBody = {
        healthCampId: healthcampid,
      } as GetPatientListByHealthCampBody;
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: 1,
        } as GetPatientListByHealthCampMainBody)
      );
    }
    dispatch(getHealthcampListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      AdminAllHealthcampList?.healthcampListValue?.HealthCampList !== undefined
    ) {
      var result =
        AdminAllHealthcampList?.healthcampListValue?.HealthCampList.find(
          (obj: HealthCampList) => {
            return obj.id === Number(healthcampid);
          }
        )?.displayName;
      if (result !== null) {
        setCampName(result! + " Patient List");
      }
    }
    // eslint-disable-next-line
  }, [AdminAllHealthcampList]);

  useEffect(() => {
    searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages !== undefined
      ? setPageListCount(
          String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages)
        )
      : setPageListCount("0");
    if (searchValueList?.PatientSearchByHealthcampIdRes?.count !== undefined) {
      console.log(
        "searchValueList?.PatientSearchByHealthcampIdRes?.count",
        String(searchValueList?.PatientSearchByHealthcampIdRes?.count)
      );
      setListCount(
        String(searchValueList?.PatientSearchByHealthcampIdRes?.count)
      );
    }
    if (patientSearchByHealthCampIdRes !== undefined) {
      let dataPointArr = patientSearchByHealthCampIdRes.map(
        (element: SearchPatientList) => ({
          id: element.patientId,
          firstname: element.givenName,
          lastname: element.familyName,
          username: element.userName,
          phone: element.phone,
          patientId: element.patientId,
          dob: dayjs(element.dob).format("MMMM D, YYYY"),
          createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
          modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
        })
      );
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(
        String(searchValueList?.PatientSearchByHealthcampIdRes?.numberOfPages)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueList]);

  function reloadPatientListApi() {
    if (selectedFilter !== null) {
      const filterPatientListBody = {
        healthCampId: healthcampid,
        orderByParam: selectedFilter.value!,
      } as GetPatientListByHealthCampBody;
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: page,
        } as GetPatientListByHealthCampMainBody)
      );
    } else {
      const filterPatientListBody = {
        healthCampId: healthcampid,
      } as GetPatientListByHealthCampBody;
      dispatch(
        getPatientListByHealthcamp({
          patientList: filterPatientListBody,
          pageNo: page,
        } as GetPatientListByHealthCampMainBody)
      );
    }
  }

  // call Store
  const AdminPatientList = useSelector(
    (state: AppState) => state.healthcampPatientListValue
  );
  const allcaseValues = AdminPatientList.healthcampPatientValue.patientList;
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  useEffect(() => {
    AdminPatientList?.healthcampPatientValue?.numberOfPages !== undefined
      ? setPageListCount(
          String(AdminPatientList?.healthcampPatientValue?.numberOfPages)
        )
      : setPageListCount("0");
    if (AdminPatientList?.healthcampPatientValue?.count !== undefined) {
      setListCount(String(AdminPatientList?.healthcampPatientValue?.count));
    }
    if (allcaseValues !== undefined) {
      let dataPointArr = allcaseValues.map((element: PatientList) => ({
        id: element.patientId,
        firstname: element.givenName,
        lastname: element.familyName,
        username: element.userName,
        phone: element.phone,
        patientId: element.patientId,
        dob: dayjs(element.dob).format("MMMM D, YYYY"),
        createDate: dayjs(element.createDate).format("MMMM D, YYYY"),
        modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(
        String(AdminPatientList?.healthcampPatientValue?.numberOfPages)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminPatientList]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoDetails(params.value);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientId",
      headerName: "Patient Id",
      width: 150,
      align: "left",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "First name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "lastname",
      headerName: "Last name",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone No",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "username",
      headerName: "User Name",
      editable: true,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "createDate",
      headerName: "Create date",
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified date",
      type: "string",
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
  ];
  const PatientListOfHealthcamp = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={filterList}
                value={selectedFilter}
                onChange={(_event: any, newValue: any) =>
                  changeFilter(newValue)
                }
                disabled={AdminPatientList?.loading}
                getOptionLabel={(option) => `${option.label}`}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter "
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Box>
                    <TextField
                      label="Search Patient in this camp"
                      size="small"
                      value={searchPatient}
                      onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                          dispatch(
                            patientSearchByHealthCampIdApi(
                              patientSearchByHealthcampBody,
                              page
                            )
                          );
                          ev.preventDefault();
                        }
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setSearchPatient(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box onKeyDown={keyDownEvent}>
                    <TextField
                      label="Go To Patient ID"
                      size="small"
                      onChange={(event: any) => setPtId(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount) || 0}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {(allcaseValues && allcaseValues.length) ||
        (patientSearchByHealthCampIdRes &&
          patientSearchByHealthCampIdRes.length) ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
  return (
    <>
      {AdminPatientList.loading || searchValueList.loading ? (
        <AdminLoader />
      ) : null}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={campName}
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={reloadPatientListApi}
            />
          </Grid>
          <Grid item>
            <Button onClick={handleClick} variant="contained">
              Registration
            </Button>
          </Grid>
        </Grid>
        {PatientListOfHealthcamp}
      </PageLayout>
    </>
  );
}
