import {
  DemographicsDetailsByPtIdDetails,
  GetDemographicsDetailsByPtIdActionTypes,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
} from "./ActionTypes";
import { GetDemographicsDetailsByPtIdRes } from "./Model";

const initialStateGetPosts: DemographicsDetailsByPtIdDetails = {
  loading: false,
  getDemographicsDetailsByPtIdRes: {} as GetDemographicsDetailsByPtIdRes,
  errRes: "",
};
export const getDemographicsDetailsByPtIdReducer = (
  state = initialStateGetPosts,
  action: GetDemographicsDetailsByPtIdActionTypes
): DemographicsDetailsByPtIdDetails => {
  switch (action.type) {
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicsDetailsByPtIdRes: action.payload,
        errRes: "",
      };
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING:
      return {
        ...state,
        loading: true,
        getDemographicsDetailsByPtIdRes: {} as GetDemographicsDetailsByPtIdRes,
      };
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL:
      return {
        ...state,
        getDemographicsDetailsByPtIdRes: {} as GetDemographicsDetailsByPtIdRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
