import axios from "axios";
import { Dispatch } from "react";
import Url from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { AssignExecutiveResponse, AssignExecutiveBody } from "./Model";
import {
  assignExecutiveAPIResClearAction,
  assignExecutiveErrorAction,
  assignExecutiveLoadingAction,
  assignExecutiveSuccessAction,
  assignExecutiveUpdateAPIMsgAction,
} from "./Actions";
import { AssignExecutiveListActionTypes } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as AssignExecutiveResponse;
export const assignExecutiveApiCall = (payload: AssignExecutiveBody) => {
  return function (dispatch: Dispatch<AssignExecutiveListActionTypes>) {
    dispatch(assignExecutiveLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/adminActions/assign_task_to_admin`,
        payload,
        { headers }
      )
      .then((res) => {
        dispatch(assignExecutiveLoadingAction(false));
        dispatch(assignExecutiveSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(assignExecutiveLoadingAction(false));
        dispatch(
          assignExecutiveErrorAction(
            {} as AssignExecutiveResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const addAssignExecutiveUpdateAPIResMsg = () => {
  return function (dispatch: Dispatch<AssignExecutiveListActionTypes>) {
    dispatch(
      assignExecutiveUpdateAPIMsgAction(
        apiRes as AssignExecutiveResponse,
        "",
        0
      )
    );
  };
};

export const clearAssignExecutiveAPIRes = () => {
  return function (dispatch: Dispatch<AssignExecutiveListActionTypes>) {
    dispatch(
      assignExecutiveAPIResClearAction({} as AssignExecutiveResponse, "", 0)
    );
  };
};
