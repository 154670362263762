import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import {
  Box,
  DialogTitle,
  Typography,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import AdminLoader from "../../AdminLoader";
import { showPatientDataPointHistoryApi } from "../../../redux/SimpleHistory/API";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import dayjs from "dayjs";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  });

type Props = {
  itemId?: string | any;
  addDataPointDialogEnable?: boolean | any;
  historyDataPointDialogUpdateState?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

export default function DataPointHistoryDialog({
  itemId,
  addDataPointDialogEnable,
  historyDataPointDialogUpdateState,
}: Props) {
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    historyDataPointDialogUpdateState(false);
  };

  useEffect(() => {
    dispatch(showPatientDataPointHistoryApi(itemId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    setDialogState(addDataPointDialogEnable);
  }, [addDataPointDialogEnable]);

  const editHistoryList = useSelector((state: AppState) => state.showHistory);

  return (
    <Box>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        PaperProps={{
          style: {
            minHeight: "40vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
        fullWidth
      >
        <Box>{editHistoryList?.loading ? <AdminLoader /> : null}</Box>
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h5">
                View Patient Edit Data History
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Timeline>
            {editHistoryList?.updatePatientDataPointRes
              ?.patientDetailsChangeHistoryList !== undefined ? (
              editHistoryList?.updatePatientDataPointRes
                ?.patientDetailsChangeHistoryList?.length > 0 ? (
                editHistoryList?.updatePatientDataPointRes?.patientDetailsChangeHistoryList?.map(
                  (history) => {
                    return (
                      <TimelineItem key={history?.history_date}>
                        <TimelineOppositeContent color="textSecondary">
                          <Stack>
                            <Typography>
                              {dayjs(history?.history_date).format(
                                "MMM D, YYYY h:mm A"
                              )}
                            </Typography>
                            <Typography
                              variant="button"
                              sx={{ textTransform: "none" }}
                            >
                              Changed By: {history?.changedBy}
                            </Typography>
                          </Stack>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="h6" component="span">
                            {history?.history_object?.description}
                          </Typography>
                          <Box>
                            {history?.history_object?.patientDocument !==
                            null ? (
                              <img
                                src={
                                  history?.history_object?.patientDocument
                                    ?.document
                                }
                                srcSet={
                                  history?.history_object?.patientDocument
                                    ?.document
                                }
                                alt=""
                                loading="lazy"
                                style={{
                                  width: "200px",
                                  height: "auto",
                                }}
                              />
                            ) : null}
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  }
                )
              ) : (
                <Typography variant="h6">
                  No update history available!
                </Typography>
              )
            ) : (
              <Typography variant="h6">No update history available!</Typography>
            )}
          </Timeline>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
