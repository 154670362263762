import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import {
  emailOtpAuthActionTypes,
  EMAIL_OTP_AUTH_FAIL,
  EMAIL_OTP_AUTH_LOADING,
  EMAIL_OTP_AUTH_SUCCESS,
} from "./ActionTypes";
import { emailOtpAuthDetails, emailOtpAuthValue } from "./Model";

export const emailOtpAuthApi = (body: emailOtpAuthDetails) => {
  return function (dispatch: Dispatch<emailOtpAuthActionTypes>) {
    dispatch({
      type: EMAIL_OTP_AUTH_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/providerorganizationapp/email-otp-auth/`, body)
      .then((res) => {
        dispatch({
          type: EMAIL_OTP_AUTH_SUCCESS,
          payload: res.data,
          errRes: "",
        });
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("firstname", res.data.user.first_name);
        localStorage.setItem("lastname", res.data.user.last_name);
      })
      .catch((error) => {
        dispatch({
          type: EMAIL_OTP_AUTH_FAIL,
          payload: {} as emailOtpAuthValue,
          errRes: error.response.data.message,
        });
      });
  };
};
