import React, { useState, useEffect, useCallback } from "react";
import {
  AlertColor,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWExceptionList from "../../../component/MWExceptionList";
import {
  GetAdminNoteListByPatientBody,
  NoteList,
} from "../../../redux/Notes/GetNotesListByPateint/Model";
import dayjs from "dayjs";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { getAdminNoteListByPatient } from "../../../redux/Notes/GetNotesListByPateint/API";
import MWSnackbar from "../../../component/MWSnackbar";
import {
  clearNoteAPIRes,
  updateNote,
} from "../../../redux/Notes/updateNoteStatus/API";
import { AddNoteBody } from "../../../redux/Notes/addNotes/Model";
import { addNote, clearAddNoteAPIRes } from "../../../redux/Notes/addNotes/API";
import AdminLoader from "../../AdminLoader";
type Props = {
  dialogStatus?: boolean | any;
  activeNoteLoader?: boolean | any;
  dialogUpdateState?: boolean | any;
};

export default function ActivePatientCaseNotes({
  dialogStatus,
  activeNoteLoader,
  dialogUpdateState,
}: Props) {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();

  const [notes, setNotes] = useState("");
  const handleNotesChange = useCallback((event: any) => {
    setNotes(event.target.value);
  }, []);

  const [notesError, setNotesError] = useState("");
  const notesErrorDiv = notesError ? (
    <Typography>{notesError}</Typography>
  ) : null;

  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    if (dialogStatus !== undefined) {
      setOpenDialog(dialogStatus);
    }
  }, [dialogStatus]);
  const closeDialog = () => {
    setOpenDialog(false);
    dialogUpdateState(false);
    setNotesError("");
    dispatch(clearAddNoteAPIRes());
  };

  const getDrNoteListBody = {
    patientId: patientid,
    caseId: caseid,
    isActive: true,
  } as GetAdminNoteListByPatientBody;

  const addDrNoteListBody = {
    patientId: patientid,
    caseId: caseid,
    note: notes,
  } as AddNoteBody;

  // Active Notes List Api Value from store
  const NoteListFromAPI = useSelector(
    (state: AppState) => state.drNoteListValue
  );
  const [adminNoteList, setAdminNoteList] = useState([] as any[]);
  const [apiRes, setAPIRes] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    activeNoteLoader(NoteListFromAPI?.loading);
    if (NoteListFromAPI?.errorMsg !== "") {
      setAPIRes(NoteListFromAPI?.errorMsg);
      setSnackBarType("error");
    }
    if (NoteListFromAPI?.getAdminNoteListByPatientRes?.noteList !== undefined) {
      let noteListItem =
        NoteListFromAPI?.getAdminNoteListByPatientRes?.noteList.map(
          (element: NoteList) => {
            return {
              id: element.id,
              note: element?.note !== undefined ? element?.note : "",
              createdDate:
                element?.createdDate !== undefined
                  ? dayjs(element?.createdDate).format("ddd, MM, DD, YYYY")
                  : "",
              createBy:
                element?.createdBy === undefined
                  ? ""
                  : element?.createdBy?.user?.first_name +
                    " " +
                    element?.createdBy?.user?.last_name,
              modifiedDate:
                element?.modifiedDate !== undefined
                  ? dayjs(element?.modifiedDate).format("ddd, MM, DD, YYYY")
                  : "",
              modifiedBy:
                element?.modifiedBy === undefined
                  ? ""
                  : element?.modifiedBy?.user?.first_name +
                    " " +
                    element?.modifiedBy?.user?.last_name,
            };
          }
        );
      setAdminNoteList(noteListItem);
    } else setAdminNoteList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NoteListFromAPI]);

  // Add Notes Api Value from store
  const addNotesVal = useSelector((state: AppState) => state.addNoteRes);
  useEffect(() => {
    console.log(NoteListFromAPI);
    if (addNotesVal?.errorMsg !== "") {
      setAPIRes(addNotesVal?.errorMsg);
      setSnackBarType("error");
    } else if (addNotesVal?.successMsg !== "") {
      setAPIRes(addNotesVal?.successMsg);
      setSnackBarType("success");
      closeDialog();
      dispatch(getAdminNoteListByPatient(getDrNoteListBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNotesVal]);

  const columns: GridColDef[] = [
    { field: "note", headerName: "Note", flex: 1 },
    { field: "createdDate", headerName: "Created Date", flex: 1 },
    {
      field: "createBy",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Modify Status",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            toggleNoteStatus(params.value);
          }}
          sx={{ textTransform: "none" }}
          disabled={updateNotesVal?.loading}
        >
          Edit
        </Button>
      ),
    },
    { field: "modifiedBy", headerName: "Modified By", flex: 1 },
    { field: "modifiedDate", headerName: "Modified Date", flex: 1 },
  ];

  // Add notes with patient & case Id details
  const addNotes = () => {
    if (notes === "") {
      setNotesError("Please add notes");
    } else {
      dispatch(addNote(addDrNoteListBody));
    }
  };

  // Notes Status change API
  function toggleNoteStatus(response: number) {
    dispatch(updateNote(response));
  }
  // update Notes Api Value from store
  const updateNotesVal = useSelector((state: AppState) => state.updateNoteRes);
  useEffect(() => {
    activeNoteLoader(updateNotesVal?.loading);
    if (updateNotesVal?.errorMsg !== "") {
      setAPIRes(updateNotesVal?.errorMsg);
      setSnackBarType("error");
    } else if (updateNotesVal?.updateNoteResRes?.message !== undefined) {
      setAPIRes(updateNotesVal?.updateNoteResRes?.message);
      dispatch(getAdminNoteListByPatient(getDrNoteListBody));
      setSnackBarType("success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNotesVal]);

  useEffect(() => {
    dispatch(getAdminNoteListByPatient(getDrNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddNoteAPIRes());
      dispatch(clearNoteAPIRes());
    }
  };

  // Submit note modal
  const NotesModalMarkup = (
    <Dialog open={openDialog} onClose={closeDialog} fullWidth>
      {addNotesVal?.loading ? <AdminLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h6">Add Note</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          value={notes}
          label="Doctor Notes"
          placeholder="Please provide notes"
          id="notesID"
          onChange={handleNotesChange}
          fullWidth
        />
        <Typography sx={{ color: "#c62828" }}>{notesErrorDiv}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button variant="outlined" onClick={closeDialog}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={addNotes}
              disabled={addNotesVal?.loading}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container maxWidth="xl">
      {adminNoteList.length > 0 ? (
        <DataGrid
          rows={adminNoteList}
          columns={columns}
          pageSize={10}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      ) : (
        <MWExceptionList />
      )}
      {NotesModalMarkup}
      <MWSnackbar
        msg={apiRes}
        type={snackBarType}
        alertClose={checkAlertClose}
      />
    </Container>
  );
}
