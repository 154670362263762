import React, { useState } from "react";
import ActiveTaskList from "./ActiveTaskList";
import InactiveTaskDetails from "./InactiveTaskList";
import AdminLoader from "../AdminLoader";
import { Tab, Box, Tabs } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 1 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Task() {
  const loadingMarkup = <AdminLoader />;
  const [activeTaskLoader, setActiveTaskLoader] = useState(false);
  const [inactiveTaskLoader, setInactiveTaskLoader] = useState(false);
  const [selected, setSelected] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  const pageMarkUp = (
    <Box>
      <Tabs value={selected} onChange={handleTabChange}>
        <Tab label="Active Meeting" {...a11yProps(0)} />
        <Tab label="Inactive Meeting" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={selected} index={0}>
        {<ActiveTaskList activeTaskLoader={setActiveTaskLoader} />}
      </TabPanel>
      <TabPanel value={selected} index={1}>
        {<InactiveTaskDetails inactiveTaskLoader={setInactiveTaskLoader} />}
      </TabPanel>
    </Box>
  );

  return (
    <Box>
      {activeTaskLoader || inactiveTaskLoader ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle title="Task" />
        {pageMarkUp}
      </PageLayout>
    </Box>
  );
}
