import { GetAdminNoteListByPatientRes } from "./Model";
export const PATIENT_NOTE_LIST_LOADING =
  "PATIENT_NOTE_LIST_LOADING";
export const PATIENT_NOTE_LIST_SUCCESS =
  "PATIENT_NOTE_LIST_SUCCESS";
export const PATIENT_NOTE_LIST_FAIL =
  "PATIENT_NOTE_LIST_FAIL";
export const PATIENT_NOTE_LIST_DATA =
  "PATIENT_NOTE_LIST_DATA";
export const PATIENT_NOTE_LIST_UPDATE_API_MSG =
  "PATIENT_NOTE_LIST_UPDATE_API_MSG";
export const PATIENT_NOTE_LIST_UPDATE_API_RES =
  "PATIENT_NOTE_LIST_UPDATE_API_RES";

export interface GetAdminNoteListByPatientState {
  getAdminNoteListByPatientRes: GetAdminNoteListByPatientRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAdminNoteListByPatientLoading {
  type: typeof PATIENT_NOTE_LIST_LOADING;
  loading: boolean;
}
export interface GetAdminNoteListByPatientSuccess {
  type: typeof PATIENT_NOTE_LIST_SUCCESS;
  payload: GetAdminNoteListByPatientRes;
}
export interface GetAdminNoteListByPatientFail {
  type: typeof PATIENT_NOTE_LIST_FAIL;
  payload: GetAdminNoteListByPatientRes;
  errorMsg: string;
  status: number;
}
export interface GetAdminNoteListByPatientUpdateAPIMsg {
  type: typeof PATIENT_NOTE_LIST_UPDATE_API_MSG;
  payload: GetAdminNoteListByPatientRes;
  errorMsg: string;
  status: number;
}
export interface GetAdminNoteListByPatientUpdateAPIRes {
  type: typeof PATIENT_NOTE_LIST_UPDATE_API_RES;
  payload: GetAdminNoteListByPatientRes;
  errorMsg: string;
  status: number;
}

export type GetAdminNoteListByPatientActionTypes =
  | GetAdminNoteListByPatientLoading
  | GetAdminNoteListByPatientSuccess
  | GetAdminNoteListByPatientFail
  | GetAdminNoteListByPatientUpdateAPIMsg
  | GetAdminNoteListByPatientUpdateAPIRes;
