import { GET_LIST } from "../../types/ActionTypes";
import { PatientPointDataListActionType } from "./ActionTypes";
import { PatientDemographicsDetails } from "./Model";

export const getPatientDataPointListAction = (
  patientDemographicsDetails: PatientDemographicsDetails,
  pageCount: number
): PatientPointDataListActionType => {
  return {
    type: GET_LIST,
    payload: patientDemographicsDetails,
    pageCount: pageCount,
  };
};
