import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Box,
  AlertColor,
  Container,
  Stack,
} from "@mui/material";
import {
  RequestAppointmentDataAPIResMsg,
  addBookAppointment,
  clearRequestAppointmentDataAPIRes,
} from "../../../../redux/Patient/PatientRequestAppointment/API";
import MWSnackbar from "../../../../component/MWSnackbar";
import { requestAppointmentDetails } from "../../../../redux/Patient/RequestAppointment/ApiCall";

type Props = {
  caseid?: string | any;
};
export default function RequestAppointment({ caseid }: Props) {
  const dispatch = useDispatch();
  const [title] = useState("Request Appointment");
  const [requestAppointmentSuccessAPIMsg, setRequestAppointmentSuccessAPIMsg] =
    useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const [requestAppointmentErrorAPIMsg, setRequestAppointmentErrorAPIMsg] =
    useState("");
  const [errorMsgType, setErrorMsgType] = useState<AlertColor>("error");
  const [addPhoneNumber, setPhoneNumber] = useState("");
  const bookAppoinmentBody = {
    caseId: caseid,
    phoneNumberForPatientCase: addPhoneNumber,
  };
  // Create Book Appointment API
  const bookAppoinment = () => {
    dispatch(addBookAppointment(bookAppoinmentBody));
  };
  const selectionTypeBody = {
    caseId: caseid,
  };
  let addrequestAppointmentMsgFromAPI = useSelector(
    (state: AppState) => state.addRequestAppointment
  );
  useEffect(() => {
    if (addrequestAppointmentMsgFromAPI?.successMsg !== "") {
      setRequestAppointmentSuccessAPIMsg(
        addrequestAppointmentMsgFromAPI?.successMsg
      );
      setMsgType("success");
      dispatch(requestAppointmentDetails(selectionTypeBody));
    } else if (addrequestAppointmentMsgFromAPI?.errorMsg !== "") {
      setRequestAppointmentErrorAPIMsg(
        addrequestAppointmentMsgFromAPI?.errorMsg
      );
      setErrorMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addrequestAppointmentMsgFromAPI]);

  // RequestAppointment Api Value from store
  let requestAppointmentListFromAPI = useSelector(
    (state: AppState) =>
      state.getrequestAppointmentDetails.getAppointmentListRes
  );

  useEffect(() => {
    if (requestAppointmentListFromAPI?.phoneNumberForPatientCase !== "") {
      setPhoneNumber(requestAppointmentListFromAPI?.phoneNumberForPatientCase);
    }
  }, [requestAppointmentListFromAPI]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearRequestAppointmentDataAPIRes());
    }
  };
  const closeBanner = () => {
    dispatch(RequestAppointmentDataAPIResMsg());
    setRequestAppointmentErrorAPIMsg("");
  };
  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bannerPageBody = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>{title}</Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={bookAppoinment}
                  sx={{ textTransform: "none" }}
                >
                  Request Appointment
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          <TextField
            label="Phone Number"
            type="number"
            value={addPhoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            placeholder="Enter Phone Number"
            fullWidth
            prefix="+91"
            id="emailID"
          />
          {requestAppointmentErrorAPIMsg !== "" ? (
            <Box py={2}>
              <Alert severity={errorMsgType} onClose={closeBanner}>
                {requestAppointmentErrorAPIMsg}
              </Alert>
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {bannerPageBody}
          <MWSnackbar
            msg={requestAppointmentSuccessAPIMsg}
            type={msgType}
            alertClose={checkAlertClose}
          />
        </Stack>
      </Container>
    </Box>
  );
}
