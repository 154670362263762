import {
  ProviderReportListDetails,
  ProviderReportListActionTypes,
  PROVIDER_REPORT_LIST_FAIL,
  PROVIDER_REPORT_LIST_LOADING,
  PROVIDER_REPORT_LIST_SUCCESS,
  PROVIDER_REPORT_LIST_API_RES,
  PROVIDER_REPORT_LIST_API_MSG,
} from "./ActionTypes";
import { ReportListResponse } from "./Model";

const initialStateGetPosts: ProviderReportListDetails = {
  loading: false,
  providerReportListResponse: {} as ReportListResponse,
  message: "",
  status: 0,
  errRes: "",
};
export const providerReportListReducer = (
  state = initialStateGetPosts,
  action: ProviderReportListActionTypes,
): ProviderReportListDetails => {
  switch (action.type) {
    case PROVIDER_REPORT_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PROVIDER_REPORT_LIST_SUCCESS:
      return {
        ...state,
        providerReportListResponse: action.payload,
      };

    case PROVIDER_REPORT_LIST_FAIL:
      return {
        ...state,
        providerReportListResponse: action.payload,
        message: action.message,
        status: action.status,
      };
    case PROVIDER_REPORT_LIST_API_MSG:
      return {
        ...state,
        providerReportListResponse: action.payload,
        message: action.message,
        status: action.status,
      };
    case PROVIDER_REPORT_LIST_API_RES:
      return {
        ...state,
        providerReportListResponse: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
