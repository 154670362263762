import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import {
  AdminGetQuestionListActionTypes, AnswerTypes, ANSWER_LOADING,
  ANSWER_STATUS, ANSWER_SUCCESS, QUESTION_LIST_FAIL, QUESTION_LIST_LOADING,
  QUESTION_LIST_SUCCESS, QUESTION_VERIFY_FAIL, QUESTION_VERIFY_LOADING,
  QUESTION_VERIFY_SUCCESS, verifyActionTypes
} from "./ActionTypes";

import Url from "../../ApiConfig";
import { addAnswerAction, getQuestionListAction, verifyQuestionAction } from "./Action";
import { setupInterceptorsTo } from "../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const getQuestionListValue = () => {
  return function (dispatch: Dispatch<AdminGetQuestionListActionTypes>) {
    dispatch({
      type: QUESTION_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl +
        "/questionandanswerapp/api/question/list_Question_for_admin",
        { headers }
      )
      .then((res) => {
        let questionData = res.data.questionList;
        dispatch(getQuestionListAction(questionData));
        dispatch({
          type: QUESTION_LIST_SUCCESS,
          payload: questionData,
        });
      })
      .catch((error) => {
        dispatch({
          type: QUESTION_LIST_FAIL,
        });
      });
  };
};

// Answer Question By Admin
export const answerQuestion = (addAnswerBody: Object) => {
  return function (dispatch: Dispatch<AnswerTypes>) {
    dispatch({
      type: ANSWER_LOADING,
    });
    dispatch(addAnswerAction(ANSWER_STATUS));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/questionandanswerapp/api/question/answer_Question`,
        addAnswerBody, { headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: ANSWER_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ANSWER_STATUS,
          payload: error.response.data,
        });
      });
  };
};

// Verify Question
export const setVerifyValue = (state = {}) => {
  return function (dispatch: Dispatch<verifyActionTypes>) {
    dispatch({
      type: QUESTION_VERIFY_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + "/questionandanswerapp/api/question/verify_Question",
        state,
        { headers }
      )
      .then((res) => {
        let questionData = res.data;
        dispatch(verifyQuestionAction(questionData));
        dispatch({
          type: QUESTION_VERIFY_SUCCESS,
          payload: questionData,
        });
      })
      .catch((error) => {
        let questionData = error.response.data;
        dispatch({
          type: QUESTION_VERIFY_FAIL,
          payload: questionData,
        });
      });
  };
};