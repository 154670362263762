import { ReportListResponse } from "./Model";

export const PROVIDER_REPORT_LIST_LOADING = "PROVIDER_REPORT_LIST_LOADING";
export const PROVIDER_REPORT_LIST_SUCCESS = "PROVIDER_REPORT_LIST_SUCCESS";
export const PROVIDER_REPORT_LIST_FAIL = "PROVIDER_REPORT_LIST_FAIL";
export const ADD_PATIENT_DATA = "ADD_PATIENT_DATA";
export const PROVIDER_REPORT_LIST_API_MSG = "PROVIDER_REPORT_LIST_API_MSG";
export const PROVIDER_REPORT_LIST_API_RES = "PROVIDER_REPORT_LIST_RES";

export interface ProviderReportListDetails {
  providerReportListResponse: ReportListResponse;
  loading: boolean;
  message: string;
  status: number;
  errRes: string;
}
export interface ProviderReportListLoading {
  type: typeof PROVIDER_REPORT_LIST_LOADING;
  loading: boolean;
}
export interface ProviderReportListSuccess {
  type: typeof PROVIDER_REPORT_LIST_SUCCESS;
  payload: ReportListResponse;
}
export interface ProviderReportListFail {
  type: typeof PROVIDER_REPORT_LIST_FAIL;
  payload: ReportListResponse;
  message: string;
  status: number;
}

export interface ProviderReportListUpdateAPIMsg {
  type: typeof PROVIDER_REPORT_LIST_API_MSG;
  payload: ReportListResponse;
  message: string;
  status: number;
}
export interface ProviderReportListUpdateAPIRes {
  type: typeof PROVIDER_REPORT_LIST_API_RES;
  payload: ReportListResponse;
  message: string;
  status: number;
}
interface ProviderReportListAction {
  type: typeof ADD_PATIENT_DATA;
  payload: ReportListResponse;
  errRes: string;
}

export type ProviderReportListActionTypes =
  | ProviderReportListAction
  | ProviderReportListLoading
  | ProviderReportListSuccess
  | ProviderReportListFail
  | ProviderReportListUpdateAPIMsg
  | ProviderReportListUpdateAPIRes;
