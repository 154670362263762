import * as React from "react";
import { Box, Container } from "@mui/material";
export default function InformConsent() {
  const pagebody = (
    <Box>
      <p>
        1. I understand that telemedicine services involve the use of electronic
        communications to enable health care providers at different locations to
        share individual patient medical information for the purpose of
        improving patient care. Providers may include primary care
        practitioners, specialists, and/or subspecialists. The information may
        include any one or more of the following: 
      </p>
      <p>a. Patient medical records </p>
      <p>b. Medical images </p>
      <p>c. Live two-way audio and video </p>
      <p>d. Output data from medical devices and sound and video files </p>
      <p>
        2. By using the AIMS2Health Telemedicine Portal, I agree to receive
        AIMS2Health Pvt Ltd.’s telehealth/telemedicine services. I understand
        that Telemedicine encompasses a broad variety of technologies and modes
        to deliver medical care, health, and education services. Four distinct
        telemedicine categories currently exist:
      </p>
      <p>
        a. Live audio and/or video (synchronous communication), b.
        Store-and-forward text, audio or video (asynchronous communication), c.
        Mobile health, and d. Remote-patient monitoring.
      </p>
      <p>
        3. I understand that Telemedicine Services can be used for: a.
        Diagnostic purposes. b. Follow-up of patients exposed to risks, either
        by way of prevention or by way of post-therapy. c. Requesting medical
        advices from specialists, including second opinion. d. Helping in the
        therapeutic decision-making process. e. Prescribing health products or
        medical services. f. Performing telemonitoring of patients’ care.
      </p>
      <p>
        4. I understand that I would have to use a computer or cell/mobile phone
        systems to access AIMS2Health Pvt Ltd.’s telemedicine services and that
        I am wholly and solely responsible for any costs to obtain/access any
        necessary equipment, accessories, or software to take part in
        AIMS2Health Pvt Ltd.’s telemedicine services.
      </p>
      <p>
        5. I understand that telemedicine requires me to have a secure
        connection to internet access. It is my responsibility to ensure that I
        am in a private area and will not hold AIMS2HealthPvt Ltd responsible
        for information that may be found by others on any electronic devices
        that I use for accessing telemedicine services.
      </p>
      <p>
        6. AIMS2Health Pvt Ltd has explained to me how the video conferencing
        technology will be used to affect such a consultation. I understand that
        this consultation will not be the same as a direct patient/health care
        provider visit due to the fact that I will not be in the same room as my
        health care provider. During my visit, my AIMS2Health provider and I
        will be able to see and/or speak with each other from remote locations
        if I want to do so.
      </p>
      <p></p>
      <p>
        7. However, I understand that there are both mandatory and permissive
        exceptions to confidentiality, including, but not limited to reporting
        child, elder, and dependent adult abuse; expressed threats of violence
        towards an ascertainable victim; and where I make my mental or emotional
        state an issue in a legal proceeding.
      </p>
      <p>
        8. I understand that others (authorized individuals) may also be present
        during the video call appointment other than my healthcare provider and
        specialty health care provider; this may be for administrative or
        operational reasons or to assist in the use of a medical equipment for
        clinical examination purposes or other clinical purposes; and that these
        individuals will also maintain confidentiality of the my private and
        confidential health information obtained through the AIMS2Health
        telemedicine platform. I further understand that I will be informed of
        their presence during the consultation and thus will have the right to
        request the following:
      </p>
      <p>
        a. omit specific details of my medical history/physical examination that
        are personally sensitive to me; b. ask non-medical personnel to leave
        the telehealth examination room; and/or c. (terminate the telehealth
        appointment at any time. I understand that I may exclude anyone from any
        site during my telehealth service but acknowledge that if I choose to
        exclude the above-named health care practitioner from my telemedicine
        service, AIMS2Health Pvt Ltd may terminate the doctor or counsellor
        patient or provider-patient relationship at that time.
      </p>
      <p>
        9. I understand that telemedicine services may include physical and
        mental health evaluation, assessment, consultation, treatment planning,
        and therapy. Teleconsultation will occur primarily through interactive
        audio and video communications. I, therefore, hereby authorize
        AIMS2Health Pvt Ltd to use their telehealth practice platform for
        communicating with me for collecting information for diagnosis and
        management of medical condition, including providing answers to specific
        health/illness-related questions, concerns and expectations. I agree to
        one or more interactive sessions via video call or, for technical
        reasons (such as internet being erratic or its speed being inadequate)
        and/or other practical reasons also or only via phone call, WhatsApp
        messaging, and/or emails.
      </p>
      <p>
        10. I understand that the information disclosed by me during the course
        telemedicine services is generally confidential. I understand that the
        laws in India that protect the confidentiality of my personal and health
        information also apply to telemedicine. As such, I understand that any
        and all personal or health information shared by me will be treated as
        confidential, just as it would be if I (or the patient, as the case may
        be) were physically present for a face-to-face medical consultation in a
        clinic/hospital.
      </p>
      <p>
        11. The health care provider/practitioner on the AIMs2Health
        Telemedicine Portal may transmit or share electronically details of
        presenting complaint(s), medical history, examinations, x-rays, tests,
        photographs or other images with one or specialist who is/are at (a)
        different location(s).
      </p>
      <p>
        12. I understand that technical difficulties may occur before or during
        the telehealth sessions and my appointment may not be started or ended
        as intended. Additionally, if my session is interrupted due to power
        failure or a faulty connection I can call AIMS2Health to re-establish
        connection or speak to a representative to reschedule it.
      </p>
      <p>13. Expected Benefits of telemedicine services include: </p>
      <p>
        a. Improved access to medical care by enabling a patient and clinician
        to engage in service provision without being in the same physical
        location. b. More efficient medical diagnosis, evaluation, management
        and monitoring. c. Obtaining expertise of a distant specialist which is
        otherwise beyond timely access. d. I or the patient (as the case may be)
        won’t risk getting sick from other people. I understand that I may
        expect the anticipated benefits from the use of telemedicine in my or
        patient’s care, but that no results can be guaranteed or assured.
      </p>
      <p>
        14. Potential risks of any telemedicine services include but may not be
        limited to: a. limited or no availability of diagnostic laboratory,
        x-ray, EKG, and other testing, and some prescriptions, to assist the
        medical provider of AIMS2Health in diagnosis, evaluation and treatment.
        b. remote doctor’s inability to conduct a hands-on physical examination.
        c. delays or errors in evaluation and treatment due to technical
        difficulties or interruptions, distortion of diagnostic images or
        specimens resulting from electronic transmission issues, unauthorized
        access to my information, or loss of information due to technical
        failures. In rare cases, information transmitted may not be sufficient
        (e.g. poor resolution of images) to allow for appropriate medical
        decision making by the physician and consultant(s). d. I understand that
        the electronic systems used by AIMS2Health incorporate network and
        software security protocols to protect the confidentiality of patient
        identification and imaging data and include measures to safeguard the
        data and to ensure its integrity against intentional or unintentional
        corruption. I understand that AIMS2Health Pvt Ltd uses other technology
        measures (including encryption methods, firewalls and back-up systems)
        that are designed to protect my privacy and that the laws that protect
        the privacy and the confidentiality of medical information also apply to
        telemedicine, and that no information obtained about me in the course of
        telemedicine consultation which identifies me will be disclosed to
        researchers or other entities without my consent. I understand that
        AIMS2Health Pvt Ltd has taken all reasonable steps to ensure that my
        communication is not accessed by any unauthorized individuals but there
        is still a risk that AIMS2Health Pvt Ltd.’s electronic communications
        may be compromised, unsecured, or accessed by unauthorized persons or
        companies. I will not hold AIMS2Health Pvt Ltd responsible for any
        unauthorised access of personal or health information, or for lost
        information due to technological failures. e. I understand that I should
        also take reasonable steps to ensure the security of our communications
        (for example, only using secure networks for telemedicine). In very rare
        instances, security protocols could fail, causing a breach of privacy of
        personal medical information; f. Delays in medical evaluation and
        treatment could occur due to deficiencies or failures of the equipment;
        g. In rare cases, a lack of access to complete medical records may
        result in adverse drug interactions or allergic reactions or other
        judgment errors;
      </p>
      <p>
        15. I further understand that my AIMS2Health Pvt Ltd provider’s advice,
        recommendations, and/or decisions may be based on factors not within
        his/her control, including incomplete or inaccurate data provided by me.
        I understand that my AIMS2Health Pvt Ltd provider relies on information
        provided by me before and during our telemedicine encounter and that I
        must provide information about my medical history, condition(s), and
        current or previous medical care that is complete and accurate to the
        best of my ability.
      </p>
      <p>
        16. I understand that the level of care provided by my AIMS2Health Pvt
        Lt’s provider is to be the same level of care that is available to me
        through an in-person medical visit. However, if my provider believes I
        would be better served by face-to-face services or another form of care,
        I will be referred to the nearest AIMS2Health affiliated provider or
        medical centre, hospital emergency department or other appropriate
        health care provider.
      </p>
      <p>
        17. I understand that the outcome of my telemedicine visit may require
        that I schedule an office visit with a doctor on the panel of
        AIMS2Health Pvt Ltd to more effectively treat my condition. I recognize
        that subsequent office visits are not included in the cost of the
        telemedicine visit, and will normally be billed separately, unless
        expressly agreed in writing with the doctor concerned.
      </p>
      <p>
        18. I understand that telemedicine based services and care may not be as
        complete as face-to-face services. I have had the alternatives to a
        telemedicine appointment explained to me, and in choosing to participate
        in a telemedicine appointment, I understand that some parts of the
        examination involving physical tests may be conducted by individuals at
        my location at the direction of the AIMS2Health Pvt Ltd and its
        staff/professionals/doctors.
      </p>
      <p>
        19. In an emergency situation, I understand that the AIMS2Health Pvt Ltd
        and its staff/professionals/doctors may direct me to emergency medical
        services, instead of continuing the telemedicine service for my health
        issues and concerns; Or the telehealth provider may discuss with and
        advise my local healthcare provider; and that AIMS2Health Pvt Ltd and
        its staff’s/professional’s and Doctor’s responsibility will end upon the
        termination of the telemedicine connection.
      </p>
      <p>
        20. I understand that AIMS2Health Pvt Ltd or I can discontinue the
        telemedicine appointment if it is felt that the telemedicine is
        inadequate for serving the intended purposes. Notwithstanding the above,
        I have the right to withhold or withdraw consent for telemedicine
        services at any time without affecting my right to future care or
        treatment, and without the loss or withdrawal of any benefits to which I
        would otherwise be eligible.
      </p>
      <p>
        21. I understand that I have the right to inspect and review all
        information obtained and recorded in the course of a telemedicine
        interaction, and may receive copies of this information for a reasonable
        fee; and that this right is subject to AIMS2Health Pvt Ltd.’s standard
        policies regarding request and receipt of medical records and applicable
        law.
      </p>
      <p>
        22. I agree that my or patient’s medical records on the servers linked
        to the AIMS2Health Telemedicine Platform may be kept for as long as
        necessary for further evaluation, analysis and documentation, and in all
        of these situations, my personal and health information will continue to
        be kept private whether it is in storage or during its transmission.
      </p>
      <p>
        23. I understand that my or patient’s medical records or extracts
        thereof may be shared with other individuals or companies for scheduling
        and billing purposes and I hereby expressly agree to it being done.
      </p>
      <p>
        24. I understand that billing for the telemedicine services is at the
        discretion of AIMS2Health Pvt Ltd and it may occur from: a. the primary
        care provider; b. AIMS2Health Pvt Ltd; and/or c. as a facility fee from
        the site from which I am presented. I understand that the billing and
        payment procedures will be explained to me separately.
      </p>
      <p>
        25. I understand that a part of the payment for telemedicine services
        encounter is not refundable. I also understand that if my current
        insurance does not cover the additional fees of the telemedicine
        services and I would solely responsible for any fee that my insurance
        company does not cover.
      </p>
      <p>
        26. RELEASE OF INFORMATION: AIMs2Health Pvt Ltd and/or physicians who
        provide professional services to me or the patient are authorized to
        furnish medical information from my or patient’s medical record to the
        referring physician, if any, and to any insurance company or third party
        payer for the purpose of obtaining payment of the account. AIMS2Health
        Pvt Ltd is authorized to release information from my or patient’s
        medical record to any other health care facility or provider to which my
        or patient’s care may be transferred.
      </p>
      <p>
        27. I have had the opportunity to ask questions in regard to
        Telemedicine processes. My questions have been answered and the risks,
        benefits and any practical alternatives have been discussed with me in a
        language that I understand. 28. I hereby release AIMS2Health Pvt Ltd and
        all its staff/professionals/doctors, and any other person participating
        in my care from any and all liability which may arise from the taking
        and authorized use of such digital text, images, radiographs, audio or
        video recordings.
      </p>
      <p>
        29. I understand that I may be released before all my medical problems
        are known or treated and it is my responsibility to make arrangements
        for follow-up care.
      </p>
      <p>
        30. I have read this document carefully, and understand the risks and
        benefits of the telehealth appointment and have had my questions
        regarding the procedure explained and I hereby consent to participate in
        a telehealth appointment visit under the terms described herein.
      </p>
      <p>
        31. FINANCIAL RESPONSIBILITY: In consideration for the telemedicine
        services rendered to me by AIMS2Health Pvt Ltd, I agree to pay the
        charges not covered by any insurer or third party payer, including any
        deductible or co-payment, or any charges not covered as a result of my
        failure to provide notification or obtain preauthorization for treatment
        as required by any insurer or third party payer to AIMS2Health Pvt Ltd.
        Should my account be referred for collection, I agree to pay AIMS2Health
        Pvt Ltd reasonable attorney fees and collection expenses.
      </p>
      <p>
        32. AIMS2Health Pvt Ltd will protect the privacy of my health
        information and will not use or disclose it except as permitted by law.
        AIMS2Health Pvt Ltd.’s privacy policies are more fully described in the
        Privacy Notice, which is available for review and download here:
        https://www.medicalWISDOM.com/medicalWISDOM-privacy-practices . By
        signing this Consent, I acknowledge receipt of the Privacy Notice and
        consent to AIMS2Health Pvt Ltd.’s use and disclosure of my health
        information in accordance
      </p>
      <p>
        with its terms. I understand that all existing confidentiality
        protections that apply to in-person treatment apply to telehealth
        services.
      </p>
      <p>
        33. I, the undersigned patient or parent/legal guardian for the minor
        patient, agree to participate in telemedicine services of AIMS2Health
        Pvt Ltd.
      </p>
      <p>
        34. If I am signing on behalf of a minor, incapacitated or otherwise
        legally dependent patient, I certify that I am a person with legal
        authority to act on behalf of the patient, including the authority to
        consent to telemedicine services, and I accept financial responsibility
        for services rendered.
      </p>
      <p>
        35. I understand that I need to have the mental capacity to give
        informed consent for telemedicine consultation. I also understand that
        assessment of my mental capacity or competence involves four main
        abilities: a. That I have sufficient capacity to understand the
        information contained in these pages about what constitutes an informed
        consent. b. That I am able to retain, use and weigh up such information
        long enough to be able to make a decision. In addition, I am also able
        to understand what the decision is about, why AIMS2Health Pvt Ltd is
        asking me to make it, and what the consequences of making or not making
        this decision might be. c. That I understand the possible benefits,
        risks and inconvenience linked to participating in telemedicine
        consultation. d. That I have the ability to communicate my decision to
        all concerned in my/patient’s team.
      </p>
      <p>
        36. By typing my name and clicking “I agree to Terms of Use” on the
        AIMS2Health Telehealth Portal, I understand and agree that I am signing
        this Consent electronically and that (i) I have reviewed, understand and
        accept the risks and benefits of telehealth services as described below
        and wish to receive such services, and (ii) I agree to the remaining
        terms of this Consent, including the terms of the AIMS2Health Privacy
        Notice attached here.
      </p>
      <p>
        37. By typing my name in the box below, I am signing the consent and I
        certify:  That I have read or had this form read and/or had this form
        explained to me  That I fully understand its contents including the
        risks and benefits of telemedicine consultation through AIMS2Health
        Telemedicine Platform and any telemedicine procedure(s).
      </p>
      <p>
         That I have been given ample opportunity to ask questions and that any
        questions have been answered to my satisfaction.  That I agree to
        participate in use the telemedicine services of AIMS2Health Pvt Ltd. I
        may revoke my consent orally or in writing at any time by contacting
        AIMS2Health at info@medicalWISDOM.com . In the next 12 months, as long
        as this consent is in force (has not been revoked) AIMS2Health may
        provide health care services to me via telemedicine without the need for
        me to sign another consent form. I may withdraw my consent also by
        signing into the patient portal and [add instructions here.] It will be
        as if you never signed this form.
      </p>
    </Box>
  );

  return <Container maxWidth="xl">{pagebody}</Container>;
}
