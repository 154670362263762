import Diagnosis from "../../../image/design-assets/active-diagnosis.svg";
import BloodSugar from "../../../image/design-assets/blood-sugar.svg";
import Bmi from "../../../image/design-assets/bmi.svg";
import Bp from "../../../image/design-assets/bp.svg";
import Ecg from "../../../image/design-assets/ecg.svg";
import HeartRate from "../../../image/design-assets/heart-rate.svg";
import Temp from "../../../image/design-assets/temp.svg";
import Weight from "../../../image/design-assets/weight.svg";
import SPO2 from "../../../image/design-assets/spo2.svg";
import SocialLife from "../../../image/design-assets/social-life.svg";
import FoodHabit from "../../../image/design-assets/food-habit.svg";
import PhysicalActivity from "../../../image/design-assets/physical-activity.svg";
import SleepQuality from "../../../image/design-assets/sleep-quality.svg";
import LivingCondition from "../../../image/design-assets/living-condition.svg";



export const CustomCardValueList = [
  {
    name: "BP",
    content: "122 / 69",
    button: "View All",
    path: "/financelogin",
    info: "1.General, analytical and material accounting     2.Budget Management",
    image: Bp,
  },
  {
    name: "BMI",
    content: "30",
    button: "View All",
    path: "/orderstock",
    info: "1.Supply Orders     2.Inventory Management       3.Stock Management       4.Distribution of blood & blood components",
    image: Bmi,
  },
  {
    name: "Blood Sugar",
    content: "118",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: BloodSugar,
  },
  {
    name: "ECG",
    content: "Normal Sinus Rhythm",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Ecg,
  },
  {
    name: "Heart Rate",
    content: "66",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: HeartRate,
  },
  {
    name: "Temperature",
    content: "98.5",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Temp,
  },
  {
    name: "Weight",
    content: "60.3 Kg",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Weight,
  },
  {
    name: "SPO2",
    content: "75",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: SPO2,
  },
  {
    name: "Social Life",
    content: "Active",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: SocialLife,
  },
  {
    name: "Active Diagnosis",
    content: "Hypertension",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: Diagnosis,
  },
  {
    name: "Food Habit",
    content: "Non Vegetarian",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: FoodHabit,
  },
  {
    name: "Physical Activity",
    content: "Active",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: PhysicalActivity,
  },
  {
    name: "Sleep Quality",
    content: "Good",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: SleepQuality,
  },
  {
    name: "Living Condition",
    content: "Healthy",
    button: "View All",
    path: "/logistics",
    info: "1.Resource Management       2.Infrastructure and equipment       3.Computer maintenance",
    image: LivingCondition,
  },
];
export default CustomCardValueList;
