import * as React from "react";
import { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ActiveAllCaseList from "./ActiveAllCaseList";
import InactiveAllCaseList from "./InactiveAllCaseList";
import MWPageTitle from "../../component/MWPageTitle";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AllCaseList()  {
  const [selected, setSelected] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };

  const CaseListPage = (
    <Box>
      <Box>
        <Tabs value={selected} onChange={handleTabChange}>
          <Tab label="Active Case List" {...a11yProps(0)} />
          <Tab label="Inactive Case List" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={selected} index={0}>
        <ActiveAllCaseList />
      </TabPanel>
      <TabPanel value={selected} index={1}>
        <InactiveAllCaseList />
      </TabPanel>
    </Box>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <MWPageTitle title="Patient Case List" />
            </Grid>
          </Grid>
          {CaseListPage}
        </Stack>
      </Container>
    </Box>
  );
}
