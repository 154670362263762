import {
  GovtIDProofDetails,
  GovtIDProofActionTypes,
  GOVT_ID_PROOF_FAIL,
  GOVT_ID_PROOF_LOADING,
  GOVT_ID_PROOF_SUCCESS,
} from "./ActionTypes";
import { GovtIdProofResponse } from "./Model";

const initialStateGetPosts: GovtIDProofDetails = {
  loading: false,
  govtIdProofResponse: {} as GovtIdProofResponse,
  message: "",
  status: "",
};
export const govtIDProofReducer = (
  state = initialStateGetPosts,
  action: GovtIDProofActionTypes
): GovtIDProofDetails => {
  switch (action.type) {
    case GOVT_ID_PROOF_LOADING:
      return {
        ...state,
        loading: action.loading,
        govtIdProofResponse: {} as GovtIdProofResponse,
      };
    case GOVT_ID_PROOF_SUCCESS:
      return {
        ...state,
        govtIdProofResponse: action.payload,
        message: action.message,
        status: action.status,
      };
    case GOVT_ID_PROOF_FAIL:
      return {
        ...state,
        govtIdProofResponse: {} as GovtIdProofResponse,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
