import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import { getHealthcampListValue } from "../../redux/healthcampList/API";
import { Button, Card, CardContent, Box } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import { HealthCampList } from "../../redux/healthcampList/Model";
import dayjs from "dayjs";
import PageLayout from "../Layout/PageLayout";

export default function HealthcampList() {
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const [listCount, setListCount] = React.useState("");

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 100,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => {
            gotoHealthcampDetails(params.value);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    { field: "displayName", headerName: "Health Camp Name", width: 400 },
    { field: "dateCreated", headerName: "Create Date", width: 250 },
    { field: "ownerFirstName", headerName: "Owner First Name", width: 250 },
    { field: "ownerUserName", headerName: "Owner User Name", width: 250 },
  ];

  const history = useHistory();
  const gotoHealthcampDetails = (rowIndex: any) => {
    history.push(`/patientlistbyhealthcamp/${rowIndex}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getHealthcampListValue());
  }, [dispatch]);

  function healthCampListApi() {
    dispatch(getHealthcampListValue());
  }

  // call Store
  const AdminAllHealthcampList = useSelector(
    (state: AppState) => state.healthcampListValue
  );
  const healthcampValues =
    AdminAllHealthcampList.healthcampListValue?.HealthCampList;

  useEffect(() => {
    AdminAllHealthcampList?.healthcampListValue.count !== undefined
      ? setListCount(String(AdminAllHealthcampList?.healthcampListValue.count))
      : setListCount("0");

    if (healthcampValues !== undefined) {
      let dataPointArr = healthcampValues.map((element: HealthCampList) => ({
        id: element.id,
        displayName: element.displayName,
        dateCreated: dayjs(element.dateCreated).format("dddd, MMMM D, YYYY"),
        ownerFirstName: element.ownerFirstName,
        ownerUserName: element.ownerUserName,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAllHealthcampList]);

  const DataGridMarkup = (
    <Card>
      <CardContent>
        {healthcampValues && healthcampValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminAllHealthcampList.loading === true ? loadingMarkup : ""}
      <PageLayout>
        <MWPageTitle
          title={"Health Camp List"}
          enableCount={true}
          count={listCount}
          reload={true}
          reloadAction={healthCampListApi}
        />
        {DataGridMarkup}
      </PageLayout>
    </Box>
  );
}
