import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { OpinionTypeResponse } from "./Model";
import {
  opinionTypeAPIResClearAction,
  opinionTypeErrorAction,
  opinionTypeLoadingAction,
  opinionTypeSuccessAction,
  opinionTypeUpdateAPIMsgAction,
} from "./Actions";
import { OpinionTypeActionTypes } from "./ActionTypes";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as OpinionTypeResponse;
export const getOpinionTypeList = () => {
  return function (dispatch: Dispatch<OpinionTypeActionTypes>) {
    dispatch(opinionTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/secondopinionapp/api/secondopinion/opinion_type`, {
        headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(opinionTypeLoadingAction(false));
        dispatch(
          opinionTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "opinion type fetch successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(opinionTypeLoadingAction(false));
        dispatch(
          opinionTypeErrorAction(
            {} as OpinionTypeResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const opinionTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<OpinionTypeActionTypes>) {
    dispatch(
      opinionTypeUpdateAPIMsgAction(apiRes as OpinionTypeResponse, "","", 0)
    );
  };
};

export const clearopinionTypeAPIRes = () => {
  return function (dispatch: Dispatch<OpinionTypeActionTypes>) {
    dispatch(
      opinionTypeAPIResClearAction({} as OpinionTypeResponse, "","", 0)
    );
  };
};
