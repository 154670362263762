import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import InformConsent from "./InformConsent";
import { GetDemographicsDetailsByPtIdRequest } from "../../../redux/DemographicsDetails/GetAppointmentList/Model";
import { getDemographicsDetailsByPtId } from "../../../redux/DemographicsDetails/GetAppointmentList/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { getRelationshipList } from "../../../redux/DemographicsDetails/RelationShipList/API";
import { getBloodGroupList } from "../../../redux/DemographicsDetails/BloodGroupList/API";
import { getGenderList } from "../../../redux/DemographicsDetails/GenderList/API";
import { getLanguageList } from "../../../redux/DemographicsDetails/LanguageList/API";
import { getMaritalListList } from "../../../redux/DemographicsDetails/MaritalStatusList/API";
import { getSexualOrientationList } from "../../../redux/DemographicsDetails/SexualOrientationList/API";
import { UpdateDemographicsDetailsReq } from "../../../redux/DemographicsDetails/UpdateDemographicsDetails/Model";
import {
  clearUpdateDemographicsDetailsAPIRes,
  updateDemographicsDetailsByPatientId,
} from "../../../redux/DemographicsDetails/UpdateDemographicsDetails/API";
import { AddDemographicsDetailsReq } from "../../../redux/DemographicsDetails/AddDemographicsDetails/Model";
import {
  addDemographicsDetails,
  clearAddDemographicsDetailsAPIRes,
} from "../../../redux/DemographicsDetails/AddDemographicsDetails/API";
import MWAutocomplete from "../../../component/MWAutocomplete";
import MWTextField from "../../../component/MWTextField";
import MWUploader from "../../../component/MWUploader";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Box,
  Paper,
  Stack,
  AlertTitle,
} from "@mui/material";
import MWDatePicker from "../../../component/MWDatePicker";
import MWMultipleAutocomplete from "../../../component/MWMultipleAutoComplete";
import { govtIdProofListApi } from "../../../redux/DemographicsDetails/govtIdProofList/API";
import GovtId from "./GovtId";
import dayjs from "dayjs";
import AdminLoader from "../../AdminLoader";
import PageLayout from "../../Layout/PageLayout";
import MWPageTitle from "../../../component/MWPageTitle";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function DemographicDetails() {
  const { patientid } = useParams() as { patientid: string };
  const [isEdit, setIsEdit] = useState(patientid !== undefined ? true : false);
  const dispatch = useDispatch();
  const location = useLocation();

  const [pathName, setPathName] = useState("");
  // check current url path name
  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);
  const [isLoading, setIsLoading] = useState(false);
  // const loadingMarkup = isLoading ? <MWLoader /> : null;
  // firstName
  const [firstName, setFirstName] = useState("");

  // lastName
  const [lastName, setLastName] = useState("");

  // D.O.B.
  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  const updateDobFromChild = (dobValue: any) => {
    setSelectedDate(dobValue);
  };

  // gender list
  const [genderValues, setGenderValues] = useState([] as any[]);
  // selected gender list
  const [selectedGen, setSelectedGen] = useState<number | null>(null);
  const genderValueFromChild = (selectedGenVal: any) => {
    setSelectedGen(selectedGenVal);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [delImg, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    // console.log("passedVal", passedVal);
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  // Phone Number
  const [phoneNo, setPhoneNo] = useState("");

  // relationship list
  const [relationValues, setRelationValues] = useState([] as any[]);
  // select relationship Values from dropdown
  const [selectedRel, setSelectedRel] = useState<number | null>(null);
  // get selected relationship value from common component
  const relationValueFromChild = async (selectedRelationValue: any) => {
    setSelectedRel(selectedRelationValue);
  };

  // show guardian
  const [guardian, setGuardian] = useState("");

  // blood group list
  const [bloodGroupValues, setBloodGroupValues] = useState([] as any[]);
  // select bloodGroup Values from dropdown
  const [selectedBloodGroup, setSelectedBloodGroup] = useState<number | null>(
    null
  );
  // get blood group from child component
  const bloodGrpValueFromChild = async (selectedBloodGroupVal: any) => {
    setSelectedBloodGroup(selectedBloodGroupVal);
  };

  // language list
  const [language, setLanguage] = useState([] as any[]);
  // selected language Value (Multiple) from dropdown
  const [languageSelected, setLanguageSelected] = useState([]);
  const languageValueFromChild = async (selectedMultiLanguageVal: any) => {
    setLanguageSelected(selectedMultiLanguageVal);
  };
  // maritalStatus list
  const [maritalStatusValues, setMaritalStatusValues] = useState([] as any[]);
  // select MaritalStatusValues from dropdown
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState<
    number | null
  >(null);
  const maritalStatusValueFromChild = async (selectedMaritalStatus: any) => {
    setSelectedMaritalStatus(selectedMaritalStatus);
  };

  // sexual Orientation list
  const [sexualOrientationValues, setSexualOrientationValues] = useState(
    [] as any[]
  );
  // selected sexual Orientation Value from dropdown
  const [selectedSexualOrientation, setSelectedSexualOrientation] = useState<
    number | null
  >(null);
  //get sexual Orientation Value from child
  const sexValueFromChild = async (selectedSexualOrientationVal: any) => {
    setSelectedSexualOrientation(selectedSexualOrientationVal);
  };

  // show city
  const [city, setCity] = useState("");

  // pin code
  const NUMERIC_REGEX = /^(0|[1-9][0-9]*)$/;
  const [textValue, setPin] = useState("");

  // get govt. id card number
  const [govtIdNumber, setGovtIdNumber] = useState("");
  // get selected govt. id card type
  const [selectedIdProofType, setSelectedIdProofType] = useState<number | null>(
    null
  );

  const [chooseLang, SetChooseLang] = useState([] as any);
  const [addedLang, SetAddedLang] = useState([] as any);

  const [addPatientMessage, setAddPatientMessage] = useState("");
  const [editPatientMessage, setEditPatientMessage] = useState("");
  const [error, setError] = useState("");
  const [addPatientError, setAddPatientError] = useState("");
  const [patientDetailsListError, setPatientDetailsListAPIError] = useState("");

  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const govtImgId = "";
  const history = useHistory();

  const handleBannerDismiss = () => {
    setAddPatientMessage("");
    setEditPatientMessage("");
    dispatch(clearAddDemographicsDetailsAPIRes());
  };
  const addPatientSuccessMessage = addPatientMessage ? (
    <Box my={3}>
      <Alert onClose={handleBannerDismiss} severity="success">
        {addPatientMessage}
      </Alert>
    </Box>
  ) : null;
  const handleEditBannerDismiss = () => {
    setAddPatientMessage("");
    setEditPatientMessage("");
    dispatch(clearUpdateDemographicsDetailsAPIRes());
  };
  const editPatientSuccessMsgLayout = editPatientMessage ? (
    <Box my={3}>
      <Alert onClose={handleEditBannerDismiss} severity="success">
        {editPatientMessage}
      </Alert>
    </Box>
  ) : null;

  const patientAddEditBannerClose = () => {
    setAddPatientError("");
    setPatientDetailsListAPIError("");
    dispatch(clearAddDemographicsDetailsAPIRes());
    dispatch(clearUpdateDemographicsDetailsAPIRes());
  };
  const errorPatientAddEditLayout = addPatientError ? (
    <Box my={3}>
      <Alert severity="error" onClose={patientAddEditBannerClose}>
        {addPatientError}
      </Alert>
    </Box>
  ) : null;

  const bannerClose = () => {
    setAddPatientError("");
    setPatientDetailsListAPIError("");
  };
  const errorPatientDetailsListLayout = patientDetailsListError ? (
    <Alert severity="error" onClose={bannerClose}>
      {patientDetailsListError}
    </Alert>
  ) : null;

  const demographicsBody = {
    gender: selectedGen!,
    relationship: selectedRel!,
    firstname: firstName,
    lastname: lastName,
    dob: selectedDate!,
    pincode: textValue,
    bloodGroup: selectedBloodGroup!,
    city: city,
    guardianName: guardian!,
    maritialStatusId: selectedMaritalStatus!,
    sexualorientationId: selectedSexualOrientation!,
    languageId: chooseLang,
    phone: phoneNo,
    govtIdNumber: govtIdNumber!,
    govtIdImage: String(govtImgId)!,
    govtIdType: String(selectedIdProofType)!,
  } as AddDemographicsDetailsReq;

  const userToken = localStorage.getItem("access");
  const isFirstName = firstName;
  const isLastName = lastName;
  const handleBannerErrorDismiss = () => {
    setError("");
  };
  const errorDiv = error ? (
    <Alert onClose={handleBannerErrorDismiss} severity="error">
      <Typography>{error}</Typography>
    </Alert>
  ) : null;

  const addHealthRecord = () => {
    if (patientid) {
      // Edit Patient Details
      if (!isFirstName.trim()) {
        setError("First name should not be empty");
        return;
      } else if (!isLastName.trim()) {
        setError("");
        setError("Last name should not be empty.");
        return;
      } else if (selectedGen === null) {
        setError("");
        setError("Gender should not be empty");
        return;
      } else if (selectedDate === null) {
        setError("");
        setError("Date of Birth should not be empty");
        return;
      } else if (
        new RegExp("^[1-9][0-9]{5}$").test(textValue.toString()) === false
      ) {
        setError("");
        setError("Please enter valid Pincode");
      } else {
        if (userToken) {
          setIsLoading(true);
          if (delImg) {
            // When user delete image
            delete demographicsBody.govtIdImage;
            let deleteImgPayload = Object.assign(demographicsBody, {
              healthRecordId: patientid,
              patientImageId: "-1",
            }) as UpdateDemographicsDetailsReq;
            dispatch(updateDemographicsDetailsByPatientId(deleteImgPayload));
          } else {
            if (imageId !== undefined) {
              // when user add new image
              delete demographicsBody.govtIdImage;
              let addImgPayload = Object.assign(demographicsBody, {
                healthRecordId: patientid,
                patientImageId: String(imageId),
              }) as UpdateDemographicsDetailsReq;
              dispatch(updateDemographicsDetailsByPatientId(addImgPayload));
            } else {
              // when user edit other details
              delete demographicsBody.govtIdImage;
              let updateWithoutImg = Object.assign(demographicsBody, {
                healthRecordId: patientid,
              }) as UpdateDemographicsDetailsReq;
              dispatch(updateDemographicsDetailsByPatientId(updateWithoutImg));
            }
          }
        }
      }
    } else {
      // Create / Add Patient Details
      if (!isFirstName.trim()) {
        setError("First name should not be empty");
        return;
      } else if (!isLastName.trim()) {
        setError("");
        setError("Last name should not be empty.");
        return;
      } else if (selectedGen === null) {
        setError("");
        setError("Gender should not be empty");
        return;
      } else if (selectedDate === null) {
        setError("");
        setError("Date of Birth should not be empty");
        return;
      } else if (
        new RegExp("^[1-9][0-9]{5}$").test(textValue.toString()) === false
      ) {
        setError("");
        setError("Please enter valid Pin code");
      } else {
        if (userToken) {
          setIsLoading(true);
          if (imageId === undefined) {
            // When user does not upload image on patient creation
            dispatch(addDemographicsDetails(demographicsBody));
          } else {
            // When user uploads image on patient creation
            addDemographicsDetails(
              Object.assign(demographicsBody, {
                patientImageId: String(imageId),
              })
            );
          }
        }
      }
    }
  };

  // Get Demographics Details by Patient Id Body
  const getDemographicsDetailsByPtIdReqBody = {
    healthRecordId: patientid,
  } as GetDemographicsDetailsByPtIdRequest;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (patientid !== undefined) {
      dispatch(
        getDemographicsDetailsByPtId(getDemographicsDetailsByPtIdReqBody)
      );
    }
    dispatch(getRelationshipList());
    dispatch(getBloodGroupList());
    dispatch(getGenderList());
    dispatch(getLanguageList());
    dispatch(getMaritalListList());
    dispatch(getSexualOrientationList());
    dispatch(govtIdProofListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call Gender List store
  const getPatientGenderListRes = useSelector(
    (state: AppState) => state.getPatientGenderList
  );
  // take following actions when Gender list api store updated
  useEffect(() => {
    setIsLoading(getPatientGenderListRes?.loading);
    if (getPatientGenderListRes?.getGenderListRes?.genderList !== undefined) {
      setGenderValues(getPatientGenderListRes?.getGenderListRes?.genderList);
      if (getPatientBloodGroupListRes?.errRes !== "") {
        setPatientDetailsListAPIError(getPatientBloodGroupListRes?.errRes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientGenderListRes]);

  // Call Relationship list store
  const getPatientRelationshipListRes = useSelector(
    (state: AppState) => state.getPatientRelationshipList
  );
  // take following actions when Relationship list api store updated
  useEffect(() => {
    setIsLoading(getPatientRelationshipListRes?.loading);
    if (
      getPatientRelationshipListRes?.getRelationshipListRes
        ?.patientRelationshipList !== undefined
    ) {
      setRelationValues(
        getPatientRelationshipListRes?.getRelationshipListRes
          ?.patientRelationshipList
      );
      if (getPatientRelationshipListRes?.errRes !== "") {
        setPatientDetailsListAPIError(getPatientRelationshipListRes?.errRes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientRelationshipListRes]);

  // Call Blood Group List store
  const getPatientBloodGroupListRes = useSelector(
    (state: AppState) => state.getPatientBloodGroupList
  );
  // take following actions when Blood group list api store updated
  useEffect(() => {
    setIsLoading(getPatientBloodGroupListRes?.loading);
    if (
      getPatientBloodGroupListRes?.getBloodGroupListRes?.bloodGroupList !==
      undefined
    ) {
      setBloodGroupValues(
        getPatientBloodGroupListRes?.getBloodGroupListRes?.bloodGroupList
      );
      if (getPatientBloodGroupListRes?.errRes !== "") {
        setPatientDetailsListAPIError(getPatientBloodGroupListRes?.errRes);
      }
    }
  }, [getPatientBloodGroupListRes]);

  // Call Language List store
  const getPatientLanguageListRes = useSelector(
    (state: AppState) => state.getPatientLanguageList
  );
  // take following actions when language list api store updated
  useEffect(() => {
    setIsLoading(getPatientLanguageListRes?.loading);
    if (
      getPatientLanguageListRes?.getLanguageListRes?.languageList !== undefined
    ) {
      setLanguage(getPatientLanguageListRes?.getLanguageListRes?.languageList);
      if (getPatientBloodGroupListRes?.errRes !== "") {
        setPatientDetailsListAPIError(getPatientBloodGroupListRes?.errRes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientLanguageListRes]);
  // take actions when language list dropdown value updated
  useEffect(() => {
    let multiLang = languageSelected?.map((item: any) => item.value);
    SetChooseLang(multiLang);
  }, [languageSelected]);

  // Call Marital Status List store
  const getPatientMaritalListRes = useSelector(
    (state: AppState) => state.getPatientMaritalList
  );
  // take following actions when Marital Status list api store updated
  useEffect(() => {
    setIsLoading(getPatientMaritalListRes?.loading);
    if (
      getPatientMaritalListRes?.getMaritalListListRes?.maritialStatusList !==
      undefined
    ) {
      setMaritalStatusValues(
        getPatientMaritalListRes?.getMaritalListListRes?.maritialStatusList
      );
      if (getPatientBloodGroupListRes?.errRes !== "") {
        setPatientDetailsListAPIError(getPatientBloodGroupListRes?.errRes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientMaritalListRes]);

  // Call Sexual Orientation List store
  const getPatientSexualOrientationRes = useSelector(
    (state: AppState) => state.getPatientSexualOrientation
  );
  // take following actions when Sexual Orientations list api store updated
  useEffect(() => {
    setIsLoading(getPatientSexualOrientationRes?.loading);
    if (
      getPatientSexualOrientationRes?.getSexualOrientationListRes
        ?.sexualOrientationList !== undefined
    ) {
      setSexualOrientationValues(
        getPatientSexualOrientationRes?.getSexualOrientationListRes
          ?.sexualOrientationList
      );
      if (getPatientBloodGroupListRes?.errRes !== "") {
        setPatientDetailsListAPIError(getPatientBloodGroupListRes?.errRes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientSexualOrientationRes]);

  // Call Update Demographics Details store
  const updateDemographicsDetailsRes = useSelector(
    (state: AppState) => state.updateDemographicsDetails
  );
  // take following actions when Update Demographics api store updated
  useEffect(() => {
    setIsLoading(updateDemographicsDetailsRes?.loading);
    if (
      updateDemographicsDetailsRes?.updatePatientDemographicsRes?.message !== ""
    ) {
      setEditPatientMessage(
        updateDemographicsDetailsRes?.updatePatientDemographicsRes?.message
      );
      // Call get demographics details by patient id api
      dispatch(
        getDemographicsDetailsByPtId(getDemographicsDetailsByPtIdReqBody)
      );
    }
    if (updateDemographicsDetailsRes?.errorMsg !== "") {
      setPatientDetailsListAPIError(updateDemographicsDetailsRes?.errorMsg);
      setEditPatientMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDemographicsDetailsRes]);

  // Call Govt Id type store
  const getGovtIDProofStoreValue = useSelector(
    (state: AppState) => state.govtIdProofList
  );
  // Get Govt. ID List
  useEffect(() => {
    setIsLoading(getGovtIDProofStoreValue?.loading);
    if (
      getGovtIDProofStoreValue?.govtIdProofResponse?.govtIdTypeList !==
      undefined
    ) {
      if (
        getGovtIDProofStoreValue?.message !== "" &&
        getGovtIDProofStoreValue?.govtIdProofResponse?.govtIdTypeList ===
          undefined
      ) {
        setPatientDetailsListAPIError(getGovtIDProofStoreValue?.message);
      }
    }
  }, [getGovtIDProofStoreValue]);

  // Call Get Demographics Details by patient id  store
  const getDemographicsDetailsByPtIdRes = useSelector(
    (state: AppState) => state.getDemographicsDetailsByPtId
  );
  // take following actions when Get Demographics Details by patient id store updated
  useEffect(() => {
    setIsLoading(getDemographicsDetailsByPtIdRes?.loading);
    if (
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails !== undefined
    ) {
      setFirstName(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails?.firstname === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails?.firstname === ""
          ? ""
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.firstname
      );
      setLastName(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails?.lastname === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails.lastname === ""
          ? ""
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.lastname
      );
      setGuardian(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.guardianName === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails.guardianName === ""
          ? ""
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.guardianName
      );
      setSelectedRel(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.patientRelationshipWithOwner === null
          ? null
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.patientRelationshipWithOwner.id
      );
      setSelectedDate(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.dob === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails.dob === ""
          ? new Date()
          : dayjs(
              getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
                ?.personalDetails.dob
            ).format("YYYY-MM-DDTHH:mmZ")
      );
      setPin(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.pincode === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails.pincode === "" ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails.pincode === undefined
          ? ""
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.pincode
      );
      setSelectedMaritalStatus(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.maritialStatus === null
          ? null
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.maritialStatus.value
      );
      setSelectedSexualOrientation(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.sexualOrientation === null
          ? null
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.sexualOrientation.value
      );

      setImageInfo(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails?.profilePicture === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails?.profilePicture === ""
          ? ""
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.profilePicture.document
      );
      // // set govt id image info from api
      // getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
      //   ?.personalDetails?.idProof === null ||
      // getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
      //   ?.personalDetails?.idProof === ""
      //   ? setGovtImageInfo("")
      //   : setGovtImageInfo(
      //       getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
      //         ?.personalDetails?.idProof?.supportingDocuments?.document
      //     );
      setSelectedGen(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.gender === null
          ? null
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.gender.value
      );
      setSelectedBloodGroup(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.bloodGroup === null
          ? null
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.bloodGroup.value
      );
      setCity(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.city === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails.city === ""
          ? ""
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.city
      );
      setGovtIdNumber(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails?.idProof === null ||
          getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails?.idProof === ""
          ? ""
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.idProof?.idNumber
      );
      setSelectedIdProofType(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails?.idProof === null
          ? null
          : getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.idProof?.idType?.value
      );
      let languageValue =
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.languagePreferred;
      SetAddedLang(languageValue);
      setPhoneNo(
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails?.phone !== undefined
          ? getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.phone !== null
            ? getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
                ?.personalDetails?.phone
            : ""
          : ""
      );
    }
  }, [getDemographicsDetailsByPtIdRes]);

  // Add new Demographics details api store
  const addDemographics = useSelector(
    (state: AppState) => state.addDemographics
  );
  // Take actions when Add Patient API calls & it's store has been updated
  useEffect(() => {
    setIsLoading(addDemographics?.loading);
    setAddPatientMessage(addDemographics?.addPatientDemographicsRes?.message);
    if (addDemographics?.successMsg !== "") {
      history.push("/Dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDemographics]);

  // Add Patient Inform Concent Layout
  const addPtInfoConcent = patientid ? null : (
    <Box>
      {patientid ? null : (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={1}
        >
          <Alert severity="info">
            <AlertTitle>Informed consent for patient</AlertTitle>
            <Box style={{ maxHeight: "200px", overflow: "auto" }}>
              <InformConsent />
            </Box>
          </Alert>
          <Typography variant="subtitle1">
            By clicking “Submit” you have agreed to this informed consent
          </Typography>
        </Stack>
      )}
    </Box>
  );

  ////// *********** Main Demographics Layout ********* ///////////
  const formBody = (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item>
              <MWTextField
                label="First Name"
                required
                placeholder="Enter Patient First Name"
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(event.target.value);
                }}
                disabled={isEdit}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item>
              <MWTextField
                label="Last Name"
                required
                placeholder="Enter Patient Last Name"
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value);
                }}
                disabled={isEdit}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item>
              <MWAutocomplete
                placeHolder="Gender"
                listvalue={genderValues}
                selectedItem={selectedGen}
                visibility={isEdit}
                dropdownValue={genderValueFromChild}
              />
            </Grid>
            <Grid item>
              <MWDatePicker
                label={"Date Of Birth"}
                disabledValue={isEdit}
                selectedDateValue={selectedDate}
                dateState={updateDobFromChild}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWUploader
            documentType="patient-image"
            criteria="Document size should be maximum 10MB."
            updatedDataState={updateDataStateFromChild}
            title="Update patient image"
            visibility={isEdit}
            imageUrl={imageInfo}
            deleteImgAction={delImgAction}
            previewImageData={viewPreviewImageData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWTextField
            label="Phone Number"
            placeholder="Enter phone number"
            value={phoneNo}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (value !== "" && !NUMERIC_REGEX.test(value)) {
                return;
              }
              setPhoneNo(value);
            }}
            disabled={isEdit}
            fullWidth
            size="small"
            type="text"
            inputProps={{ maxLength: 10, minLength: 10 }}
            error={phoneNo !== "" && phoneNo.length !== 10 ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWAutocomplete
            placeHolder="Relationship"
            listvalue={relationValues}
            selectedItem={selectedRel}
            dropdownValue={relationValueFromChild}
            visibility={isEdit}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MWTextField
            label="Guardian"
            placeholder="Enter guardian"
            value={guardian}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setGuardian(event.target.value);
            }}
            disabled={isEdit}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWAutocomplete
            placeHolder="Blood Group"
            listvalue={bloodGroupValues}
            selectedItem={selectedBloodGroup}
            dropdownValue={bloodGrpValueFromChild}
            visibility={isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWMultipleAutocomplete
            placeHolder="Preferred Language"
            listvalue={language}
            selectedItem={addedLang}
            dropdownValue={languageValueFromChild}
            visibility={isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWAutocomplete
            placeHolder="Marital Status"
            listvalue={maritalStatusValues}
            selectedItem={selectedMaritalStatus}
            dropdownValue={maritalStatusValueFromChild}
            visibility={isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWAutocomplete
            placeHolder="Sexual Orientation"
            listvalue={sexualOrientationValues}
            selectedItem={selectedSexualOrientation}
            dropdownValue={sexValueFromChild}
            visibility={isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWTextField
            label="City"
            placeholder="Enter city name"
            value={city}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCity(event.target.value);
            }}
            disabled={isEdit}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWTextField
            label="Pin code"
            placeholder="Enter pin code"
            value={textValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (value !== "" && !NUMERIC_REGEX.test(value)) {
                return;
              }
              setPin(value);
            }}
            disabled={isEdit}
            fullWidth
            size="small"
            type="text"
            inputProps={{ maxLength: 6, minLength: 6 }}
            required
            error={textValue !== "" && textValue.length !== 6 ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {addPatientSuccessMessage}
          {editPatientSuccessMsgLayout}
          <Box my={3}>{errorPatientAddEditLayout}</Box>
          <Box my={3}>{errorPatientDetailsListLayout}</Box>
          <Box>{errorDiv}</Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
  ////// *********** ADD ********* ///////////
  const ActualPageMarkup = (
    //edit health record part.........
    <React.Fragment>
      {patientid ? (
        <Box>
          <Card>
            <CardContent>{formBody}</CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={2}
              >
                <Grid item>
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => {
                      setIsEdit((isEdit) => !isEdit);
                      setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                    }}
                  >
                    {isEdit ? "Edit" : "Cancel"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="large"
                    variant="contained"
                    disabled={isEnableSubmit}
                    onClick={addHealthRecord}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
          <Paper sx={{ my: 2 }}>
            <GovtId />
          </Paper>
        </Box>
      ) : (
        //add health record part.........
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            {addPtInfoConcent}
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Card>
              <CardContent>{formBody}</CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={addHealthRecord}
                      sx={{ textTransform: "none" }}
                      disabled={isLoading || checkImgUpload}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );

  return (
    <Box>
      {pathName !== "" ? (
        pathName.includes("/demographicdetails") ? (
          // Render in Add Patient page & View Patient Demographics details page
          <Box>
            {pathName !== "" ? (
              pathName.includes("/demographicdetails") && isLoading ? (
                <AdminLoader />
              ) : null
            ) : null}
            <PageLayout>
              <MWPageTitle
                backButton={true}
                title={
                  patientid
                    ? `Demographic Details ( ${firstName} ${lastName} )`
                    : "Add Patient"
                }
              />
              {ActualPageMarkup}
            </PageLayout>
          </Box>
        ) : (
          // Render in File Preparation Section
          <Box>
            {pathName !== "" ? (
              pathName.includes("/demographicdetails") && isLoading ? (
                <AdminLoader />
              ) : null
            ) : null}
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography variant="h4" sx={{ my: 2, fontWeight: "bold" }}>
                {patientid
                  ? `Demographic Details ( ${firstName} ${lastName} )`
                  : "Add Patient"}
              </Typography>
              {ActualPageMarkup}
            </Stack>
          </Box>
        )
      ) : null}
    </Box>
  );
}
