import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AppointmentListBody, AppointmentListResponse } from "./Model";
import {
  appointmentListAPIResClearAction,
  appointmentListErrorAction,
  appointmentListLoadingAction,
  appointmentListSuccessAction,
  appointmentListUpdateAPIMsgAction,
} from "./Actions";
import { AppointmentListActionTypes } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as AppointmentListResponse;
export const appointmentListCallApi = (body: AppointmentListBody, pageNo: number) => {
  return function (dispatch: Dispatch<AppointmentListActionTypes>) {
    dispatch(appointmentListLoadingAction(true));
    axios
      .post(Url.baseUrl + `/appointmentapp/api/appointmentList/get_appointment_list_for_admin?size=50&page=${pageNo}`, body, {
        headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(appointmentListLoadingAction(false));
        dispatch(
          appointmentListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Appointment list fetched successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(appointmentListLoadingAction(false));
        dispatch(
          appointmentListErrorAction(
            {} as AppointmentListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const appointmentListAPIResMsg = () => {
  return function (dispatch: Dispatch<AppointmentListActionTypes>) {
    dispatch(
      appointmentListUpdateAPIMsgAction(apiRes as AppointmentListResponse, "", 0)
    );
  };
};

export const clearappointmentListAPIRes = () => {
  return function (dispatch: Dispatch<AppointmentListActionTypes>) {
    dispatch(
      appointmentListAPIResClearAction({} as AppointmentListResponse, "", 0)
    );
  };
};
