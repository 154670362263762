import React from "react";
import {  useParams } from "react-router-dom";

import {  Box, Stack, Container } from "@mui/material";
import RequestAppointment from "./RequestAppointment";
import AppointmentSelectedOption from "./AppointmentSelectedOption";
import AppointmentList from "./AppointmentList";

type Props = {
  appointmentBookLoader?: boolean | any;
};
export default function AppointmentRequest({ appointmentBookLoader }: Props)  {
  const { caseid } = useParams() as {
    caseid: string;
  };

  const apptSelectLoderUpdatedState = (passedVal: boolean) => {
    appointmentBookLoader(passedVal);
  };
  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <AppointmentSelectedOption
            caseid={caseid}
            apptSelectLoder={apptSelectLoderUpdatedState}
          />
          <RequestAppointment caseid={caseid} />
          <AppointmentList caseid={caseid} />
        </Stack>
      </Container>
    </Box>
  );
}
