import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetProviderDetailsActionTypes } from "./ActionTypes";
import { GetProviderDetailsRes, LoginBody } from "./Model";
import {
  getProviderDetailsAPIResClearAction,
  getProviderDetailsErrorAction,
  getProviderDetailsLoadingAction,
  getProviderDetailsSuccessAction,
  getProviderDetailsAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as GetProviderDetailsRes;
export const providerLoginDetailsApiCall = (payload: LoginBody) => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${payload.access}`,
  };
  return function (dispatch: Dispatch<GetProviderDetailsActionTypes>) {
    dispatch(getProviderDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationAdmin/providerOrgLoginDetails`,
        { providerOrganizationUniqueId: payload.providerOrganizationUniqueId },
        {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getProviderDetailsLoadingAction(false));
        dispatch(
          getProviderDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Login successful"
          )
        );
        localStorage.setItem("loginDetails", JSON.stringify(res.data!)!);
        localStorage.setItem("orgId", JSON.stringify(res.data!.orgId)!);
      })
      .catch((error) => {
        dispatch(getProviderDetailsLoadingAction(false));
        dispatch(
          getProviderDetailsErrorAction(
            {} as GetProviderDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const getProviderDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetProviderDetailsActionTypes>) {
    dispatch(
      getProviderDetailsAPIMsgAction(apiRes as GetProviderDetailsRes, "", 0)
    );
  };
};

export const clearGetProviderDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetProviderDetailsActionTypes>) {
    dispatch(
      getProviderDetailsAPIResClearAction({} as GetProviderDetailsRes, "", 0)
    );
  };
};
