import { AddPatientPointDataDetails } from "./Model";

// Patient Demographics Loading State
export const ADD_PATIENT_DATA_POINT_LOADING = "ADD_PATIENT_DATA_POINT_LOADING";
export const ADD_PATIENT_DATA_POINT_SUCCESS = "ADD_PATIENT_DATA_POINT_SUCCESS";
export const ADD_PATIENT_DATA_POINT_FAIL = "ADD_PATIENT_DATA_POINT_FAIL";
export const ADD_PATIENT_DATA_POINT_UPDATE_API_MSG =
  "ADD_PATIENT_DATA_POINT_UPDATE_API_MSG";
export const ADD_PATIENT_DATA_POINT_CLEAR_API_RES =
  "ADD_PATIENT_DATA_POINT_CLEAR_API_RES";
export interface AddPatientPointDataState {
  addPatientDataPointRes: AddPatientPointDataDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface AddPatientDataPointLoading {
  type: typeof ADD_PATIENT_DATA_POINT_LOADING;
  loading: boolean;
}
export interface AddPatientDataPointSuccess {
  type: typeof ADD_PATIENT_DATA_POINT_SUCCESS;
  payload: AddPatientPointDataDetails;
}
export interface AddPatientDataPointFail {
  type: typeof ADD_PATIENT_DATA_POINT_FAIL;
  payload: AddPatientPointDataDetails;
  message: string;
  status: number;
}
export interface AddPatientDataPointUpdateAPIMsg {
  type: typeof ADD_PATIENT_DATA_POINT_UPDATE_API_MSG;
  payload: AddPatientPointDataDetails;
  message: string;
  status: number;
}
export interface AddPatientDataPointUpdateAPIRes {
  type: typeof ADD_PATIENT_DATA_POINT_CLEAR_API_RES;
  payload: AddPatientPointDataDetails;
  message: string;
  status: number;
}

export type AddPatientDataPointActionTypes =
  | AddPatientDataPointLoading
  | AddPatientDataPointSuccess
  | AddPatientDataPointFail
  | AddPatientDataPointUpdateAPIMsg
  | AddPatientDataPointUpdateAPIRes;
