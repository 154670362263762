import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { PaymentStatusBody, PaymentStatusResponse } from "./Model";
import {
  statusPaymentAPIResClearAction,
  statusPaymentErrorAction,
  statusPaymentLoadingAction,
  statusPaymentSuccessAction,
  statusPaymentUpdateAPIMsgAction,
} from "./Actions";
import { PaymentStatusActionTypes } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as PaymentStatusResponse;
export const paymentStatusCallApi = (body: PaymentStatusBody) => {
  return function (dispatch: Dispatch<PaymentStatusActionTypes>) {
    dispatch(statusPaymentLoadingAction(true));
    axios
      .post(Url.baseUrl + `/paymentapp/api/payment/get_payment_status`, body, {
        headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(statusPaymentLoadingAction(false));
        dispatch(
          statusPaymentSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "payment details updated successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(statusPaymentLoadingAction(false));
        dispatch(
          statusPaymentErrorAction(
            {} as PaymentStatusResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const statusPaymentAPIResMsg = () => {
  return function (dispatch: Dispatch<PaymentStatusActionTypes>) {
    dispatch(
      statusPaymentUpdateAPIMsgAction(apiRes as PaymentStatusResponse, "", 0)
    );
  };
};

export const clearstatusPaymentAPIRes = () => {
  return function (dispatch: Dispatch<PaymentStatusActionTypes>) {
    dispatch(
      statusPaymentAPIResClearAction({} as PaymentStatusResponse, "", 0)
    );
  };
};
