import * as React from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import MWResourceList from "../../../component/MWResourceList";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWPageTitle from "../../../component/MWPageTitle";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";
import AdminLoader from "../../AdminLoader";

export default function CaseListExpanded()  {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  // Reload Patient Case List API
  const reloadPatientCaseList = () => {
    dispatch(getPatientCaseList(patientid));
  };
  React.useEffect(() => {
    dispatch(getPatientCaseList(patientid));
   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const emptyPageMarkup = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box p={3}>
          <Typography variant="h6" gutterBottom>
            No case list available
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const caseHistory = useSelector((state: AppState) => state.caseValue);
  console.log("caseHistory", caseHistory);
  const pageBody = (
    <Box py={2}>
      {caseHistory?.caseValue !== undefined ? (
        caseHistory?.caseValue.length > 0 ? (
          caseHistory?.caseValue.map((element: any) => (
            <Box key={element.id} style={{ textDecoration: "none", textTransform: "none" }}>
              <Link
                to={`/casedetails/${element.patientId}/${element.caseId}`}
                style={{ textDecoration: "none" }}
              >
                <MWResourceList
                  icon={true}
                  title={element?.whyWant2ndOpinion}
                  showSubtitle={true}
                  subtitle={element?.secondOpinionReasons}
                  showReason={true}
                  reason={element?.consultationType}
                  showNavigation={true}
                />
              </Link>
            </Box>
          ))
        ) : (
          <Box>{emptyPageMarkup}</Box>
        )
      ) : (
        <Box>{emptyPageMarkup}</Box>
      )}
    </Box>
  );
  return (
    <Box>
      {caseHistory?.loading ? <AdminLoader /> : null}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <MWPageTitle
            backButton={true}
            title="Patient Case List"
            enableCount={true}
            count={
              caseHistory?.caseValue !== undefined
                ? caseHistory?.caseValue.length
                : "0"
            }
            reload={true}
            reloadAction={reloadPatientCaseList}
          />
        </Stack>
        <Box>{pageBody}</Box>
      </Container>
    </Box>
  );
}
