import axios from "axios";
import { Dispatch } from "react";
import Url from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { DoctorQuriesListResponse } from "./Model";
import {
  executiveListErrorAction,
  doctorQuriesListLoadingAction,
  doctorQuriesListSuccessAction,
} from "./Actions";
import { DoctorQuriesListActionTypes } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const DoctorQuriesListApi = () => {
  return function (dispatch: Dispatch<DoctorQuriesListActionTypes>) {
    dispatch(doctorQuriesListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/adminapp/api/queries/doctor_query_list`, {
        headers,
      })
      .then((res) => {
        dispatch(doctorQuriesListLoadingAction(false));
        dispatch(doctorQuriesListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(doctorQuriesListLoadingAction(false));
        dispatch(
          executiveListErrorAction(
            {} as DoctorQuriesListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};
