import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailOtpGenerationApi } from "../../redux/emailOtpGeneration/API";
import { emailOtpGenerationDetails } from "../../redux/emailOtpGeneration/Model";
import { AppState } from "../../redux/store/Store";
import { emailOtpAuthDetails } from "../../redux/emailOtpAuth/Model";
import { emailOtpAuthApi } from "../../redux/emailOtpAuth/API";
import { Alert, Box, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import MWTextField from "../../component/MWTextField";

export default function Loginwithotpcomponent() {
  const dispatch = useDispatch();
  const [proOrgId, setProOrgId] = useState("");
  const [errorProOrgId, setErrorProOrgId] = useState("");
  const [otpemail, setOtpEmail] = useState("");
  const [errorotpemail, setErrorOtpEmail] = useState("");
  const [isDisableOTPField, setIsDisableOTPField] = useState(true);
  const [otpStatus, setOtpStatus] = useState("");
  const [otpMsg, setOtpMsg] = useState("");
  const [otpinvlid, setOtpInvalid] = useState("");
  const [buttonText, setButtonText] = useState(
    "SEND OTP"
  );
  const [validotp, setvalidOtp] = useState("");
  const [errorotp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = React.useState(false);
  const [loginLoading, setloginLoading] = React.useState(false);
  const history = useHistory();

  const errorOtpEmail = errorotpemail ? (
    <Typography>{errorotpemail}</Typography>
  ) : (
    ""
  );

  const closeSuccessBanner = () => {
    setOtpMsg("");
  };

  const closeErrorBanner = () => {
    setOtpInvalid("");
  };

  const OTPText = otpMsg ? (
    <Alert
      variant="standard"
      severity={otpStatus === 'Failed' ? "error" : "success"}
      onClose={() => closeSuccessBanner()}
    >
      <Typography>{otpMsg}</Typography>
    </Alert>
  ) : (
    ""
  );

  const errorOtp = errorotp ? <Typography>{errorotp}</Typography> : "";
  const wrongOtp = otpinvlid ? (
    <Alert
      variant="standard"
      severity="error"
      title={errorotp}
      onClose={() => closeErrorBanner()}
    >
      <Typography>{otpinvlid}</Typography>
    </Alert>
  ) : (
    ""
  );

  const emailotpbody = {
    email: otpemail.toLowerCase(),
  } as emailOtpGenerationDetails;

  const otpbody = {
    providerOrganizationUniqueId: proOrgId.toString(),
    email: otpemail.toLowerCase(),
    otp: validotp,
  } as emailOtpAuthDetails;

  //handle button click of otp generation
  const handleLoginwithotp = () => {
    if (otpemail === "") {
      setErrorOtpEmail("Please enter email id");
      return;
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        otpemail.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(otpemail.toLowerCase()) === false
    ) {
      setErrorOtpEmail("Please enter valid email id");
    } else {
      setErrorOtpEmail("");
      dispatch(emailOtpGenerationApi(emailotpbody));
    }
  };
  const emailOtpGen = useSelector(
    (state: AppState) => state.emailOtpGeneration
  );
  const emailOtpAuth = useSelector((state: AppState) => state.emailOtpAuth);
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLoginwithValidotp();
    }
  };
  useEffect(() => {
    emailOtpGen?.loading
      ? setOtpLoading(true)
      : setOtpLoading(false);
  }, [emailOtpGen]);

  useEffect(() => {
    emailOtpAuth?.loading
      ? setloginLoading(true)
      : setloginLoading(false);
  }, [emailOtpAuth]);

  useEffect(() => {
    if (emailOtpGen?.emailOtpGenerationRes?.message !== undefined) {
      setOtpMsg(emailOtpGen?.emailOtpGenerationRes?.message);
      setOtpStatus("success");
      setIsDisableOTPField(false);
      setButtonText("Resend OTP");
    } else {
      setOtpMsg(emailOtpGen.errRes);
      setOtpStatus("failed");
      setloginLoading(false);
      setButtonText("Send OTP");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpGen]);

  // otp validation
  const handleLoginwithValidotp = () => {
    if (proOrgId !== "" && validotp !== "") {
      dispatch(emailOtpAuthApi(otpbody));
    } else {
      setErrorProOrgId("Please enter Provider Organization Id");
      setOtp("Please enter valid OTP");
      return;
    }
  };

  useEffect(() => {
    if (emailOtpAuth?.emailOtpAuthRes?.access !== undefined) {
      history.push("/dashboard");
      window.location.reload();
    } else {
      setOtpInvalid(emailOtpAuth.errRes);
      history.push("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpAuth]);

  return (
    <Box sx={{ minWidth: 350 }} onKeyDown={keyDownEvent}>
      <MWTextField
        fullWidth
        sx={{ mt: 2, mb: 1 }}
        error={errorProOrgId === "" ? false : true}
        value={proOrgId}
        label={"Provider Organization Id"}
        placeholder="Pleace Enter Id"
        id="providerOrgID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setProOrgId(event.target.value);
          setErrorProOrgId("");
        }}
        size="small"
        helperText={errorProOrgId === "" ? "Please provide provider organization Id" : errorProOrgId}
      />
      <MWTextField
        fullWidth
        sx={{ my: 1 }}
        error={errorOtpEmail === "" ? false : true}
        value={otpemail}
        label={"Provider Organization Email"}
        placeholder="Pleace Enter Email"
        id="emailFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setOtpEmail(event.target.value);
          setErrorOtpEmail("");
        }}
        size="small"
        helperText={errorOtpEmail === "" ? "Please provide email" : errorOtpEmail}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        loading={otpLoading}
        loadingIndicator="Loading…"
        onClick={handleLoginwithotp}
      >
        {buttonText}
      </LoadingButton>
      <Box my={1}>{OTPText}</Box>

      {/*............ for validate otp code .............. */}
      <MWTextField
        fullWidth
        sx={{ my: 1 }}
        value={validotp}
        error={errorOtp === "" ? false : true}
        label={"OTP"}
        placeholder={"OTP"}
        id="OTPFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setvalidOtp(event.target.value);
          setOtp('');
        }}
        disabled={isDisableOTPField}
        size="small"
        helperText={errorOtp === "" ? "Please provide OTP" : errorOtp}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleLoginwithValidotp}
        disabled={isDisableOTPField}
        loading={loginLoading}
        loadingIndicator="Loading…"
      >
        Log In
      </LoadingButton>
      <Box mt={1}>{wrongOtp}</Box>
    </Box>
  );
}
