import { AddPatientOrganizationRes } from "./Model";

export const ADD_ORGANIZATION_PATIENT_LOADING =
  "ADD_ORGANIZATION_PATIENT_LOADING";
export const ADD_ORGANIZATION_PATIENT_SUCCESS =
  "ADD_ORGANIZATION_PATIENT_SUCCESS";
export const ADD_ORGANIZATION_PATIENT_FAIL =
  "ADD_ORGANIZATION_PATIENT_FAIL";
export const ADD_ORGANIZATION_PATIENT_DATA =
  "ADD_ORGANIZATION_PATIENT_DATA";

export interface AddOrganizationPatientDetails {
  addOrganizationPatientRes: AddPatientOrganizationRes;
  loading: boolean;
  errRes: string;
}
export interface AddOrganizationPatientLoading {
  type: typeof ADD_ORGANIZATION_PATIENT_LOADING;
}
export interface AddOrganizationPatientSuccess {
  type: typeof ADD_ORGANIZATION_PATIENT_SUCCESS;
  payload: AddPatientOrganizationRes;
  errRes: string;
}
export interface AddOrganizationPatientFail {
  type: typeof ADD_ORGANIZATION_PATIENT_FAIL;
  payload: AddPatientOrganizationRes;
  errRes: string;
}
interface AddOrganizationPatientAction {
  type: typeof ADD_ORGANIZATION_PATIENT_DATA;
  payload: AddPatientOrganizationRes;
  errRes: string;
}

export type PatientAddOrganizationActionTypes =
  | AddOrganizationPatientAction
  | AddOrganizationPatientLoading
  | AddOrganizationPatientSuccess
  | AddOrganizationPatientFail;
