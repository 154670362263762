import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWExceptionList from "../../component/MWExceptionList";
import MWPageTitle from "../../component/MWPageTitle";
import { providerDoctorList } from "../../redux/doctorList/API";
import { ProviderDoctorListBody } from "../../redux/doctorList/Model";
import PageLayout from "../Layout/PageLayout";
import AddDoctorDialog from "./AddDoctorDialog";

export default function Doctor() {
  const dispatch = useDispatch();
  const history = useHistory();

  // Api call from store.....
  const doctorList = useSelector(
    (state: AppState) => state.providerDoctorListValue
  );

  const gePatientListApiBody = {
    providerorgId: localStorage.getItem("orgId"),
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const [listCount, setListCount] = React.useState("");

  const [showAddDialog, setShowAddDialog] = React.useState(false);
  const updateDialogState = () => {
    setShowAddDialog(true);
  };
  const updateAddDialog = (passedVal: boolean) => {
    setShowAddDialog(passedVal);
  };

  useEffect(() => {
    dispatch(
      providerDoctorList(gePatientListApiBody as ProviderDoctorListBody, 1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const doctorDetails = (doctorId: any) => {
    history.push(`/doctordetails/${doctorId}`);
  };

  useEffect(() => {
    doctorList?.providerDoctorListResponse?.DoctorList !== undefined
      ? setListCount(
          String(doctorList?.providerDoctorListResponse?.DoctorList.length)
        )
      : setListCount("0");

    if (
      doctorList?.providerDoctorListResponse?.DoctorList !== undefined &&
      doctorList?.providerDoctorListResponse?.DoctorList.length > 0
    ) {
      let dataPointArr = doctorList?.providerDoctorListResponse?.DoctorList.map(
        (element: any) => ({
          id: element.id,
          doctorName: element.fullName,
          specialtyWithYearsOfExperience:
            element.specialtyWithYearsOfExperience !== null
              ? element.specialtyWithYearsOfExperience
              : "Not Set",
        })
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorList]);

  const doctorListReload = () => {
    dispatch(
      providerDoctorList(gePatientListApiBody as ProviderDoctorListBody, 1)
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Doctor Details",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            doctorDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    { field: "doctorName", headerName: "Doctor Name", flex: 1 },
    {
      field: "specialtyWithYearsOfExperience",
      headerName: "Speciality",
      flex: 1,
    },
  ];
  const DoctorListTable = (
    <Card>
      <CardContent>
        {doctorList?.providerDoctorListResponse?.DoctorList !== undefined &&
        doctorList?.providerDoctorListResponse?.DoctorList.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {doctorList.loading === true ? <AdminLoader /> : ""}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <MWPageTitle
              title="Doctor List"
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={doctorListReload}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => updateDialogState()}
              sx={{ textTransform: "none" }}
            >
              Add New Doctor
            </Button>
          </Grid>
        </Grid>
        {DoctorListTable}
      </PageLayout>
      {showAddDialog ? (
        <AddDoctorDialog
          dialogEnable={showAddDialog}
          addDialogUpdateState={updateAddDialog}
        />
      ) : null}
    </Box>
  );
}
