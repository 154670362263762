import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { getHealthcampListAction } from "./Actions";
import {
  AdminGetHealthcampListActionTypes,
  HEALTHCAMP_LIST_FAIL,
  HEALTHCAMP_LIST_LOADING,
  HEALTHCAMP_LIST_SUCCESS,
} from "./ActionTypes";
import { headers } from "../../component/Utility";

export const getHealthcampListValue = () => {
  return function (dispatch: Dispatch<AdminGetHealthcampListActionTypes>) {
    dispatch({
      type: HEALTHCAMP_LIST_LOADING,
    });
    axios
      .get(
        Url.baseUrl + "/caregiverapp/api/healthcamp/health_camp_list_by_staff",
        {
          headers,
        }
      )
      .then((res) => {
        let healthcampData = res.data;
        dispatch(getHealthcampListAction(healthcampData));
        dispatch({
          type: HEALTHCAMP_LIST_SUCCESS,
          payload: healthcampData,
        });
      })
      .catch((error) => {
        dispatch({
          type: HEALTHCAMP_LIST_FAIL,
        });
      });
  };
};