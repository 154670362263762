import { GetProviderDetailsRes } from "./Model";
export const GET_PROVIDER_LOGIN_DETAILS_LOADING =
  "GET_PROVIDER_LOGIN_DETAILS_LOADING";
export const GET_PROVIDER_LOGIN_DETAILS_SUCCESS =
  "GET_PROVIDER_LOGIN_DETAILS_SUCCESS";
export const GET_PROVIDER_LOGIN_DETAILS_FAIL =
  "GET_PROVIDER_LOGIN_DETAILS_FAIL";
export const GET_PROVIDER_LOGIN_DETAILS_API_MSG =
  "GET_PROVIDER_LOGIN_DETAILS_API_MSG";
export const GET_PROVIDER_LOGIN_DETAILS_API_RES =
  "GET_PROVIDER_LOGIN_DETAILS_API_RES";

export interface GetProviderLoginDetailsState {
  getProviderLoginDetailsRes: GetProviderDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetProviderLoginDetailsLoading {
  type: typeof GET_PROVIDER_LOGIN_DETAILS_LOADING;
  loading: boolean;
}
export interface GetProviderLoginDetailsSuccess {
  type: typeof GET_PROVIDER_LOGIN_DETAILS_SUCCESS;
  payload: GetProviderDetailsRes;
  successMsg: string;
}
export interface GetProviderLoginDetailsFail {
  type: typeof GET_PROVIDER_LOGIN_DETAILS_FAIL;
  payload: GetProviderDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetProviderLoginDetailsAPIMsg {
  type: typeof GET_PROVIDER_LOGIN_DETAILS_API_MSG;
  payload: GetProviderDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetProviderLoginDetailsAPIRes {
  type: typeof GET_PROVIDER_LOGIN_DETAILS_API_RES;
  payload: GetProviderDetailsRes;
  errorMsg: string;
  status: number;
}

export type GetProviderDetailsActionTypes =
  | GetProviderLoginDetailsLoading
  | GetProviderLoginDetailsSuccess
  | GetProviderLoginDetailsFail
  | GetProviderLoginDetailsAPIMsg
  | GetProviderLoginDetailsAPIRes;
