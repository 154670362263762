import {
    REVIEW_NEEDED_DATA,
    REVIEW_NEEDED_FAIL,
    REVIEW_NEEDED_LOADING,
    REVIEW_NEEDED_SUCCESS,
    ReviewNeededActionTypes,
  } from "./ActionTypes";
  import { NeededReviewResponse } from "./Model";
  
  export const reviewNeededAction = (
    reviewNeededRes: NeededReviewResponse
  ): ReviewNeededActionTypes => {
    return {
      type: REVIEW_NEEDED_DATA,
      payload: reviewNeededRes,
    };
  };
  
  export const reviewNeededLoadingAction = (
    loading: boolean
  ): ReviewNeededActionTypes => {
    return {
      type: REVIEW_NEEDED_LOADING,
      loading: loading,
    };
  };
  
  export const reviewNeededSuccessAction = (
    reviewNeededRes: NeededReviewResponse,
    successMsg: string,
    status: number    
  ): ReviewNeededActionTypes => {
    return {
      type: REVIEW_NEEDED_SUCCESS,
      payload: reviewNeededRes,
      successMsg: successMsg,
      status: status      
    };
  };
  
  export const reviewNeededErrorAction = (
    reviewNeededRes: NeededReviewResponse,
    errMsg: string,
    status: number
  ): ReviewNeededActionTypes => {
    return {
      type: REVIEW_NEEDED_FAIL,
      payload: reviewNeededRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const reviewNeededAPIMsgAction = (
    reviewNeededRes: NeededReviewResponse,
    errMsg: string,
    status: number
  ): ReviewNeededActionTypes => {
    return {
      type: REVIEW_NEEDED_FAIL,
      payload: reviewNeededRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const reviewNeededAPIResClearAction = (
    reviewNeededRes: NeededReviewResponse,
    errMsg: string,
    status: number
  ): ReviewNeededActionTypes => {
    return {
      type: REVIEW_NEEDED_FAIL,
      payload: reviewNeededRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  