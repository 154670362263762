import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { getProviderOrganizationListValue } from "../../redux/ProviderOrganizationList/API";
import { AppState } from "../../redux/store/Store";
import { ProviderOrganizationList } from "../../redux/ProviderOrganizationList/Model";
import MWExceptionList from "../../component/MWExceptionList";
import AdminLoader from "../AdminLoader";
import PageLayout from "../Layout/PageLayout";
import MWPageTitle from "../../component/MWPageTitle";

export default function ProviderOrgListPage(props: any) {
  /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
//   const { providerOrgListLoader } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageListCount, setPageListCount] = React.useState("");
  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    dispatch(getProviderOrganizationListValue(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getProviderOrganizationListValue(value));
  };
  const organizationList = useSelector(
    (state: AppState) => state.getProviderOrganizationListValue
  );
  const orgValues =
    organizationList?.getProviderOrganizationRes?.providerOrganizationList;
  console.log("orgValues", orgValues);
  useEffect(() => {
    if (
      organizationList?.getProviderOrganizationRes?.providerOrganizationList !==
      undefined
    ) {
      setPageListCount(
        String(organizationList?.getProviderOrganizationRes?.numberOfPages)
      );
    }
    if (orgValues !== undefined) {
      let dataPointArr = orgValues.map((element: ProviderOrganizationList) => ({
        id: element.id,
        displayName: element.displayName,
        tagLine: element.tagLine,
        email: element.email,
        createDate: dayjs(element.dateCreated).format("ddd, MMM D, YYYY"),
        description: element.description,
        action: element.providerOrganizationUniqueId,
      }));
      setDataGridArray(dataPointArr);
    } else {
      setPageListCount(
        String(organizationList?.getProviderOrganizationRes?.numberOfPages)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationList]);

  const gotoAdministratorList = (rowIndex: any) => {
    history.push(`/patientdetailslist/${rowIndex}`);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 200,
      align: "left",
      //hide: urlPath !== "/searchpatient" ? true : false,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            gotoAdministratorList(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          View
        </Button>
      ),
    },
    {
      field: "displayName",
      headerName: "Organization Name",
      width: 80,
      align: "left",
      flex: 1,
    },
    {
      field: "tagLine",
      headerName: "Organization Tagline",
      width: 80,
      align: "left",
      flex: 1,
    },

    {
      field: "createDate",
      headerName: "Created Date",
      width: 200,
      align: "left",
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      align: "left",
      flex: 1,
    },
  ];

  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                pt={2}
              >
                <Grid item>
                  <Typography>Page: {page}</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageListCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {orgValues && orgValues.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {organizationList.loading === true ? <AdminLoader /> : null}
      <PageLayout>
        <MWPageTitle
          title="Provider Organization List"
          reload={false}
        />
        {actualPageMarkup}
      </PageLayout>
    </Box>
  );
}
