import * as React from "react";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";

export default function ImportFromEmail() {
  const importDataFromEmail = () => {};
  const bodyMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">From Email</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item>
                  <Button
                    size="large"
                    onClick={importDataFromEmail}
                    variant="outlined"
                    startIcon={<ImportExportIcon />}
                  >
                    Import Data
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <MWExceptionList />
      </CardContent>
    </Card>
  );

  return <Box>{bodyMarkup}</Box>;
}
