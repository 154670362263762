import { AddDoctorTeamTypes, AddDoctorToCaseTypes, ADD_DOCTORTEAMTYPE, ADD_DOCTORTOCASE, 
  DeleteDoctorFromCaseTypes, 
  DELETE_DOCTORFROMCASE, 
  DoctorAddToCaseTypes, GET_DOCTOR_TO_CASE, SelectedDoctorTeamTypes, 
  SelectedSpecialistDoctorTypes, SELECTED_DOCTOR_TEAM_TYPE_LIST, SELECTED_SPECIALIST_DOCTOR_TYPE_LIST, 
  SpecialistDoctorTypes,SPECIALIST_DOCTOR_TYPE_LIST
} from "./ActionTypes";

// export const DoctorTeamTypeListAction = (doctorTeamType: any): DoctorTeamTypes => {
//   return {
//     type: DOCTOR_TEAM_TYPE_LIST,
//     payload: doctorTeamType
//   };
// };

export const AddDoctorTeamTypeAction = (adddoctorTeamType: any): AddDoctorTeamTypes => {
  return {
    type: ADD_DOCTORTEAMTYPE,
    payload: adddoctorTeamType
  };
};

export const SelectedDoctorTeamTypeListAction = (getdoctorTeamType: any): SelectedDoctorTeamTypes => {
  return {
    type: SELECTED_DOCTOR_TEAM_TYPE_LIST,
    payload: getdoctorTeamType
  };
};

export const SpecialistDoctorTypeListAction = (specialist: any): SpecialistDoctorTypes => {
  return {
    type: SPECIALIST_DOCTOR_TYPE_LIST,
    payload: specialist
  };
};

export const SelectedSpecialistDoctorTypeListAction = (selectedSpecialistDoctor: any): SelectedSpecialistDoctorTypes => {
  return {
    type: SELECTED_SPECIALIST_DOCTOR_TYPE_LIST,
    payload: selectedSpecialistDoctor
  };
};

export const AddDoctorToCaseAction = (adddoctorToCase: any): AddDoctorToCaseTypes => {
  return {
    type: ADD_DOCTORTOCASE,
    payload: adddoctorToCase
  };
};

export const AddedDoctorToCaseListAction = (addeddoctortocase: any): DoctorAddToCaseTypes => {
  return {
    type: GET_DOCTOR_TO_CASE,
    payload: addeddoctortocase
  };
};

export const DeleteDoctorFromCaseAction = (deletedoctorfromCase: any): DeleteDoctorFromCaseTypes => {
  return {
    type: DELETE_DOCTORFROMCASE,
    payload: deletedoctorfromCase
  };
};