import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { PatientSearchActionTypes } from "./ActionTypes";
import { PatientSearchBody, PatientSearchDetails } from "./Model";
import {
  patientSearchAPIResClearAction,
  patientSearchErrorAction,
  patientSearchLoadingAction,
  patientSearchSuccessAction,
  patientSearchUpdateAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as PatientSearchDetails;
export const patientSearchApi = (searchKey: PatientSearchBody, pageNo: any) => {
  return function (dispatch: Dispatch<PatientSearchActionTypes>) {
    dispatch(patientSearchLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/patientapp/api/search-patient/search_patient_list?page=${Number(
            pageNo
          )}&size=12`,
        searchKey,
        { headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(patientSearchLoadingAction(false));
        dispatch(patientSearchSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(patientSearchLoadingAction(false));
        dispatch(
          patientSearchErrorAction(
            {} as PatientSearchDetails,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientSearchActionTypes>) {
    dispatch(
      patientSearchUpdateAPIMsgAction(apiRes as PatientSearchDetails, "", 0)
    );
  };
};

export const clearSearchAPIRes = () => {
  return function (dispatch: Dispatch<PatientSearchActionTypes>) {
    dispatch(patientSearchAPIResClearAction({} as PatientSearchDetails, "", 0));
  };
};
