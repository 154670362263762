import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetQuestionnaireListActionTypes } from "./ActionTypes";
import { GetPatientDataDetailsBody, GetQuestionnaireListRes } from "./Model";
import {
  getQuestionnaireListAPIResClearAction,
  getQuestionnaireListErrorAction,
  getQuestionnaireListLoadingAction,
  getQuestionnaireListSuccessAction,
  getQuestionnaireListUpdateAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as GetQuestionnaireListRes;
export const getQuestionnaireList = (payload: GetPatientDataDetailsBody) => {
  return function (dispatch: Dispatch<GetQuestionnaireListActionTypes>) {
    dispatch(getQuestionnaireListLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/patientapp/api/patientMedicalData/get_patientQuestionnaireList`,
        payload,
        {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getQuestionnaireListLoadingAction(false));
        dispatch(
          getQuestionnaireListSuccessAction(
            res.data,
            res?.data !== undefined
              ? res?.data?.message
                ? res.data.message
                : "Patient questionnaire list has been fetched successfully"
              : "Patient questionnaire list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getQuestionnaireListLoadingAction(false));
        dispatch(
          getQuestionnaireListErrorAction(
            {} as GetQuestionnaireListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetQuestionnaireListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetQuestionnaireListActionTypes>) {
    dispatch(
      getQuestionnaireListUpdateAPIMsgAction(
        apiRes as GetQuestionnaireListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearQuestionnaireListAPIRes = () => {
  return function (dispatch: Dispatch<GetQuestionnaireListActionTypes>) {
    dispatch(
      getQuestionnaireListAPIResClearAction(
        {} as GetQuestionnaireListRes,
        "",
        "",
        0
      )
    );
  };
};
