import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetAdminNoteListByPatientActionTypes } from "./ActionTypes";
import { GetAdminNoteListByPatientBody, GetAdminNoteListByPatientRes } from "./Model";
import {
  getAdminNoteListByPatientAPIResClearAction,
  getAdminNoteListByPatientErrorAction,
  getAdminNoteListByPatientLoadingAction,
  getAdminNoteListByPatientSuccessAction,
  getAdminNoteListByPatientUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetAdminNoteListByPatientRes;
export const getAdminNoteListByPatient = (
  payload: GetAdminNoteListByPatientBody
) => {
  return function (dispatch: Dispatch<GetAdminNoteListByPatientActionTypes>) {
    dispatch(getAdminNoteListByPatientLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/erpapp/api/adminNote/get_admin_notes_by_patient`,
        payload,
        {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getAdminNoteListByPatientLoadingAction(false));
        dispatch(getAdminNoteListByPatientSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getAdminNoteListByPatientLoadingAction(false));
        dispatch(
          getAdminNoteListByPatientErrorAction(
            {} as GetAdminNoteListByPatientRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateAdminNoteListByPatientEditAPIResMsg = () => {
  return function (dispatch: Dispatch<GetAdminNoteListByPatientActionTypes>) {
    dispatch(
      getAdminNoteListByPatientUpdateAPIMsgAction(
        apiRes as GetAdminNoteListByPatientRes,
        "",
        0
      )
    );
  };
};

export const clearAdminNoteListByPatientEditAPIRes = () => {
  return function (dispatch: Dispatch<GetAdminNoteListByPatientActionTypes>) {
    dispatch(
      getAdminNoteListByPatientAPIResClearAction(
        {} as GetAdminNoteListByPatientRes,
        "",
        0
      )
    );
  };
};
