import { AppointmentList, PaymentList } from "./Model";

// Case Status
export const PAYMENT_LIST_LOADING = "PAYMENT_LIST_LOADING";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL";
export const PAYMENT_LIST_TYPE = 'PAYMENT_LIST_TYPE';

export interface PaymentListStateType {
    loading: boolean;
    paymentList: PaymentList[];
    consultationType: string;
    doctorTeamType: string;
    responseTimeType: string;
    message: string;
    status: number;
}
export interface PaymentListLoading {
    type: typeof PAYMENT_LIST_LOADING;
}
export interface PaymentListSuccess {
    type: typeof PAYMENT_LIST_SUCCESS;
    paymentList: PaymentList[];
    consultationType: string;
    doctorTeamType: string;
    responseTimeType: string;
    message: string;
    status: number;
}
export interface PaymentListFail {
    type: typeof PAYMENT_LIST_FAIL;
    message: string;
    status: number;
}

interface PaymentListType {
    type: typeof PAYMENT_LIST_TYPE;
    paymentList: PaymentList[];
    consultationType: string;
    doctorTeamType: string;
    responseTimeType: string;
    message: string;
    status: number;
}

export type PaymentListTypes = PaymentListType | PaymentListLoading | PaymentListSuccess | PaymentListFail;

// Appointment List
export const APPOINTMENT_LIST_LOADING = "APPOINTMENT_LIST_LOADING";
export const APPOINTMENT_LIST_SUCCESS = "APPOINTMENT_LIST_SUCCESS";
export const APPOINTMENT_LIST_FAIL = "APPOINTMENT_LIST_FAIL";
export const APPOINTMENT_LIST_TYPE = 'APPOINTMENT_LIST_TYPE';

export interface AppointmentListStateType {
    loading: boolean;
    appointmentList: AppointmentList[];
    consultationType: string;
    doctorTeamType: string;
    responseTimeType: string;
    message: string;
    status: number;
}
export interface AppointmentListLoading {
    type: typeof APPOINTMENT_LIST_LOADING;
}
export interface AppointmentListSuccess {
    type: typeof APPOINTMENT_LIST_SUCCESS;
    appointmentList: AppointmentList[];
    consultationType: string;
    doctorTeamType: string;
    responseTimeType: string;
    message: string;
    status: number;
}
export interface AppointmentListFail {
    type: typeof APPOINTMENT_LIST_FAIL;
    message: string;
    status: number;
}

interface AppointmentListType {
    type: typeof APPOINTMENT_LIST_TYPE;
    appointmentList: AppointmentList[];
    consultationType: string;
    doctorTeamType: string;
    responseTimeType: string;
    message: string;
    status: number;
}

export type AppointmentListTypes = AppointmentListType | AppointmentListLoading | AppointmentListSuccess | AppointmentListFail;
// Appointment List
export const CHECK_PAYMENT_STATUS_LOADING = "CHECK_PAYMENT_STATUS_LOADING";
export const CHECK_PAYMENT_STATUS_SUCCESS = "CHECK_PAYMENT_STATUS_SUCCESS";
export const CHECK_PAYMENT_STATUS_FAIL = "CHECK_PAYMENT_STATUS_FAIL";
export const CHECK_PAYMENT_STATUS_TYPE = 'CHECK_PAYMENT_STATUS_TYPE';

export interface CheckPaymentStatusStateType {
    loading: boolean;
    message: string;
    status: number;
}
export interface CheckPaymentStatusLoading {
    type: typeof CHECK_PAYMENT_STATUS_LOADING;
}
export interface CheckPaymentStatusSuccess {
    type: typeof CHECK_PAYMENT_STATUS_SUCCESS;
    message: string;
    status: number;
}
export interface CheckPaymentStatusFail {
    type: typeof CHECK_PAYMENT_STATUS_FAIL;
    message: string;
    status: number;
}

interface CheckPaymentStatusType {
    type: typeof CHECK_PAYMENT_STATUS_TYPE;
    message: string;
    status: number;
}

export type CheckPaymentStatusTypes = CheckPaymentStatusType | CheckPaymentStatusLoading | CheckPaymentStatusSuccess | CheckPaymentStatusFail;
// Appointment Confirm
export const APPOINTMENT_CONFIRM_LOADING = "APPOINTMENT_CONFIRM_LOADING";
export const APPOINTMENT_CONFIRM_SUCCESS = "APPOINTMENT_CONFIRM_SUCCESS";
export const APPOINTMENT_CONFIRM_FAIL = "APPOINTMENT_CONFIRM_FAIL";
export const APPOINTMENT_CONFIRM_TYPE = 'APPOINTMENT_CONFIRM_TYPE';

export interface AppointmentConfirmStateType {
    loading: boolean;
    message: string;
    status: number;
}
export interface AppointmentConfirmLoading {
    type: typeof APPOINTMENT_CONFIRM_LOADING;
}
export interface AppointmentConfirmSuccess {
    type: typeof APPOINTMENT_CONFIRM_SUCCESS;
    message: string;
    status: number;
}
export interface AppointmentConfirmFail {
    type: typeof APPOINTMENT_CONFIRM_FAIL;
    message: string;
    status: number;
}

interface AppointmentConfirmType {
    type: typeof APPOINTMENT_CONFIRM_TYPE;
    message: string;
    status: number;
}

export type AppointmentConfirmTypes = AppointmentConfirmType | AppointmentConfirmLoading | AppointmentConfirmSuccess | AppointmentConfirmFail;
