import {
  forgotPasswordDetails,
  forgotPasswordActionTypes,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
} from "./ActionTypes";
import { forgotPasswordValue } from "./Model";

const initialStateGetPosts: forgotPasswordDetails = {
  loading: false,
  forgotPasswordRes: {} as forgotPasswordValue,
  errRes: "",
};
export const forgotPasswordReducer = (
  state = initialStateGetPosts,
  action: forgotPasswordActionTypes
): forgotPasswordDetails => {
  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordRes: action.payload,
        errRes: "",
      };
    case FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        forgotPasswordRes: {} as forgotPasswordValue,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPasswordRes: {} as forgotPasswordValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
