import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";
import MWExceptionList from "../../../component/MWExceptionList";
import MWPageTitle from "../../../component/MWPageTitle";
import { ObservationList } from "../../../redux/GetViewExtractObservation/Model";

type Props = {
  getExtractObservationDetailsValue?: any;
};

export default function DataObservationList({
  getExtractObservationDetailsValue,
}: Props)  {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [getExtractObservationValue, setGetExtractObservationValue] =
    React.useState([] as any[]);
  React.useEffect(() => {
    setGetExtractObservationValue(getExtractObservationDetailsValue);
  }, [getExtractObservationDetailsValue]);
  const caseHistoryPtName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName
  );
  const [patientName, setPatientName] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("");
  const [listCount, setListCount] = React.useState("");

  useEffect(() => {
    patientName !== ""
      ? setPageTitle(`View Observation List of ${patientName}`)
      : setPageTitle("");
  }, [patientName]);

  useEffect(() => {
    if (caseHistoryPtName === undefined) {
      dispatch(getPatientCaseList(patientid));
    }
    if (caseHistoryPtName !== undefined) {
      setPatientName(caseHistoryPtName);
    } else setPatientName("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseHistoryPtName]);

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    getExtractObservationValue !== undefined
      ? setListCount(String(getExtractObservationValue?.length))
      : setListCount("0");

    if (getExtractObservationValue !== undefined) {
      let dataPointArr = getExtractObservationValue.map(
        (element: ObservationList) => ({
          id: element.id,
          value: element.value,
          startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY"),
          displayValue: element.patientObservationType.displayValue,
          modifiedDate: dayjs(element.modifiedDate).format(
            "dddd, MMMM D, YYYY"
          ),
        })
      );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
    }
  }, [getExtractObservationValue]);

  const columns: GridColDef[] = [
    {
      field: "displayValue",
      headerName: "Observation",
      type: "string",
      width: 120,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
    {
      field: "value",
      headerName: "Value",
      width: 80,
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
  ];
  const patientPointDataTable = (
    <>
      <MWPageTitle title={pageTitle} enableCount={true} count={listCount} />
      {dataGridArray && dataGridArray.length ? (
        <DataGrid
          sx={{ mt: 2 }}
          rows={dataGridArray}
          columns={columns}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      ) : (
        <MWExceptionList />
      )}
    </>
  );
  return <Box>{patientPointDataTable}</Box>;
}
