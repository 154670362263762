import axios from "axios";
import Url from "../../ApiConfig";
import { Dispatch } from "react";
import { AddPatientCaseActionTypes, ADD_PATIENT_CASE_FAIL, ADD_PATIENT_CASE_LOADING, ADD_PATIENT_CASE_STATUS, ADD_PATIENT_CASE_SUCCESS, AdminGetConsultationTypeListActionTypes, AdminGetOpinionTypeListActionTypes, AdminGetSecondOpinionListActionTypes, CONSULTATION_TYPE_LIST_FAIL, CONSULTATION_TYPE_LIST_LOADING, CONSULTATION_TYPE_LIST_SUCCESS, EditPatientCaseActionTypes, EDIT_PATIENT_CASE_FAIL, EDIT_PATIENT_CASE_LOADING, EDIT_PATIENT_CASE_STATUS, EDIT_PATIENT_CASE_SUCCESS, OPINIONTYPELIST_FAIL, OPINIONTYPELIST_LOADING, OPINIONTYPELIST_SUCCESS, PatientCaseDetailsActionTypes, PATIENT_CASE_DETAILS_FAIL, PATIENT_CASE_DETAILS_LOADING, PATIENT_CASE_DETAILS_SUCCESS, ResponseTimeActionTypes, RESPONSE_TIME_FAIL, RESPONSE_TIME_LOADING, RESPONSE_TIME_STATUS, RESPONSE_TIME_SUCCESS, SECOND_OPINION_LIST_FAIL, SECOND_OPINION_LIST_LOADING, SECOND_OPINION_LIST_SUCCESS } from "./ActionTypes";
import { AddPatientCase, consultationTypeListAction, EditPatientCase, getOpinionTypeListAction, GetPatientCaseDetails, responseTimeListAction, secondOpinionListAction } from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

// Admin get opinion type List API
export const getOpinionTypeList = () => {
  return function (dispatch: Dispatch<AdminGetOpinionTypeListActionTypes>) {
    dispatch({
      type: OPINIONTYPELIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + "/secondopinionapp/api/secondopinion/opinion_type", {
        headers,
      })
      .then((res) => {
        let opinionTypeList = res.data.opinionTypeList;
        let message = res.data.message;
        let status = res.data.status;
        dispatch(getOpinionTypeListAction(opinionTypeList, message, status));
        dispatch({
          type: OPINIONTYPELIST_SUCCESS,
          payload: opinionTypeList,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: OPINIONTYPELIST_FAIL,
          message: message,
          status: status
        });
      });
  };
};
// Admin get 2nd opinion list API
export const secondOpinionList = () => {
  return function (dispatch: Dispatch<AdminGetSecondOpinionListActionTypes>) {
    dispatch({
      type: SECOND_OPINION_LIST_LOADING,
    });
    axios
      .get(Url.baseUrl + "/secondopinionapp/api/secondopinion/second_opinion_option", {
        headers,
      })
      .then((res) => {
        let secondOpinionList = res.data.secondOpinionList;
        let message = res.data.message;
        let status = res.data.status;
        dispatch(secondOpinionListAction(secondOpinionList, message, status));
        dispatch({
          type: SECOND_OPINION_LIST_SUCCESS,
          payload: secondOpinionList,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: SECOND_OPINION_LIST_FAIL,
          message: message,
          status: status
        });
      });
  };
};
// Admin get consultation type API
export const getConsultationList = () => {
  return function (dispatch: Dispatch<AdminGetConsultationTypeListActionTypes>) {
    dispatch({
      type: CONSULTATION_TYPE_LIST_LOADING,
    });
    axios
      .get(Url.baseUrl + "/secondopinionapp/api/consultationtype/consultation_type", {
        headers,
      })
      .then((res) => {
        let consulatationTypeList = res.data.consulatationTyeList;
        let message = res.data.message;
        let status = res.data.status;
        dispatch(consultationTypeListAction(consulatationTypeList, message, status));
        dispatch({
          type: CONSULTATION_TYPE_LIST_SUCCESS,
          payload: consulatationTypeList,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: CONSULTATION_TYPE_LIST_FAIL,
          message: message,
          status: status
        });
      });
  };
};

// Admin response time API ..
export const getResponseTimeList = (state = {}) => {
  return function (dispatch: Dispatch<ResponseTimeActionTypes>) {
    dispatch({
      type: RESPONSE_TIME_LOADING,
    });
    dispatch(responseTimeListAction(getResponseTimeList(RESPONSE_TIME_STATUS)));
    axios
      .post(
        Url.baseUrl + "/patientapp/api/patientMedicalData/responseTime_typeList",
        state,
        { headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: RESPONSE_TIME_SUCCESS,
            payload: result.data.responseTimeTypeList,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: RESPONSE_TIME_FAIL,
          payload: error.response.data,
        });
      });
  };
};

// Admin add patient case API ..
export const addPatientCase = (state = {}) => {
  return function (dispatch: Dispatch<AddPatientCaseActionTypes>) {
    dispatch({
      type: ADD_PATIENT_CASE_LOADING,
    });
    dispatch(AddPatientCase(addPatientCase(ADD_PATIENT_CASE_STATUS)));
    axios
      .post(
        Url.baseUrl + "/patientapp/api/patient/add_patient_case",
        state,
        { headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: ADD_PATIENT_CASE_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_PATIENT_CASE_FAIL,
          payload: error.response.data,
        });
      });
  };
};

// Admin edit patient case API ..
export const editPatientCase = (state = {}) => {
  return function (dispatch: Dispatch<EditPatientCaseActionTypes>) {
    dispatch({
      type: EDIT_PATIENT_CASE_LOADING,
    });
    dispatch(EditPatientCase(editPatientCase(EDIT_PATIENT_CASE_STATUS)));
    axios
      .post(
        Url.baseUrl + "/patientapp/api/patient/edit_patient_case",
        state,
        { headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: EDIT_PATIENT_CASE_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EDIT_PATIENT_CASE_FAIL,
          payload: error.response.data,
        });
      });
  };
};
// Admin edit patient case API ..
export const getcaseDetails = (state = {}) => {
  return function (dispatch: Dispatch<PatientCaseDetailsActionTypes>) {
    dispatch({
      type: PATIENT_CASE_DETAILS_LOADING,
    });
    axios
      .post(
        Url.baseUrl + "/patientapp/api/patient/patient_caseDetails_by_id",
        state,
        { headers }
      )
      .then((result) => {
        let PatientCaseDetail = result.data.patientCaseDetail;
        let Status = result.data.status;
        dispatch(GetPatientCaseDetails(PatientCaseDetail, Status));
        dispatch({
          type: PATIENT_CASE_DETAILS_SUCCESS,
          patientCaseDetail: PatientCaseDetail,
          status: Status
        });
      })
      .catch((error) => {
        dispatch({
          type: PATIENT_CASE_DETAILS_FAIL,
          patientCaseDetail: error.response.data,
          status: error.response.status
        });
      });
  };
};