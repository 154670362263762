import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { ProviderReportListActionTypes } from "./ActionTypes";
import { ReportListResponse, ReportListBody } from "./Model";
import {
  providerReportListAPIResClearAction,
  providerReportListErrorAction,
  providerReportListLoadingAction,
  providerReportListSuccessAction,
  providerReportListUpdateAPIMsgAction,
} from "./Action";
import { headers } from "../../component/Utility";

let apiRes = {} as ReportListResponse;
export const reportList = (body: ReportListBody, pageNo: number) => {
  return function (dispatch: Dispatch<ProviderReportListActionTypes>) {
    dispatch(providerReportListLoadingAction(true));
    axios
      .post(
        baseUrl.baseUrl +
          `/providerorganizationapp/api/providerOrganizationPatientDetails/get_patientDetailList_providerOrganization?size=50&page=${Number(
            pageNo
          )}`,
        body,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(providerReportListLoadingAction(false));
        dispatch(providerReportListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(providerReportListLoadingAction(false));
        dispatch(
          providerReportListErrorAction(
            {} as ReportListResponse,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : 0
          )
        );
      });
  };
};

export const updateproviderReportListAPIResMsg = () => {
  return function (dispatch: Dispatch<ProviderReportListActionTypes>) {
    dispatch(
      providerReportListUpdateAPIMsgAction(apiRes as ReportListResponse, "", 0)
    );
  };
};

export const clearproviderReportListAPIRes = () => {
  return function (dispatch: Dispatch<ProviderReportListActionTypes>) {
    dispatch(
      providerReportListAPIResClearAction({} as ReportListResponse, "", 0)
    );
  };
};
