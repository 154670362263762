import Url from "../../../ApiConfig";
import axios from "axios";
import { Dispatch } from "redux";
import {
    AskQuestionTypes,
    ASK_QUESTION_FAIL,
    ASK_QUESTION_LOADING,
    ASK_QUESTION_SUCCESS,
    CreateOrScheduleMeetingTypes,
    CREATE_OR_SCHEDULE_FAIL,
    CREATE_OR_SCHEDULE_LOADING,
    CREATE_OR_SCHEDULE_SUCCESS,
    DeleteMeetingTypes,
    DELETE_MEETING_FAIL,
    DELETE_MEETING_LOADING,
    DELETE_MEETING_SUCCESS,
    DoctorListForCaseTypes, DOCTOR_LIST_FAIL, DOCTOR_LIST_LOADING, DOCTOR_LIST_SUCCESS,
    MeetingListTypes, MEETING_LIST_FAIL, MEETING_LIST_LOADING, MEETING_LIST_SUCCESS, QuestionListTypes, QUESTION_LIST_FAIL, QUESTION_LIST_LOADING, QUESTION_LIST_SUCCESS,
} from "./ActionTypes";
import { askQuestionAction, createOrScheduleMeetingAction, deleteMeetingAction, doctorListForCaseAction, meetingListAction, questionListAction } from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

// get doctor list for case
export const getDoctorListForCase = (caseId: string) => {
    return function (dispatch: Dispatch<DoctorListForCaseTypes>) {
        dispatch({
            type: DOCTOR_LIST_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + `/patientapp/api/patientMedicalData/doctorList_for_patientCase`,
            { "caseId": caseId }, { headers })
            .then((result) => {
                if (result) {
                    let patientCaseDoctorlist = result.data.patientCaseDoctorlist;
                    let message = result.data.message;
                    let status = result.data.status;
                    dispatch(doctorListForCaseAction(patientCaseDoctorlist, message, status));
                    dispatch({
                        type: DOCTOR_LIST_SUCCESS,
                        patientCaseDoctorlist: patientCaseDoctorlist,
                        message: message,
                        status: status
                    });
                }
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: DOCTOR_LIST_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};
// get Meeting List
export const getTelehealthMeetingList = (caseId: string) => {
    return function (dispatch: Dispatch<MeetingListTypes>) {
        dispatch({
            type: MEETING_LIST_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + `/telehealthapp/api/telehealth/getMeetingList`,
            { "caseId": caseId }, { headers })
            .then((result) => {
                if (result) {
                    let meetingList = result.data.meetingList;
                    let message = result.data.message;
                    let status = result.data.status;
                    dispatch(meetingListAction(meetingList, message, status));
                    dispatch({
                        type: MEETING_LIST_SUCCESS,
                        meetingList: meetingList,
                        message: message,
                        status: status
                    });
                }
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: MEETING_LIST_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};

// Create or Schedule Meeting
export const createOrScheduleMeeting = (body: object, url: string) => {
    return function (dispatch: Dispatch<CreateOrScheduleMeetingTypes>) {
        dispatch({
            type: CREATE_OR_SCHEDULE_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + url,
            body, { headers })
            .then((result) => {
                if (result) {
                    let message = result.data.message;
                    let status = result.data.status;
                    dispatch(createOrScheduleMeetingAction(message, status));
                    dispatch({
                        type: CREATE_OR_SCHEDULE_SUCCESS,
                        message: message,
                        status: status
                    });
                }
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: CREATE_OR_SCHEDULE_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};
// Deactive meeting 
export const deactivetelehealthMeeting = (meetingId: any) => {
    return function (dispatch: Dispatch<DeleteMeetingTypes>) {
        dispatch({
            type: DELETE_MEETING_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + `/telehealthapp/api/telehealth/deactivate_meeting`,
            { "teleHealthAppointmentId": meetingId }, { headers })
            .then((result) => {
                if (result) {
                    let message = result.data.message;
                    let status = result.data.status;
                    dispatch(deleteMeetingAction(message, status));
                    dispatch({
                        type: DELETE_MEETING_SUCCESS,
                        message: message,
                        status: status
                    });
                }
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: DELETE_MEETING_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};
// Case Status value
export const getQuestionList = (body: object) => {
    return function (dispatch: Dispatch<QuestionListTypes>) {
        dispatch({
            type: QUESTION_LIST_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + `/questionandanswerapp/api/question/list_Question_for_doctor`,
            body, { headers })
            .then((result) => {
                if (result) {
                    let questionList = result.data.questionList;
                    let message = result.data.message;
                    let status = result.data.status;
                    dispatch(questionListAction(questionList, message, status));
                    dispatch({
                        type: QUESTION_LIST_SUCCESS,
                        questionList: questionList,
                        message: message,
                        status: status
                    });
                }
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: QUESTION_LIST_FAIL,
                    message: message,
                    status: status
                });
            });
    };
}
// Ask Question
export const askQuestionToPatient = (body: object) => {
    return function (dispatch: Dispatch<AskQuestionTypes>) {
        dispatch({
            type: ASK_QUESTION_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + `/questionandanswerapp/api/question/ask_Question`,
            body, { headers })
            .then((result) => {
                if (result) {
                    let message = result.data.message;
                    let status = result.data.status;
                    dispatch(askQuestionAction(message, status));
                    dispatch({
                        type: ASK_QUESTION_SUCCESS,
                        message: message,
                        status: status
                    });
                }
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: ASK_QUESTION_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};
// Answer Question
export const answerQuestionToPatient = (body: object) => {
    return function (dispatch: Dispatch<AskQuestionTypes>) {
        dispatch({
            type: ASK_QUESTION_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl + `/questionandanswerapp/api/question/answer_Question`,
            body, { headers })
            .then((result) => {
                if (result) {
                    let message = result.data.message;
                    let status = result.data.status;
                    dispatch(askQuestionAction(message, status));
                    dispatch({
                        type: ASK_QUESTION_SUCCESS,
                        message: message,
                        status: status
                    });
                }
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: ASK_QUESTION_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};