import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "./routes/PublicRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import Login from "./pages/ProviderOrganizationOnBoarding/Login";
import Dashboard from "./pages/Dashboard";
import DoctorList from "./pages/Doctor";
import PatientList from "./pages/PatientList";
import PatientDetails from "./pages/PatientDetails/PatientDetails";
import NotFound from "./pages/NotFound";
import AppointmenList from "./pages/AppointmentList";
import PatientQueries from "./pages/PatientQueries";
import DoctorQueries from "./pages/DoctorQueries";
import Payment from "./pages/Payment";
import ImportData from "./pages/ImportData";
import PatientQueriesDetails from "./pages/PatientQueriesDetails";
import DoctorQueriesDetails from "./pages/DoctorQueriesDetails";
import Task from "./pages/Task/Task";
import TaskDetails from "./pages/Task/TaskDetails";
import SelfTaskDetails from "./pages/SelfTaskDetails";
import PatientListByHealthcamp from "./pages/PatientListByHealthCamp/index";
import QuestionAnswer from "./pages/QuestionAnswer";
import CaseDetails from "./pages/Patient/CaseDetails";
import AddHealthRecord from "./pages/Patient/FilePreparation";
import DemographicDetails from "./pages/Patient/FilePreparation/DemographicDetails";
import DoctorAppointments from "./pages/DoctorAppointments";
import AllCaseList from "./pages/CaseList/AllCaseList";
import ExecutiveTaskDetails from "./pages/ExecutiveTaskDetails";
import PatientTeam from "./pages/PatientTeam/index";
import TeleHealth from "./pages/TeleHealth/TeleHealth";
import PatientTeamDetails from "./pages/PatientTeamDetails";
import PatientNotes from "./pages/Notes/PatientNote/PatientNotes";
import NotesDetails from "./pages/Notes/PatientCaseNotesForDoctor/NotesDetails";
import PatientListByOrganization from "./pages/PatientListByOrganization/index";
import PatientDataPointDetails from "./pages/PatientDataPointDetails/index";
import HealthcampList from "./pages/HealthcampList/index";
import SearchPatient from "./pages/SearchPatient";
import CaseListExpanded from "./pages/PatientDashboard/CaseList/CaseListExpanded";
import AdminDetails from "./pages/AdminDetails";
import PatientRegistration from "./pages/PatientListByHealthCamp/PatientRegistration";
import LabReportList from "./pages/LabReportList";
import PatientDetailsList from "./pages/PatientDetailsList";
import ProviderOrgListPage from "./pages/ProviderOrganizationList";

function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/signin" component={Login} />
      <PrivateRoute path="/admindetails" component={AdminDetails} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/healthcamplist" component={HealthcampList} />
      <PrivateRoute path="/doctors" component={DoctorList} />
      <PrivateRoute
        path="/providerorganization"
        component={ProviderOrgListPage}
      />
      <PrivateRoute
        path="/patientdetailslist/:organizationid?"
        component={PatientDetailsList}
      />
      <PrivateRoute path="/labreportlist" component={LabReportList} />
      <PrivateRoute path="/patients" component={PatientList} />
      <PrivateRoute path="/allcaselist" component={AllCaseList} />
      <PrivateRoute
        path="/patientdetails/:patientid?"
        component={PatientDetails}
      />
      <PrivateRoute
        path="/patientdataobservationlist/:patientid?"
        component={PatientDataPointDetails}
      />
      <PrivateRoute
        path="/addhealthrecord/:patientid?"
        component={AddHealthRecord}
      />
      <PrivateRoute
        path="/demographicdetails/:patientid?"
        component={DemographicDetails}
      />
      <PrivateRoute
        path="/casedetails/:patientid?/:caseid?/"
        component={CaseDetails}
      />
      <PrivateRoute path="/patient-queries" component={PatientQueries} />
      <PrivateRoute path="/doctor-queries" component={DoctorQueries} />
      <PrivateRoute path="/payment" component={Payment} />
      <PrivateRoute path="/importdata" component={ImportData} />
      <PrivateRoute path="/appointmentlist/" component={AppointmenList} />
      <PrivateRoute
        path="/patient-queries-details/:id?"
        component={PatientQueriesDetails}
      />
      <PrivateRoute
        path="/doctor-queries-details/:id?"
        component={DoctorQueriesDetails}
      />
      <PrivateRoute path="/task" component={Task} />
      <PrivateRoute path="/question-answer" component={QuestionAnswer} />
      <PrivateRoute
        path="/taskdetails/:currentPage?/:rowIndex?"
        component={TaskDetails}
      />
      <PrivateRoute
        path="/selftaskdetails/:rowIndex?"
        component={SelfTaskDetails}
      />
      <PrivateRoute
        path="/patientlistbyhealthcamp/:healthcampid?"
        component={PatientListByHealthcamp}
      />
      <PrivateRoute
        path="/patientregistration/:healthcampid?"
        component={PatientRegistration}
      />
      <PrivateRoute
        path="/patientlistbyorganization/:organizationid?"
        component={PatientListByOrganization}
      />
      <PrivateRoute
        path="/doctorapoointment/:doctorId"
        component={DoctorAppointments}
      />
      <PrivateRoute
        path="/executivetaskdetails/:executiveId?"
        component={ExecutiveTaskDetails}
      />
      <PrivateRoute path="/patientteam" component={PatientTeam} />
      <PrivateRoute path="/telehealth" component={TeleHealth} />
      <PrivateRoute
        path="/patientteamdetails/:patientTeamCurrentPage?/:patientDetailsID?"
        component={PatientTeamDetails}
      />
      <PrivateRoute path="/patientnotes/:profileId?" component={PatientNotes} />
      <PrivateRoute
        path="/notesdetails/:patientid?/:caseid?/:doctorId?"
        component={NotesDetails}
      />
      <PrivateRoute path="/searchpatient" component={SearchPatient} />
      <PrivateRoute
        path="/patientcaselist/:patientid?"
        component={CaseListExpanded}
      />

      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
