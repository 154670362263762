import {
  ProviderDoctorListDetails,
  ProviderDoctorListActionTypes,
  PROVIDER_DOCTOR_LIST_FAIL,
  PROVIDER_DOCTOR_LIST_LOADING,
  PROVIDER_DOCTOR_LIST_SUCCESS,
  PROVIDER_DOCTOR_LIST_API_RES,
  PROVIDER_DOCTOR_LIST_API_MSG,
} from "./ActionTypes";
import { ProviderDoctorListRes } from "./Model";

const initialStateGetPosts: ProviderDoctorListDetails = {
  loading: false,
  providerDoctorListResponse: {} as ProviderDoctorListRes,
  message: "",
  status: 0,
  errRes: "",
};
export const providerDoctorListReducer = (
  state = initialStateGetPosts,
  action: ProviderDoctorListActionTypes,
): ProviderDoctorListDetails => {
  switch (action.type) {
    case PROVIDER_DOCTOR_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PROVIDER_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        providerDoctorListResponse: action.payload,
      };

    case PROVIDER_DOCTOR_LIST_FAIL:
      return {
        ...state,
        providerDoctorListResponse: action.payload,
        message: action.message,
        status: action.status,
      };
    case PROVIDER_DOCTOR_LIST_API_MSG:
      return {
        ...state,
        providerDoctorListResponse: action.payload,
        message: action.message,
        status: action.status,
      };
    case PROVIDER_DOCTOR_LIST_API_RES:
      return {
        ...state,
        providerDoctorListResponse: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
