import {
  UPDATE_PATIENT_DATA_DETAILS_DATA,
  UPDATE_PATIENT_DATA_DETAILS_FAIL,
  UPDATE_PATIENT_DATA_DETAILS_LOADING,
  UPDATE_PATIENT_DATA_DETAILS_SUCCESS,
  UpdatePatientDataDetailsActionTypes,
} from "./ActionTypes";
import { UpdatePatientDataDetailsRes } from "./Model";

export const updatePatientDataPointAction = (
  updatePatientDataPointRes: UpdatePatientDataDetailsRes
): UpdatePatientDataDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DATA_DETAILS_DATA,
    payload: updatePatientDataPointRes,
  };
};

export const updatePatientDataPointLoadingAction = (
  loading: boolean
): UpdatePatientDataDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DATA_DETAILS_LOADING,
    loading: loading,
  };
};

export const updatePatientDataPointSuccessAction = (
  updatePatientDataPointResponse: UpdatePatientDataDetailsRes
): UpdatePatientDataDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DATA_DETAILS_SUCCESS,
    payload: updatePatientDataPointResponse,
  };
};

export const updatePatientDataPointErrorAction = (
  updatePatientDataPointResponse: UpdatePatientDataDetailsRes,
  errMsg: string,
  status: number
): UpdatePatientDataDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DATA_DETAILS_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
export const updatePatientDataPointUpdateAPIMsgAction = (
  updatePatientDataPointResponse: UpdatePatientDataDetailsRes,
  errMsg: string,
  status: number
): UpdatePatientDataDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DATA_DETAILS_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};

export const updatePatientDataPointAPIResClearAction = (
  updatePatientDataPointResponse: UpdatePatientDataDetailsRes,
  errMsg: string,
  status: number
): UpdatePatientDataDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DATA_DETAILS_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
