import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { GetProviderDetailsRes } from "../redux/jwtProviderLogIn/providerLoginDetails/Model";
import PageLayout from "./Layout/PageLayout";
import MWPageTitle from "../component/MWPageTitle";

export default function AdminDetails() {
  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetProviderDetailsRes;

  const adminDetailsMarkup = (
    <>
      <Card>
        <CardContent>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            sx={{ pt: 2 }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  First Name:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {userDetails?.user?.first_name !== undefined
                    ? userDetails?.user?.first_name
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Last Name:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {userDetails?.user?.last_name !== undefined
                    ? userDetails?.user?.last_name
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {userDetails?.user?.email !== undefined
                    ? userDetails?.user?.email
                    : null}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
  return (
    <>
      <PageLayout>
        <MWPageTitle
          title="Admin Details"
          enableCount={true}
          // count={listCount}
          reload={false}
          // reloadAction={doctorListReload}
        />
        {adminDetailsMarkup}
      </PageLayout>
    </>
  );
}
