import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import LoginScreen from "../../image/featureimage-admin.png";
import logo from "../../image/logo/appLogo.png";
import MWSelectLang from "../../component/MWSelectLang";

interface OnboardingLayoutProps {
  children?: ReactNode;
}
const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="space-between"
      sx={{ height: "100vh" }}
    >
      <Grid container item direction="column" alignItems="flex-end" xs={12}>
        <MWSelectLang />
      </Grid>
      <Grid xs={12} container item>
        <Grid
          xs={12}
          md={6}
          container
          item
          alignItems="center"
          justifyContent="center"
        >
          <Grid container item alignItems="center" justifyContent="center">
            <Card
              sx={{
                minWidth: 520,
                maxWidth: 520,
                minHeight: 620,
                maxHeight: 620,
              }}
            >
              <CardContent>
                <Grid item>
                  <Link underline="none" href="https://medicalwisdom.in">
                    <img src={logo} alt="Logo" height="80" />
                  </Link>
                  <Box mt={2}>
                    <Typography variant="h6" gutterBottom>
                      Log into provider organization dashboard
                    </Typography>
                  </Box>
                  {children}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          md={6}
          container
          item
          alignItems="center"
          justifyContent="center"
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box>
            <img
              src={LoginScreen}
              alt=""
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item direction="column" justifyContent="center" xs={12}>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="caption">
            © Copyright medicalWISDOM 2023. All rights reserved.
          </Typography>
          <Typography variant="caption">
            <Link
              underline="none"
              href="https://medicalwisdom.in/terms-of-use.html"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </Link>
            &nbsp;&nbsp;
            <Link
              underline="none"
              href="https://medicalwisdom.in/privacy-statement.html"
              target="_blank"
              rel="noreferrer"
            >
              Privacy & Cookies
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

OnboardingLayout.propTypes = {
  children: PropTypes.node,
};

export default OnboardingLayout;
