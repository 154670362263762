import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { getHealthcampPatientAction } from "./Actions";
import {
  HealthcampPatientGetActionTypes,
  HCPATIENT_LIST_FAIL,
  HCPATIENT_LIST_LOADING,
  HCPATIENT_LIST_SUCCESS,
} from "./ActionTypes";
import { GetPatientListByHealthCampMainBody } from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const getPatientListByHealthcamp = (
  payload: GetPatientListByHealthCampMainBody
) => {
  return function (dispatch: Dispatch<HealthcampPatientGetActionTypes>) {
    dispatch({
      type: HCPATIENT_LIST_LOADING,
    });

    axios
      .post(
        Url.baseUrl +
          `/caregiverapp/api/healthcamppatient/patient_list_of_healthcamp?page=${Number(
            payload.pageNo
          )}&size=50`,
        payload.patientList,
        { headers }
      )
      .then((res) => {
        let resData = res.data;
        let pageCount = res.data.numberOfPages;
        dispatch(getHealthcampPatientAction(resData, pageCount));
        dispatch({
          type: HCPATIENT_LIST_SUCCESS,
          payload: resData,
          pageCount: pageCount,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: HCPATIENT_LIST_FAIL,
        });
      });
  };
};
