import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { FindUserActionTypes } from "./ActionTypes";
import {
  FindUserBody,
  FindUserResponse,
} from "./Model";
import {
  findUserAPIResClearAction,
  findUserErrorAction,
  findUserLoadingAction,
  findUserSuccessAction,
  findUserUpdateAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as FindUserResponse;
export const findUserApi = (
  payload: FindUserBody
) => {
  return function (dispatch: Dispatch<FindUserActionTypes>) {
    dispatch(findUserLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/ehrapp/api/incomeCall/find_user_by_phone_number`,
        payload,
        { headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(findUserLoadingAction(false));
        dispatch(findUserSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(findUserLoadingAction(false));
        dispatch(
          findUserErrorAction(
            {} as FindUserResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const findUserAPIResMsg = () => {
  return function (dispatch: Dispatch<FindUserActionTypes>) {
    dispatch(
      findUserUpdateAPIMsgAction(
        apiRes as FindUserResponse,
        "",
        0
      )
    );
  };
};

export const clearfindUserAPIRes = () => {
  return function (dispatch: Dispatch<FindUserActionTypes>) {
    dispatch(
      findUserAPIResClearAction(
        {} as FindUserResponse,
        "",
        0
      )
    );
  };
};
