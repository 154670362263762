import { GetExtractObservationDetailsRes } from "./Model";
export const GET_EXTRACT_OBSERVATION_LOADING = "GET_EXTRACT_OBSERVATION_LOADING";
export const GET_EXTRACT_OBSERVATION_SUCCESS = "GET_EXTRACT_OBSERVATION_SUCCESS";
export const GET_EXTRACT_OBSERVATION_FAIL = "GET_EXTRACT_OBSERVATION_FAIL";
export const GET_EXTRACT_OBSERVATION_DATA = "GET_EXTRACT_OBSERVATION_DATA";
export const GET_EXTRACT_OBSERVATION_API_MSG =
  "GET_EXTRACT_OBSERVATION_API_MSG";
export const GET_EXTRACT_OBSERVATION_API_RES =
  "GET_EXTRACT_OBSERVATION_API_RES";

export interface GetExtractObservationState {
  getExtractObservationRes: GetExtractObservationDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetExtractObservationLoading {
  type: typeof GET_EXTRACT_OBSERVATION_LOADING;
  loading: boolean;
}
export interface GetExtractObservationSuccess {
  type: typeof GET_EXTRACT_OBSERVATION_SUCCESS;
  payload: GetExtractObservationDetailsRes;
}
export interface GetExtractObservationFail {
  type: typeof GET_EXTRACT_OBSERVATION_FAIL;
  payload: GetExtractObservationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetExtractObservationAPIMsg {
  type: typeof GET_EXTRACT_OBSERVATION_API_MSG;
  payload: GetExtractObservationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetExtractObservationAPIRes {
  type: typeof GET_EXTRACT_OBSERVATION_API_RES;
  payload: GetExtractObservationDetailsRes;
  errorMsg: string;
  status: number;
}

export type GetExtractObservationDetailsActionTypes =
  | GetExtractObservationLoading
  | GetExtractObservationSuccess
  | GetExtractObservationFail
  | GetExtractObservationAPIMsg
  | GetExtractObservationAPIRes;
