import {
  ProviderPatientListDetails,
  ProviderPatientListActionTypes,
  PROVIDER_PATIENT_LIST_FAIL,
  PROVIDER_PATIENT_LIST_LOADING,
  PROVIDER_PATIENT_LIST_SUCCESS,
  ADD_PATIENT_API_RES,
  ADD_PATIENT_API_MSG,
} from "./ActionTypes";
import { ProviderPatientListRes } from "./Model";

const initialStateGetPosts: ProviderPatientListDetails = {
  loading: false,
  providerOrgListRes: {} as ProviderPatientListRes,
  message: "",
  status: 0,
  errRes: "",
};
export const providerPatientListReducer = (
  state = initialStateGetPosts,
  action: ProviderPatientListActionTypes,
): ProviderPatientListDetails => {
  switch (action.type) {
    case PROVIDER_PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PROVIDER_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        providerOrgListRes: action.payload,
      };

    case PROVIDER_PATIENT_LIST_FAIL:
      return {
        ...state,
        providerOrgListRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADD_PATIENT_API_MSG:
      return {
        ...state,
        providerOrgListRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ADD_PATIENT_API_RES:
      return {
        ...state,
        providerOrgListRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
