import React, { useEffect, useState } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import AdminLoader from "./AdminLoader";
import { getExecutiveTaskList } from "../redux/Task/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";

export default function ExecutiveTaskDetails() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const { executiveId } = useParams() as {
    executiveId: string;
  };
  const [toSignin] = useState(false);
  const history = useHistory();
  function gotoDetails(executiveCaseId: Number, executivePatientId: Number) {
    history.push(`/casedetails/${executivePatientId}/${executiveCaseId}`);
  }

  const executiveTaskListBody = {
    executiveId: executiveId,
  };
  // Executive task list by id api
  const executiveTaskList = () => {
    setIsLoading(true);
    dispatch(getExecutiveTaskList(executiveTaskListBody));
  };
  const truncate = (str: string) => {
    return str.length > 0 ? str.substring(0, 20) + "..." : str;
  };

  // CAll Executive task list from Store
  const appointmentList = useSelector(
    (state: AppState) => state.executiveTaskList.executiveList
  );
  console.log("APPTLIST", appointmentList);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  let taskValueList = [];
  for (var i = 0; i < appointmentList.length; i++) {
    let executiveCaseId = appointmentList[i].caseId;
    let executivePatientId = appointmentList[i].patientId;
    let executiveTaskTitle =
      appointmentList[i].title == null ? "" : appointmentList[i].title;
    let executiveAssignPatient =
      appointmentList[i].patientName == null
        ? ""
        : appointmentList[i].patientName;
    let executiveSecondOpinionReasons =
      appointmentList[i].secondOpinionReasons == null
        ? ""
        : truncate(appointmentList[i].secondOpinionReasons);
    let executiveConsultationType =
      appointmentList[i].consultationType == null
        ? ""
        : appointmentList[i].consultationType;
    let executiveTaskCreateDate =
      appointmentList[i].created_date == null
        ? ""
        : new Date(appointmentList[i].created_date).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    taskValueList.push([
      <Button
        variant="outlined"
        onClick={() => gotoDetails(executiveCaseId, executivePatientId)}
        startIcon={<PreviewIcon />}
      >
        View
      </Button>,
      executiveTaskTitle,
      executiveAssignPatient,
      executiveSecondOpinionReasons,
      executiveConsultationType,
      executiveTaskCreateDate,
    ]);
  }
  const taskListValue: any[] = taskValueList;

  useEffect(() => {
    executiveTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExecutiveTaskTable = (
    <Box sx={{ pt: 4 }}>
      <Card>
        <Grid>
          <CardContent>
            <Box pl={3}>
              {taskListValue && taskListValue.length ? (
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>View</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Second Opinion Reasons</TableCell>
                        <TableCell>ConsultationType</TableCell>
                        <TableCell>Task Create Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {taskListValue.map((row: any) => (
                        <TableRow
                          key={row.description}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row[0]}</TableCell>
                          <TableCell align="left">{row[1]}</TableCell>
                          <TableCell align="left">{row[2]}</TableCell>
                          <TableCell align="left">{row[3]}</TableCell>
                          <TableCell align="left">{row[4]}</TableCell>
                          <TableCell align="left">{row[5]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>
                  <MWExceptionList />
                </Typography>
              )}
            </Box>
          </CardContent>
        </Grid>
      </Card>
    </Box>
  );

  return (
    <Box>
      {loadingMarkup}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <MWPageTitle backButton={true} title="Executive Task List" />
        {toSignin ? <Redirect to="/signin" /> : null}
        {ExecutiveTaskTable}
      </Container>
    </Box>
  );
}
