import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Box,
  Container,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "../../../../component/MWExceptionList";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  AppointmentRequestList,
  GetAppointmentListBody,
} from "../../../../redux/Patient/GetAppointmentList/Model";
import { requestAppointmentDetails } from "../../../../redux/Patient/GetAppointmentList/API";
import dayjs from "dayjs";

type Props = {
  caseid?: string | any;
};
export default function AppointmentList({ caseid }: Props) {
  const dispatch = useDispatch();
  const [isBookAppointmentResult, setIsBookAppoinmentResult] = useState(
    [] as any[]
  );

  const selectionTypeBody = {
    caseId: caseid,
  } as GetAppointmentListBody;

  //Selection Type GET API
  const getAppoinmentRequestList = () => {
    dispatch(requestAppointmentDetails(selectionTypeBody));
  };

  // RequestAppointment Api Value from store
  let requestAppointmentListDataFromAPI = useSelector(
    (state: AppState) =>
      state.getrequestAppointmentDetails?.getAppointmentListRes
        ?.appointmentRequestList
  );

  useEffect(() => {
    if (requestAppointmentListDataFromAPI !== undefined) {
      let appointmentListConfig = requestAppointmentListDataFromAPI.map(
        (item: AppointmentRequestList) => {
          return {
            id: item.id,
            description: item?.name !== undefined ? item.name : "",
            createdDate:
              item?.createDate !== undefined
                ? dayjs(item?.createDate).format("ddd, MM/DD/YYYY")
                : "",
            active: item?.isActive !== undefined ? item.isActive : "",
            modifiedDate:
              item?.modifiedDate !== undefined
                ? dayjs(item?.modifiedDate).format("ddd, MM/DD/YYYY")
                : "",
            appoinmentStatus: item?.status !== undefined ? item.status : "",
          };
        }
      );
      setIsBookAppoinmentResult(appointmentListConfig);
    }
  }, [requestAppointmentListDataFromAPI]);
  const columns: GridColDef[] = [
    { field: "description", headerName: "Description", flex: 1 },
    { field: "createdDate", headerName: "Create Date", flex: 1 },
    { field: "active", headerName: "Active", flex: 1 },
    { field: "modifiedDate", headerName: "Modified Date", flex: 1 },
    { field: "appoinmentStatus", headerName: "Status", flex: 1 },
  ];

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    getAppoinmentRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appointmentTableBody = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>Existing Appointment Details</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() => getAppoinmentRequestList}
                  sx={{ textTransform: "none" }}
                >
                  Reload Appointment List
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {isBookAppointmentResult && isBookAppointmentResult.length ? (
            <DataGrid
              rows={isBookAppointmentResult}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      <br />
    </Box>
  );
  return (
    <Box>
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {appointmentTableBody}
        </Stack>
      </Container>
    </Box>
  );
}
