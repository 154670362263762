import {
    AddOrUpdateHealthRecordTypes,
    ADD_OR_UPDATE_HEALTH_RECORD_TYPE,
    BloodgroupTypes, BLOODGROUP_LIST, GenderListTypes, GENDER_LIST, GetHealthRecordTypes, GET_HEALTH_RECORD_TYPE, LanguageListTypes, LANGUAGE_ORIENTATION_LIST,
    MaritalStatusTypes, MARITAL_STATUS_LIST, RelationshipListTypes, RELATIONSHIP_LIST,
    SexualOrientationTypes, SEXUAL_ORIENTATION_LIST
} from "./ActionTypes";

export const genderDropdownListAction = (genderList: [], message: any, status: any): GenderListTypes => {
    return {
        type: GENDER_LIST,
        genderList: genderList,
        message: message,
        status: status,
    };
};
// Relationship dropdown list action
export const relationshipDropdownListAction = (relationList: [], message: any, status: any): RelationshipListTypes => {
    return {
        type: RELATIONSHIP_LIST,
        relationList: relationList,
        message: message,
        status: status,
    };
};
// Relationship dropdown list action
export const bloodGroupDropdownListAction = (bloodGroupList: [], message: any, status: any): BloodgroupTypes => {
    return {
        type: BLOODGROUP_LIST,
        bloodGroupList: bloodGroupList,
        message: message,
        status: status,
    };
};
// Marital Status dropdown list action
export const maritalStatusDropdownListAction = (maritialStatusList: [], message: any, status: any): MaritalStatusTypes => {
    return {
        type: MARITAL_STATUS_LIST,
        maritialStatusList: maritialStatusList,
        message: message,
        status: status,
    };
};
// Marital Status dropdown list action
export const sexualOrientaataionDropdownListAction = (sexualOrientationList: [], message: any, status: any): SexualOrientationTypes => {
    return {
        type: SEXUAL_ORIENTATION_LIST,
        sexualOrientationList: sexualOrientationList,
        message: message,
        status: status,
    };
};
// Language dropdown list action
export const getLanguageDropdownListAction = (languageList: [], message: any, status: any): LanguageListTypes => {
    return {
        type: LANGUAGE_ORIENTATION_LIST,
        languageList: languageList,
        message: message,
        status: status,
    };
};
// Add health Record
export const addOrUpdatehealthRecordAction = (message: any, status: any): AddOrUpdateHealthRecordTypes => {
    return {
        type: ADD_OR_UPDATE_HEALTH_RECORD_TYPE,
        message: message,
        status: status,
    };
};
// Add health Record
export const gethealthRecordAction = (personalDetails:any, message: any, status: any): GetHealthRecordTypes => {
    return {
        type: GET_HEALTH_RECORD_TYPE,
        personalDetails: personalDetails,
        message: message,
        status: status,
    };
};