import React from "react";
import { FC } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardHeader,
  Grid,
  CardContent,
  CardActions,
  Button,
  Stack,
} from "@mui/material";
import CardLogo from "./CardLogo";
interface CardTitleProps {
  heading?: string;
  subHeading?: string;
  // onButtonClick?: () => void;
  buttonText: string;
  info: string;
  image?: string;
}

const CustomCard: FC<CardTitleProps> = ({
  heading,
  subHeading,
  buttonText,
  image,
}) => {
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        boxShadow: 4,
      }}
    >
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography gutterBottom variant="h6" sx={{ fontWeight: "bold" }}>
                {heading}
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Stack direction="column" justifyContent="center" alignItems="stretch">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <CardLogo image={image} />
            </Grid>
            <Grid item>
              <Typography variant="body1">{subHeading}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item>
            <Button
              size="small"
              endIcon={<ArrowRightAltIcon />}
              sx={{ textTransform: "none" }}
              // onClick={onButtonClick}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

CustomCard.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  // onButtonClick: PropTypes.any,
  image: PropTypes.any,
};

export default CustomCard;
