import {
  GOVT_ID_PROOF_FAIL,
  GOVT_ID_PROOF_LOADING,
  GOVT_ID_PROOF_SUCCESS,
  GovtIDProofActionTypes,
} from "./ActionTypes";
import { GovtIdProofResponse } from "./Model";

export const govtIDProofLoadingAction = (
  loading: boolean
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_LOADING,
    loading: loading,
  };
};

export const govtIDProofSuccessAction = (
  govtIDProofResponse: GovtIdProofResponse,
  errMsg: string,
  status: string
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_SUCCESS,
    payload: govtIDProofResponse,
    message: errMsg,
    status: status,
  };
};
export const govtIDProofErrorAction = (
  govtIDProofResponse: GovtIdProofResponse,
  errMsg: string,
  status: string
): GovtIDProofActionTypes => {
  return {
    type: GOVT_ID_PROOF_FAIL,
    payload: govtIDProofResponse,
    message: errMsg,
    status: status,
  };
};
