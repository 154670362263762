import {
  PatientImportConfigListDetails,
  getPatientImportConfigListActionTypes,
  GET_PATIENT_IMPORT_CONFIG_LIST_FAIL,
  GET_PATIENT_IMPORT_CONFIG_LIST_LOADING,
  GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
} from "./ActionTypes";
import { PatientImportConfigListValue } from "./Model";

const initialStateGetPosts: PatientImportConfigListDetails = {
  loading: false,
  getPatientImportConfigListRes: {} as PatientImportConfigListValue,
  errRes: "",
};
export const getPatientImportConfigListReducer = (
  state = initialStateGetPosts,
  action: getPatientImportConfigListActionTypes
): PatientImportConfigListDetails => {
  switch (action.type) {
    case GET_PATIENT_IMPORT_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getPatientImportConfigListRes: action.payload,
        errRes: "",
      };
    case GET_PATIENT_IMPORT_CONFIG_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getPatientImportConfigListRes: {} as PatientImportConfigListValue,
      };
    case GET_PATIENT_IMPORT_CONFIG_LIST_FAIL:
      return {
        ...state,
        getPatientImportConfigListRes: {} as PatientImportConfigListValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
