import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { ProviderPatientListActionTypes } from "./ActionTypes";
import { ProviderPatientListRes, ProviderPatientListBody } from "./Model";
import {
  providerPatientListAPIResClearAction,
  providerPatientListErrorAction,
  providerPatientListLoadingAction,
  providerPatientListSuccessAction,
  providerPatientListUpdateAPIMsgAction,
} from "./Action";
import { headers } from "../../component/Utility";

let apiRes = {} as ProviderPatientListRes;
export const providerPatientList = (
  editBody: ProviderPatientListBody,
  pageNo: number
) => {
  return function (dispatch: Dispatch<ProviderPatientListActionTypes>) {
    dispatch(providerPatientListLoadingAction(true));
    axios
      .post(
        baseUrl.baseUrl +
          `/providerorganizationapp/api/providerOrganizationPatientList/get_patientList_providerOrganization?size=50&page=${Number(
            pageNo
          )}`,
        editBody,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(providerPatientListLoadingAction(false));
        dispatch(providerPatientListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(providerPatientListLoadingAction(false));
        dispatch(
          providerPatientListErrorAction(
            {} as ProviderPatientListRes,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : 0
          )
        );
      });
  };
};

export const updateproviderPatientListAPIResMsg = () => {
  return function (dispatch: Dispatch<ProviderPatientListActionTypes>) {
    dispatch(
      providerPatientListUpdateAPIMsgAction(
        apiRes as ProviderPatientListRes,
        "",
        0
      )
    );
  };
};

export const clearproviderPatientListAPIRes = () => {
  return function (dispatch: Dispatch<ProviderPatientListActionTypes>) {
    dispatch(
      providerPatientListAPIResClearAction({} as ProviderPatientListRes, "", 0)
    );
  };
};
