import * as React from "react";
import { useEffect } from "react";
import { getPatientCaseList } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import { useHistory, useParams } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import { Box, Button, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Demographics from "../PatientDashboard/Demographics/Index";
import CaseList from "../PatientDashboard/CaseList/Index";
import CarePlan from "../PatientDashboard/CarePlan/Index";
import MedicationSummary from "../PatientDashboard/MedicationSummary/Index";
import HealthCondition from "../PatientDashboard/HealthCondition/Index";
import DataObservationList from "../PatientDashboard/DataObservationList/Index";
import { getpatientDetails } from "../../redux/Patient/DemographicsDetails/ApiCall";
import { PatientDemographicsReq } from "../../redux/Patient/DemographicsDetails/Model";
import { useDispatch, useSelector } from "react-redux";
import MWPageTitle from "../../component/MWPageTitle";
import PatientDataDetails from "../PatientDataDetails";
import CustomCard from "../../component/CardComponent";
import CarePlanList from "../PatientDashboard/CarePlan/CustomCardDataList";
import PageLayout from "../Layout/PageLayout";

export interface MenuItemType {
  name: string;
  content: string;
  button: string;
  path: string;
  info: string;
  image: string;
}

export default function PatientDetails() {
  const menuItems = CarePlanList;
  const history = useHistory();
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();

  const { patientid } = useParams() as {
    patientid: string;
  };

  const handleViewNotesToggle = () => {
    history.push(`/patientnotes/${patientid}`);
  };

  const viewdemographic = () => {
    history.push(`/demographicdetails/${patientid}`);
  };

  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}`);
  };
  const handleViewPtDataPoint = () => {
    history.push(`/patientdataobservationlist/${patientid}`);
  };

  // Get Patient Demographics Details Body
  const getPatientDemographicsDetailsBody = {
    healthRecordId: patientid,
  } as PatientDemographicsReq;
  // encounter list api call ...
  useEffect(() => {
    dispatch(getPatientCaseList(patientid));
    dispatch(getpatientDetails(getPatientDemographicsDetailsBody));
    //dispatch(getPatientDataPointLists(patientid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const caseListValue = useSelector((state: AppState) => state.caseValue);

  const casehistoryname = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName
  );

  const pageTitle = `Patient Details of  ${
    casehistoryname ? casehistoryname : ""
  } `;

  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };
  const pagebody = (
    <Grid container direction="column" rowSpacing={1} columnSpacing={1}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Demographics />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <CaseList />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          sm={12}
          md={8}
          lg={8}
          rowSpacing={1}
          columnSpacing={1}
        >
          {JSON.parse(JSON.stringify(menuItems)).map(
            (element: MenuItemType) => (
              <Grid item key={element.name} xs={6} sm={6} md={3} lg={3}>
                <CustomCard
                  heading={element.name}
                  subHeading={element.content}
                  buttonText={element.button}
                  // onButtonClick={() => gotoDetails(element?.path)}
                  info={element.info}
                  image={element.image}
                />
              </Grid>
            )
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <CarePlan />
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <MedicationSummary />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <HealthCondition />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} sm={12} lg={12}>
            <TabContext value={selected}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange}>
                  <Tab label="Patient Details List" value="1" />
                  <Tab label="Patient Observation List" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">{<PatientDataDetails />}</TabPanel>
              <TabPanel value="2">{<DataObservationList />}</TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {caseListValue.loading === true ? loadingMarkup : ""}
      {/* <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        > */}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Grid item>
            <MWPageTitle title={pageTitle} backButton={true} />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="text"
                      size="medium"
                      onClick={() => handleViewPtDataPoint()}
                      sx={{ textTransform: "none" }}
                    >
                      View Patient Observation
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      onClick={() => handleViewNotesToggle()}
                      sx={{ textTransform: "none" }}
                    >
                      View Note
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      onClick={() => viewdemographic()}
                      sx={{ textTransform: "none" }}
                    >
                      Demographic Details
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => handleAddEncounterToggle()}
                      sx={{ textTransform: "none" }}
                    >
                      Add Case
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
        {pagebody}
      </PageLayout>

      {/* </Stack>
      </Container> */}
    </Box>
  );
}
