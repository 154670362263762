import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AdminPaymentResponse } from "./Model";
import {
  adminPaymentAPIResClearAction,
  adminPaymentErrorAction,
  adminPaymentLoadingAction,
  adminPaymentSuccessAction,
  adminPaymentUpdateAPIMsgAction,
} from "./Actions";
import { AdminPaymentActionTypes } from "./ActionTypes";
import { headers } from "../../../component/Utility";

let apiRes = {} as AdminPaymentResponse;
export const paymentCallApi = (pageNo: any, body = {}) => {
  return function (dispatch: Dispatch<AdminPaymentActionTypes>) {
    dispatch(adminPaymentLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/paymentapp/api/paymentListForAdmin/get_payment_list_for_admin?page=${Number(
            pageNo
          )}&size=10`,
        body,
        {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(adminPaymentLoadingAction(false));
        dispatch(adminPaymentSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(adminPaymentLoadingAction(false));
        dispatch(
          adminPaymentErrorAction(
            {} as AdminPaymentResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const adminPaymentAPIResMsg = () => {
  return function (dispatch: Dispatch<AdminPaymentActionTypes>) {
    dispatch(
      adminPaymentUpdateAPIMsgAction(apiRes as AdminPaymentResponse, "", 0)
    );
  };
};

export const clearadminPaymentAPIRes = () => {
  return function (dispatch: Dispatch<AdminPaymentActionTypes>) {
    dispatch(adminPaymentAPIResClearAction({} as AdminPaymentResponse, "", 0));
  };
};
