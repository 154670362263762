import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetAppointmentListActionTypes } from "./ActionTypes";
import { GetAppointmentListBody, GetAppointmentListRes } from "./Model";
import {
  getAppointmentListAPIResClearAction,
  getAppointmentListErrorAction,
  getAppointmentListLoadingAction,
  getAppointmentListSuccessAction,
  getAppointmentListAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as GetAppointmentListRes;
export const requestAppointmentDetails = (payload: GetAppointmentListBody) => {
  return function (dispatch: Dispatch<GetAppointmentListActionTypes>) {
    dispatch(getAppointmentListLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/appointmentapp/api/appointment/get_appointment_details_for_case`,
        payload,
        {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getAppointmentListLoadingAction(false));
        dispatch(
          getAppointmentListSuccessAction(
            res.data,
            res?.data !== undefined
              ? res?.data?.message
                ? res.data.message
                : "Patient questionnaire list has been fetched successfully"
              : "Patient questionnaire list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getAppointmentListLoadingAction(false));
        dispatch(
          getAppointmentListErrorAction(
            {} as GetAppointmentListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getAppointmentListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetAppointmentListActionTypes>) {
    dispatch(
      getAppointmentListAPIMsgAction(
        apiRes as GetAppointmentListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAppointmentListAPIRes = () => {
  return function (dispatch: Dispatch<GetAppointmentListActionTypes>) {
    dispatch(
      getAppointmentListAPIResClearAction(
        {} as GetAppointmentListRes,
        "",
        "",
        0
      )
    );
  };
};
