import Url from "../../../ApiConfig";
import axios from "axios";
import { Dispatch } from "redux";
import {
  AddOrUpdateHealthRecordTypes,
  ADD_OR_UPDATE_HEALTH_RECORD_FAIL,
  ADD_OR_UPDATE_HEALTH_RECORD_LOADING,
  ADD_OR_UPDATE_HEALTH_RECORD_SUCCESS,
  BloodgroupTypes,
  BLOODGROUP_LIST_FAIL,
  BLOODGROUP_LIST_LOADING,
  BLOODGROUP_LIST_SUCCESS,
  GenderListTypes,
  GENDER_LIST_FAIL,
  GENDER_LIST_LOADING,
  GENDER_LIST_SUCCESS,
  GetHealthRecordTypes,
  GET_HEALTH_RECORD_FAIL,
  GET_HEALTH_RECORD_LOADING,
  GET_HEALTH_RECORD_SUCCESS,
  LanguageListTypes,
  LANGUAGE_LIST_LOADING,
  LANGUAGE_ORIENTATION_LIST_FAIL,
  LANGUAGE_ORIENTATION_LIST_SUCCESS,
  MaritalStatusTypes,
  MARITAL_STATUS_LIST_FAIL,
  MARITAL_STATUS_LIST_LOADING,
  MARITAL_STATUS_LIST_SUCCESS,
  RelationshipListTypes,
  RELATIONSHIP_LIST_FAIL,
  RELATIONSHIP_LIST_LOADING,
  RELATIONSHIP_LIST_SUCCESS,
  SexualOrientationTypes,
  SEXUAL_ORIENTATION_LIST_FAIL,
  SEXUAL_ORIENTATION_LIST_LOADING,
  SEXUAL_ORIENTATION_LIST_SUCCESS,
} from "./ActionTypes";
import {
  addOrUpdatehealthRecordAction,
  bloodGroupDropdownListAction,
  genderDropdownListAction,
  gethealthRecordAction,
  getLanguageDropdownListAction,
  maritalStatusDropdownListAction,
  relationshipDropdownListAction,
  sexualOrientaataionDropdownListAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { PatientDemographicsReq } from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

// gender Dropdownlost
export const getGenderDropDownList = () => {
  return function (dispatch: Dispatch<GenderListTypes>) {
    dispatch({
      type: GENDER_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/indivoapp/api/gender/dropdown_list`, { headers })
      .then((result) => {
        if (result) {
          let genderList = result.data.genderList;
          let message = result.data.message;
          let status = result.data.status;
          dispatch(genderDropdownListAction(genderList, message, status));
          dispatch({
            type: GENDER_LIST_SUCCESS,
            genderList: genderList,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: GENDER_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// relationship Dropdownlost
export const getRelationshipDropDownList = () => {
  return function (dispatch: Dispatch<RelationshipListTypes>) {
    dispatch({
      type: RELATIONSHIP_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/patient_relationship_list`, {
        headers,
      })
      .then((result) => {
        if (result) {
          let relationList = result.data.patientRelationshipList;
          let message = result.data.message;
          let status = result.data.status;
          dispatch(
            relationshipDropdownListAction(relationList, message, status)
          );
          dispatch({
            type: RELATIONSHIP_LIST_SUCCESS,
            relationList: relationList,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: RELATIONSHIP_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// Bloodgroup dropdownlist
export const getBloodgroupDropDownList = () => {
  return function (dispatch: Dispatch<BloodgroupTypes>) {
    dispatch({
      type: BLOODGROUP_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/patient_bloodgroup_list`, {
        headers,
      })
      .then((result) => {
        if (result) {
          let bloodGroupList = result.data.bloodGroupList;
          let message = result.data.message;
          let status = result.data.status;
          dispatch(
            bloodGroupDropdownListAction(bloodGroupList, message, status)
          );
          dispatch({
            type: BLOODGROUP_LIST_SUCCESS,
            bloodGroupList: bloodGroupList,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: BLOODGROUP_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// Marital Status dropdownlist
export const getMaritalStatusDropDownList = () => {
  return function (dispatch: Dispatch<MaritalStatusTypes>) {
    dispatch({
      type: MARITAL_STATUS_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/maritial_list_dropdown`, {
        headers,
      })
      .then((result) => {
        if (result) {
          let maritialStatusList = result.data.maritialStatusList;
          let message = result.data.message;
          let status = result.data.status;
          dispatch(
            maritalStatusDropdownListAction(maritialStatusList, message, status)
          );
          dispatch({
            type: MARITAL_STATUS_LIST_SUCCESS,
            maritialStatusList: maritialStatusList,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: MARITAL_STATUS_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// Marital Status dropdownlist
export const getSexualOrientationDropDownList = () => {
  return function (dispatch: Dispatch<SexualOrientationTypes>) {
    dispatch({
      type: SEXUAL_ORIENTATION_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/sexualOrientation_dropdown`, {
        headers,
      })
      .then((result) => {
        if (result) {
          let sexualOrientationList = result.data.sexualOrientationList;
          let message = result.data.message;
          let status = result.data.status;
          dispatch(
            sexualOrientaataionDropdownListAction(
              sexualOrientationList,
              message,
              status
            )
          );
          dispatch({
            type: SEXUAL_ORIENTATION_LIST_SUCCESS,
            sexualOrientationList: sexualOrientationList,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: SEXUAL_ORIENTATION_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// Marital Status dropdownlist
export const getLanguageDropDownList = () => {
  return function (dispatch: Dispatch<LanguageListTypes>) {
    dispatch({
      type: LANGUAGE_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/doctorapp/api/onboarding/language_list_dropdown`, {
        headers,
      })
      .then((result) => {
        if (result) {
          let languageList = result.data.languageList;
          let message = result.data.message;
          let status = result.data.status;
          dispatch(
            getLanguageDropdownListAction(languageList, message, status)
          );
          dispatch({
            type: LANGUAGE_ORIENTATION_LIST_SUCCESS,
            languageList: languageList,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: LANGUAGE_ORIENTATION_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};

// Add or Update health Record
export const addOrUpdatehealthRecord = (body: object, url: string) => {
  return function (dispatch: Dispatch<AddOrUpdateHealthRecordTypes>) {
    dispatch({
      type: ADD_OR_UPDATE_HEALTH_RECORD_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + url, body, { headers })
      .then((result) => {
        if (result) {
          let message = result.data.message;
          let status = result.data.status;
          dispatch(addOrUpdatehealthRecordAction(message, status));
          dispatch({
            type: ADD_OR_UPDATE_HEALTH_RECORD_SUCCESS,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: ADD_OR_UPDATE_HEALTH_RECORD_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// Add or Update health Record
export const getpatientDetails = (body: PatientDemographicsReq) => {
  return function (dispatch: Dispatch<GetHealthRecordTypes>) {
    dispatch({
      type: GET_HEALTH_RECORD_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          "/patientapp/api/patient/get_patient_demographics_details",
        body,
        { headers }
      )
      .then((result) => {
        if (result) {
          let personalDetails = result.data.personalDetails;
          let message = result.data.message;
          let status = result.data.status;
          dispatch(gethealthRecordAction(personalDetails, message, status));
          dispatch({
            type: GET_HEALTH_RECORD_SUCCESS,
            personalDetails: personalDetails,
            message: message,
            status: status,
          });
        }
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: GET_HEALTH_RECORD_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
