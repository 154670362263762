import {
  AlertColor,
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Paper,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import AddDataPointDialog from "../AddDataPointDialog";
import DataPointHistoryDialog from "../DataPointHistoryDialog";
import { useDispatch, useSelector } from "react-redux";
import { clearPatientDataPointClearAPIRes } from "../../../redux/patientDataDetails/addPatientDetails/API";
import { clearPatientDataPointEditAPIRes } from "../../../redux/patientDataDetails/UpdatePatientDataPoint/API";
import { clearImageUploadAPIRes } from "../../../redux/ImageUpload/API";
import ViewObservation from "../ViewObservation/Index";
import { getPatientDataDetailsById } from "../../../redux/patientDataDetails/patientDetailsById/API";
import { AppState } from "../../../redux/store/Store";
import {
  clearExtractObservationDetailsAPIRes,
  extractObservationDetailsById,
} from "../../../redux/extractObservationDetailsById/API";
import { ExtractObservationDetailsBody } from "../../../redux/extractObservationDetailsById/Model";
import MWSnackbar from "../../../component/MWSnackbar";
import {
  clearViewExtractObservationDetailsAPIRes,
  getExtractObservationByDetailsId,
} from "../../../redux/GetViewExtractObservation/API";
import { ViewExtractObservationBody } from "../../../redux/GetViewExtractObservation/Model";

import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";

const Controls = ({
  zoomIn,
  zoomOut,
  resetTransform,
}: ReactZoomPanPinchContentRef) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 2,
      transform: "translate(10px, 10px)",
      maxWidth: "calc(100% - 20px)",
    }}
  >
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);

type Props = {
  dialogState?: boolean | any;
  dialogClose?: any;
  value?: any;
  page?: number | any;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DocumentViewer({
  dialogState,
  dialogClose,
  value,
  page,
}: Props) {
  const dispatch = useDispatch();

  const [valueNav, setValueNav] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueNav(newValue);
  };
  const [docViwerActive, setDocViwerActive] = useState(false);
  const handleDocViewerChange = () => {
    setDocViwerActive(false);
    dialogClose(false);
    dispatch(clearPatientDataPointClearAPIRes());
    dispatch(clearPatientDataPointEditAPIRes());
    dispatch(clearImageUploadAPIRes());
    dispatch(clearViewExtractObservationDetailsAPIRes());
  };
  const getPatientDetailsById = useSelector(
    (state: AppState) => state.getPatientDetailsById
  );
 
  useEffect(() => {
    setDocViwerActive(dialogState);
    dispatch(getPatientDataDetailsById(value.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogState]);

  const [showAddDataPointDialog, setShowAddDataPointDialog] =
    React.useState(false);

  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
  };
  const [showHistoryDataPointDialog, setShowHistoryDataPointDialog] =
    React.useState(false);
  const updateHistoryDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowHistoryDataPointDialog(passedVal);
  };
  const [updateDrDetailsAPIMsg, setUpdateDrDetailsAPIMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const extractObservationByDetailsIdVal = useSelector(
    (state: AppState) => state.extractObservationByDetailsIdValue
  );
  const getExtractObservationDetailsValue = useSelector(
    (state: AppState) =>
      state.getExtractObservationByDetailsValue.getExtractObservationRes
        .observationList
  );
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearExtractObservationDetailsAPIRes());
    }
  };
  useEffect(() => {
    if (
      extractObservationByDetailsIdVal?.extractObservationDetailsRes
        ?.message !== undefined
    ) {
      setMsgType("success");
      setUpdateDrDetailsAPIMsg(
        extractObservationByDetailsIdVal?.extractObservationDetailsRes?.message
      );
      viewExtractObservation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractObservationByDetailsIdVal]);
  const extractObservation = () => {
    let extractBody = {
      patientDetailsId: value.id,
    } as ExtractObservationDetailsBody;
    dispatch(extractObservationDetailsById(extractBody));
  };

  const viewExtractObservation = () => {
    let viewExtractBody = {
      patientDetailsId: value.id,
    } as ViewExtractObservationBody;
    dispatch(getExtractObservationByDetailsId(viewExtractBody));
  };

  return (
    <Box>
      <Dialog fullScreen open={docViwerActive}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDocViewerChange}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Health Details Of <b>{value.patientName}</b>
            </Typography>
            <Button
              sx={{ mr: 2 }}
              onClick={() => setShowAddDataPointDialog(true)}
            >
              Edit
            </Button>
            <Button variant="outlined" onClick={extractObservation}>
              Extract Observation
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid xs={7}>
            <Tooltip
              title={
                value?.imageId !== undefined
                  ? `ImageID: ${value?.imageId}`
                  : "File Not Uploaded "
              }
              arrow
            >
              <Paper square sx={{ minHeight: "100vh" }}>
                {value?.imageId !== undefined ? (
                  <TransformWrapper
                    initialScale={1}
                    initialPositionX={50}
                    initialPositionY={50}
                  >
                    {(utils) => (
                      <React.Fragment>
                        <Controls {...utils} />
                        <TransformComponent
                          wrapperStyle={{
                            width: "60vw",
                            height: "100vw",
                            maxWidth: "100%",
                            maxHeight: "calc(100vh - 50px)",
                          }}
                        >
                          {getPatientDetailsById?.patientDetailsById
                            ?.patientDetails?.patientDocument?.document !==
                          "" ? (
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "mp4"
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "pdf"
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "quicktime"
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "webm"
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "3gpp"
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "x-flv"
                            ) ||
                            getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "x-ms-asf"
                            ) ||   getPatientDetailsById?.patientDetailsById?.patientDetails?.patientDocument?.document.includes(
                              "mp3"
                            )  ? (
                              <iframe
                                src={
                                  getPatientDetailsById?.patientDetailsById
                                    ?.patientDetails?.patientDocument?.document
                                }
                                title="Small document"
                                width="900"
                                height="700"
                                allow="fullscreen"
                              ></iframe>
                            ) : (
                              <img
                                style={{
                                  maxInlineSize: "100%",
                                  blockSize: "auto",
                                }}
                                src={
                                  getPatientDetailsById?.patientDetailsById
                                    ?.patientDetails?.patientDocument?.document
                                }
                                alt="test"
                              />
                            )
                          ) : null}
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    pt={8}
                  >
                    <Alert variant="outlined" severity="info">
                      Image File Not Uploaded
                    </Alert>
                  </Grid>
                )}
              </Paper>
            </Tooltip>
          </Grid>
          <Grid xs={5}>
            <Box sx={{ width: "100%", px: 2, py: 2 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography>
                  <Grid item>
                    <Typography gutterBottom>
                      Health Details Of: <b>{value.patientName}</b> || Recorded
                      on:{" "}
                      <b>
                        {dayjs(
                          getPatientDetailsById?.patientDetailsById
                            ?.patientDetails?.startDate
                        ).format("dddd, MMMM D, YYYY h:mm A")}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom>
                      Observation:{" "}
                      <b>
                        {
                          getPatientDetailsById?.patientDetailsById
                            ?.patientDetails?.description
                        }
                      </b>
                    </Typography>
                  </Grid>
                </Typography>
                <Grid item>
                  <Tooltip
                    title={`Patient Details Id: ${getPatientDetailsById?.patientDetailsById?.patientDetails?.id}`}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valueNav}
                  onChange={handleChange}
                  aria-label="Health Details Tabs"
                  centered
                >
                  <Tab label="History" {...a11yProps(0)} />
                  <Tab
                    label="Observations"
                    onClick={viewExtractObservation}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={valueNav} index={0}>
                <DataPointHistoryDialog
                  itemId={value.id}
                  addDataPointDialogEnable={showHistoryDataPointDialog}
                  historyDataPointDialogUpdateState={
                    updateHistoryDataPointDialogValFromChild
                  }
                />
              </TabPanel>
              <TabPanel value={valueNav} index={1}>
                <ViewObservation
                  getExtractObservationDetailsValue={
                    getExtractObservationDetailsValue
                  }
                />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      <MWSnackbar
        msg={updateDrDetailsAPIMsg}
        type={msgType}
        alertClose={checkAlertClose}
      />
      {showAddDataPointDialog ? (
        <AddDataPointDialog
          itemId={value.id}
          addDataPointDialogEnable={showAddDataPointDialog}
          addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
          value={getPatientDetailsById?.patientDetailsById?.patientDetails}
          page={page}
        />
      ) : null}
      {showHistoryDataPointDialog ? (
        <DataPointHistoryDialog
          itemId={value.id}
          addDataPointDialogEnable={showHistoryDataPointDialog}
          historyDataPointDialogUpdateState={
            updateHistoryDataPointDialogValFromChild
          }
        />
      ) : null}
    </Box>
  );
}
