import {
  DATA_POINT_HISTORY_SEARCH_DATA,
  DATA_POINT_HISTORY_SEARCH_FAIL,
  DATA_POINT_HISTORY_SEARCH_LOADING,
  DATA_POINT_HISTORY_SEARCH_SUCCESS,
  UpdatePatientDataPointActionTypes,
} from "./ActionTypes";
import { SimpleHistoryRes } from "./Model";

export const showPatientDataPointHistoryAction = (
  updatePatientDataPointRes: SimpleHistoryRes
): UpdatePatientDataPointActionTypes => {
  return {
    type: DATA_POINT_HISTORY_SEARCH_DATA,
    payload: updatePatientDataPointRes,
  };
};

export const updatePatientDataPointHistoryLoadingAction = (
  loading: boolean
): UpdatePatientDataPointActionTypes => {
  return {
    type: DATA_POINT_HISTORY_SEARCH_LOADING,
    loading: loading,
  };
};

export const updatePatientDataPointHistorySuccessAction = (
  updatePatientDataPointResponse: SimpleHistoryRes
): UpdatePatientDataPointActionTypes => {
  return {
    type: DATA_POINT_HISTORY_SEARCH_SUCCESS,
    payload: updatePatientDataPointResponse,
  };
};

export const updatePatientDataPointErrorAction = (
  updatePatientDataPointResponse: SimpleHistoryRes,
  errMsg: string,
  status: number
): UpdatePatientDataPointActionTypes => {
  return {
    type: DATA_POINT_HISTORY_SEARCH_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
export const updatePatientDataPointUpdateAPIMsgAction = (
  updatePatientDataPointResponse: SimpleHistoryRes,
  errMsg: string,
  status: number
): UpdatePatientDataPointActionTypes => {
  return {
    type: DATA_POINT_HISTORY_SEARCH_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};

export const updatePatientDataPointAPIResClearAction = (
  updatePatientDataPointResponse: SimpleHistoryRes,
  errMsg: string,
  status: number
): UpdatePatientDataPointActionTypes => {
  return {
    type: DATA_POINT_HISTORY_SEARCH_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
