import * as React from "react";
import { useEffect, useState } from "react";
import InformConsent from "./InformConsent";
import { useParams } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  Alert,
  Paper,
  Container,
} from "@mui/material";
import { TabList } from "./TabList";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

type Props = {
  filePreparationLoader?: boolean | any;
};

export default function FilePreparation({ filePreparationLoader }: Props)  {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [tabValue, setTabValue] = React.useState(1);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    localStorage.setItem("selctedprofiletab", String(newValue));
    window.scrollTo(0, 0);
  };
  const [demographicsLoader] = useState(false);
  filePreparationLoader(demographicsLoader);

  // Call Get demographics details store
  const getDemographicsDetailsByPtIdRes = useSelector(
    (state: AppState) => state.getDemographicsDetailsByPtId
  );
  // add Demographics details store
  const addDemographics = useSelector(
    (state: AppState) => state.addDemographics
  );
  // Call Relationship list store
  const getPatientRelationshipListRes = useSelector(
    (state: AppState) => state.getPatientRelationshipList
  );
  // Call Blood Group List store
  const getPatientBloodGroupListRes = useSelector(
    (state: AppState) => state.getPatientBloodGroupList
  );
  // Call Gender List store
  const getPatientGenderListRes = useSelector(
    (state: AppState) => state.getPatientGenderList
  );
  // Call Language List store
  const getPatientLanguageListRes = useSelector(
    (state: AppState) => state.getPatientLanguageList
  );
  // Call Marital Status List store
  const getPatientMaritalListRes = useSelector(
    (state: AppState) => state.getPatientMaritalList
  );
  // Call Sexual Orientation List store
  const getPatientSexualOrientationRes = useSelector(
    (state: AppState) => state.getPatientSexualOrientation
  );
  // Call Update Dempographics Details store
  const updateDemographicsDetailsRes = useSelector(
    (state: AppState) => state.updateDemographicsDetails
  );
  // Call Govt Id type store
  const getGovtIDProofStoreValue = useSelector(
    (state: AppState) => state.govtIdProofList
  );
  // Call Questionnaire List Store
  const questionnaireListRes = useSelector(
    (state: AppState) => state.patientQuestionnaireListRes
  );
  // Get Patient Data List store
  const patientDataListRes = useSelector(
    (state: AppState) => state.patientDataListRes
  );
  //Call Import Data List store
  const importedValue = useSelector((state: AppState) => state.ccValue);
  // Pass Loading value to Case Details Page(Parent Component)
  useEffect(() => {
    filePreparationLoader(
      getDemographicsDetailsByPtIdRes?.loading ||
        addDemographics?.loading ||
        updateDemographicsDetailsRes?.loading ||
        getPatientRelationshipListRes?.loading ||
        getPatientBloodGroupListRes?.loading ||
        getPatientGenderListRes?.loading ||
        getPatientLanguageListRes?.loading ||
        getPatientMaritalListRes?.loading ||
        getPatientSexualOrientationRes?.loading ||
        getGovtIDProofStoreValue?.loading ||
        questionnaireListRes?.loading ||
        patientDataListRes?.loading ||
        importedValue?.loading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDemographicsDetailsByPtIdRes,
    addDemographics,
    updateDemographicsDetailsRes,
    getPatientRelationshipListRes,
    getPatientBloodGroupListRes,
    getPatientGenderListRes,
    getPatientLanguageListRes,
    getPatientMaritalListRes,
    getPatientSexualOrientationRes,
    getGovtIDProofStoreValue,
    questionnaireListRes,
    patientDataListRes,
    importedValue,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      localStorage.getItem("consultationopt") ===
      "Comprehensive ( Recommended )"
    ) {
      setTabValue(19);
    } else if (
      localStorage.getItem("consultationopt") === "Medical Report Based"
    ) {
      setTabValue(1);
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {/* Vertical Tab List */}
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Paper variant="outlined">
            {patientid ? (
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Patient Health Record"
                indicatorColor="secondary"
                sx={{
                  alignItems: "center",
                  textTransform: "none",
                  fontSize: "1.5rem",
                }}
              >
                {TabList.map((item: any, index) => {
                  return <Tab label={item.label} {...a11yProps(index)} />;
                })}
              </Tabs>
            ) : (
              <Box>
                <Alert>
                  <Box sx={{ maxHeight: "340px", overflow: "auto" }}>
                    <InformConsent />
                  </Box>
                </Alert>
                <Box py="5">
                  <Typography>
                    By clicking “Submit” you have agreed to this informed
                    consent
                  </Typography>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
        {/* Vertical Tab Content */}
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {patientid ? (
            <Box>
              <Box sx={{ marginTop: "-30px" }}>
                {TabList.map((item: any, index) => {
                  return (
                    <TabPanel value={tabValue} index={index} key={index}>
                      {item.content}
                    </TabPanel>
                  );
                })}
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}
