import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {  useParams } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import {
  Pagination,
  Typography,
  Box,
  Button,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  CardContent,
  AlertColor,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import AddDataPointDialog from "./AddDataPointDialog";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridCellParams,
} from "@mui/x-data-grid";
import MWPageTitle from "../../component/MWPageTitle";
import MWExceptionList from "../../component/MWExceptionList";
import DataPointHistoryDialog from "./DataPointHistoryDialog";
import { getPatientDataDetailsList } from "../../redux/patientDataDetails/patientDataPointListByPatientID/API";
import DocumentViewer from "./DocumentViewer";
import { getDemographicsDetailsByPtId } from "../../redux/DemographicsDetails/GetAppointmentList/API";
import { PatientDemographicsReq } from "../../redux/Patient/DemographicsDetails/Model";
import { ExtractObservationDetailsBody } from "../../redux/extractObservationDetailsByPatientId/Model";
import {
  clearExtractObservationDetailsAPIRes,
  extractObservationByPatientId,
} from "../../redux/extractObservationDetailsByPatientId/API";
import MWSnackbar from "../../component/MWSnackbar";

export default function PatientDataDetails()  {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };

  const patientFullName = useSelector(
    (state: AppState) =>
      state.getDemographicsDetailsByPtId?.getDemographicsDetailsByPtIdRes
        ?.personalDetails
  );

  const dialogClose = (childState: any) => {
    setDocViwerActive(childState);
  };
  const [docViwerActive, setDocViwerActive] = React.useState(false);

  const [showHistoryDataPointDialog, setShowHistoryDataPointDialog] =
    React.useState(false);
  const [viewHistoryId, setViewHistoryID] = React.useState("");
  console.log(setViewHistoryID);
  const [page, setPage] = React.useState(1);
  const [patientName, setPatientName] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getPatientDataDetailsList(patientid, page));
    }
  };

  // patient details list api call ...
  useEffect(() => {
    if (patientid) {
      dispatch(getPatientDataDetailsList(patientid, page));
      dispatch(
        getDemographicsDetailsByPtId({
          healthRecordId: patientid,
        } as PatientDemographicsReq)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [extractSuccessMessage, setExtractSuccessMessage] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");
  const extractObservationByPatientIdVal = useSelector(
    (state: AppState) => state.extractObservationByPatientIdValue
  );
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearExtractObservationDetailsAPIRes());
    }
  };
  useEffect(() => {
    if (
      extractObservationByPatientIdVal?.extractObservationDetailsRes
        ?.message !== undefined
    ) {
      setMsgType("success");
      setExtractSuccessMessage(
        extractObservationByPatientIdVal?.extractObservationDetailsRes?.message
      );
    }
  }, [extractObservationByPatientIdVal]);

  useEffect(() => {
    setPageTitle(
      "Patient Details List of " +
        patientFullName?.firstname +
        " " +
        patientFullName?.lastname
    );
    setPatientName(
      patientFullName?.firstname + " " + patientFullName?.lastname
    );
  }, [patientFullName]);

  const patientPointDataList = useSelector(
    (state: AppState) => state.getPatientDataDetailsRes
  );

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getPatientDataDetailsList(patientid, value));
  };

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    patientPointDataList?.pageCount !== undefined
      ? setPageListCount(String(patientPointDataList?.pageCount))
      : setPageListCount("0");

    patientPointDataList?.getPatientDataDetailsRes?.count !== undefined
      ? setListCount(
          String(patientPointDataList?.getPatientDataDetailsRes?.count)
        )
      : setListCount("0");

    if (
      patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList !==
      undefined
    ) {
      let dataPointArr =
        patientPointDataList?.getPatientDataDetailsRes?.patientDetailsList.map(
          (element: any) => ({
            id: element.id,
            patientId: element.patientId,
            description: element.description,
            startDate: dayjs(element.startDate).format(
              "dddd, MMMM D, YYYY h:mm A"
            ),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
          })
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(String(patientPointDataList?.pageCount));
    }
  }, [patientPointDataList]);

  const [selectedItem, setSelectedItem] = React.useState<any>(
    ({} as any) || undefined
  );

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      align: "left",
      flex: 0.5,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setDocViwerActive(true);
            console.log("VIEWDATA", params.row);
            setSelectedItem(Object.assign(params.row, { patientName }));
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient details I'd",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const patientExtractObservation = () => {
    let extractPBody = {
      patientId: patientid,
    } as ExtractObservationDetailsBody;
    dispatch(extractObservationByPatientId(extractPBody));
  };

  const [showAddDataPointDialog, setShowAddDataPointDialog] =
    React.useState(false);
  const updateDialogState = () => {
    setShowAddDataPointDialog(true);
  };
  const updateDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowAddDataPointDialog(passedVal);
    setSelectedItem(undefined);
  };

  const updateHistoryDataPointDialogValFromChild = (passedVal: boolean) => {
    setShowHistoryDataPointDialog(passedVal);
    setSelectedItem(undefined);
  };

  return (
    <Box>
      {patientPointDataList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={pageTitle}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={reloadActionFromChild}
              />
            </Grid>
            <Grid item m={2}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={patientExtractObservation}
                  >
                    Extract Observation
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => updateDialogState()}
                  >
                    Add Patient Details
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {patientPointDataTable}
          <MWSnackbar
            msg={extractSuccessMessage}
            type={msgType}
            alertClose={checkAlertClose}
          />
          {showAddDataPointDialog ? (
            <AddDataPointDialog
              addDataPointDialogEnable={showAddDataPointDialog}
              addDataPointDialogUpdateState={updateDataPointDialogValFromChild}
              page={page}
            />
          ) : null}
          {showHistoryDataPointDialog ? (
            <DataPointHistoryDialog
              itemId={viewHistoryId}
              addDataPointDialogEnable={showHistoryDataPointDialog}
              historyDataPointDialogUpdateState={
                updateHistoryDataPointDialogValFromChild
              }
            />
          ) : null}
          {docViwerActive ? (
            <DocumentViewer
              dialogState={docViwerActive}
              dialogClose={dialogClose}
              value={selectedItem}
              page={page}
            />
          ) : null}
        </Stack>
      </Container>
    </Box>
  );
}
