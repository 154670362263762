import { ProviderPatientListRes } from "./Model";

export const PROVIDER_PATIENT_LIST_LOADING = "PROVIDER_PATIENT_LIST_LOADING";
export const PROVIDER_PATIENT_LIST_SUCCESS = "PROVIDER_PATIENT_LIST_SUCCESS";
export const PROVIDER_PATIENT_LIST_FAIL = "PROVIDER_PATIENT_LIST_FAIL";
export const ADD_PATIENT_DATA = "ADD_PATIENT_DATA";
export const ADD_PATIENT_API_MSG = "ADD_PATIENT_API_MSG";
export const ADD_PATIENT_API_RES = "ADD_PATIENT_API_RES";

export interface ProviderPatientListDetails {
  providerOrgListRes: ProviderPatientListRes;
  loading: boolean;
  message: string;
  status: number;
  errRes: string;
}
export interface ProviderPatientListLoading {
  type: typeof PROVIDER_PATIENT_LIST_LOADING;
  loading: boolean;
}
export interface ProviderPatientListSuccess {
  type: typeof PROVIDER_PATIENT_LIST_SUCCESS;
  payload: ProviderPatientListRes;
}
export interface ProviderPatientListFail {
  type: typeof PROVIDER_PATIENT_LIST_FAIL;
  payload: ProviderPatientListRes;
  message: string;
  status: number;
}

export interface ProviderPatientListUpdateAPIMsg {
  type: typeof ADD_PATIENT_API_MSG;
  payload: ProviderPatientListRes;
  message: string;
  status: number;
}
export interface ProviderPatientListUpdateAPIRes {
  type: typeof ADD_PATIENT_API_RES;
  payload: ProviderPatientListRes;
  message: string;
  status: number;
}
interface ProviderPatientListAction {
  type: typeof ADD_PATIENT_DATA;
  payload: ProviderPatientListRes;
  errRes: string;
}

export type ProviderPatientListActionTypes =
  | ProviderPatientListAction
  | ProviderPatientListLoading
  | ProviderPatientListSuccess
  | ProviderPatientListFail
  | ProviderPatientListUpdateAPIMsg
  | ProviderPatientListUpdateAPIRes;
