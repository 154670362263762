import React, { useState, useEffect } from "react";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import PreviewIcon from "@mui/icons-material/Preview";
import PaymentIcon from "@mui/icons-material/Payment";
import { useParams } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPaymentStatus,
  confirmAppointment,
  getAppointmentList,
  getAppointmentPaymentList,
} from "../../redux/Patient/AppointmentPayment/ApiCall";
import { AppState } from "../../redux/store/Store";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Divider,
  Alert,
  Stack,
  Container,
  Snackbar,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";

export default function AppointmentPayment(props: any)  {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  const { appointmentPaymentLoader } = props;

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  // const [, setIsDisable] = useState(true);

  const [consultationType, setConsultationType] = useState("");
  const [doctorTeamType, setDoctorTeamType] = useState("");
  const [responseTimeType, setResponseTimeType] = useState("");

  // call Payment List from store
  const PaymentListLoadingvalue = useSelector(
    (state: AppState) => state.appointmentPaymentListValue.loading
  );
  const paymentListFromAPI = useSelector(
    (state: AppState) => state.appointmentPaymentListValue.paymentList
  );

  // call Appointment List from store
  const appointmentListLoadingvalue = useSelector(
    (state: AppState) => state.appointmentListValue.loading
  );
  const appointmentListConsultationType = useSelector(
    (state: AppState) => state.appointmentListValue.consultationType
  );
  const appointmentListDoctorTeamType = useSelector(
    (state: AppState) => state.appointmentListValue.doctorTeamType
  );
  const appointmentListResponseTimeType = useSelector(
    (state: AppState) => state.appointmentListValue.responseTimeType
  );
  const appontmentListFromAPI = useSelector(
    (state: AppState) => state.appointmentListValue.appointmentList
  );

  // call Check Payment Store
  const checkPaymentStatusLoadingValue = useSelector(
    (state: AppState) => state.appointmentPaymentStatusValue.loading
  );
  const checkPaymentStatusMessage = useSelector(
    (state: AppState) => state.appointmentPaymentStatusValue.message
  );
  const checkPaymentStatusStatus = useSelector(
    (state: AppState) => state.appointmentPaymentStatusValue.status
  );

  // check payment status success toast
  const [
    sucessCheckPaymentStatusToastActive,
    setSucessCheckPaymentStatusToastActive,
  ] = useState(false);
  const toggleSuccessCheckPaymentStatusToggleActive = () => {
    setSucessCheckPaymentStatusToastActive(
      (sucessCheckPaymentStatusToastActive) =>
        !sucessCheckPaymentStatusToastActive
    );
    getAppoinmentRequestList();
  };

  const toastSucessCheckPaymentStatusMarkup =
    sucessCheckPaymentStatusToastActive ? (
      <Snackbar
        open={sucessCheckPaymentStatusToastActive}
        autoHideDuration={6000}
        onClose={toggleSuccessCheckPaymentStatusToggleActive}
      >
        <Alert
          severity="success"
          onClose={toggleSuccessCheckPaymentStatusToggleActive}
          sx={{ width: "100%" }}
        >
          {checkPaymentStatusMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // check payment status error toast
  const [
    errorCheckPaymentStatusToastActive,
    setErrorCheckPaymentStatusToastActive,
  ] = useState(false);
  const toggleErrorCheckPaymentStatusToggleActive = () => {
    setErrorCheckPaymentStatusToastActive(
      (errorCheckPaymentStatusToastActive) =>
        !errorCheckPaymentStatusToastActive
    );
    getAppoinmentRequestList();
  };
  const toastErrorCheckPaymentStatusMarkup =
    errorCheckPaymentStatusToastActive ? (
      <Snackbar
        open={errorCheckPaymentStatusToastActive}
        autoHideDuration={6000}
        onClose={toggleErrorCheckPaymentStatusToggleActive}
      >
        <Alert
          severity="error"
          onClose={toggleErrorCheckPaymentStatusToggleActive}
          sx={{ width: "100%" }}
        >
          {checkPaymentStatusMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // call Confirm Appointment Store
  const confirmAppointmentLoadingValue = useSelector(
    (state: AppState) => state.appointmentConfirmValue.loading
  );
  const confirmAppointmentMessage = useSelector(
    (state: AppState) => state.appointmentConfirmValue.message
  );
  const confirmAppointmentStatus = useSelector(
    (state: AppState) => state.appointmentConfirmValue.status
  );

  // Confirm appointment success toast
  const [
    sucessConfirmAppointmentToastActive,
    setSucessConfirmAppointmentToastActive,
  ] = useState(false);
  const toggleSuccessConfirmAppointmentToggleActive = () => {
    setSucessConfirmAppointmentToastActive(
      (sucessConfirmAppointmentToastActive) =>
        !sucessConfirmAppointmentToastActive
    );
    getAppoinmentRequestList();
  };

  const toastSucessConfirmAppointmentMarkup =
    sucessConfirmAppointmentToastActive ? (
      <Snackbar
        open={sucessConfirmAppointmentToastActive}
        autoHideDuration={6000}
        onClose={toggleSuccessConfirmAppointmentToggleActive}
      >
        <Alert
          severity="success"
          onClose={toggleSuccessConfirmAppointmentToggleActive}
          sx={{ width: "100%" }}
        >
          {confirmAppointmentMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // Confirm appointment error toast
  const [
    errorConfirmAppointmentToastActive,
    setErrorConfirmAppointmentToastActive,
  ] = useState(false);
  const toggleErrorConfirmAppointmentToggleActive = () => {
    setErrorConfirmAppointmentToastActive(
      (errorConfirmAppointmentToastActive) =>
        !errorConfirmAppointmentToastActive
    );
    getAppoinmentRequestList();
  };
  const toastErrorConfirmAppointmentMarkup =
    errorConfirmAppointmentToastActive ? (
      <Snackbar
        open={errorConfirmAppointmentToastActive}
        autoHideDuration={6000}
        onClose={toggleErrorConfirmAppointmentToggleActive}
      >
        <Alert
          severity="error"
          onClose={toggleErrorConfirmAppointmentToggleActive}
          sx={{ width: "100%" }}
        >
          {confirmAppointmentMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // payment & appointment list api body
  const body = {
    caseId: caseid,
  };

  // Get Payment List
  const getPaymentList = () => {
    dispatch(getAppointmentPaymentList(body));
  };

  // Selection Type GET API
  const getAppoinmentRequestList = () => {
    dispatch(getAppointmentList(body));
  };

  // Check Payment Status API
  function checkPaymentStatusDetailsApicall(id: any) {
    dispatch(checkPaymentStatus(id));
    setSucessCheckPaymentStatusToastActive(
      (sucessCheckPaymentStatusToastActive) =>
        !sucessCheckPaymentStatusToastActive
    );
    setErrorCheckPaymentStatusToastActive(
      (errorCheckPaymentStatusToastActive) =>
        !errorCheckPaymentStatusToastActive
    );
  }

  // confirm Appointment API
  const conFirmAppointment = (appointmentId: any) => {
    dispatch(confirmAppointment(appointmentId));
    setSucessConfirmAppointmentToastActive(
      (sucessConfirmAppointmentToastActive) =>
        !sucessConfirmAppointmentToastActive
    );
    setErrorConfirmAppointmentToastActive(
      (errorConfirmAppointmentToastActive) =>
        !errorConfirmAppointmentToastActive
    );
  };
  // configure payment list table
  const paymentsValueListConfig: any = [];
  for (var i = 0; i < paymentListFromAPI.length; i++) {
    let paymentid = paymentListFromAPI[i].id;
    let selectedResponse = paymentListFromAPI[i].paymentLink;
    let description =
      paymentListFromAPI[i].note === ""
        ? "No Details Available"
        : paymentListFromAPI[i].note;
    let amount =
      paymentListFromAPI[i].amount === ""
        ? "Amount Not Available"
        : paymentListFromAPI[i].amount;
    let round_amount = amount / 100;
    let createDate =
      paymentListFromAPI[i].create_date == null
        ? ""
        : new Date(paymentListFromAPI[i].create_date).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let appoinmentPaidTime = paymentListFromAPI[i].hasOwnProperty("paidTime")
      ? paymentListFromAPI[i].paidTime === "" ||
        paymentListFromAPI[i].paidTime === null
        ? ""
        : new Date(paymentListFromAPI[i].paidTime).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          ) +
          " at " +
          new Date(paymentListFromAPI[i].paidTime).getHours() +
          ":" +
          new Date(paymentListFromAPI[i].paidTime).getMinutes()
      : "";
    let paymentstatus =
      paymentListFromAPI[i].status === null ? "" : paymentListFromAPI[i].status;
    paymentsValueListConfig.push([
      description,
      createDate,
      "Rs. " + round_amount,
      <Button
        variant="outlined"
        disabled={
          paymentstatus === "paid" || paymentstatus === "free" ? true : false
        }
        onClick={() => paymentLink(selectedResponse)}
        startIcon={<PaymentIcon />}
      >
        Pay Now
      </Button>,
      appoinmentPaidTime,
      <Typography>{paymentstatus}</Typography>,
      <Button
        variant="outlined"
        disabled={
          paymentstatus === "paid" || paymentstatus === "free" ? true : false
        }
        onClick={() => checkPaymentStatusDetailsApicall(paymentid)}
        startIcon={<PreviewIcon />}
      >
        Check Now
      </Button>,
    ]);
  }
  let paymentListTable: any[] = paymentsValueListConfig;

  // configure appointment list table
  const appointmentValueListConfig: any = [];
  for (var j = 0; j < appontmentListFromAPI?.length; j++) {
    let appointmentId =
      appontmentListFromAPI[j]?.id == null
        ? "No id Available"
        : appontmentListFromAPI[j]?.id;
    let description =
      appontmentListFromAPI[j]?.name === "" ||
      appontmentListFromAPI[j]?.name === null
        ? "No Details Available"
        : appontmentListFromAPI[j]?.name;
    let createdDate =
      appontmentListFromAPI[j]?.createDate == null
        ? ""
        : new Date(appontmentListFromAPI[j]?.createDate).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let endDate =
      appontmentListFromAPI[j]?.end == null
        ? ""
        : new Date(appontmentListFromAPI[j]?.end).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let modifiedDate =
      appontmentListFromAPI[j]?.modifiedDate == null
        ? ""
        : new Date(appontmentListFromAPI[j]?.modifiedDate).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let appoinmentStatus = appontmentListFromAPI[j]?.hasOwnProperty("status")
      ? appontmentListFromAPI[j]?.status === null ||
        appontmentListFromAPI[j]?.status === ""
        ? ""
        : appontmentListFromAPI[j]?.status
      : "";
    appointmentValueListConfig.push([
      description,
      createdDate,
      endDate,
      modifiedDate,
      appoinmentStatus,
      <Button
        variant="outlined"
        onClick={() => conFirmAppointment(appointmentId)}
        startIcon={<MobileFriendlyIcon />}
      >
        Confirm
      </Button>,
    ]);
  }
  let appoinmentRequestListValue: any[] = appointmentValueListConfig;

  function paymentLink(response: any) {
    window.open(response);
  }

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    getPaymentList();
    getAppoinmentRequestList();
    Object.keys(appointmentListConsultationType).length > 0
      ? setConsultationType(appointmentListConsultationType)
      : setConsultationType("");
    Object.keys(appointmentListDoctorTeamType).length > 0
      ? setDoctorTeamType(appointmentListDoctorTeamType)
      : setDoctorTeamType("");
    Object.keys(appointmentListResponseTimeType).length > 0
      ? setResponseTimeType(appointmentListResponseTimeType)
      : setResponseTimeType("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, appointmentListConsultationType]);

  const tableMarkup = (
    <Card>
      <CardHeader title="Selectioned Options"></CardHeader>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Box p={3}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Stack>
                <Typography component="h6" style={{ fontWeight: "bold" }}>
                  Consultation Type
                </Typography>
                <Divider />
                <Box mt={2}>
                  <Alert variant="outlined" severity="info">
                    <strong>{consultationType}</strong>
                  </Alert>
                </Box>
              </Stack>
              <Stack>
                <Typography component="h6" style={{ fontWeight: "bold" }}>
                  Response Timing
                </Typography>
                <Divider />
                <Box mt={2}>
                  <Alert variant="outlined" severity="info">
                    <strong>{responseTimeType}</strong>
                  </Alert>
                </Box>
              </Stack>
              <Stack>
                <Typography component="h6" style={{ fontWeight: "bold" }}>
                  Doctor Selection Type
                </Typography>
                <Divider />
                <Box mt={2}>
                  <Alert variant="outlined" severity="info">
                    <strong>{doctorTeamType}</strong>
                  </Alert>
                </Box>
              </Stack>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <CardContent>
            <Box pl={3}>
              <CardHeader
                title={
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>Payment List</Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={getPaymentList}
                      >
                        Reload Payment List
                      </Button>
                    </Grid>
                  </Grid>
                }
              ></CardHeader>
              {paymentListTable && paymentListTable.length ? (
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Create Date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Pay</TableCell>
                        <TableCell>Paid On</TableCell>
                        <TableCell>Payment Status</TableCell>
                        <TableCell>Check Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentListTable.map((row: any) => (
                        <TableRow
                          key={row.description}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row[0]}</TableCell>
                          <TableCell align="left">{row[1]}</TableCell>
                          <TableCell align="left">{row[2]}</TableCell>
                          <TableCell align="left">{row[3]}</TableCell>
                          <TableCell align="left">{row[4]}</TableCell>
                          <TableCell align="left">{row[5]}</TableCell>
                          <TableCell align="left">{row[6]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>
                  <DescriptionIcon />
                  &nbsp;&nbsp; No Payment Details is available!
                </Typography>
              )}
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
  const appointmentTableBody = (
    <Box sx={{ pt: 2 }}>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>Existing Appointment Details</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={getAppoinmentRequestList}
                  sx={{ textTransform: "none" }}
                >
                  Reload Appointment List
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {appoinmentRequestListValue && appoinmentRequestListValue.length ? (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Create Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Modified Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Confirm Appoinment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appoinmentRequestListValue.map((row: any) => (
                    <TableRow
                      key={row.description}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{row[0]}</TableCell>
                      <TableCell align="left">{row[1]}</TableCell>
                      <TableCell align="left">{row[2]}</TableCell>
                      <TableCell align="left">{row[3]}</TableCell>
                      <TableCell align="left">{row[4]}</TableCell>
                      <TableCell align="left">{row[5]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>
              <MWExceptionList />
            </Typography>
          )}
        </CardContent>
      </Card>
      <br />
    </Box>
  );

  return (
    <Container maxWidth="xl">
      {PaymentListLoadingvalue === true ||
      appointmentListLoadingvalue === true ||
      checkPaymentStatusLoadingValue === true ||
      confirmAppointmentLoadingValue === true
        ? appointmentPaymentLoader(true)
        : appointmentPaymentLoader(false)}
      {tableMarkup}
      {appointmentTableBody}
      {checkPaymentStatusStatus! === 1
        ? toastSucessCheckPaymentStatusMarkup
        : ""}
      {checkPaymentStatusStatus! === 0
        ? toastErrorCheckPaymentStatusMarkup
        : ""}
      {confirmAppointmentStatus! === 1
        ? toastSucessConfirmAppointmentMarkup
        : ""}
      {confirmAppointmentStatus! === -3
        ? toastErrorConfirmAppointmentMarkup
        : ""}
    </Container>
  );
}
