import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ResponseTimeTypeBody, ResponseTimeTypeResponse } from "./Model";
import {
  responseTimeTypeAPIResClearAction,
  responseTimeTypeErrorAction,
  responseTimeTypeLoadingAction,
  responseTimeTypeSuccessAction,
  responseTimeTypeUpdateAPIMsgAction,
} from "./Actions";
import { ResponseTimeTypeActionTypes } from "./ActionTypes";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as ResponseTimeTypeResponse;
export const getResponseTimeTypeList = (body: ResponseTimeTypeBody) => {
  return function (dispatch: Dispatch<ResponseTimeTypeActionTypes>) {
    dispatch(responseTimeTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/patientapp/api/patientMedicalData/responseTime_typeList`,
        body,
        {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(responseTimeTypeLoadingAction(false));
        dispatch(
          responseTimeTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "consultation type fetch successfully",
            res.data.status
          )
        );
      })
      .catch((error) => {
        dispatch(responseTimeTypeLoadingAction(false));
        dispatch(
          responseTimeTypeErrorAction(
            {} as ResponseTimeTypeResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const responseTimeTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<ResponseTimeTypeActionTypes>) {
    dispatch(
      responseTimeTypeUpdateAPIMsgAction(
        apiRes as ResponseTimeTypeResponse,
        "",
        "",
        0
      )
    );
  };
};

export const clearresponseTimeTypeAPIRes = () => {
  return function (dispatch: Dispatch<ResponseTimeTypeActionTypes>) {
    dispatch(
      responseTimeTypeAPIResClearAction(
        {} as ResponseTimeTypeResponse,
        "",
        "",
        0
      )
    );
  };
};
