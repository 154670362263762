import { GovtIdProofResponse } from "./Model";

export const GOVT_ID_PROOF_LOADING = "GOVT_ID_PROOF_LOADING";
export const GOVT_ID_PROOF_SUCCESS = "GOVT_ID_PROOF_SUCCESS";
export const GOVT_ID_PROOF_PROGRESS = "GOVT_ID_PROOF_PROGRESS";
export const GOVT_ID_PROOF_FAIL = "GOVT_ID_PROOF_FAIL";
export const GOVT_ID_PROOF = "GOVT_ID_PROOF";

export interface GovtIDProofDetails {
  govtIdProofResponse: GovtIdProofResponse;
  loading: boolean;
  message: string;
  status: string;
}
export interface GovtIDProofLoading {
  type: typeof GOVT_ID_PROOF_LOADING;
  loading: boolean;
}
export interface GovtIDProofSuccess {
  type: typeof GOVT_ID_PROOF_SUCCESS;
  payload: GovtIdProofResponse;
  message: string;
  status: string;
}
export interface GovtIDProofFail {
  type: typeof GOVT_ID_PROOF_FAIL;
  payload: GovtIdProofResponse;
  message: string;
  status: string;
}

export type GovtIDProofActionTypes =
  | GovtIDProofLoading
  | GovtIDProofSuccess
  | GovtIDProofFail;
