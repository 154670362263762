import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { ProviderOrgPtDetailsActionTypes } from "./ActionTypes";
import { ProviderOrgListBody, ProviderOrgPatientDetailsRes } from "./Model";
import {
  proOrgPtDetailsAPIResClearAction,
  proOrgPtDetailsErrorAction,
  proOrgPtDetailsLoadingAction,
  proOrgPtDetailsSuccessAction,
  proOrgPtDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as ProviderOrgPatientDetailsRes;
export const providerOrgPtDetails = (payload: ProviderOrgListBody) => {
  return function (dispatch: Dispatch<ProviderOrgPtDetailsActionTypes>) {
    dispatch(proOrgPtDetailsLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/providerorganizationapp/api/providerOrganizationPatientDetails/get_patientDetail_providerOrganization?page=1&size=100`,
        payload,
        {
          headers: headers,
        },
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(proOrgPtDetailsLoadingAction(false));
        dispatch(proOrgPtDetailsSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(proOrgPtDetailsLoadingAction(false));
        dispatch(
          proOrgPtDetailsErrorAction(
            {} as ProviderOrgPatientDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1,
          ),
        );
      });
  };
};

export const providerOrgPtDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<ProviderOrgPtDetailsActionTypes>) {
    dispatch(proOrgPtDetailsAPIMsgAction(apiRes as ProviderOrgPatientDetailsRes, "", 0));
  };
};

export const clearproviderOrgPtDetailsAPIRes = () => {
  return function (dispatch: Dispatch<ProviderOrgPtDetailsActionTypes>) {
    dispatch(proOrgPtDetailsAPIResClearAction({} as ProviderOrgPatientDetailsRes, "", 0));
  };
};
