const userToken = localStorage.getItem("access");
let headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
} as any;

let ApiHeader = {
  "Content-type": "application/json",
} as any;

export { headers, ApiHeader };

export function checkReturnValue(value: any) {
  if (value == null) {
    // The value is either undefined or null
    // Handle the case accordingly
    return "Not Set";
  } else {
    // The value is not undefined or null
    // Proceed with other logic
    return value;
  }
}
