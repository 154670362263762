import { AddedDoctorToCaseTypeList, AddResponse, DeleteResponse, SelectedSpecialistDoctorTypeList, SetDoctorTeamType, SpecialistDoctorTypeList 
} from "./Model";

// export const DOCTOR_TEAM_TYPE_LIST_LOADING = "DOCTOR_TEAM_TYPE_LIST_LOADING";
// export const DOCTOR_TEAM_TYPE_LIST_SUCCESS = "DOCTOR_TEAM_TYPE_LIST_SUCCESS";
// export const DOCTOR_TEAM_TYPE_LIST_FAIL = "DOCTOR_TEAM_TYPE_LIST_FAIL";
// export const DOCTOR_TEAM_TYPE_LIST = 'DOCTOR_TEAM_TYPE_LIST';

// export interface DoctorTeamTypeStateType {
//   loading: boolean;
//   response: DoctorTeamTypeList[];
// }
// export interface DoctorTeamTypeLoading {
//   type: typeof DOCTOR_TEAM_TYPE_LIST_LOADING;
// }
// export interface DoctorTeamTypeSuccess {
//   type: typeof DOCTOR_TEAM_TYPE_LIST_SUCCESS;
//   payload: DoctorTeamTypeList[];
// }
// export interface DoctorTeamTypeFail {
//   type: typeof DOCTOR_TEAM_TYPE_LIST_FAIL;
// }

// interface DoctorTeamType {
//   type: typeof DOCTOR_TEAM_TYPE_LIST;
//   payload: DoctorTeamTypeList[];
// }

// export type DoctorTeamTypes = DoctorTeamType | DoctorTeamTypeLoading | DoctorTeamTypeSuccess | DoctorTeamTypeFail ;

export const ADD_DOCTORTEAMTYPE_LOADING = "ADD_DOCTORTEAMTYPE_LOADING";
export const ADD_DOCTORTEAMTYPE_SUCCESS = "ADD_DOCTORTEAMTYPE_SUCCESS";
export const ADD_DOCTORTEAMTYPE_FAIL = "ADD_DOCTORTEAMTYPE_FAIL";
export const ADD_DOCTORTEAMTYPE = 'ADD_DOCTORTEAMTYPE';

export interface SetAddDoctorTeamTypeStateType {
  addCC: SetDoctorTeamType[];
  loading: boolean;
  response: AddResponse;
}
export interface AddDoctorTeamTypeLoading {
  type: typeof ADD_DOCTORTEAMTYPE_LOADING;
}
export interface AddDoctorTeamTypeSuccess {
  type: typeof ADD_DOCTORTEAMTYPE_SUCCESS;
  payload: AddResponse;
}
export interface AddDoctorTeamTypeFail {
  type: typeof ADD_DOCTORTEAMTYPE_FAIL;
}

interface SetAddDoctorTeamType {
  type: typeof ADD_DOCTORTEAMTYPE;
  payload: SetDoctorTeamType[];
}

export type AddDoctorTeamTypes = SetAddDoctorTeamType | AddDoctorTeamTypeLoading | AddDoctorTeamTypeSuccess | AddDoctorTeamTypeFail;

export const SELECTED_DOCTOR_TEAM_TYPE_LIST_LOADING = "SELECTED_DOCTOR_TEAM_TYPE_LIST_LOADING";
export const SELECTED_DOCTOR_TEAM_TYPE_LIST_SUCCESS = "SELECTED_DOCTOR_TEAM_TYPE_LIST_SUCCESS";
export const SELECTED_DOCTOR_TEAM_TYPE_LIST_FAIL = "DOCTOR_TEAM_TYPE_LIST_FAIL";
export const SELECTED_DOCTOR_TEAM_TYPE_LIST = 'DOCTOR_TEAM_TYPE_LIST';

export interface SelectedDoctorTeamTypeStateType {
  loading: boolean;
  response: number;
}
export interface SelectedDoctorTeamTypeLoading {
  type: typeof SELECTED_DOCTOR_TEAM_TYPE_LIST_LOADING;
}
export interface SelectedDoctorTeamTypeSuccess {
  type: typeof SELECTED_DOCTOR_TEAM_TYPE_LIST_SUCCESS;
  payload: number;
}
export interface SelectedDoctorTeamTypeFail {
  type: typeof SELECTED_DOCTOR_TEAM_TYPE_LIST_FAIL;
}

interface SelectedDoctorTeamType {
  type: typeof SELECTED_DOCTOR_TEAM_TYPE_LIST;
  payload: number;
}
export type SelectedDoctorTeamTypes = SelectedDoctorTeamType | SelectedDoctorTeamTypeLoading | SelectedDoctorTeamTypeSuccess | SelectedDoctorTeamTypeFail ;


export const SPECIALIST_DOCTOR_TYPE_LIST_LOADING = "SPECIALIST_DOCTOR_TYPE_LIST_LOADING";
export const SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS = "SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS";
export const SPECIALIST_DOCTOR_TYPE_LIST_FAIL = "SPECIALIST_DOCTOR_TYPE_LIST_FAIL";
export const SPECIALIST_DOCTOR_TYPE_LIST = 'SPECIALIST_DOCTOR_TYPE_LIST';

export interface SpecialistDoctorTypeStateType {
  loading: boolean;
  response: SpecialistDoctorTypeList[];
}
export interface SpecialistDoctorTypeLoading {
  type: typeof SPECIALIST_DOCTOR_TYPE_LIST_LOADING;
}
export interface SpecialistDoctorTypeSuccess {
  type: typeof SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS;
  payload: SpecialistDoctorTypeList[];
}
export interface SpecialistDoctorTypeFail {
  type: typeof SPECIALIST_DOCTOR_TYPE_LIST_FAIL;
}

interface SpecialistDoctorType {
  type: typeof SPECIALIST_DOCTOR_TYPE_LIST;
  payload: SpecialistDoctorTypeList[];
}
export type SpecialistDoctorTypes = SpecialistDoctorType | SpecialistDoctorTypeLoading | SpecialistDoctorTypeSuccess | SpecialistDoctorTypeFail ;


export const SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_LOADING = "SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_LOADING";
export const SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS = "SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS";
export const SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_FAIL = "SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_FAIL";
export const SELECTED_SPECIALIST_DOCTOR_TYPE_LIST = 'SELECTED_SPECIALIST_DOCTOR_TYPE_LIST';

export interface SelectedSpecialistDoctorTypeStateType {
  loading: boolean;
  response: SelectedSpecialistDoctorTypeList[];
}
export interface SelectedSpecialistDoctorTypeLoading {
  type: typeof SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_LOADING;
}
export interface SelectedSpecialistDoctorTypeSuccess {
  type: typeof SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_SUCCESS;
  payload: SelectedSpecialistDoctorTypeList[];
}
export interface SelectedSpecialistDoctorTypeFail {
  type: typeof SELECTED_SPECIALIST_DOCTOR_TYPE_LIST_FAIL;
}

interface SelectedSpecialistDoctorType {
  type: typeof SELECTED_SPECIALIST_DOCTOR_TYPE_LIST;
  payload: SelectedSpecialistDoctorTypeList[];
}
export type SelectedSpecialistDoctorTypes = SelectedSpecialistDoctorType | SelectedSpecialistDoctorTypeLoading | SelectedSpecialistDoctorTypeSuccess | SelectedSpecialistDoctorTypeFail ;

export const ADD_DOCTORTOCASE_LOADING = "ADD_DOCTORTOCASE_LOADING";
export const ADD_DOCTORTOCASE_SUCCESS = "ADD_DOCTORTOCASE_SUCCESS";
export const ADD_DOCTORTOCASE_FAIL = "ADD_DOCTORTOCASE_FAIL";
export const ADD_DOCTORTOCASE = 'ADD_DOCTORTOCASE';

export interface SetAddDoctorToCaseStateType {
  loading: boolean;
  response: AddResponse;
}
export interface AddDoctorToCaseLoading {
  type: typeof ADD_DOCTORTOCASE_LOADING;
}
export interface AddDoctorToCaseSuccess {
  type: typeof ADD_DOCTORTOCASE_SUCCESS;
  payload: AddResponse;
}
export interface AddDoctorToCaseFail {
  type: typeof ADD_DOCTORTOCASE_FAIL;
}

interface SetAddDoctorToCaseType {
  type: typeof ADD_DOCTORTOCASE;
  payload: SetDoctorTeamType[];
}

export type AddDoctorToCaseTypes = SetAddDoctorToCaseType | AddDoctorToCaseLoading | AddDoctorToCaseSuccess | AddDoctorToCaseFail;

export const GET_DOCTOR_TO_CASE_LIST_LOADING = "GET_DOCTOR_TO_CASE_LIST_LOADING";
export const GET_DOCTOR_TO_CASE_LIST_SUCCESS = "GET_DOCTOR_TO_CASE_LIST_SUCCESS";
export const GET_DOCTOR_TO_CASE_LIST_FAIL = "GET_DOCTOR_TO_CASE_LIST_FAIL";
export const GET_DOCTOR_TO_CASE = 'GET_DOCTOR_TO_CASE';

export interface DoctorAddToCaseTypeStateType {
  loading: boolean;
  response: AddedDoctorToCaseTypeList[];
}
export interface DoctorAddToCaseTypeLoading {
  type: typeof GET_DOCTOR_TO_CASE_LIST_LOADING;
}
export interface DoctorAddToCaseTypeSuccess {
  type: typeof GET_DOCTOR_TO_CASE_LIST_SUCCESS;
  payload: AddedDoctorToCaseTypeList[];
}
export interface DoctorAddToCaseTypeFail {
  type: typeof GET_DOCTOR_TO_CASE_LIST_FAIL;
}

interface DoctorAddToCaseType {
  type: typeof GET_DOCTOR_TO_CASE;
  payload: AddedDoctorToCaseTypeList[];
}
export type DoctorAddToCaseTypes = DoctorAddToCaseType | DoctorAddToCaseTypeLoading | DoctorAddToCaseTypeSuccess | DoctorAddToCaseTypeFail ;


export const DELETE_DOCTORFROMCASE_LOADING = "DELETE_DOCTORFROMCASE_LOADING";
export const DELETE_DOCTORFROMCASE_SUCCESS = "DELETE_DOCTORFROMCASE_SUCCESS";
export const DELETE_DOCTORFROMCASE_FAIL = "DELETE_DOCTORFROMCASE_FAIL";
export const DELETE_DOCTORFROMCASE = 'ADD_DOCTORTOCASE';

export interface SetDeleteDoctorFromCaseStateType {
  loading: boolean;
  response: DeleteResponse;
}
export interface DeleteDoctorFromCaseLoading {
  type: typeof DELETE_DOCTORFROMCASE_LOADING;
}
export interface DeleteDoctorFromCaseSuccess {
  type: typeof DELETE_DOCTORFROMCASE_SUCCESS;
  payload: DeleteResponse;
}
export interface DeleteDoctorFromCaseFail {
  type: typeof DELETE_DOCTORFROMCASE_FAIL;
}

interface SetDeleteDoctorFromCaseType {
  type: typeof DELETE_DOCTORFROMCASE;
  payload: SetDoctorTeamType[];
}

export type DeleteDoctorFromCaseTypes = SetDeleteDoctorFromCaseType | DeleteDoctorFromCaseLoading | DeleteDoctorFromCaseSuccess | DeleteDoctorFromCaseFail;