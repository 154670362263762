import React, { useState, useEffect } from "react";
import { AlertColor, Button, Container } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWExceptionList from "../../../component/MWExceptionList";
import {
  GetAdminNoteListByPatientBody,
  NoteList,
} from "../../../redux/Notes/GetNotesListByPateint/Model";
import dayjs from "dayjs";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { getAdminNoteListByPatient } from "../../../redux/Notes/GetNotesListByPateint/API";
import MWSnackbar from "../../../component/MWSnackbar";
import {
  clearNoteAPIRes,
  updateNote,
} from "../../../redux/Notes/updateNoteStatus/API";

type Props = {
  inactiveNoteLoader?: boolean | any;
};

export default function ActivePatientCaseNotes({ inactiveNoteLoader }: Props) {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();

  const getNoteListBody = {
    patientId: patientid,
    caseId: caseid,
    isActive: false,
  } as GetAdminNoteListByPatientBody;

  // Inactive Notes List Api Value from store
  const NoteListFromAPI = useSelector(
    (state: AppState) => state.drNoteListValue
  );
  const [adminNoteList, setAdminNoteList] = useState([] as any[]);
  const [apiRes, setAPIRes] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    inactiveNoteLoader(NoteListFromAPI?.loading);
    if (NoteListFromAPI?.errorMsg !== "") {
      setAPIRes(NoteListFromAPI?.errorMsg);
      setSnackBarType("error");
    }
    if (NoteListFromAPI?.getAdminNoteListByPatientRes?.noteList !== undefined) {
      let noteListItem =
        NoteListFromAPI?.getAdminNoteListByPatientRes?.noteList.map(
          (element: NoteList) => {
            return {
              id: element.id,
              note: element?.note !== undefined ? element?.note : "",
              createdDate:
                element?.createdDate !== undefined
                  ? dayjs(element?.createdDate).format("ddd, MM, DD, YYYY")
                  : "",
              createBy:
                element?.createdBy === undefined
                  ? ""
                  : element?.createdBy?.user?.first_name +
                    " " +
                    element?.createdBy?.user?.last_name,
              modifiedDate:
                element?.modifiedDate !== undefined
                  ? dayjs(element?.modifiedDate).format("ddd, MM, DD, YYYY")
                  : "",
              modifiedBy:
                element?.modifiedBy === undefined
                  ? ""
                  : element?.modifiedBy?.user?.first_name +
                    " " +
                    element?.modifiedBy?.user?.last_name,
            };
          }
        );
      setAdminNoteList(noteListItem);
    } else setAdminNoteList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NoteListFromAPI]);

  const columns: GridColDef[] = [
    { field: "note", headerName: "Note", flex: 1 },
    { field: "createdDate", headerName: "Created Date", flex: 1 },
    {
      field: "createBy",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "id",
      headerName: "Modify Status",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            toggleNoteStatus(params.value);
          }}
          sx={{ textTransform: "none" }}
          disabled={updateNotesVal?.loading}
        >
          Edit
        </Button>
      ),
    },
    { field: "modifiedBy", headerName: "Modified By", flex: 1 },
    { field: "modifiedDate", headerName: "Modified Date", flex: 1 },
  ];

  // Notes Status change API
  function toggleNoteStatus(response: number) {
    dispatch(updateNote(response));
  }
  // update Notes Api Value from store
  const updateNotesVal = useSelector((state: AppState) => state.updateNoteRes);
  useEffect(() => {
    inactiveNoteLoader(updateNotesVal?.loading);
    if (updateNotesVal?.errorMsg !== "") {
      setAPIRes(updateNotesVal?.errorMsg);
      setSnackBarType("error");
    } else if (updateNotesVal?.updateNoteResRes?.message !== undefined) {
      setAPIRes(updateNotesVal?.updateNoteResRes?.message);
      dispatch(getAdminNoteListByPatient(getNoteListBody));
      setSnackBarType("success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNotesVal]);

  useEffect(() => {
    dispatch(getAdminNoteListByPatient(getNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearNoteAPIRes());
    }
  };
  return (
    <Container maxWidth="xl">
      {adminNoteList.length > 0 ? (
        <DataGrid
          rows={adminNoteList}
          columns={columns}
          pageSize={10}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      ) : (
        <MWExceptionList />
      )}
      <MWSnackbar
        msg={apiRes}
        type={snackBarType}
        alertClose={checkAlertClose}
      />
    </Container>
  );
}
