import {
  PATIENT_SEARCH_BY_ORGANIZATION_DATA,
  PATIENT_SEARCH_BY_ORGANIZATION_FAIL,
  PATIENT_SEARCH_BY_ORGANIZATION_LOADING,
  PATIENT_SEARCH_BY_ORGANIZATION_SUCCESS,
  PatientSearchByOrganizationIdActionTypes,
} from "./ActionTypes";
import { PageCount, PatientSearchByOrganizationIdDetails } from "./Model";

export const patientSearchOrganizationIdAction = (
  PatientSearchByOrganizationIdDetails: PatientSearchByOrganizationIdDetails,
  pageCount: PageCount
): PatientSearchByOrganizationIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_ORGANIZATION_DATA,
    payload: PatientSearchByOrganizationIdDetails,
    pageCount: pageCount,
  };
};

export const PatientSearchByOrganizationIdLoadingAction = (
  loading: boolean
): PatientSearchByOrganizationIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_ORGANIZATION_LOADING,
    loading: loading,
  };
};

export const PatientSearchByOrganizationIdSuccessAction = (
  patientSearchResponse: PatientSearchByOrganizationIdDetails
): PatientSearchByOrganizationIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_ORGANIZATION_SUCCESS,
    payload: patientSearchResponse,
  };
};

export const PatientSearchByOrganizationIdErrorAction = (
  patientSearchResponse: PatientSearchByOrganizationIdDetails,
  errMsg: string,
  status: number
): PatientSearchByOrganizationIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_ORGANIZATION_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
export const PatientSearchByOrganizationIdAPIMsgAction = (
  patientSearchResponse: PatientSearchByOrganizationIdDetails,
  errMsg: string,
  status: number
): PatientSearchByOrganizationIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_ORGANIZATION_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};

export const PatientSearchByOrganizationIdAPIResClearAction = (
  patientSearchResponse: PatientSearchByOrganizationIdDetails,
  errMsg: string,
  status: number
): PatientSearchByOrganizationIdActionTypes => {
  return {
    type: PATIENT_SEARCH_BY_ORGANIZATION_FAIL,
    payload: patientSearchResponse,
    message: errMsg,
    status: status,
  };
};
