import {
  PROVIDER_REPORT_LIST_FAIL,
  PROVIDER_REPORT_LIST_LOADING,
  PROVIDER_REPORT_LIST_SUCCESS,
  PROVIDER_REPORT_LIST_API_MSG,
  PROVIDER_REPORT_LIST_API_RES,
  ProviderReportListActionTypes,
} from "./ActionTypes";
import { ReportListResponse } from "./Model";

export const providerReportListLoadingAction = (
  loading: boolean,
): ProviderReportListActionTypes => {
  return {
    type: PROVIDER_REPORT_LIST_LOADING,
    loading: loading,
  };
};

export const providerReportListSuccessAction = (
  providerReportListResponse: ReportListResponse,
): ProviderReportListActionTypes => {
  return {
    type: PROVIDER_REPORT_LIST_SUCCESS,
    payload: providerReportListResponse,
  };
};

export const providerReportListErrorAction = (
  providerReportListResponse: ReportListResponse,
  errMsg: string,
  status: number,
): ProviderReportListActionTypes => {
  return {
    type: PROVIDER_REPORT_LIST_FAIL,
    payload: providerReportListResponse,
    message: errMsg,
    status: status,
  };
};
export const providerReportListUpdateAPIMsgAction = (
  providerReportListResponse: ReportListResponse,
  errMsg: string,
  status: number,
): ProviderReportListActionTypes => {
  return {
    type: PROVIDER_REPORT_LIST_API_MSG,
    payload: providerReportListResponse,
    message: errMsg,
    status: status,
  };
};

export const providerReportListAPIResClearAction = (
  providerReportListResponse: ReportListResponse,
  message: string,
  status: number,
): ProviderReportListActionTypes => {
  return {
    type: PROVIDER_REPORT_LIST_API_RES,
    payload: providerReportListResponse,
    message: message,
    status: status,
  };
};
