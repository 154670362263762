import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  dialogEnable: boolean;
  addDialogUpdateState: (value: boolean) => void;
};

export default function AddDoctorDialog({
  dialogEnable,
  addDialogUpdateState,
}: Props) {
  const handleDialogClose = () => {
    addDialogUpdateState(false);
  };

  return (
    <Box>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={dialogEnable!}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "40vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title">
          Add Doctor
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                label="Email / Phone Number"
                // value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  // setEmail(event.target.value);
                }}
                placeholder="Please email or phone number"
                id="emailPhoneID"
                fullWidth
                size="small"
              />
              {/* <Typography mt={1}>{emailErrordiv}</Typography> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="First Name"
                // value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  // setFirstName(event.target.value);
                }}
                placeholder="Please First Name"
                id="firstNameId"
                fullWidth
                size="small"
              />
              {/* <Typography mt={1}>{firstNameErrordiv}</Typography> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Last Name"
                // value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  // setLastName(event.target.value);
                }}
                placeholder="Please Last Name"
                id="lastNameId"
                fullWidth
                size="small"
              />
              {/* <Typography mt={1}>{lastNameErrordiv}</Typography> */}
            </Grid>
          </Grid>
          <Box px={2}>
            {/* <MWErrorText
              errormessage={errorMsg}
              bannerErrorState={bannerState}
              closeBannerState={closeBanner}
            /> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            // onClick={addPatientSubmit}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
            disabled={true}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
