import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetOriganizationListActionTypes } from "./ActionTypes";
import {
  GetOriganizationListRes,
} from "./Model";
import {
  getOriganizationListAPIResClearAction,
  getOriganizationListErrorAction,
  getOriganizationListLoadingAction,
  getOriganizationListSuccessAction,
  getOriganizationListAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as GetOriganizationListRes;
export const getOrganizationListValue = (
) => {
  return function (
    dispatch: Dispatch<GetOriganizationListActionTypes>
  ) {
    dispatch(getOriganizationListLoadingAction(true));
    axios
      .get(
        Url.baseUrl +
          `/adminapp/api/patientorganizationlist/org_list_where_as_administrator`,
          {
          headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getOriganizationListLoadingAction(false));
        dispatch(getOriganizationListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getOriganizationListLoadingAction(false));
        dispatch(
          getOriganizationListErrorAction(
            {} as GetOriganizationListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const getOrganizationListAPIResMsg = () => {
  return function (
    dispatch: Dispatch<GetOriganizationListActionTypes>
  ) {
    dispatch(
      getOriganizationListAPIMsgAction(
        apiRes as GetOriganizationListRes,
        "",
        0
      )
    );
  };
};

export const clearOrganizationListAPIRes = () => {
  return function (
    dispatch: Dispatch<GetOriganizationListActionTypes>
  ) {
    dispatch(
      getOriganizationListAPIResClearAction(
        {} as GetOriganizationListRes,
        "",
        0
      )
    );
  };
};
