import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { UpdatePatientDataPointActionTypes } from "./ActionTypes";
import { UpdatePatientDataPointBody, UpdatePatientDataPointRes } from "./Model";
import {
  updatePatientDataPointAPIResClearAction,
  updatePatientDataPointErrorAction,
  updatePatientDataPointLoadingAction,
  updatePatientDataPointSuccessAction,
  updatePatientDataPointUpdateAPIMsgAction,
} from "./Actions";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};
let apiRes = {} as UpdatePatientDataPointRes;
export const updatePatientDataPointApi = (
  payload: UpdatePatientDataPointBody
) => {
  return function (dispatch: Dispatch<UpdatePatientDataPointActionTypes>) {
    dispatch(updatePatientDataPointLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/adminapp/api/patientDatapoint/edit_patientDatapoint`,
        payload,
        { headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updatePatientDataPointLoadingAction(false));
        dispatch(updatePatientDataPointSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(updatePatientDataPointLoadingAction(false));
        dispatch(
          updatePatientDataPointErrorAction(
            {} as UpdatePatientDataPointRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updatePatientDataPointEditAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdatePatientDataPointActionTypes>) {
    dispatch(
      updatePatientDataPointUpdateAPIMsgAction(
        apiRes as UpdatePatientDataPointRes,
        "",
        0
      )
    );
  };
};

export const clearPatientDataPointEditAPIRes = () => {
  return function (dispatch: Dispatch<UpdatePatientDataPointActionTypes>) {
    dispatch(
      updatePatientDataPointAPIResClearAction(
        {} as UpdatePatientDataPointRes,
        "",
        0
      )
    );
  };
};
