import {
  PROVIDER_PATIENT_LIST_FAIL,
  PROVIDER_PATIENT_LIST_LOADING,
  PROVIDER_PATIENT_LIST_SUCCESS,
  ADD_PATIENT_API_MSG,
  ADD_PATIENT_API_RES,
  ProviderPatientListActionTypes,
} from "./ActionTypes";
import { ProviderPatientListRes } from "./Model";

export const providerPatientListLoadingAction = (
  loading: boolean,
): ProviderPatientListActionTypes => {
  return {
    type: PROVIDER_PATIENT_LIST_LOADING,
    loading: loading,
  };
};

export const providerPatientListSuccessAction = (
  providerPatientListResponse: ProviderPatientListRes,
): ProviderPatientListActionTypes => {
  return {
    type: PROVIDER_PATIENT_LIST_SUCCESS,
    payload: providerPatientListResponse,
  };
};

export const providerPatientListErrorAction = (
  providerPatientListResponse: ProviderPatientListRes,
  errMsg: string,
  status: number,
): ProviderPatientListActionTypes => {
  return {
    type: PROVIDER_PATIENT_LIST_FAIL,
    payload: providerPatientListResponse,
    message: errMsg,
    status: status,
  };
};
export const providerPatientListUpdateAPIMsgAction = (
  providerPatientListResponse: ProviderPatientListRes,
  errMsg: string,
  status: number,
): ProviderPatientListActionTypes => {
  return {
    type: ADD_PATIENT_API_MSG,
    payload: providerPatientListResponse,
    message: errMsg,
    status: status,
  };
};

export const providerPatientListAPIResClearAction = (
  providerPatientListResponse: ProviderPatientListRes,
  message: string,
  status: number,
): ProviderPatientListActionTypes => {
  return {
    type: ADD_PATIENT_API_RES,
    payload: providerPatientListResponse,
    message: message,
    status: status,
  };
};
