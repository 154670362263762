import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { getPatientDetailsById } from "./Actions";
import {
  PatientDetailsActionType,
  PATIENT_DETAILS_FAIL,
  PATIENT_DETAILS_LOADING,
  PATIENT_DETAILS_SUCCESS,
} from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const getPatientDataDetailsById= (patientDetailsItemid: string) => {
  return function (dispatch: Dispatch<PatientDetailsActionType>) {
    dispatch({
      type: PATIENT_DETAILS_LOADING,
    });

    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/patientDetails/getPatientDetailsFromDashboard`,
        { patientDetailsId: patientDetailsItemid },
        { headers }
      )
      .then((res) => {
        let resData = res.data;
        dispatch(getPatientDetailsById(resData));
        dispatch({
          type: PATIENT_DETAILS_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: PATIENT_DETAILS_FAIL,
        });
      });
  };
};
