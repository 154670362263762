import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import {
  PatientAddOrganizationActionTypes,
  ADD_ORGANIZATION_PATIENT_FAIL,
  ADD_ORGANIZATION_PATIENT_LOADING,
  ADD_ORGANIZATION_PATIENT_SUCCESS,
} from "./ActionTypes";
import {  AddPatientOrganizationRes, AddPatientOrganizationBody } from "./Model";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

export const addOrganizationPatient = (editBody : AddPatientOrganizationBody) => {
  return function (dispatch: Dispatch<PatientAddOrganizationActionTypes>) {
    dispatch({
      type: ADD_ORGANIZATION_PATIENT_LOADING,
    });
    axios
      .post(
        baseUrl.baseUrl + `/caregiverapp/api/healthcamppatient/add_patient_for_healthcamp`,
        editBody,
        {
          headers,
        }
      )
      .then((res) => {
        dispatch({
          type: ADD_ORGANIZATION_PATIENT_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_ORGANIZATION_PATIENT_FAIL,
          payload: {} as AddPatientOrganizationRes,
          errRes: error.response.data.message,
        });
      });
  };
};
