import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { AddPatientDataPointBody, AddPatientPointDataDetails } from "./Model";
import {
  addPatientDataPointAPIResClearAction,
  addPatientDataPointErrorAction,
  addPatientDataPointLoadingAction,
  addPatientDataPointSuccessAction,
  addPatientDataPointUpdateAPIMsgAction,
} from "./Actions";
import { AddPatientDataPointActionTypes } from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Bearer ${userToken}`,
};

let apiRes = {} as AddPatientPointDataDetails;
export const addPatientDataPoint = (payload: AddPatientDataPointBody) => {
  return function (dispatch: Dispatch<AddPatientDataPointActionTypes>) {
    dispatch(addPatientDataPointLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/adminapp/api/patientDatapoint/add_patientDatapoint`,
        payload,
        { headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addPatientDataPointLoadingAction(false));
        dispatch(addPatientDataPointSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(addPatientDataPointLoadingAction(false));
        dispatch(
          addPatientDataPointErrorAction(
            {} as AddPatientPointDataDetails,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const addPatientDataPointUpdateAPIResMsg = () => {
  return function (dispatch: Dispatch<AddPatientDataPointActionTypes>) {
    dispatch(
      addPatientDataPointUpdateAPIMsgAction(
        apiRes as AddPatientPointDataDetails,
        "",
        0
      )
    );
  };
};

export const clearPatientDataPointClearAPIRes = () => {
  return function (dispatch: Dispatch<AddPatientDataPointActionTypes>) {
    dispatch(
      addPatientDataPointAPIResClearAction(
        {} as AddPatientPointDataDetails,
        "",
        0
      )
    );
  };
};
