import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { Redirect, useParams, useHistory } from "react-router-dom";
import {
  addSpecialistDoctor,
  deleteSpecialistDoctor,
  editDoctorTeamType,
  getaddedSpecialistDoctor,
  getSpecialistDoctorList,
  selectedDoctorTeamTypeList,
} from "../../redux/Patient/AppointmentSelectDoctor/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import MWToast from "../../component/MWToast";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { getDoctorTeamTypeList } from "../../redux/Patient/AppointmentSelectDoctor/DoctorTeamTypeList/ApiCall";
import { GetDoctorTeamTypeListBody } from "../../redux/Patient/AppointmentSelectDoctor/DoctorTeamTypeList/Model";

export default function AppointmentSelectDoctor(props: any)  {
  const [toSignin] = useState(false);
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };

  const { appointmentDoctorPageLoader } = props;
  const [doctorName, setDoctorName] = useState("");
  const [selectedDoctorId, setSelectedDoctorId] = useState("");
  const [doctorList, setDoctorList] = useState<any[]>([]);
  const [value, setValue] = useState() as any;
  const [getdoctorValue, setdoctorValue] = useState() as any;
  const [radiovalue, setRadioValue] = React.useState("");

  const [isEdit, setIsEdit] = useState(true);
  const [isEnableSubmit, setEnableSubmit] = useState(true);

  const closeDoctortypeSuccess = () => {
    setDoctorTypeSuccess("");
  };

  const [doctorTypeSuccess, setDoctorTypeSuccess] = useState("");
  const successDiv = doctorTypeSuccess ? (
    <Alert
      severity="success"
      onClose={() => {
        closeDoctortypeSuccess();
      }}
    >
      <Typography>{doctorTypeSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const closeDoctortypeError = () => {
    setDoctorTypeError("");
  };
  const [doctorTypeError, setDoctorTypeError] = useState("");
  const errorDoctorTypeDiv = doctorTypeError ? (
    <Alert
      severity="error"
      onClose={() => {
        closeDoctortypeError();
      }}
    >
      <Typography>{doctorTypeError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [selectedDocName, setSelectedDocName] = useState<any | null>(null);
  const handleDocNameChange = (_e: any, dropdownDayName: any) => {
    setSelectedDocName(dropdownDayName);
  };

  // Add Specialist success toast
  const [successToastActive, setSuccessToastActive] = useState(false);

  // Delete Specialist success toast
  const [deletesuccessToastActive, setDeleteSuccessToastActive] =
    useState(false);

  const [modalActive, setModalActive] = useState(false);
  const handleToggle = () => {
    setModalActive((modalActive) => !modalActive);
    setDoctorTypeSuccess("");
    setDoctorTypeError("");
  };
  function deleteDoctor(doctorId: any, doctorName: any) {
    setModalActive((modalActive) => !modalActive);
    setDoctorName(doctorName);
    setSelectedDoctorId(doctorId);
    setDoctorTypeSuccess("");
    setDoctorTypeError("");
  }

  const [categoryValues, setCategoryValues] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

  const handleSelectedCategoryChange = (_e: any, dropdownDayName: any) => {
    setSelectedCategory(dropdownDayName);
    if (selectedCategory === "" || selectedCategory === null) {
      setDoctorList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const history = useHistory();
  const getDoctorTeamTypeListBody = {
    caseId: caseid,
  } as GetDoctorTeamTypeListBody;

  // Get DoctorTeamType List Api call
  const getDocTeamtype = () => {
    dispatch(getDoctorTeamTypeList(getDoctorTeamTypeListBody));
  };

  // Get Doctor team Type list store
  const DoctorTeamTypeListFromAPI = useSelector(
    (state: AppState) => state.doctorTeamTypeListValue
  );
  useEffect(() => {
    appointmentDoctorPageLoader(DoctorTeamTypeListFromAPI?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DoctorTeamTypeListFromAPI]);

  const listapiValues =
    DoctorTeamTypeListFromAPI.getDoctorTeamTypeListRes.doctorTeamTypeList;
  let doctorTeamTypeListarray: Array<{ value: number; label: string }> = [];
  if (listapiValues !== undefined) {
    if (listapiValues.length > 0) {
      let values = listapiValues;
      values.forEach((element: { value: any; label: any }) => {
        doctorTeamTypeListarray.push({
          value: element.value,
          label: element.label,
        });
      });
    }
  }

  let doctorTeamTypeList: any[] = doctorTeamTypeListarray;

  // get SelectedDoctorTeamType api call
  const getDocSelectedTeamtype = () => {
    appointmentDoctorPageLoader(true);
    dispatch(selectedDoctorTeamTypeList(caseid));
  };

  // SelectedDoctorTeamType value
  const SelectedDoctorTeamTypeListFromAPI = useSelector(
    (state: AppState) => state.selectedDoctorTeamType
  );
  const selectedDoctor = SelectedDoctorTeamTypeListFromAPI.response;
  console.log("SELECTEDDOCTOR", selectedDoctor);

  // Doctor Team Type Edit Body
  const doctorTypeEditBody = {
    caseId: caseid,
    doctorTeamTypeId: String(value),
  };

  // doctor type submit
  const doctorTypeSubmit = () => {
    appointmentDoctorPageLoader(true);
    dispatch(editDoctorTeamType(doctorTypeEditBody));
  };

  // Speciality List dropdown api call.........
  const getCategoryList = () => {
    appointmentDoctorPageLoader(true);
    dispatch(getSpecialistDoctorList());
  };

  // Get Speciality List dropdown Value from store
  const SpecialistDoctorValue = useSelector(
    (state: AppState) => state.specialist.response
  );

  // Get Specialist Doctor List dropdown Value from store
  const SelectedSpecialistDoctorValue = useSelector(
    (state: AppState) => state.specialistDoctor.response
  );

  // Doctor Add api body
  const doctorAddBody = {
    caseId: caseid,
    doctorId: selectedDocName,
  };

  // Add Doctor in Patient case API
  const handleAddDoctor = () => {
    appointmentDoctorPageLoader(true);
    dispatch(addSpecialistDoctor(doctorAddBody));
    setSuccessToastActive((successToastActive) => !successToastActive);
  };

  const addSuccessMarkup = successToastActive ? (
    <MWToast message="Doctor added successfully." />
  ) : null;

  const AddSpecialistDoctorStatus = useSelector(
    (state: AppState) => state.addSpecialist.response.status
  );

  // Get Selected Doctor API
  const getSelectedDoc = () => {
    appointmentDoctorPageLoader(true);
    dispatch(getaddedSpecialistDoctor(caseid));
  };

  // Added doctor Api Value from store
  const DoctorListFromAPI = useSelector(
    (state: AppState) => state.getaddedDoctor
  );
  const DoctorListDetailsResponse = DoctorListFromAPI.response;

  const columns: GridColDef[] = [
    {
      field: "DoctorName",
      headerName: "Dr. Name",
      width: 350,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="text"
          onClick={() => {
            history.push(`/doctordetails/${params.row.id}`);
          }}
        >
          {params.row.firstname || null} {params.row.lastname || null}
        </Button>
      ),
      valueGetter: (params) => {
        return ``;
      },
    },
    { field: "qualification", headerName: "Qualification", width: 200 },
    { field: "speciality", headerName: "Speciality", width: 350 },
    {
      field: "requestStatus",
      headerName: "Appoinment Status",
      width: 250,
    },
    {
      field: "id",
      headerName: "Delete",
      width: 250,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DeleteIcon />}
          variant="outlined"
          onClick={() => {
            deleteDoctor(params.row.id, params.row.firstname);
          }}
        ></Button>
      ),
    },
  ];

  // Delete Doctor Body
  const doctorDeleteBody = {
    caseId: caseid,
    doctorId: selectedDoctorId,
  };

  // doctor Delete submit
  const deleteDoctorAPI = () => {
    appointmentDoctorPageLoader(true);
    dispatch(deleteSpecialistDoctor(doctorDeleteBody));
    setDeleteSuccessToastActive(
      (deletesuccessToastActive) => !deletesuccessToastActive
    );
    setModalActive((modalActive) => !modalActive);
  };

  // Delete Specialist suucess response Value from store
  const DeleteSpecialistDoctor = useSelector(
    (state: AppState) => state.deletedoctor.response.message
  );
  console.log("DeleteSpecialistDoctor", DeleteSpecialistDoctor);

  const deleteSuccessMarkup = deletesuccessToastActive ? (
    <MWToast message={DeleteSpecialistDoctor} />
  ) : null;

  const DeleteSpecialistDoctorStatus = useSelector(
    (state: AppState) => state.deletedoctor.response.status
  );
  console.log("DeleteSpecialistDoctorStatus", DeleteSpecialistDoctorStatus);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDocTeamtype();
    getCategoryList();
    getDocSelectedTeamtype();
    getSelectedDoc();
    if (selectedDoctor !== 0) {
      setValue(selectedDoctor);
      setdoctorValue(selectedDoctor);
    }
    setCategoryValues(SpecialistDoctorValue);
    setDoctorList(SelectedSpecialistDoctorValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoctor, SelectedSpecialistDoctorValue]);

  const doctorAddInfo = (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          {getdoctorValue ? (
            <Card>
              <CardHeader title="Doctor Team Type" />
              <CardContent>
                {doctorTeamTypeList.map((doctorTeam) => (
                  <FormControl
                    key={doctorTeam.value}
                    disabled={isEdit}
                    fullWidth
                    variant="outlined"
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setValue((event.target as HTMLInputElement).value)
                      }
                    >
                      <FormControlLabel
                        value={doctorTeam.value}
                        control={<Radio />}
                        label={doctorTeam.label}
                      />
                    </RadioGroup>
                  </FormControl>
                ))}
                <Box p={1}>{successDiv}</Box>
                <Box p={1}>{errorDoctorTypeDiv}</Box>
              </CardContent>

              <CardActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        setIsEdit((isEdit) => !isEdit);
                        setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                      }}
                    >
                      {isEdit ? "Edit" : "Cancel"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => doctorTypeSubmit()}
                      disabled={isEnableSubmit}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          ) : (
            <Card>
              <CardHeader title="Doctor Team Type" />
              <CardContent>
                <FormControl fullWidth variant="outlined">
                  {doctorTeamTypeList.map((doctorTeam) => (
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={radiovalue}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setRadioValue(
                            (event.target as HTMLInputElement).value
                          )
                        }
                      >
                        <FormControlLabel
                          value={doctorTeam.value}
                          control={
                            <Radio
                              checked={value === doctorTeam.value}
                              disabled={isEdit}
                            />
                          }
                          label={doctorTeam.label}
                        />
                      </RadioGroup>
                    </FormControl>
                  ))}
                </FormControl>
                <Box p={1}>{successDiv}</Box>
                <Box p={1}>{errorDoctorTypeDiv}</Box>
              </CardContent>

              <CardActions>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => doctorTypeSubmit()}
                      disabled={isEnableSubmit}
                    >
                      Select Doctor Team
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          )}
        </Grid>
        <Grid item>
          <Card>
            <CardHeader title="Select Doctor" />
            <Box p={5}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} sm={4}>
                  <Box style={{ marginBottom: "5px" }}>Category</Box>
                  <Autocomplete
                    value={selectedCategory}
                    id="templateId"
                    options={categoryValues}
                    onChange={(event: any, newValue: any) => {
                      handleSelectedCategoryChange(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="select category" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={9} sm={11} lg={11}>
                      <Box style={{ marginBottom: "5px" }}>Select Doctor</Box>
                      <Autocomplete
                        value={selectedDocName}
                        id="templateId"
                        options={doctorList}
                        onChange={(event: any, newValue: any) => {
                          handleDocNameChange(event, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="select Doctor" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3} sm={1} lg={1}>
                      <Box pt={4}>
                        <Button
                          variant="outlined"
                          size="medium"
                          onClick={() => handleAddDoctor}
                          sx={{ textTransform: "none" }}
                        >
                          Add
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box>{addSuccessMarkup}</Box>
            </Box>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader title="Selected Doctor List">
              <Button
                variant="outlined"
                size="medium"
                startIcon={<RefreshIcon />}
                onClick={() => getSelectedDoc}
                sx={{ textTransform: "none" }}
              >
                Reload Doctor List
              </Button>
            </CardHeader>
            <CardContent>
              <Paper style={{ maxHeight: 220, overflow: "auto" }}>
                {DoctorListDetailsResponse &&
                DoctorListDetailsResponse.length ? (
                  <DataGrid
                    rows={DoctorListDetailsResponse}
                    columns={columns}
                    pageSize={10}
                    hideFooter
                    hideFooterPagination
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                      toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                  />
                ) : (
                  <MWExceptionList />
                )}
              </Paper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  const deleteDoctorModal = (
    <Dialog open={modalActive} onClose={handleToggle} fullWidth>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography>Delete Doctor</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleToggle}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography style={{ fontWeight: "bold" }}>
            Are you sure, you want to delete this "{doctorName}" from your
            doctor team ?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={handleToggle}>
          No
        </Button>
        <Button variant="contained" size="medium" onClick={deleteDoctorAPI}>
          Yes
        </Button>
      </DialogActions>
      <Box p={1}>{deleteSuccessMarkup}</Box>
    </Dialog>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        {toSignin ? <Redirect to="/signin" /> : null}
        {doctorAddInfo}
        {deleteDoctorModal}
        {AddSpecialistDoctorStatus! === 1 ? addSuccessMarkup : ""}
        {DeleteSpecialistDoctorStatus! === 1 ? deleteSuccessMarkup : ""}
      </Container>
    </Box>
  );
}
