import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Pagination,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdminLoader from "./AdminLoader";
import dayjs from "dayjs";
import MWExceptionList from "../component/MWExceptionList";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import MWPageTitle from "../component/MWPageTitle";
import { ProviderPatientListBody } from "../redux/patientList/Model";
import { providerPatientList } from "../redux/patientList/API";
import PageLayout from "./Layout/PageLayout";
import AddPatientDialog from "./PatientListByHealthCamp/AddPatientDialog";

export default function PatientListAdmin() {
  const dispatch = useDispatch();
  const history = useHistory();

  // call Store
  const AdminPatientList = useSelector(
    (state: AppState) => state.providerPatientListValue
  );
  const addPatientValueRes = useSelector(
    (state: AppState) => state.addHealthcampPatientData
  );
  const patientListRes = AdminPatientList?.providerOrgListRes;

  const [listCount, setListCount] = React.useState("");
  const [patientList, setPatientList] = useState([] as any[]);
  const [pageListCount, setPageListCount] = React.useState("");
  const [addHealthCampPatientSuccess, setAddHealthCampPatientSuccess] =
    useState("");
  const [bannerState, setBannerState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      providerPatientList(
        gePatientListApiBody as ProviderPatientListBody,
        value
      )
    );
  };

  const gePatientListApiBody = {
    providerorgId: localStorage.getItem("orgId"),
  };

  function gotoDetails(patientId: number) {
    history.push(`/patientdetails/${patientId}`);
  }

  const [showAddPatientDialog, setShowAddPatientDialog] = React.useState(false);
  const updateDialogState = () => {
    setShowAddPatientDialog(true);
  };
  const updateAddPatientDialog = (passedVal: boolean) => {
    setShowAddPatientDialog(passedVal);
  };

  const closeBanner = (passedVal: boolean) => {
    setBannerState(passedVal);
    setAddHealthCampPatientSuccess("");
  };

  useEffect(() => {
    dispatch(
      providerPatientList(gePatientListApiBody as ProviderPatientListBody, 1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // const truncate = (str: string) => {
  //   return str.length > 20 ? str.substring(0, 25) + "..." : str;
  // };

  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(
        providerPatientList(
          gePatientListApiBody as ProviderPatientListBody,
          page
        )
      );
    }
  };

  useEffect(() => {
    patientListRes?.numberOfPages !== undefined
      ? setPageListCount(String(patientListRes?.numberOfPages))
      : setPageListCount("0");
    if (
      patientListRes?.patientList !== undefined &&
      patientListRes?.patientList.length > 0
    ) {
      let ptList = patientListRes?.patientList.map((element: any) => ({
        id: element.patientId,
        firstName: element?.givenName !== undefined ? element?.givenName : "",
        lastName: element?.familyName !== undefined ? element?.familyName : "",
        dob: dayjs(element.dob).format("MMM D, YYYY"),
      }));
      setPatientList(ptList);
    } else setPatientList([] as any[]);
  }, [patientListRes]);

  useEffect(() => {
    AdminPatientList?.providerOrgListRes?.count !== undefined
      ? setListCount(String(AdminPatientList?.providerOrgListRes?.count))
      : setListCount("0");
  }, [AdminPatientList]);

  useEffect(() => {
    if (addPatientValueRes?.successMsg !== "") {
      setAddHealthCampPatientSuccess(addPatientValueRes?.successMsg);
      setBannerState(true);
    }
    if (addPatientValueRes?.errorMsg !== "") {
      setErrorMsg(addPatientValueRes?.errorMsg);
      setBannerState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientValueRes]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoDetails(params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "id",
      headerName: "Patient Id",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "dob",
      headerName: "Date Of Birth",
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const patientListTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount) || 0}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {patientList && patientList.length ? (
          <DataGrid
            rows={patientList}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminPatientList.loading ? <AdminLoader /> : null}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <MWPageTitle
              backButton={false}
              title={"Patient List"}
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={reloadActionFromChild}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => updateDialogState()}
              sx={{ textTransform: "none" }}
            >
              Add New Patient
            </Button>
          </Grid>
        </Grid>
        {patientListTable}
      </PageLayout>
      {showAddPatientDialog ? (
        <AddPatientDialog
          // itemId={healthcampid}
          addPatientDialogEnable={showAddPatientDialog}
          addPatientDialogUpdateState={updateAddPatientDialog}
        />
      ) : null}
      {addHealthCampPatientSuccess !== "" ? (
        <Snackbar
          open={bannerState}
          autoHideDuration={3000}
          onClose={() => closeBanner(false)}
        >
          <Alert
            variant="filled"
            severity="success"
            onClose={() => closeBanner(false)}
            sx={{ width: "100%" }}
          >
            {addHealthCampPatientSuccess}
          </Alert>
        </Snackbar>
      ) : null}
      {errorMsg !== "" ? (
        <Snackbar
          open={bannerState}
          autoHideDuration={3000}
          onClose={() => closeBanner(false)}
        >
          <Alert
            variant="filled"
            severity="error"
            onClose={() => closeBanner(false)}
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  );
}
