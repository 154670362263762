import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import Url from "../../../ApiConfig";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { appointmentListAction, confirmAppointmentAction, paymentListAction, paymentStatusAction } from "./Actions";
import {
    AppointmentConfirmTypes,
    AppointmentListTypes, APPOINTMENT_CONFIRM_FAIL, APPOINTMENT_CONFIRM_LOADING, APPOINTMENT_CONFIRM_SUCCESS, APPOINTMENT_LIST_FAIL, APPOINTMENT_LIST_LOADING, APPOINTMENT_LIST_SUCCESS, CheckPaymentStatusTypes, CHECK_PAYMENT_STATUS_FAIL, CHECK_PAYMENT_STATUS_LOADING, CHECK_PAYMENT_STATUS_SUCCESS, PaymentListTypes,
    PAYMENT_LIST_FAIL, PAYMENT_LIST_LOADING, PAYMENT_LIST_SUCCESS
} from "./ActionTypes";

const userToken = localStorage.getItem("access");
const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${userToken}`,
};

// Get Payment List
export const getAppointmentPaymentList = (body: object) => {
    return function (dispatch: Dispatch<PaymentListTypes>) {
        dispatch({
            type: PAYMENT_LIST_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl +
            `/paymentapp/api/payment/get_payment_link`,
            body, { headers })
            .then((res) => {
                let consultationType = res.data.consultationType;
                let responseTimeType = res.data.responseTimeType;
                let doctorTeamType = res.data.doctorTeamType;
                let paymentList = res.data.paymentList;
                let message = res.data.message;
                let status = res.data.status;
                dispatch(paymentListAction(
                    consultationType, responseTimeType, doctorTeamType, paymentList, message, status
                ));
                dispatch({
                    type: PAYMENT_LIST_SUCCESS,
                    paymentList: paymentList,
                    consultationType: consultationType,
                    responseTimeType: responseTimeType,
                    doctorTeamType: doctorTeamType,
                    message: message,
                    status: status
                });
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.message;
                dispatch({
                    type: PAYMENT_LIST_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};

// Get Payment List
export const getAppointmentList = (body: object) => {
    return function (dispatch: Dispatch<AppointmentListTypes>) {
        dispatch({
            type: APPOINTMENT_LIST_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl +
            `/appointmentapp/api/appointment/get_appointment_details_for_case`,
            body, { headers })
            .then((res) => {
                let consultationType = res.data.consultationType;
                let responseTimeType = res.data.responseTimeType;
                let doctorTeamType = res.data.doctorTeamType;
                let appointmentList = res.data.appointmentRequestList;
                let message = res.data.message;
                let status = res.data.status;
                dispatch(appointmentListAction(
                    consultationType, responseTimeType, doctorTeamType, appointmentList, message, status
                ));
                dispatch({
                    type: APPOINTMENT_LIST_SUCCESS,
                    appointmentList: appointmentList,
                    consultationType: consultationType,
                    responseTimeType: responseTimeType,
                    doctorTeamType: doctorTeamType,
                    message: message,
                    status: status
                });
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.message;
                dispatch({
                    type: APPOINTMENT_LIST_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};
// Check Payment Status
export const checkPaymentStatus = (paymentId: object) => {
    return function (dispatch: Dispatch<CheckPaymentStatusTypes>) {
        dispatch({
            type: CHECK_PAYMENT_STATUS_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl +
            `/paymentapp/api/payment/get_payment_status`,
            { "paymentId": paymentId }, { headers })
            .then((res) => {
                let message = res.data.message;
                let status = res.data.status;
                dispatch(paymentStatusAction(
                    message, status
                ));
                dispatch({
                    type: CHECK_PAYMENT_STATUS_SUCCESS,
                    message: message,
                    status: status
                });
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: CHECK_PAYMENT_STATUS_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};
// Check Payment Status
export const confirmAppointment = (appointmentId: object) => {
    return function (dispatch: Dispatch<AppointmentConfirmTypes>) {
        dispatch({
            type: APPOINTMENT_CONFIRM_LOADING,
        });
        setupInterceptorsTo(axios).post(Url.baseUrl +
            `/adminapp/api/adminActions/confirm_appointment_for_patient_case`,
            { "appointmentRequestId": appointmentId }, { headers })
            .then((res) => {
                let message = res.data.message;
                let status = res.data.status;
                dispatch(confirmAppointmentAction(
                    message, status
                ));
                dispatch({
                    type: APPOINTMENT_CONFIRM_SUCCESS,
                    message: message,
                    status: status
                });
            })
            .catch((error) => {
                let message = error.response.data.message;
                let status = error.response.data.status;
                dispatch({
                    type: APPOINTMENT_CONFIRM_FAIL,
                    message: message,
                    status: status
                });
            });
    };
};