import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import AdminLoader from "../../AdminLoader";
import {
  Pagination,
  Typography,
  Box,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { getExtractObservationByPatientId } from "../../../redux/getExtractObservationByPatientId/API";
import { getPatientCaseList } from "../../../redux/effects/ApiCall";
import { ObservationList } from "../../../redux/getExtractObservationByPatientId/Model";
import MWExceptionList from "../../../component/MWExceptionList";
import MWPageTitle from "../../../component/MWPageTitle";

export default function DataObservationList() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();

  const { patientid } = useParams() as {
    patientid: string;
  };
  const [page, setPage] = React.useState(1);
  const getExtractObservationValue = useSelector(
    (state: AppState) => state.getExtractObservationValue
  );

  // extract observation list api call ...
  useEffect(() => {
    dispatch(getExtractObservationByPatientId(patientid, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const caseHistoryPtName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName
  );
  const [patientName, setPatientName] = React.useState("");
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");
  const [listCount, setListCount] = React.useState("");
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(getExtractObservationByPatientId(patientid, page));
    }
  };
  useEffect(() => {
    patientName !== ""
      ? setPageTitle(`Patient Observation List of ${patientName}`)
      : setPageTitle("");
  }, [patientName]);

  useEffect(() => {
    if (caseHistoryPtName === undefined) {
      dispatch(getPatientCaseList(patientid));
    }
    if (caseHistoryPtName !== undefined) {
      setPatientName(caseHistoryPtName);
    } else setPatientName("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseHistoryPtName]);

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getExtractObservationByPatientId(patientid, value));
  };

  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    getExtractObservationValue?.pageCount !== undefined
      ? setPageListCount(String(getExtractObservationValue?.pageCount))
      : setPageListCount("0");

    getExtractObservationValue?.getExtractObservationRes?.count !== undefined
      ? setListCount(
          String(getExtractObservationValue?.getExtractObservationRes?.count)
        )
      : setListCount("0");

    if (
      getExtractObservationValue?.getExtractObservationRes?.observationList !==
      undefined
    ) {
      let dataPointArr =
        getExtractObservationValue?.getExtractObservationRes?.observationList.map(
          (element: ObservationList) => ({
            id: element.id,
            value: element.value,
            startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY"),
            displayValue: element.patientObservationType.displayValue,
            modifiedDate: dayjs(element.modifiedDate).format(
              "dddd, MMMM D, YYYY"
            ),
          })
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(String(getExtractObservationValue?.pageCount));
    }
  }, [getExtractObservationValue]);

  const columns: GridColDef[] = [
    {
      field: "displayValue",
      headerName: "Observation Name",
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "value",
      headerName: "Observation Value",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
  ];

  const patientPointDataTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handleChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {dataGridArray && dataGridArray.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {getExtractObservationValue.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={pageTitle}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={reloadActionFromChild}
              />
            </Grid>
          </Grid>
          {patientPointDataTable}
        </Stack>
      </Container>
    </Box>
  );
}
