import {
  PATIENT_SEARCH_DATA,
  PATIENT_SEARCH_FAIL,
  PATIENT_SEARCH_LOADING,
  PATIENT_SEARCH_SUCCESS,
  UpdatePatientDataPointActionTypes,
} from "./ActionTypes";
import { UpdatePatientDataPointRes } from "./Model";

export const updatePatientDataPointAction = (
  updatePatientDataPointRes: UpdatePatientDataPointRes
): UpdatePatientDataPointActionTypes => {
  return {
    type: PATIENT_SEARCH_DATA,
    payload: updatePatientDataPointRes,
  };
};

export const updatePatientDataPointLoadingAction = (
  loading: boolean
): UpdatePatientDataPointActionTypes => {
  return {
    type: PATIENT_SEARCH_LOADING,
    loading: loading,
  };
};

export const updatePatientDataPointSuccessAction = (
  updatePatientDataPointResponse: UpdatePatientDataPointRes
): UpdatePatientDataPointActionTypes => {
  return {
    type: PATIENT_SEARCH_SUCCESS,
    payload: updatePatientDataPointResponse,
  };
};

export const updatePatientDataPointErrorAction = (
  updatePatientDataPointResponse: UpdatePatientDataPointRes,
  errMsg: string,
  status: number
): UpdatePatientDataPointActionTypes => {
  return {
    type: PATIENT_SEARCH_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
export const updatePatientDataPointUpdateAPIMsgAction = (
  updatePatientDataPointResponse: UpdatePatientDataPointRes,
  errMsg: string,
  status: number
): UpdatePatientDataPointActionTypes => {
  return {
    type: PATIENT_SEARCH_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};

export const updatePatientDataPointAPIResClearAction = (
  updatePatientDataPointResponse: UpdatePatientDataPointRes,
  errMsg: string,
  status: number
): UpdatePatientDataPointActionTypes => {
  return {
    type: PATIENT_SEARCH_FAIL,
    payload: updatePatientDataPointResponse,
    message: errMsg,
    status: status,
  };
};
