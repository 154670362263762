import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import ActiveDrNotes from "./ActivePatientCaseNotes";
import InActiveDrNotes from "./InActivePatientCaseNotes";
import AdminLoader from "../../AdminLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MWPageTitle from "../../../component/MWPageTitle";
import PageLayout from "../../Layout/PageLayout";

export default function Task() {
  const loadingMarkup = <AdminLoader />;
  const [activeNoteLoader, setActiveNoteLoader] = useState(false);
  const [inactiveNoteLoader, setInactiveNoteLoader] = useState(false);
  const [selected, setSelected] = useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  return (
    <Box>
      {activeNoteLoader === true || inactiveNoteLoader === true
        ? loadingMarkup
        : null}
      <PageLayout>
        <MWPageTitle backButton={true} title="Patient Notes" />
        <TabContext value={selected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab label="Active Note List" value="1" />
              <Tab label="Inactive Note List" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {<ActiveDrNotes activeNoteLoader={setActiveNoteLoader} />}
          </TabPanel>
          <TabPanel value="2">
            {<InActiveDrNotes inactiveNoteLoader={setInactiveNoteLoader} />}
          </TabPanel>
        </TabContext>
      </PageLayout>
    </Box>
  );
}
